import { SubscriptionFilter } from './../../../models/subscription-filter';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CreateSubscriptionFilterComponent } from '../../../models/create-subscription-filter-component';
import { SubscriptionFilterChangedEvent } from '../../../models/subscription-filter-changed-event';
import { Supplier } from '../../../models/supplier';
import { selectSupplierOptions } from '../../../store/data-exchange.selectors';
import { SubscriptionFilterKey } from '../../../models/enum/subscription-filter-key';

@Component({
  selector: 'app-inbound-fill-rate-filter',
  templateUrl: './inbound-fill-rate-filter.component.html',
  styleUrls: []
})
export class InboundFillRateFilterComponent implements OnInit, CreateSubscriptionFilterComponent {

  supplierOptions$: Observable<Supplier[]>;
  selectedSupplier: Supplier = null;
  inboundFillRateUpperLimit: number = null;
  min = .000000000000000001;
  max = 1;

  @Output()
  filterChanged = new EventEmitter<SubscriptionFilterChangedEvent>();

  constructor(private _store: Store) { }


  ngOnInit() {
    this.supplierOptions$ = this._store.pipe(select(selectSupplierOptions));
  }

  reset() {
    this.selectedSupplier = null;
    this.inboundFillRateUpperLimit = null;
    this.publishFilterChangedEvent();
  }

  onSupplierSelected() {
    this.publishFilterChangedEvent();
  }

  isFormValid(): boolean {
    return !!this.selectedSupplier &&
    !!this.inboundFillRateUpperLimit &&
    +this.inboundFillRateUpperLimit > 0 &&
    +this.inboundFillRateUpperLimit <= 1;
  }

  onFillRateLimitChanged() {
    this.publishFilterChangedEvent();
  }

  onFillRateFocus() {
    this.publishFilterChangedEvent(false);
  }

  getFilters(): SubscriptionFilter[] {
    return [
      { key: SubscriptionFilterKey.FillRateUpperLimit, value: [this.inboundFillRateUpperLimit.toString()] },
    ];
  }

  publishFilterChangedEvent(validState = true): void {
    const formValid = this.isFormValid() && validState;

    this.filterChanged.emit({
      filtersValid: formValid,
      selectedSupplier: this.selectedSupplier,
      filters: formValid ? this.getFilters() : [],
    });
  }
}
