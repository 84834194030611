import { AlertMessage } from './../../../../models/alert-message';
import { HelpCenterGroup } from './../../models/enum/help-center-group';
import { environment } from './../../../../../environments/environment';
import { ReportParameters } from './../../models/report-parameters';
import { IDropdownOption } from '@app/models/idropdown-option';
import { AlertEvents } from '@kehe/phoenix-notifications';
import { map, takeUntil } from 'rxjs/operators';
import { selectReport, selectDefault, loadPage, alertClosed } from './../../store/reporting.actions';
import {
  selectSelectedReport,
  selectReportOptions,
  selectReportsVisible,
  selectReportParameters,
  selectAlertMessage,
} from './../../store/reporting.selectors';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { DestroyableDirective } from '../../../../abstract/destroyable';
import { ReportName } from '../../models/enum/report-name.enum';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PermissionsService } from '@app/services/permissions.service';
import { ExternalVideo } from '../../../../models/external-video';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss']
})
export class ReportingPageComponent extends DestroyableDirective  implements OnInit {

  constructor(
    private _store: Store,
    private _sanitizer: DomSanitizer,
    private _permissionsService: PermissionsService
  ) { super(); }

  alert$: Observable<AlertMessage>;
  reportOptions$: Observable<IDropdownOption[]>;
  reportsVisible$: Observable<boolean>;
  reportParameters$: Observable<ReportParameters>;
  defaultItem = { label: 'Select a Report', value: null };
  reportUrl: SafeResourceUrl = null;
  showHelpModal = false;
  helpVideos: ExternalVideo[] = [
    {
      videoTitle: 'Get to Know Available Reporting',
      vimeoId: '502637404/3fefc2e8b2',
      group: HelpCenterGroup.ConnectBi,
    },
  ];

  ngOnInit() {
    this._store.dispatch(loadPage());
    this.alert$ = this._store.pipe(select(selectAlertMessage));
    this.reportOptions$ = this._store.pipe(
      select(selectReportOptions),
      map(reports => (
        [this.defaultItem].concat(reports
          .filter( report =>
            report.url !== null &&
            this._permissionsService.userHasAction(report.requiredResourceAction)
          )
          .map( report => ({
            value: `${environment.ssrsReportUrlPrefix}/${report.url}?v2=true`,
            label: ReportName.toString(report.type)
          })))
      ))
    );

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedReport)
    ).subscribe(selectedReport => {
      if (!!selectedReport) {
        this.reportUrl = this._sanitizer.bypassSecurityTrustResourceUrl(selectedReport.url);
      }
    });

    this.reportsVisible$ = this._store.pipe(select(selectReportsVisible));
    this.reportParameters$ = this._store.pipe(select(selectReportParameters));
  }

  handleAlertEvents(e: { type: string }) {
    if (e.type === AlertEvents.Closed) {
      this._store.dispatch(alertClosed());
    }
  }

  reportSelectionChanged(event: IDropdownOption): void {
    if (event.label === this.defaultItem.label) {
      this._store.dispatch(selectDefault());
    } else {
      this._store.dispatch(selectReport({
        selectedReport: {
          type: ReportName.parse(event.label),
          url: event.value
        }
      }));
    }
  }
}
