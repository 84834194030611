import { on, createReducer } from "@ngrx/store";
import * as DeviceActions from "@app/device/device.actions";
import { DeviceInfo } from "ngx-device-detector";

export const deviceKey = 'device';

export interface State {
    deviceInfo: DeviceInfo;
    isDesktop: boolean;
    isMobile: boolean;
    isTablet: boolean;
}

export const initialState: State = {
    deviceInfo: null,
    isDesktop: false,
    isMobile: false,
    isTablet: false,
};

export const reducer = createReducer(
    initialState,
    on(DeviceActions.getDeviceInfoSuccess, (state, action) => {
        return {
            ...state,
            deviceInfo: action.deviceInfo,
            isDesktop: action.isDesktop,
            isMobile: action.isMobile,
            isTablet: action.isTablet,
        };
    }),
);

export const getDeviceInfo = (state: State) => state && state.deviceInfo;
export const getIsDesktop = (state: State) => state.isDesktop;
export const getIsMobile = (state: State) => state.isMobile;
export const getIsTablet = (state: State) => state.isTablet;