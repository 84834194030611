import { Action, on, createReducer } from "@ngrx/store";
import { TreatmentsWithConfig } from "@splitsoftware/splitio/types/splitio";
import * as FeatureFlagActions from "../../../feature-flag/feature-flag.actions";
import * as FeatureFlags from "../../../feature-flag/index";

export const featureFlagKey = 'featureFlag';

export interface State {
    featureFlags: TreatmentsWithConfig,
    haveFeatureFlagsBeenFetched: boolean,
}

export const initialState: State = {
    featureFlags: FeatureFlags.mapFeatureFlagsToSplitTreatmentsWithConfig(),
    haveFeatureFlagsBeenFetched: false,
};

const rootReducer = createReducer(
    initialState,
    on(FeatureFlagActions.getFeatureFlagSuccess, (state, action) => {
        return {
            ...state,
            featureFlags: action.featureFlags,
            haveFeatureFlagsBeenFetched: true,
        };
    }),
    on(FeatureFlagActions.refreshFeatureFlagSuccess, (state, action) => {
        return {
            ...state,
            featureFlags: action.featureFlags,
            haveFeatureFlagsBeenFetched: true,
        };
    }),
);

// note: this function can go away once ngrx is upgraded and the rootReducer can be renamed to `reducer` and exported
export function reducer(state: State | undefined, action: Action) {
    return rootReducer(state, action);
  }

export const getFeatureFlags = (state: State) => state && state.featureFlags;
export const getHaveFeatureFlagsBeenFetched = (state: State) => state.haveFeatureFlagsBeenFetched;