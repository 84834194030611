import { ITag } from "@kehe/phoenix-indicators";

export interface CarouselItem {
    name: string;
    type: CarouselTypeName;
    slides: Slide[];
    showId: string;
    distributionCenters: Array<{
        id: number;
        carouselId: number;
        distributionCenterNumber: string;
        distributionCenterName: string;
    }>;
    status: CarouselStatusName;
    startDate: Date;
    endDate: Date;
    created: Date;
    // Used for UI
    id?: string;
    statusTag?: ITag,
    isShowCarousel?: boolean,
    typeDescription?: string
}
export interface Slide {
    carouselId: number;
    brandName: string;
    linkType: string;
    linkTypeName: string;
    mediaType: string;
    mediaTypeName: string;
    mediaUrl: string;
    mediaName: string;
    mediaThumbnailUrl: string;
    linkUrl: string;
    sortOrder: number;
    id: number;
    isActive: boolean;
    created: Date;
    createdBy: string;
    deleted: Date;
    deletedBy: string;
    lastUpdated: Date;
    lastUpdatedBy: string
}
export enum CarouselStatusName {
    Draft = 'Draft',
    Published = 'Published',
    Live = 'Live',
    Completed = 'Completed',
}
export enum CarouselTypeId {
    RetailerHome,
    RetailerPublications,
    RetailerShow,
    SupplierShow,
}
export enum CarouselTypeName {
    RetailerHome = 'RetailerHome',
    RetailerPublications = 'RetailerPublications',
    RetailerShow = 'RetailerShow',
    SupplierShow = 'SupplierShow',
}