import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tile-count-alt',
  templateUrl: './tile-count-alt.component.html',
  styleUrls: ['./tile-count-alt.component.scss']
})
export class TileCountAltComponent {

  @Input() title = 'No Title';
  @Input() count = '0';
  @Input() color = '#ccc';
  @Output() TileClick: EventEmitter<any> = new EventEmitter();

  tileClicked(): void {
    this.TileClick.emit(true);
  }

}
