<kehe-phoenix-modal
  name="clear-notifications"
  size="sm"
  modalTitle="Clear All Notifications"
  [show]="showModal"
  (modalEvent)="handleKeheModalEvents($event)"
>
  <div body>
    Are you sure you want to clear <strong>{{notificationsCount}}</strong> notifications? Once cleared, notifications cannot be recovered.
  </div>

  <div footer>
    <div class="footer-actions">
      <kehe-phoenix-button (click)="dismissModal()" name="cancelClearNotifications" classAttr="btn-link btn-medium">Cancel</kehe-phoenix-button>
      <kehe-phoenix-button (click)="confirmClearAll()" name="confirmClearNotifications" classAttr="btn-primary btn-medium">Confirm</kehe-phoenix-button>
    </div>
  </div>

</kehe-phoenix-modal>