import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMerchantCredentials, PaymentTypes, IPaymentType } from '@kehe/connect-common-credit-card-web';
import { environment } from '../../../../../../environments/environment';
import { ClientMerchantCredentialsService } from '../../services/client-merchant-credentials.service';
import { Observable } from 'rxjs';
import { selectIsOrderProcessing } from '@app/modules/data-exchange/store/data-exchange.selectors';
import { submitOrderResponseReceived } from '@app/modules/data-exchange/store/data-exchange.actions';
import { IAuthorizeNetResponse } from '../../models/iauthorize-net-response';

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss']
})
export class CheckoutFormComponent implements OnInit {

  public isFormValid = false;
  public readonly isProduction = environment.production;
  isOrderProcessing$: Observable<boolean>;
  merchantCredentials$: Observable<IMerchantCredentials>;

  supplierPaymentTypes: IPaymentType[] = [
    { text: 'Credit Card', value: PaymentTypes.CreditCard },
    { text: 'ACH', value: PaymentTypes.ACH },
  ]

  @Output()
  formValidity: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private clientMerchantCredentialsService: ClientMerchantCredentialsService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.merchantCredentials$ = this.getMerchantCredentials();
    this.isOrderProcessing$ = this.store.select(selectIsOrderProcessing);
  }

  getMerchantCredentials(): Observable<IMerchantCredentials> {
    return this.clientMerchantCredentialsService.get();
  }

  getTokenResult(response: IAuthorizeNetResponse) {
    this.store.dispatch(submitOrderResponseReceived({ paymentTokenResponse: response }));
  }

  formValid(isValid: boolean) {
    this.isFormValid = isValid;
    this.formValidity.emit(isValid);
  }

}
