import {
  TreatmentWithConfig,
  TreatmentsWithConfig,
} from "@splitsoftware/splitio/types/splitio";

class FeatureFlag {
  constructor(
    public key: string,
    public isOn: boolean,
    public config?: string
  ) {}
}

export const FeatureFlags = {
  SupplierOutageBanner: new FeatureFlag('Supplier_Top_Level_Banner', false),
  SupplierVendorPackets: new FeatureFlag('CSP-Supplier_Onboarding-Vendor-Packet', false),
  SupplierOrderProjectionsReport: new FeatureFlag('Supplier_Order_Projections_Report', false),
  SupplierPromotionalRoadmap: new FeatureFlag('CS_RDP-139_Promotional_Roadmap_Access', false),
  SupplierRoadmapNationalDeals: new FeatureFlag('CS_RDP-109_Roadmap_National_Deals_Page', false),
  UserAgreement: new FeatureFlag('connect-user-agreements', false),
  SupplierPurchasingOptionsEdit: new FeatureFlag(
    'Supplier_PurchasingOptions_Edit',
    false
),
  ShipDateToRetailer: new FeatureFlag('CE_CSP-7164_KeHE_Shipdate_to_Retailers', false),
  Messaging: new FeatureFlag('web-messaging', false),
  AddUser: new FeatureFlag('enterprise-web-add-user', false),
  UserNotifications: new FeatureFlag(
      'user_notifictions_UMGT-1347_entperise_front_end',
      false
  ),
  EditShipmentsEditButton: new FeatureFlag('edit-shipments-edit-button', false),
  EditShipmentsAddAction: new FeatureFlag('edit-shipments-add-action', false),
  EditShipmentsDeleteAction: new FeatureFlag(
      'edit-shipments-delete-action',
      false
  ),
  EnterPriseProductEditScheduleNonDateFields: new FeatureFlag(
      'Enterprise_Product_Edit_ScheduleNonDateFields',
      false
  ),
  SupplierProductCloneProduct: new FeatureFlag(
      'Supplier_Product_CloneProduct',
      false
  ),
  CustomerActivation: new FeatureFlag('Enterprise_Customer_Activation', false),
  CustomerEditAddresses: new FeatureFlag(
      'Enterprise_Customer_Edit_Addresses',
      false
  ),
  CustomerEditReps: new FeatureFlag('Enterprise_Customer_Edit_KeHE_Reps', false),
  CustomerEditAccounting: new FeatureFlag(
      'Enterprise_Customer_Edit_Accounting',
      false
  ),
  CustomerEditNotices: new FeatureFlag(
      'Enterprise_Customer_Edit_Notices_Tags_and_Stickers',
      false
  ),
  CustomerEditGeneral: new FeatureFlag('Enterprise_Customer_Edit_General', false),
  CustomerClone: new FeatureFlag('Enterprise_Customer_Clone', false),
  EnterpriseCustomerFieldDefinitions: new FeatureFlag(
      'Enterprise_Customer_Field_Definitions',
      false
  ),
  CreateOrderMultiModal: new FeatureFlag('Create_Order_Multi_Modal', false),
  CreateOrderMultiModalPendingTab: new FeatureFlag(
      'Create_Order_Multi_Modal_Pending_Tab',
      false
  ),
  BulkSubmitAction: new FeatureFlag(
      'Enterprise_SOM-2446_Bulk_Submit_Action',
      false
  ),
  EnterpriseCustomerList: new FeatureFlag('Enterprise_Customer_List', false),
  EnterpriseCustomerEditContacts: new FeatureFlag(
      'Enterprise_Customer_Edit_Contacts',
      false
  ),
  EnterpriseCustomerContactsMarketing: new FeatureFlag(
      'Enterprise_Customer_Contacts_Marketing',
      false
  ),
  ShipmentDetailDepartmentNumber: new FeatureFlag(
      'Enterprise_SOM-2591_Department_Number',
      false
  ),
  EnterpriseProductLinkedDrafts: new FeatureFlag('Enterprise_Product_Linked_Drafts', false),
  EditShipmentHeader: new FeatureFlag('CE_SOM-2728_Edit_Shipment_Header', false),
  ShowReportEmails: new FeatureFlag('Show_Report_Emails', false),
  SupplierProductApplyAll: new FeatureFlag('Supplier_Product_Add_ApplyAll', false),
  ProductComparableUpcRefreshPricing: new FeatureFlag('Product_comparable_upc_refresh_pricing', false),
  DataExchangeForBrokers: new FeatureFlag('Supplier_D4b', null),
  DataExchangeTrialPeriod: new FeatureFlag('supplier_d4b_trial_period', false),
  ShowImpersonation: new FeatureFlag('CS_Show_Impersonation', false),
  SupplierGeneralTabView: new FeatureFlag('Supplier_GeneralTab_View', false),
  ViewAnnouncements: new FeatureFlag('View_Announcements', false),
  ProductSupplierGtinValidation: new FeatureFlag('Product_Supplier_Inner_Pack_Gtin_Validator', false),
  BrokerV2: new FeatureFlag('SBM_FE_MENU_ITEM', false),
  EnterpriseIXoneSelfService: new FeatureFlag('Enterprise_IXone_Self_Service', false),
  SupplierPurchasingOptionsActiveEdit: new FeatureFlag('Supplier_PurchasingOptions_Active_Edit', false),
  EnterpriseProductCatchWeightType: new FeatureFlag('Enterprise_Product_Catch_Weight_Type', false),
  PurchasingOptionSupplierDown: new FeatureFlag('PurchasingOption_SUP_Down', false),
  PurchasingOptionsIgnoreBracket: new FeatureFlag('PO_FE_IGR_BCKT', false),
  PurchasingOptionsWizardFlow: new FeatureFlag('PurchasingOption_FE_Wizard_Flow', false),
  SupplierOnboardingV4: new FeatureFlag('CSP-Supplier_onboarding-v4', false),
  EnterprisePurchasingOptionRequiredPriceChanges: new FeatureFlag('Enterprise_PurchasingOption_SubmitAlwaysWithComments', false),
  PurchasingOptionWizardFlowV2: new FeatureFlag('PurchasingOption_FE_Wizard_Flow_V2', false),
};

export function mapFeatureFlagsToSplitTreatmentsWithConfig(): TreatmentsWithConfig {
  let treatmentsWithConfig: TreatmentsWithConfig = {};
  const featureFlags: FeatureFlag[] = Object.values(FeatureFlags);
  featureFlags.forEach((featureFlag) => {
    const treatmentWithConfig: TreatmentWithConfig = {
      treatment: featureFlag.isOn ? "on" : "off",
      config: featureFlag.config,
    };
    treatmentsWithConfig[featureFlag.key] = treatmentWithConfig;
  });
  return treatmentsWithConfig;
}

export const FeatureFlagKeys = Object.values(FeatureFlags).map((f) => f.key);



