import { Message } from './../service/API.service';
import { IMessage } from './../models/IMessage';
import { IChatRoom } from './../models/IChatRoom';
import { IChatRoomGraphQL } from './../models/IChatRoomGraphQL';

import { createAction, props } from '@ngrx/store';
import { ISupplier } from '../models/ISupplier';
import { ICustomer } from '../models/ICustomer';


export enum MessagesActionType {
  setUserFullName = '[Messages] Set User Full Name',
  setUserEmail = '[Messages] Set User Email',
  setSuppliers = '[Messages] Set Suppliers',
  setCustomers = '[Messages] Set Customers',

  getChatRoomByCustomerNumberSuccess = '[Messages] Get Chat Room By Customer Number Success',


  createNewChat = '[Messages] Create New Chat',
  CloseNewChat = '[Messages] Close New Chat',

  openChat = '[Messages] Open Chat',
  openChatSuccessNew = '[Messages] Open Chat Success New',
  openChatSuccessExisting = '[Messages] Open Chat Success Existing',

  sendMessage = '[Messages] Send Message',
  sendMessageSuccess  = '[Messages] Send Message Success',
  sendMessageWithChatAlreadyCreated = '[Messages] Send Message With Chat Already Created',
  sendMessageWithChatAlreadyCreatedSuccess = '[Messages] Send Message With Chat Already Created Success',

  receiveMessage = '[Messages] Receive Message',
  receiveChatRoomUpdate = '[Messages] Receive Chat Room Message',

  createChatRoom = '[Messages] Create Chat Room',

  closeChat = '[Messages] Close Chat',
  toggleCollapseChat = '[Messages] Toggle Collapse Chat',
  subscriptionCreated = '[Messages] Subscription Created',

  readMessageCollapsedChat = '[Messages] Read Message From Collapsed Chat',

  subscribeToChatRoomsByCustomer = '[Messages] Subscribe To Chatroom By Customer',

  getMessagesSuccess = '[Messages] Get Messages Success',

  getMoreMessages = '[Messages] Get More Messages',
  getMoreMessagesSuccess = '[Messages] Get More Messages Success',

  getUnreadsBySupplierNumberSuccess = '[Messages] Get Unreads By Supplier Number Success',
  notifyChatIsReadSuccess = '[Messages] Notify chat is read Success',
  saveDraftMessageChat = '[Messages] Save Draft Message Chat',

  setFilterHeaderChatsText = '[Messages] Set filter header chats text',

}


/* Product List */

export const openChat = createAction(
  MessagesActionType.openChat,
  props<{ customerNumber: string,
          supplierNumber: string,
          customerName: string,
          supplierName: string
          }>()
);

export const createNewChat = createAction(
  MessagesActionType.createNewChat
);

export const closeNewChat = createAction(
  MessagesActionType.CloseNewChat
);

export const openChatSuccessNew = createAction(
  MessagesActionType.openChatSuccessNew,
  props<{ chatRoom: IChatRoom}>()
);

export const openChatSuccessExisting = createAction(
  MessagesActionType.openChatSuccessExisting,
  props<{ chatRoom: IChatRoomGraphQL, userEmail: string }>()
);

export const closeChat = createAction(
  MessagesActionType.closeChat,
  props<{ chatId: string }>()
);

export const toggleCollapseChat = createAction(
  MessagesActionType.toggleCollapseChat,
  props<{ chatRoom: IChatRoom }>()
);

export const readMessageCollapsedChat = createAction(
  MessagesActionType.readMessageCollapsedChat,
  props<{ chatRoom: IChatRoom }>()
);

export const createChatRoom = createAction(
  MessagesActionType.createChatRoom,
  props<{ messageBeingSend: IMessage, chatMessageBeingSend: IChatRoom }>()
);

export const sendMessage = createAction(
  MessagesActionType.sendMessage,
  props<{ message: string, chatId: string, userFullName: string, userEmail: string }>()
);

export const sendMessageSuccess = createAction(
  MessagesActionType.sendMessageSuccess,
  props<{ message: IMessage, chatRoomID: string }>()
);

export const sendMessageWithChatAlreadyCreated = createAction(
  MessagesActionType.sendMessageWithChatAlreadyCreated,
  props<{messageBeingSend: IMessage, chatMessageBeingSend: IChatRoom, newChatRoom: IChatRoom }>()
);

export const sendMessageWithChatAlreadyCreatedSuccess = createAction(
  MessagesActionType.sendMessageWithChatAlreadyCreatedSuccess,
  props<{message: IMessage, chatRoom: IChatRoom }>()
);

export const receiveMessage = createAction(
  MessagesActionType.receiveMessage,
  props<{message: Message }>()
);

export const setUserFullName = createAction(
  MessagesActionType.setUserFullName,
  props<{userFullName: string }>()
);

export const setUserEmail = createAction(
  MessagesActionType.setUserEmail,
  props<{userEmail: string; }>()
);

export const setSuppliers = createAction(
  MessagesActionType.setSuppliers,
  props<{suppliers: Array<ISupplier>; }>()
);


export const setCustomers = createAction(
  MessagesActionType.setCustomers,
  props<{customers: Array<ICustomer>; }>()
);

export const subscriptionCreated = createAction(
  MessagesActionType.subscriptionCreated,
);

export const subscribeToChatRoomsByCustomer = createAction(
  MessagesActionType.subscribeToChatRoomsByCustomer,
);

export const receiveChatRoomUpdate = createAction(
  MessagesActionType.receiveChatRoomUpdate,
  props<{chatRooms: Array<IChatRoom>; }>()
);


export const getChatRoomByCustomerNumberSuccess = createAction(
  MessagesActionType.getChatRoomByCustomerNumberSuccess,
  props<{chatRooms: Array<IChatRoom>, earliestShowStartDate: string }>()
);

export const getMessagesSuccess = createAction(
  MessagesActionType.getMessagesSuccess,
  props<{messages: Array<IMessage>, nextTokenMessages: string, chatRoom: IChatRoomGraphQL, userEmail: string }>()
);

export const getMoreMessages = createAction(
  MessagesActionType.getMoreMessages,
  props<{chatRoom: IChatRoom }>()
);

export const getMoreMessagesSuccess = createAction(
  MessagesActionType.getMoreMessagesSuccess,
  props<{messages: Array<IMessage>, nextTokenMessages: string, chatRoom: IChatRoom }>()
);

export const getUnreadsBySupplierNumberSuccess = createAction(
  MessagesActionType.getUnreadsBySupplierNumberSuccess,
  props<{unreads: Array<{items: Array<IChatRoom>, scannedCount: number}> }>()
);

export const notifyChatIsReadSuccess = createAction(
  MessagesActionType.notifyChatIsReadSuccess,
  props<{chatRoom: IChatRoom}>()
);

export const saveDraftMessageChat = createAction(
  MessagesActionType.saveDraftMessageChat,
  props<{chatId: string, draftMessage: string}>()
);

export const setFilterHeaderChatsText = createAction(
  MessagesActionType.setFilterHeaderChatsText,
  props<{text: string}>()
);
