export const ANNOUNCEMENTS_FEATURE = 'Announcements';

export enum FeaturesV1 {
    ItemSearch = '0AC00A70-EF05-483C-A4F5-568C1F31312D',
    NewItem = '0AC00A70-EF05-483C-A4F5-568C1F31312D',
    Promotions = '06B49DBF-2EB3-493D-BCC3-96A03A1F572D',
    DocumentsAndDownloads = '207D8B81-4140-41AB-A9D6-96D647093078',
    Ksolve = '788E92B1-6BDE-45F5-85CF-D6751D4E6D27',
    VendorManagement = 'E7247226-5FFE-430D-9719-03925BFFC82C',
    UserManagement = 'A317E3D3-B3DD-45A2-A10B-F2C273E7AE88',
    Reports = '7579F556-B453-4E20-9B68-00FABD9A1569'
}
