import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss']
})
export class SelectAllComponent {

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  onClick(): void {
    this.clicked.emit();
  }

}
