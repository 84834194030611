import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { routerStoreKey } from '../index';

const selectRouter = createFeatureSelector<
    fromRouter.RouterReducerState<any>
>(routerStoreKey);

export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const selectShowIdFromUrl = createSelector(
    selectRouter,
    (state: fromRouter.RouterReducerState) => {
        const showId: string = state?.state?.root?.firstChild?.params?.showId;
        return showId || null;
    }
);
