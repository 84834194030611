import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierListState } from './supplier-list.reducer';

export const getSupplierListState = createFeatureSelector<SupplierListState>(
  'supplierList'
);

export const getSupplierListFilter = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter
);

export const getSupplierList = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierList
);

export const getSupplierListLoading = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.isLoading
);

export const getSupplierListCount = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.availableCount
);

export const getSupplierListPageCount = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.pageCount
);

export const getDisplaySupplierFilterPanel = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.displayFilterPanel
);

export const getSupplierListCurrentPage = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.pageIndex + 1
);

export const getSupplierListSkip = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.skip
);

export const getSupplierListSearch = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.search
);

export const getSupplierListFilterChips = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilterChips
);

export const getSupplierListSort = createSelector(
  getSupplierListState,
  (state: SupplierListState) => {
    return { orderNumb: state.supplierListFilter.sortName, dir: state.supplierListFilter.orderDirection };
  }
);
