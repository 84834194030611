import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client-ts';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userManager: UserManager;

  constructor(private httpClient: HttpClient) {
    const config = {
      authority: environment.identityApi,
      client_id: 'connect-supplier-web',
      redirect_uri: environment.web + '/callback',
      response_type: 'code',
      scope: 'openid profile vendor-pricing-mgmt-api power-bi-report-api customer-api kehe-api',
      post_logout_redirect_uri : environment.web,
      loadUserInfo: true,
    };
    this.userManager = new UserManager(config);
  }

  getUser(): Observable<User> {
    return from(this.userManager.getUser());
  }

  login(redirectUrl: string = ''): Observable<any> {
    return from(this.userManager.signinRedirect({
      state: {
        redirectUrl,
      } 
    }));
  }

  handleRedirect(): Observable<User> {
    return from(this.userManager.signinRedirectCallback());
  }

  logout(): void {
    this.userManager.signoutRedirect();
  }

}