import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum ChecklistNavigationTabStatus {
  checked,
  unchecked,
  info,
  warning,
  locked,
}

export interface ChecklistNavigationTab {
  tab: string;
  remainingCount: number;
  optional?: boolean;
  status: ChecklistNavigationTabStatus;
  disabled?: boolean;
}
@Component({
  selector: 'app-checklist-navigation-panel',
  templateUrl: './checklist-navigation-panel.component.html',
  styleUrls: ['./checklist-navigation-panel.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ChecklistNavigationPanelComponent {

  readonly tabStatus = ChecklistNavigationTabStatus;

  @Input() activeTab: string;
  @Input() containerMarginTop: number;
  @Input() open: boolean;
  @Input() tabs: ChecklistNavigationTab[] = [];
  @Input() tabContainerWidth: number = null;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tabSelected: EventEmitter<ChecklistNavigationTab> = new EventEmitter<ChecklistNavigationTab>();

  get marginTop(): string {
    return this.containerMarginTop !== 0 ? `${this.containerMarginTop}` : '';
  }

  public togglePanel() {
    this.toggled.emit(this.open);
  }

  public setTab(tab: ChecklistNavigationTab) {
    const thisTab = this.tabs.find(t => t.tab === tab.tab);
    if (thisTab !== null && thisTab.disabled) {
      return;
    }
    this.tabSelected.emit(tab);
  }
}
