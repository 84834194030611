import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../models/payload';
import { SafeHtml } from '@angular/platform-browser';

export const loadUserAgreementStatusAction = createAction(
  '[User Agreement] Load User Agreement Status'
);

  export const loadUserAgreementStatusSuccessAction = createAction(
  '[User Agreement] Load User Agreement Status Success',
  props<{ response: Payload<boolean> }>()
);

  export const setUserAgreementAction = createAction(
  '[User Agreement] Set User Agreement'
);

  export const setUserAgreementSuccessAction = createAction(
  '[User Agreement] Set User Agreement Success'
);

  export const loadTermsAndConditionsPageAction = createAction(
  '[User Agreement] Load Terms and Conditions Page'
);

  export const loadTermsAndConditionsPageSuccessAction = createAction(
  '[User Agreement] Load Terms and Conditions Page Success',
  props<{ response: SafeHtml }>()
);

  export const loadPrivacyStatementPageAction = createAction(
  '[User Agreement] Load Privacy Statement Page'
);

  export const loadPrivacyStatementPageSuccessAction = createAction(
  '[User Agreement] Load Privacy Statement Page Success',
  props<{ response: SafeHtml }>()
);

  export const userAgreementErrorAction = createAction(
  '[User Agreement] User Agreement Error'
);
