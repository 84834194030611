import { ShowEligibleCustomer, ShowModel } from "@app/models/show-models";
import { SliderElement } from "@kehe/phoenix-carousel";
import { createAction, props } from "@ngrx/store";

export const checkSupplierInShowSuccess = createAction(
    '[Show API] Check Supplier In Show Success',
    props<{ shows: ShowModel[] }>()
);
export const checkSupplierInShowFailure = createAction(
    '[Show API] Check Supplier In Show Failure'
);
export const checkImpersonatedSupplierInShowSuccess = createAction(
    '[Show API] Check Impersonated Supplier In Show Success',
    props<{ shows: ShowModel[] }>()
);
export const checkImpersonatedSupplierInShowFailure = createAction(
    '[Show API] Check Impersonated Supplier In Show Failure'
);
export const getSupplierSliderElementsSuccess = createAction(
    '[Show API] Get Supplier Slider Elements Success',
    props<{ sliderElements: SliderElement[] }>()
);
export const getSupplierSliderElementsFailed = createAction(
    '[Show API] Get Supplier Slider Elements Failed',
);
export const getAllShowCustomersSuccess = createAction(
    '[Show API] Get All Show Customers Success',
    props<{ showCustomers: ShowEligibleCustomer[] }>()
);
export const getAllShowCustomersFailure = createAction(
    '[Show API] Get All Show Customers Failure'
);