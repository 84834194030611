import { HelpCenterGroup } from './../../modules/reporting/models/enum/help-center-group';
import { Faq } from './../../models/faq';

export const analyticsDashboardFaqs: Faq[] = [
  {
    question: 'What is the difference between "COGS" and "Purchases"? ',
    answer: `COGS is defined as the dollar amount sold to retailers at KeHE cost plus freight
      (e.g Cost of Goods Sold). Purchases is defined as the dollar amount that KeHE purchased
      (at invoice cost) from a supplier regardless of whether it was sold to a retailer.`,
  },
  {
    question: 'What is "SKU Prod"?',
    answer: `SKU Prod is defined as the COGS per DC SKU (e.g. SKU Productivity).`,
  },
  {
    question: 'What is considered a good SKU Prod value?',
    answer: `SKU Prod ranges by category and/or product segment. In general, SKU Prod
      for dry grocery should exceed $, refrigerated should exceed $, and frozen should exceed $.`,
  },
  {
    question: 'What is Promo Pass Through?',
    answer: `Promo Pass Through are the dollars KeHE passes to retailers on promotion.
      The pass is penny for penny OI to the retailer.`,
  },
  {
    question: 'What is Placement Recovery and why is that different than slotting?',
    answer: `Placement is product (1st case) offered at discount, typically 100% or 50%,
      to encourage/incentivize retailers to place supplier's product on shelf. Slotting
      is monies (flat fee) required/offered by supplier to retailer for adding new products
      to retailer's stores. `,
  },
  {
    question: 'What factors affect GBB? (OI?, Promos?)',
    answer: `The factors taken into consideration in the brand scorecard (GBB) are sales and growth
      (KeHE and syndicated) in comparison to other brands in the same category, off invoice
      promotions, Marketing Allowance program, cash discounts (e.g. terms), and spoils recovery.
      A brand is considered new for one year post-first receipt date.`,
  },
  {
    question: 'Is Albertsons lumped into one or can they select a banner?',
    answer: `Albertsons can be selected by division. There are 13 divisions in the structure;
      JEWEL, UNITED, SHAWS, SOUTHERN, SOUTHWEST, ACME, DENVER, EASTERN, NORTHERN CAL, SEATTLE,
      SOUTHERN CAL, PORTLAND, and INTERMOUNTAIN.`,
  },
  {
    question: 'I want to be "Best". How do I use GBB tab? What is this telling me?',
    answer: `It is showing you the COGS associated with each brand/category combination under
      your ESN (enterprise supplier number). Because GBB is calculated at brand/category level,
      there can be multiple scores under one supplier. If you have areas of opportunity, you can
      reach out to your SDM or CM.`,
  },
  {
    question: 'How recent is the data in the dashboard?',
    answer: `This dashboard updates every Sunday with the data from the prior calendar week,
      which is Sunday through Saturday.`,
  },
].map(item => ({
  ...item,
  id: 0,
  videoId: null,
  sequence: 0,
  version: 1,
  isDeleted: false,
  group: HelpCenterGroup.ConnectBi
}));
