<div class="events-banner kehe-card-action" keheConfetti>

  <div class="title">
    Welcome to KeHE CONNECT Supplier<em class="trademark-logo far fa-registered"></em>!
  </div>

  <div class="body">
    <div class="description">
      {{eventsBannerDescription$ | async}}
    </div>

    <div>
      <kehe-phoenix-button (click)="onManageOrdersBtnClick()" classAttr="btn btn-secondary btn-small">
        Manage Orders
      </kehe-phoenix-button>
    </div>
  </div>

</div>
