<div class="chat-bubble">
  <div [id]="'bubble-sender'" *ngIf="message?.isMeSender" class="d-flex justify-content-end align-items-end mt-2">
    <div class="d-flex flex-column w-75">
      <div class="d-flex flex-row justify-content-between message-info mx-2">
        <span>{{message?.userName}}</span>
        <span>{{message?.createdAt | date: 'hh:mm a'}}</span>
      </div>
      <div class="bubble me-sender" [ngClass]="{'sending-to-server': !!message?.sendingToServer}" >{{message?.content}}</div>
    </div>
    <em class="fas icon" [ngClass]="message.clientType === 'Retailer' ? 'fa-shopping-basket' : 'fa-dolly'" aria-hidden="true"></em>
  </div>

  <div [id]="'bubble-message'" *ngIf="!message?.isMeSender" class="d-flex justify-content-start align-items-end mt-2">
    <em class="fas icon" [ngClass]="message.clientType === 'Retailer' ? 'fa-shopping-basket' : 'fa-dolly'" aria-hidden="true"></em>
    <div class="d-flex flex-column w-75">
      <div class="d-flex flex-row justify-content-between message-info mx-2">
        <span>{{message?.userName}}</span>
        <span>{{message?.createdAt | date: 'hh:mm a'}}</span>
      </div>
      <div class="bubble not-me-sender">{{message?.content}}</div>
    </div>
  </div>
</div>
