import {
  userNotificationAdapter,
  userNotificationCardAdapter,
  userNotificationsInitialState,
  UserNotificationsState
} from './user-notifications.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  clearUserNotificationsSuccessAction,
  clearAllUserNotificationsSuccessAction,
  getUserNotificationPreferencesSuccessAction,
  getUserNotificationsSuccessAction,
  getUserNotificationsCountSuccessAction,
  updateUserNotificationPreferencesAction,
  updateUserNotificationPreferencesSuccessAction,
  toggleClearNotificationsModalAction,
} from './user-notifications.actions';
import {
  UserNotificationPreferencesRecord,
  UserNotificationRecord
} from '../model/service/user-notifications';
import { Payload } from '../../../models/payload';
import {
  UserNotificationEntity,
  UserNotificationPreferences,
  NewPreference
} from '../model/user-notifications';
import { UserNotificationCard } from '@kehe/phoenix-top-bar';

const reducer = createReducer(userNotificationsInitialState,
  on(getUserNotificationPreferencesSuccessAction,
      (state, { record }) => userNotificationPreferencesSuccess(state, record)
  ),
  on(updateUserNotificationPreferencesAction,
    (state, { newPreference }) => updateUserNotificationPreferencesSuccess(state, newPreference)
),
  on(updateUserNotificationPreferencesSuccessAction,
    (state, { record }) => userNotificationPreferencesSuccess(state, record)
  ),
  on(getUserNotificationsSuccessAction,
    (state, { response }) => getUserNotificationsSuccess(state, response)
  ),
  on(getUserNotificationsCountSuccessAction,
    (state, { response }) => getUserNotificationsCountSuccess(state, response)
  ),
  on(clearUserNotificationsSuccessAction,
    (state, { notificationIds }) => clearUserNotificationsSuccess(state, notificationIds)
  ),
  on(clearAllUserNotificationsSuccessAction,
    (state) => clearAllUserNotificationsSuccess(state)
  ),
  on(toggleClearNotificationsModalAction,
    (state) => toggleClearNotificationsModal(state)
  )
);

export function userNotificationReducer(state: UserNotificationsState | undefined, action: Action) {
  return reducer(state, action);
}

function userNotificationPreferencesSuccess(
  state: UserNotificationsState,
  record: UserNotificationPreferencesRecord
): UserNotificationsState {
  return {
    ...state,
    preferences: record.preferences
  };
}

function updateUserNotificationPreferencesSuccess(
  state: UserNotificationsState,
  newPreference: NewPreference
): UserNotificationsState {
  return {
    ...state,
    preferences: buildNotificationPreferences(state, newPreference)
  };
}

function getUserNotificationsSuccess(
  state: UserNotificationsState,
  response: Payload<UserNotificationRecord[]>
): UserNotificationsState {
  return {
    ...state,
    entityState: userNotificationAdapter.setAll(
      response.data.map(record => convertToUserNotificationEntity(record)),
      state.entityState
    ),
    displayCardState: userNotificationCardAdapter.setAll(
      response.data.map(record => buildUserNotificationCard(record)),
      state.displayCardState
    )
  };
}

function getUserNotificationsCountSuccess(
  state: UserNotificationsState,
  response: number,
  ): UserNotificationsState {
  return {
    ...state,
    notificationCount: response
  };
}

function clearUserNotificationsSuccess(
  state: UserNotificationsState,
  notificationIds: number[] = []
): UserNotificationsState {
  return {
    ...state,
    entityState: userNotificationAdapter.removeMany(
      notificationIds,
      state.entityState
    ),
    displayCardState: userNotificationCardAdapter.removeMany(
      notificationIds,
      state.displayCardState
    ),
    notificationCount: state.notificationCount - notificationIds.length
  };
}

function clearAllUserNotificationsSuccess(state: UserNotificationsState): UserNotificationsState {
  return userNotificationsInitialState;
}

function toggleClearNotificationsModal(state: UserNotificationsState): UserNotificationsState {
  return {
    ...state,
    clearNotificationsModalOpen: !state.clearNotificationsModalOpen
  };
}

function convertToUserNotificationEntity(record: UserNotificationRecord): UserNotificationEntity {
  return {
    created: record.created,
    email: record.email,
    linkText: record.linkText,
    linkUrl: record.linkUrl,
    message: record.message,
    notificationId: record.notificationId,
    origin: record.origin,
    subtitle: record.subtitle,
    title: record.title
  };
}

function buildUserNotificationCard(record: UserNotificationRecord): UserNotificationCard {
  return {
    notificationId: record.notificationId,
    title: record.title,
    titleIcon: getTitleIcon(record.title),
    created: record.created,
    subtitle: record.subtitle,
    message: record.message,
    linkText: record.linkText,
    linkUrl: record.linkUrl,
  };
}
export function getShowIdFromUrl(linkUrl: string): string {
  let showId = ''
  const index1 = linkUrl?.indexOf('show/');
  const index2 = linkUrl?.indexOf('/orders')
  const showIdFromUrl = linkUrl?.substring(index1+5, index2);
  if (showIdFromUrl?.length > 1) {
    showId = showIdFromUrl
  }
  return showId;
}

function buildNotificationPreferences(
  state: UserNotificationsState,
  newPreference: NewPreference
): UserNotificationPreferences {
  const newPreferences = {
    ...state.preferences,
    [newPreference.method]: {
      ...state.preferences[newPreference.method],
      [newPreference.option]: newPreference.status
    }
  };

  console.log('new preferences: ', newPreferences);

  return newPreferences;
}

function getTitleIcon(title: string): string {
  switch (title) {
    case 'Countered':
      return 'far fa-exchange';
    case 'Declined':
      return 'fas fa-times-circle';
    case 'Proposed':
      return 'fas fa-envelope';
    case 'Submitted':
      return 'fas fa-handshake';
    case 'Updated':
      return 'fas fa-bell';
    case 'Called Back':
      return 'fas fa-arrow-alt-circle-left';
    default:
      return undefined;
  }
}
