import { Injectable } from "@angular/core";
import { createEffect} from "@ngrx/effects";
import { DeviceDetectorService } from "ngx-device-detector";
import { of } from 'rxjs';
import * as DeviceActions from './device.actions';

@Injectable()
export class DeviceEffects {

    constructor(private deviceDetectorService: DeviceDetectorService) {}

    getDeviceInfo$ = createEffect(() => {
        return of(DeviceActions.getDeviceInfoSuccess({
            deviceInfo: this.deviceDetectorService.getDeviceInfo(),
            isDesktop: this.deviceDetectorService.isDesktop(),
            isMobile: this.deviceDetectorService.isMobile(),
            isTablet: this.deviceDetectorService.isTablet(),
        }));
    });

}