import { SubscriptionFilterChangedEvent } from './../../models/subscription-filter-changed-event';
import { SubscriptionFilter } from './../../models/subscription-filter';
import { Supplier } from './../../models/supplier';
import { DataSubscriptionPayload } from './../../models/data-subscription-payload';
import {
  selectShowAddSubscriptionModal,
  selectCreateSubscriptionLoading,
} from './../../store/data-exchange.selectors';
import { takeUntil } from 'rxjs/operators';
import { addSubscriptionModalDismissed } from './../../store/data-exchange.actions';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { DayOfWeek, dayOfWeekAbbreviationMap } from './../../models/enum/day-of-week';
import { RecurrenceType } from './../../models/enum/recurrence-type';
import { ReportName } from '../../../reporting/models/enum/report-name.enum';
import { IDropdownOption } from '@app/models/idropdown-option';
import { KeheModalEventsTypes } from '@kehe/phoenix-modal';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { ReportType } from '../../../../modules/reporting/models/enum/report-type.enum';

export const selectAllText = 'Select All';

@Component({
  selector: 'app-create-subscription-modal',
  templateUrl: './create-subscription-modal.component.html',
  styleUrls: ['./create-subscription-modal.component.scss']
})
export class CreateSubscriptionModalComponent extends DestroyableDirective  implements OnInit {

  @Output()
  subscribeClicked = new EventEmitter<DataSubscriptionPayload>();

  showModal: boolean;
  createSubscriptionLoading$: Observable<boolean>;
  selectedSupplier: Supplier;
  isFilterValid = false;
  reportName = ReportName;
  reportOptions: IDropdownOption[] = [
    ReportName.BrandItemLocation,
    ReportName.ChainsAndStoresThatOrderedAnItem,
    ReportName.EnterpriseSupplierChainSalesbyBrand,
    ReportName.InboundFillRate,
    ReportName.InventoryAtRisk,
    ReportName.InventoryStockStatus,
    ReportName.Item360RecapHd,
    ReportName.KeheFullPodVendor,
  ].map(report => ({value: report, label: ReportName.toString(report)}));

  frequencyOptions: IDropdownOption[] = [
    RecurrenceType.Weekly,
  ].map(frequency => ({value: frequency, label: frequency}));

  dayOptions: {id: DayOfWeek, text: string, selected: boolean }[] = [];
  selectedDay: DayOfWeek;
  selectedReport: IDropdownOption;
  selectedRecurrence: IDropdownOption = this.frequencyOptions[0];
  filters: SubscriptionFilter[];

  constructor(
    private _store: Store
  ) { super(); }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectShowAddSubscriptionModal)
    ).subscribe(showModal => {
      this.showModal = showModal;
      if (!showModal) { this.resetForm(); }
    });

    this.createSubscriptionLoading$ = this._store.pipe(select(selectCreateSubscriptionLoading));
    this.dayOptions = this.getDayOptions();
  }

  getDayOptions(): { id: DayOfWeek, text: string, selected: boolean }[] {
    const days = [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday
    ].map(day => ({
      id: day,
      text: dayOfWeekAbbreviationMap.get(day),
      selected: false
    }));
    // Randomize which day is selected by default
    const randomIndex = Math.floor(Math.random() * this.dayOptions.length);
    this.selectedDay = days[randomIndex].id;
    return days;
  }

  handleModalEvent(event) {
    switch (event.type) {
      case KeheModalEventsTypes.Close:
        this._store.dispatch(addSubscriptionModalDismissed());
        break;
      default:
    }
  }

  cancelClicked() {
    this._store.dispatch(addSubscriptionModalDismissed());
  }

  onReportChanged() {
    this.isFilterValid = false;
  }

  daySelected() {
    this.dayOptions = this.dayOptions.map(option => ({
      ...option,
      selected: option.id === this.selectedDay
    }));
  }

  resetForm() {
    this.selectedReport = this.reportOptions[0];
    this.selectedRecurrence = this.frequencyOptions[0];
    this.dayOptions = this.getDayOptions();
    this.isFilterValid = false;
  }

  isFormValid(): boolean {
    const fieldsPopulated =
      !!this.selectedReport &&
      !!this.selectedRecurrence;

    const daySelectionValid = this.selectedRecurrence.value === RecurrenceType.Weekly ?
      this.getDaysList().length > 0 : true;

    return fieldsPopulated && daySelectionValid && this.isFilterValid;
  }

  getDaysList(): DayOfWeek[] {
    if (this.selectedRecurrence.value === RecurrenceType.Weekly) {
      return this.dayOptions.filter(day => day.id === this.selectedDay).map(day => day.id);
    } else {
      return [];
    }
  }

  onFilterChanged(event: SubscriptionFilterChangedEvent) {
    this.isFilterValid = event.filtersValid;

    if (event.filtersValid) {
      this.selectedSupplier = event.selectedSupplier;
      this.filters = event.filters;
    }
  }

  onSubmit() {
    if (this.isFormValid()) {
      const payload: DataSubscriptionPayload = {
        reportType: ReportType.External,
        reportName: ReportName[this.selectedReport.value],
        supplier: this.selectedSupplier,
        recurrence: RecurrenceType[this.selectedRecurrence.value],
        days: this.getDaysList(),
        filter: this.filters
      };
      this.subscribeClicked.emit(payload);
    }
  }
}
