<div class="row py-2 px-2 data-exchange-cart-toast">
  <div class="col-3 p-0 d-flex justify-content-center align-items-center icon-container">
    <em class="fas fa-file-alt icon"></em>
  </div>
  <div class="col-9 d-flex flex-column justify-center align-center">
    <div class="mb-1">{{toastHeader}}</div>
    <a class="mb-1" [routerLink]="['/shopping-cart/cart']">
      <span class="toast-link">View Cart</span>
    </a>
    <p class="text">Data Exchange Subscription</p>
  </div>
</div>
