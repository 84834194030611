import { Injectable } from '@angular/core';
import { UserAgreementService } from '../service/user-agreement.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  loadPrivacyStatementPageAction,
  loadPrivacyStatementPageSuccessAction,
  loadTermsAndConditionsPageAction,
  loadTermsAndConditionsPageSuccessAction,
  loadUserAgreementStatusAction,
  loadUserAgreementStatusSuccessAction,
  setUserAgreementAction,
  setUserAgreementSuccessAction,
  userAgreementErrorAction
} from './user-agreement.actions';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';
import { SafeHtml } from '@angular/platform-browser';
import * as FeatureFlagActions from '@app/feature-flag/feature-flag.actions';
import * as FeatureFlagSelectors from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag';

@Injectable({ providedIn: 'root' })
export class UserAgreementEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private userAgreementService: UserAgreementService,
    private sanitizeHtmlPipe: SanitizeHtmlPipe
  ) {}

  loadUserAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(FeatureFlagActions.getFeatureFlagSuccess),
    withLatestFrom(this.store.select(FeatureFlagSelectors.selectIsFeatureFlagOn(FeatureFlags.UserAgreement.key))),
    filter(([_, isFlagOn]) => isFlagOn),
    map(() => loadUserAgreementStatusAction())
  ));

  loadUserAgreementStatus$ = createEffect(() => this.actions$.pipe(
    ofType(loadUserAgreementStatusAction),
    exhaustMap(() => this.userAgreementService.getUserAgreementStatus().pipe(
      map(response => loadUserAgreementStatusSuccessAction({ response })),
      catchError(() => of(userAgreementErrorAction()))
    ))
  ));

  setUserAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(setUserAgreementAction),
    exhaustMap(() => this.userAgreementService.setUserAgreement().pipe(
      map(() => setUserAgreementSuccessAction()),
      catchError(() => of(userAgreementErrorAction()))
    ))
  ));

  loadTermsAndConditionsPage$ = createEffect(() => this.actions$.pipe(
    ofType(loadTermsAndConditionsPageAction),
    exhaustMap(() =>
      this.userAgreementService.getKeheStaticPage(
        'https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/CS-TermsAndConditions.html'
      ).pipe(
      map(response => {
        try {
          const safeHtml: SafeHtml = this.sanitizeHtmlPipe.transform(response);
          return loadTermsAndConditionsPageSuccessAction({ response: safeHtml });
        } catch (error) {
          return userAgreementErrorAction();
        }
      }),
      catchError(() => of(userAgreementErrorAction()))
    ))
  ));

  loadPrivacyStatementPage$ = createEffect(() => this.actions$.pipe(
    ofType(loadPrivacyStatementPageAction),
    exhaustMap(() => this.userAgreementService.getKeheStaticPage(
      'https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/CS-PrivacyStatement.html'
    ).pipe(
      map(response => {
        try {
          const safeHtml: SafeHtml = this.sanitizeHtmlPipe.transform(response);
          return loadPrivacyStatementPageSuccessAction({ response: safeHtml });
        } catch (error) {
          return userAgreementErrorAction();
        }
      }),
      catchError(() => of(userAgreementErrorAction()))
    ))
  ));
}
