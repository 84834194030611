import { createAction, props } from '@ngrx/store';

import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncement } from '../interfaces/recent-announcement.interface';

export const loadRecentLiveCountSuccess = createAction(
  '[Recent Announcements API]: Load Recent Live Count Success',
  props<{ recentLiveCount: IAnnouncementsRecentLiveCount }>()
);

export const loadRecentLiveCountError = createAction(
  '[Recent Announcements API]: Load Recent Live Count Error'
);

export const loadRecentAnnouncementsTilesSuccess = createAction(
  '[Recent Announcements Tiles API]: Load Success',
  props<{ announcements: IRecentAnnouncement[] }>()
);

export const loadRecentAnnouncementsTilesError = createAction(
  '[Recent Announcements Tiles API]: Load Error',
  props<{ msg: string }>()
);
