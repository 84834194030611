import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  clearUserNotificationsAction,
  clearUserNotificationsSuccessAction,
  clearAllUserNotificationsAction,
  clearAllUserNotificationsSuccessAction,
  getUserNotificationPreferencesSuccessAction,
  getUserNotificationsAction,
  getUserNotificationsSuccessAction,
  getUserNotificationsCountAction,
  getUserNotificationsCountSuccessAction,
  updateUserNotificationPreferencesAction,
  updateUserNotificationPreferencesSuccessAction,
  userNotificationsErrorAction
} from './user-notifications.actions';
import { UserNotificationsService } from '../service/user-notifications.service';
import { UserNotificationsFacade } from '../store/user-notifications.facade';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { HttpErrorResponse } from '@angular/common/http';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import * as AuthenticationActions from '@app/authentication/authentication.actions';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private notificationsService: UserNotificationsService,
    private notificationsFacade: UserNotificationsFacade
  ) {}


  getUserNotificationPreferences$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthenticationActions.logInSuccess),
    switchMap(() => this.notificationsService.getNotificationPreferences().pipe(
      map(record => getUserNotificationPreferencesSuccessAction({ record }))
    ))
  ));


  updateUserNotificationPreferences$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(updateUserNotificationPreferencesAction),
    withLatestFrom(
      this.store.select(AuthenticationSelectors.selectUserEmail),
      this.notificationsFacade.getAllLoadedUserNotificationPreferences()
    ),
    switchMap(([action, email, preferences]) => this.notificationsService.updateNotificationPreferences(email, preferences).pipe(
      map(record => updateUserNotificationPreferencesSuccessAction({ record }))
    ))
  ));


  getUserNotifications$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      AuthenticationActions.logInSuccess,
      getUserNotificationsAction
    ),
    exhaustMap(() => this.notificationsService.getUserNotifications().pipe(
      map(response => getUserNotificationsSuccessAction({ response }))
    ))
  ));


  getUserNotificationsCount$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(getUserNotificationsCountAction),
    withLatestFrom(this.store.select(AuthenticationSelectors.selectToken)),
    filter(([action, token]) => !!token),
    switchMap(() => this.notificationsService.getUserNotificationsCount().pipe(
      map(response => getUserNotificationsCountSuccessAction({ response })),
      catchError((err: HttpErrorResponse) => of(userNotificationsErrorAction({ err })))
    ))
  ));


  routerNavigated$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    map(() =>
      getUserNotificationsCountAction()
    )
  ));


  getUserNotificationsInterval$: Observable<Action> = createEffect(() => interval(300000).pipe(
    map(() =>
      getUserNotificationsCountAction()
    )
  ));


  clearUserNotifications$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(clearUserNotificationsAction),
    switchMap(({ notificationIds }) => this.notificationsService.clearUserNotifications(notificationIds).pipe(
      map(() => clearUserNotificationsSuccessAction({ notificationIds })),
      catchError((err: HttpErrorResponse) => of(userNotificationsErrorAction({ err })))
    ))
  ));


  clearAllUserNotifications$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(clearAllUserNotificationsAction),
    switchMap(() => this.notificationsService.clearAllUserNotifications().pipe(
      map(() => clearAllUserNotificationsSuccessAction()),
      catchError((err: HttpErrorResponse) => of(userNotificationsErrorAction({ err })))
    ))
  ));
}
