import { HelpModule } from './../help/help.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CamelCaseToSentenceCasePipe } from './../../utilities/camel-case-to-sentence-case.pipe';
import { DataExchangeEffects } from './store/data-exchange.effects';
import { EffectsModule } from '@ngrx/effects';
import { dataExchangeFeatureKey, reducer } from './store/data-exchange.reducer';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataExchangePageComponent } from './pages/data-exchange-page/data-exchange-page.component';
import { CreateSubscriptionModalComponent } from './components/create-subscription-modal/create-subscription-modal.component';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { SubscriptionInfoComponent } from './components/subscription-info/subscription-info.component';
import { SubscriptionInfoModalComponent } from './components/subscription-info-modal/subscription-info-modal.component';
import { InventoryStockStatusFilterComponent } from './components/create-subscription-filters/inventory-stock-status-filter/inventory-stock-status-filter.component';
import { ItemRecapHdFilterComponent } from './components/create-subscription-filters/item-recap-hd-filter/item-recap-hd-filter.component';
import { ChainSalesBrandFilterComponent } from './components/create-subscription-filters/chain-sales-brand-filter/chain-sales-brand-filter.component';
import { SupplierDcBrandFilterComponent } from './components/create-subscription-filters/supplier-dc-brand-filter/supplier-dc-brand-filter.component';
import { ChainsStoresItemFilterComponent } from './components/create-subscription-filters/chains-stores-item-filter/chains-stores-item-filter.component';
import { InboundFillRateFilterComponent } from './components/create-subscription-filters/inbound-fill-rate-filter/inbound-fill-rate-filter.component';
import { InventoryAtRiskFilterComponent } from './components/create-subscription-filters/inventory-at-risk-filter/inventory-at-risk-filter.component';
import { DeleteSubscriptionModalComponent } from './components/delete-subscription-modal/delete-subscription-modal.component';
import { KeheFullPodFilterComponent } from './components/create-subscription-filters/kehe-full-pod-filter/kehe-full-pod-filter.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { PagerModule } from '@progress/kendo-angular-pager';
import {
    PhoenixButtonModule,
    PhoenixMenuButtonModule
} from '@kehe/phoenix-button';
import { SelectAllComponent } from './components/select-all/select-all.component';
import {
    ComboBoxModule,
    DropDownListModule,
    MultiSelectModule
} from '@progress/kendo-angular-dropdowns';
import {
    CheckBoxModule,
    FormFieldModule,
    NumericTextBoxModule,
    RadioButtonModule
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import {
    PhoenixAlertsModule,
    PhoenixToastModule
} from '@kehe/phoenix-notifications';
import { CheckoutPageComponent } from './checkout/pages/checkout-page/checkout-page.component';
import { ShoppingCartPageComponent } from './checkout/pages/shopping-cart-page/shopping-cart-page.component';
import { CheckoutFormComponent } from './checkout/components/checkout-form/checkout-form.component';
import { OrderComponent } from './checkout/components/order/order.component';
import { CreditCardLibModule } from '@kehe/connect-common-credit-card-web';
import { OrderSummaryComponent } from './checkout/components/order-summary/order-summary.component';
import { AddToCartToastComponent } from './components/add-to-cart-toast/add-to-cart-toast.component';
import { ManageRecipientsModalComponent } from './components/manage-recipients-modal/manage-recipients-modal.component';
import { SubscriptionOverviewModalComponent } from './components/subscription-overview-modal/subscription-overview-modal.component';
import { CancelEsnSubscriptionModalComponent } from './components/cancel-esn-subscription-modal/cancel-esn-subscription-modal.component';
import { EsnSubscriptionStatusTagComponent } from './components/esn-subscription-status-tag/esn-subscription-status-tag.component';
import { EsnSubscriptionActionsBarComponent } from './components/esn-subscription-actions-bar/esn-subscription-actions-bar.component';
import { EsnSubscriptionGridComponent } from './components/esn-subscription-grid/esn-subscription-grid.component';
import { BreadCrumbsComponent } from '@app/breadcrumbs/breadcrumbs.component';
import { EsnSubscriptionsComponent } from './components/esn-subscriptions/esn-subscriptions.component';
import { ChooseFrequencyModalComponent } from './components/choose-frequency-modal/choose-frequency-modal.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';

export const routes: Routes = [
    {
        path: 'shopping-cart',
        children: [
            {
                path: 'cart',
                pathMatch: 'full',
                component: ShoppingCartPageComponent
            },
            {
                path: 'checkout',
                pathMatch: 'full',
                component: CheckoutPageComponent
            }
        ]
    }
];

export const dataExchangeRoute = 'data-exchange';
@NgModule({
    declarations: [
        DataExchangePageComponent,
        CreateSubscriptionModalComponent,
        SubscriptionInfoComponent,
        SubscriptionInfoModalComponent,
        CamelCaseToSentenceCasePipe,
        InventoryStockStatusFilterComponent,
        ItemRecapHdFilterComponent,
        ChainSalesBrandFilterComponent,
        SupplierDcBrandFilterComponent,
        ChainsStoresItemFilterComponent,
        InboundFillRateFilterComponent,
        InventoryAtRiskFilterComponent,
        DeleteSubscriptionModalComponent,
        KeheFullPodFilterComponent,
        SelectAllComponent,
        CheckoutPageComponent,
        ShoppingCartPageComponent,
        CheckoutFormComponent,
        OrderComponent,
        OrderSummaryComponent,
        AddToCartToastComponent,
        ManageRecipientsModalComponent,
        SubscriptionOverviewModalComponent,
        CancelEsnSubscriptionModalComponent,
        EsnSubscriptionStatusTagComponent,
        EsnSubscriptionStatusTagComponent,
        EsnSubscriptionActionsBarComponent,
        EsnSubscriptionGridComponent,
        EsnSubscriptionsComponent,
        ChooseFrequencyModalComponent,
        SubscriptionsComponent
    ],
    imports: [
        CommonModule,
        HelpModule,
        PhoenixModalModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
        RouterModule.forChild(routes),
        StoreModule.forFeature(dataExchangeFeatureKey, reducer),
        EffectsModule.forFeature([DataExchangeEffects]),
        GridModule,
        PhoenixIndicatorsModule,
        PagerModule,
        PhoenixButtonModule,
        ComboBoxModule,
        DropDownListModule,
        MultiSelectModule,
        FormFieldModule,
        NumericTextBoxModule,
        RadioButtonModule,
        LabelModule,
        PhoenixAlertsModule,
        CreditCardLibModule,
        PhoenixToastModule,
        CheckBoxModule,
        BreadCrumbsComponent,
        PhoenixMenuButtonModule
    ]
})
export class DataExchangeModule {}
