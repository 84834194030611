import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IMerchantCredentials } from '@kehe/connect-common-credit-card-web';

@Injectable({
  providedIn: 'root'
})
export class ClientMerchantCredentialsService {

  private url: string;
  private credentials: IMerchantCredentials = null;

  constructor(private http: HttpClient) {
    this.url = `${environment.bimlApi}/api-broker-analytics/merch-creds`;
  }

  get(): Observable<IMerchantCredentials> {
    if (this.credentials === null) {
      return this.http.get<IMerchantCredentials>(this.url).pipe(
        map((val: any) => {
          this.credentials = {
            clientKey: val.clientKey,
            apiLoginID: val.name
          };
          return this.credentials;
        })
      );
    } else {
      return of(this.credentials);
    }
  }
}
