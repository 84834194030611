import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ReportConfig {

    constructor() {}
    public id: string;
    public url: string;
    public type: string;
    public token: string;
    public height: number;

    public authHeaders: HttpHeaders;

    public buildConfig(id: string, url: string, type: string, token: string, height: number) {
        this.id = id;
        this.url = !url || url === undefined ? `${environment.powerbiReportApi}reportId=${this.id}` : url;
        this.type = !type || type === undefined ? 'report' : type;
        this.token = !token || token === undefined ? '' : token;
        this.height = !height || height === undefined ? 500 : height;

        this.authHeaders = new HttpHeaders();
        this.authHeaders = this.authHeaders.append('Content-Type', 'application/json, text/plain, /');
        this.authHeaders = this.authHeaders.append('Access-Control-Allow-Origin', '*');
        if (this.token !== undefined && this.token !== null) {
         this.authHeaders = this.authHeaders.append('Authorization', 'bearer ' + this.token);
        }
    }

}
