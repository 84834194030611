import { createAction, props } from "@ngrx/store";
import { BreadCrumbItem } from "@progress/kendo-angular-navigation";

export const updateKendoBreadCrumbs = createAction(
    '[Product List Page] Update BreadCrumbs',
    props <{ item: BreadCrumbItem }>(),
);

export const clearKendoBreadCrumbs = createAction(
    '[Product List Page] Clear BreadCrumbs',
);