<div class="recent-announcements-tiles-container"
     *ngIf="(tiles$ | async) as list"
     [style.--count-of-items]="list.length"
>
  <kehe-phoenix-announcement-card *ngFor="let tile of list"
                          [state]="tile.state"
                          [headline]="tile.header"
                          [preview]="tile.subHeader"
                          (clicked)="readMore(tile.id)"
  >
  </kehe-phoenix-announcement-card>

  <kehe-phoenix-announcement-links *ngIf="getMobileConfig(list) as linksConfig"
                           [state]="linksConfig?.state"
                           [headline]="linksConfig?.headline"
                           [links]="linksConfig?.links"
  >
  </kehe-phoenix-announcement-links>
</div>
