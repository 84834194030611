import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, filter, map, tap, withLatestFrom } from "rxjs/operators";
import { SupplierService } from "@app/services/supplier.service";
import * as AuthenticationActions from '@app/authentication/authentication.actions';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import * as SupplierApiActions from './supplier.actions';
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import * as SupplierSelectors from '@app/shared/state/supplier/supplier.selectors';
import { Router } from "@angular/router";
import { DashboardService } from "@app/services/dashboard.service";

@Injectable()
export class SupplierEffects {

    constructor(
        private actions$: Actions, 
        private store: Store,
        private router: Router,
        private dashboardService: DashboardService,
        private supplierService: SupplierService,
    ) {}

    getOutOfComplianceDocuments$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.getLegacyTokenSuccess),
            exhaustMap(() => this.dashboardService.getOutOfComplianceDocuments().pipe(
                map(response => SupplierApiActions.getOutOfComplianceDocumentsSuccess({ outOfComplianceDocuments: response })),
                catchError(() => of(SupplierApiActions.getOutOfComplianceDocumentsFailed()))
            ))
        )
    })

    loadSuppliers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            withLatestFrom(this.store.select(AuthenticationSelectors.selectUserEmail)),
            exhaustMap(([action, email]) => this.supplierService.getSuppliersForUser(email).pipe(
                map(response => SupplierApiActions.loadSuppliersForUserSuccess({ suppliers: response.data })),
                catchError((error) => of(SupplierApiActions.loadSuppliersForUserFailed())),
            )),
        )
    });

    getDraftEsns$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            exhaustMap(() => this.supplierService.getDraftESNs().pipe(
                map(response => SupplierApiActions.loadDraftEsnsSuccess({ esns: response?.esns ?? [] })),
                catchError(() => of(SupplierApiActions.loadDraftEsnsFailed()))
            )),
        )
    });

    getSuppliers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SupplierApiActions.loadDraftEsnsSuccess),
            exhaustMap(() => this.supplierService.getSuppliers().pipe(
                map(response => SupplierApiActions.loadSuppliersSuccess({ suppliers: response.data })),
                catchError(() => of(SupplierApiActions.loadSuppliersFailed()))
            )),
        )
    });

    navigateToDraftSupplierDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SupplierApiActions.loadSuppliersSuccess),
            withLatestFrom(
                this.store.select(SupplierSelectors.selectDraftSupplierEsns),
                this.store.select(SupplierSelectors.selectDoesUserHaveAccessToSingleDraftEsn),
            ),
            filter(([action, draftEsns, hasOnly1DraftEsn]) => hasOnly1DraftEsn),
            tap(([action, draftEsns]) => this.router.navigateByUrl(`supplier/${draftEsns[0]}`)),
        )
    }, { dispatch: false })




  

}