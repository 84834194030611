import { Constants } from '@app/constants/constants';
import { HelpCenterGroup } from '@app/modules/reporting/models/enum/help-center-group';

export const faqs = [
  {
    id: 1,
    question: 'How do I initiate an order?',
    videoId: null,
    answer: 'From the "Manage Show Orders" page, you will see a "Create Order" button in the top right of the page. The button will bring up a modal in which you can select the supplier and the retailer that you want to create an order for.',
    sequence: 1,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 2,
    question: 'Can I add an item to more that one book at a time?',
    videoId: null,
    answer: `Yes, there are two ways:
             <br><br>1. On the order details page, when you click on "Add" from a product in the left panel, the add product modal will appear. In the modal, all the available books for the show will appear and you can add quantity for multiple books or use the All Books feature.
             <br>2. On the order details page, you can use the Copy Books button to copy products from book to another.`,
    sequence: 2,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 3,
    question: 'Can I add multiple items to a book or books?',
    videoId: null,
    answer: `Not currently. This will be a future enhancement.`,
    sequence: 3,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 4,
    question: 'What is MCB and how do is it added to an item?',
    videoId: null,
    answer: `MCB stands for Manufacturer Charge Back and provides additional dollars or percentage discounts. You can add MCB while you are adding products to an order or making edits to an order.`,
    sequence: 4,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 5,
    question: 'What is Extra Performance and how do you add it to an order?',
    videoId: null,
    answer: `Extra Performance (EP) allows you to request advertising, placement, and demo dollars. EP can be added to an order in two different ways.
    <br>
    <br>
    1. In the order header, the “Extra Performance” label is hyperlinked. When you click the link, a modal will appear that allows you to add an advertisement, placement, or demo across the books.
    <br>
    <br>
    2. In the book headers, which live under the book tabs, are the “Advertisement”, “Placement”, and “Demo” labels that are hyperlinked. When you click the link, the same modal will appear that allows you to add an advertisement, placement, or demo across the books.`,
    sequence: 5,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 6,
    question: 'How do I chat with a Retailer?',
    videoId: null,
    answer: `There are two ways to chat with a retailer:
    <br>
    <br>
    1. Start a chat with a Retailer by clicking the chat icon found at the top right of the application. When the chat panel opens, there will be a "New" button. Click the button to open the chat drawer and select the Supplier and Retailer you want to start a chat with.
    <br>
    <br>
    2. On the order details page, at the top right of the page is an ellipsis (three dot) button. Click the button to see the "Message Retailer" option and it will open the chat drawer with selected retailer.
    <br>
    <br>
    Note: A Retailer may not always be at their computer to respond immediately. You will see a red dot when you have an unread message. Click on the chat icon again and select the retailer you want to chat with.`,
    sequence: 6,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 7,
    question: 'How do I view product details?',
    videoId: null,
    answer: `On the order details page, click on the product image or the UPC of a product. This will open a quick view modal with all the product information including pricing, ingredients, and traits. Additionally, if you are viewing a shipper, you will be able to view the shipper contents.`,
    sequence: 7,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 8,
    question: 'How do I include notes on my order?',
    videoId: null,
    answer: `Within the order details, click on the "Order Notes" button found at the top of the order by the "Submit"/"Propose" button. This will open a modal where you can include notes to the Retailer about your order.`,
    sequence: 8,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 9,
    question: 'How do I know which retailers are particpating in Show Only Deals?',
    videoId: null,
    answer: `On the order details page, if the retailer is participating in Show Only Deals, there will be an orange banner at the top of the page saying the Retailer is participating.`,
    sequence: 9,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 10,
    question: 'How do you add free goods to an order?',
    videoId: null,
    answer: `On the order details page, when viewing a product in your order that you want to add free goods for, add the quantity in the "Free Qty" column.`,
    sequence: 10,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 11,
    question: 'How do I get notified about orders that are in negotiation?',
    videoId: null,
    answer: `You will see a bell icon at the top right of the application. When you click on it, you will see a list of notifications indicating you have order(s) that are ready to reveiw.`,
    sequence: 11,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 12,
    question: 'Are Show orders live orders?',
    videoId: null,
    answer: `No, these are future dated orders that go through the AOM process. Once the show is over your KeHE contact will reach out to you for next steps, if any are required.`,
    sequence: 12,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 13,
    question: 'What do the different order statuses mean?',
    videoId: null,
    answer: `Draft - The order is in the process of being built. Retailers don't have visability into the order.
            <br><br>Proposed - The Retailer proposed an order with deals to you. You can either accept, decline, or counter the order.
            <br><br>Countered - An order you countered with changes and sent back to the Retailer to review.
            <br><br>Accepted - A negotiation took place and both the Retailer and Supplier agreed to the order.
            <br><br>Countered-Draft - You have started the process of countering a Retailer's proposed order, but have not officially "Countered". Once you are satisified with the changes, you will click "Counter Order" to send back to the Retailer.
            <br><br>Submitted - The order had no deals, therefore no approval was needed.
            <br><br>Declined - An order with a deal was declined by the Retailer.`,
    sequence: 13,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 14,
    question: 'How do I delete an order?',
    videoId: null,
    answer: `Navigate to Orders page, and find the order you want to delete. Then, click the trashcan icon in the last column of the table. A confirmation modal will display to make sure you want to delete this order. Click "Delete" to confirm or "Cancel" if you choose not to delete the order.
             <br><br>Note: You can only delete Draft and Declined orders.`,
    sequence: 14,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 15,
    question: `What does "Not available in Retailer's DC" mean?`,
    videoId: null,
    answer: `This product is currently not available in the Retailer's Distribution Center (DC) that you are negotiating with. This product may not ship but will drive demand. If there is enough demand for this product, the higher the chances of getting it into your DC, and therefore making it available for the retailer to order.`,
    sequence: 15,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 16,
    question: `Where can I get my Show reports?`,
    videoId: null,
    answer: `On the Orders page, there is an Export Report button in the top right by the Order Upload button. After you click it, it will download an Excel report to your computer.`,
    sequence: 16,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },
  {
    id: 17,
    question: `How can I recall an order?`,
    videoId: null,
    answer: `You can recall a Countered, Submitted or Declined order by opening the order details, clicking on the ellipsis (three dots) in the top right of the page, and selecting Callback Order. A confirmation modal will then appear explaining what will happen. Click "Callback" to revert the order to an editable status so you can make changes and re-submit or re-counter.`,
    sequence: 17,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.Show
  },

  {
    id: 18,
    question: `What is ${Constants.KeHE_CONNECT_BI} and what are the key features?`,
    videoId: null,
    answer: `KeHE CONNECT BI consists of two pillars; 1) CONNECT Data (Reporting and Data Exchange),
            2) CONNECT Analytics (Analytics Dashboard and Gap & Void Dashboard).`,
    sequence: 18,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 19,
    question: `How do I access ${Constants.KeHE_CONNECT_BI}?`,
    videoId: null,
    answer: `Log into KeHE CONNECT Supplier and then navigate to the ${Constants.KeHE_CONNECT_BI} drop down option.`,
    sequence: 19,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 20,
    question: `How can I access ${Constants.KeHE_CONNECT_BI} training?`,
    videoId: null,
    answer: `Numerous training documents, videos, webinars and support materials are available to ensure you're
            comfortable with these new tools.  Please refer to the training documents posted in the CONNECT portal.`,
    sequence: 20,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 21,
    question: 'How do I setup Data Exchange?​',
    videoId: null,
    answer: `Access via the ${Constants.KeHE_CONNECT_BI} drop down in KeHE CONNECT Supplier.
            Once in BI, click "CONNECT Data" and then "Data Exchange" to subscribe.`,
    sequence: 21,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 22,
    question: 'Is the Marketing Allowance Program and Reporting no longer in place?',
    videoId: null,
    answer: `The legacy Marketing Allowance Program is being renamed ${Constants.KeHE_CONNECT_BI} and includes the new suite of data services.`,
    sequence: 22,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 23,
    question: `Who will have access to ${Constants.KeHE_CONNECT_BI} on my behalf?`,
    videoId: null,
    answer: `You can designate internal teams and brokers to have access.`,
    sequence: 23,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 24,
    question: `I am not part of the current Marketing Allowance Program - can I have a trial period for ${Constants.KeHE_CONNECT_BI}?`,
    videoId: null,
    answer: `We do not offer a trial period and require participation in ${Constants.KeHE_CONNECT_BI} to access these highly valued tools.
            Our teams are available to provide you more information and demonstrations if you are interested in learning more.`,
    sequence: 24,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 25,
    question: `Is there an additional fee for ${Constants.KeHE_CONNECT_BI}?`,
    videoId: null,
    answer: `The current fee for the Marketing Allowance (renamed ${Constants.KeHE_CONNECT_BI}) is being increased from 1.5% of
            Supplier Purchases to 2% in June 2021.`,
    sequence: 25,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 26,
    question: `My current Marketing Allowance Program fee is different than 1.5% - what will my new fee be for ${Constants.KeHE_CONNECT_BI}?`,
    videoId: null,
    answer: `There will be a .50% (50bps) increase to your current Marketing Allowance rate.
            At times, Suppliers elect to pay a higher Marketing Allowance Program fee to offset
            other GBB (Good, Better, Best) components.`,
    sequence: 26,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 27,
    question: `When will the ${Constants.KeHE_CONNECT_BI} fee be implemented by KeHE?`,
    videoId: null,
    answer: `The new data toolset launch will start in January 2020 and rollout will be completed by March 2021.
            Each supplier will receive a welcome email coinciding with their launch date.  The new fee structure will
            be implemented in June 2021.`,
    sequence: 27,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 28,
    question: 'My Broker currently has access to the Reports tile in CONNECT - will they now have access to the new tools?',
    videoId: null,
    answer: `Yes - brokers of record for a Supplier will have access to ${Constants.KeHE_CONNECT_BI} for participating suppliers once the
            platform is deployed to all Suppliers.  Access to the existing Reports tile will continue to be available for
            brokers until the launch is complete.`,
    sequence: 28,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
  {
    id: 29,
    question: 'Can you explain what Retailer Hierarchy is currently available in the new platform?',
    videoId: null,
    answer: `Definitions from largest to smallest:
    <ol>
      <li><b>Retailer Organization (Entity Name)</b>: a Retailer Organization contains multiple Retailer Areas (ex. ALBERTSON'S, SAFEWAY, HEB).</li>
      <li><b>Retailer Area (Banner Name)</b>: a localized chain or banner, but group of stores that are under single banner and generally are located within a specific region or geographical location (ex. HEB, CENTRAL MARKETS, ALBERTSONS DENVER, ALBERTSONS SOUTHERN, ALBERTSONS SEATTLE, SAFEWAY SEATTLE).</li>
      <li><b>Retailer Name (Subbanner Name)</b>: a localized chain but rolls up in a greater division (ex. SAFEWAY NORTHERN CALIFORNIA, SAFEWAY HAWAII).</li>
      <li><b>Customer Store</b>: an individual store location including their name and unique address.</li>
    </ol>`,
    sequence: 29,
    version: 1,
    isDeleted: false,
    group: HelpCenterGroup.ConnectBi
  },
];
