import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EsnSubscription } from '../../models/esn-subscription';
import { esnSubscriptionSelected, supplierPageChange, supplierSortChange } from '../../store/data-exchange.actions';
import {
  getSelectedEsnsForGrid,
  selectAvailableCount,
  selectEsnSubscriptionsForGrid,
  selectIsLoading,
  selectSupplierSkip,
  selectSupplierTake
} from '../../store/data-exchange.selectors';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { PermissionsService } from '@app/services/permissions.service';
import { ResourceActions } from '@app/constants/resource-actions';

@Component({
  selector: 'app-esn-subscription-grid',
  templateUrl: './esn-subscription-grid.component.html',
  styleUrls: ['./esn-subscription-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EsnSubscriptionGridComponent extends DestroyableDirective implements OnInit {

  sort$: Observable<SortDescriptor[]>;
  skip$: Observable<number>;
  take$: Observable<number>;
  count$: Observable<number>;
  sort: SortDescriptor[] = [{ dir: 'asc', field: 'supplierName'}];

  suppliersSubscriptions$: Observable<EsnSubscription[]>;
  selectedEsns$: Observable<string[]>;
  isLoading: boolean;
  thirdPartyUsers: boolean = false;

  constructor(
    private _store: Store,
    private _permSvc: PermissionsService, ) {
    super();
  }

  ngOnInit() {
    this.suppliersSubscriptions$ = this._store.select(selectEsnSubscriptionsForGrid);
    this.selectedEsns$ = this._store.select(getSelectedEsnsForGrid);
    if (this._permSvc.userHasAction(ResourceActions.ThirdPartyOfRecord)) {
      this.thirdPartyUsers = true;
    }

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectIsLoading),
    ).subscribe(val => this.isLoading = val);

    this.skip$ = this._store.select(selectSupplierSkip);
    this.take$ = this._store.select(selectSupplierTake);
    this.count$ = this._store.select(selectAvailableCount);

    // sort is not MVP
    // this.sort$ = this._store.pipe(
    //   takeUntil(this.destroy$),
    //   select(selectSupplierSort),
    //   map(sort => [{...sort}]),
    // );

  }

  esnSubscriptionSelected(esns: string[]): void {
    this._store.dispatch(esnSubscriptionSelected({ esns }));
  }

  onBrokersGridPageChange(event: PageChangeEvent): void {
    this._store.dispatch(supplierPageChange({
      skip: event.skip,
      take: event.take,
    }));
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  sortChange(sortEvent: SortDescriptor[]) {
    this.sort = sortEvent;
    const dir = sortEvent[0].dir == undefined ? 'asc' : sortEvent[0].dir;
    const field = sortEvent[0].field;
    this._store.dispatch(supplierSortChange({ sort: {dir: dir, field: field} }));
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }


}
