import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@app/services/permissions.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResourceActions } from '@app/constants/resource-actions';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

// Endpoint to use when the dashboard is not deployed to AAS
export const nonAasEndpoint = 'embedreport';
// Endpoint to use when the dashboard is deployed to AAS
export const aasEndpoint = 'customdata';

export const orderProjectionDashboardRoute = 'order-projection';
@Component({
  selector: 'app-order-projection-page',
  templateUrl: './order-projection-page.component.html',
  styleUrls: ['./order-projection-page.component.scss']
})
export class OrderProjectionPageComponent implements OnInit {

  public userToken$: Observable<string>;
  public dashboardHeight: any;
  public showDashboard: boolean;
  public dashboardUrl: string;
  showHelpModal = false;

  headerHeight = 50;
  navbarHeight = 40;
  titleHeight = 30;
  marginsHeight = 40;

  constructor(public _store: Store,
    private _permSvc: PermissionsService,) { }

  ngOnInit() {
    this.dashboardUrl = `${environment.powerbiReportApi}/embedreport?reportId=${environment.orderProjectionDashboardId}`;
    this.dashboardHeight = window.innerHeight - this.headerHeight - this.navbarHeight - this.titleHeight - this.marginsHeight;
    this.userToken$ = this._store.select(AuthenticationSelectors.selectToken);
    this.showDashboard = this._permSvc.userHasAction(ResourceActions.SupplierBiDataRead);
  }

}
