import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payload } from '../../../models/payload';
import { SupplierListFilter } from '../models/supplier-list-filter';
import { SupplierListItem } from '../models/supplier-list-item';
import { environment } from '../../../../environments/environment';
import { map, retry } from 'rxjs/operators';
import { Constants } from '../../../constants/constants';

@Injectable()
export class SupplierService {
  private _url: string;
  private _supplierListUrl: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.supplierEnterprise;
    this._supplierListUrl = environment.supplierApi;
  }

  getSupplierList(
    supplierListFilter: SupplierListFilter
  ): Observable<Payload<Array<SupplierListItem>>> {
    return this.httpClient
      .get<Payload<Array<SupplierListItem>>>(
        this._supplierListUrl,
        { params: this.generateParams(supplierListFilter) }
      )
      .pipe(retry(2), map(suppliers => {
        if (suppliers.data) {
          suppliers.data = suppliers.data.filter(supplier => supplier.esn !== Constants.DraftPlaceholderEsn);
          suppliers.availableCount = suppliers.data.length;
        }
        return suppliers;
      }));
  }

  get(
    supplierListFilter: SupplierListFilter
  ): Observable<Payload<Array<SupplierListItem>>> {
    return this.httpClient
      .get<any>(`${this._url}/list`, { params: this.generateParams(supplierListFilter) })
      .pipe(retry(2), map(suppliers => {
        if (suppliers.data) {
          suppliers.data = suppliers.data.filter(supplier => supplier.esn !== Constants.DraftPlaceholderEsn);
        }
        return suppliers;
      }));
  }

  private generateParams(
    supplierListFilter: SupplierListFilter
  ): HttpParams {
    let params = new HttpParams()
      .set('search', supplierListFilter.search)
      .set('pagecount', supplierListFilter.pageCount.toString())
      .set('pageindex', supplierListFilter.pageIndex.toString())
      .set('orderdirection', supplierListFilter.orderDirection.toString())
      .set('sortname', supplierListFilter.sortName.toString())
      .set(
        'includeavailablecount',
        supplierListFilter.includeAvailableCount.toString()
      );

    if (supplierListFilter.status) {
      params = params.append('status', supplierListFilter.status);
    }
    if (supplierListFilter.supplierDevManager && supplierListFilter.supplierDevManager.value) {
      params = params.append(
        'supplierdevelopmentmanager',
        supplierListFilter.supplierDevManager.value
      );
    }
    if (supplierListFilter.categoryManager && supplierListFilter.categoryManager.value) {
      params = params.append(
        'categorymanagercode',
        supplierListFilter.categoryManager.value
      );
    }
    if (!!supplierListFilter.esn && supplierListFilter.esn.length > 0) {
      params = params.append(
        'esn',
        supplierListFilter.esn.join(',')
      );
    }
    if (!!supplierListFilter.marketingAllowanceCode) {
      params = params.append(
        'marketingAllowanceCode',
        supplierListFilter.marketingAllowanceCode
      );
    }
    return params;
  }
}
