import { ExternalVideo } from './../../../../models/external-video';
import { SubscriptionBulkAction } from './../../models/enum/subscription-bulk-action';
import { DataSubscriptionPayload } from './../../models/data-subscription-payload';
import {
  selectSort,
  selectIsLoading,
  selectSubscriptions,
  selectAvailableCount,
  selectAlertMessage,
  selectSelectedSubscriptionsLength,
  selectSkip,
  selectTake,
} from './../../store/data-exchange.selectors';
import {
  loadSubscriptions,
  pageChange,
  sortChange,
  addSubscriptionClicked,
  createSubscription,
  alertClosed,
  showInfoModal,
  loadSuppliers,
  loadRetailerAreaNames,
  singleDeleteClicked,
  bulkDeleteClicked,
  subscriptionSelected,
  userLeavesDataExchangePage,
} from './../../store/data-exchange.actions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DataSubscription } from '../../models/data-subscription';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { AlertMessage } from '../../../../models/alert-message';
import { AlertEvents } from '@kehe/phoenix-notifications';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { HelpCenterGroup } from '../../../../modules/reporting/models/enum/help-center-group';
import { map, takeUntil } from 'rxjs/operators';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { RowArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsComponent extends DestroyableDirective  implements OnInit, OnDestroy {
  hasTransactionSucceeded$: Observable<any>;

  constructor(private _store: Store) {
    super();
  }

  // grid vars
  sort$: Observable<SortDescriptor[]>;
  skip$: Observable<number>;
  take$: Observable<number>;
  count: number;
  subscriptions$: Observable<Array<DataSubscription>>;
  isLoading: boolean;

  selectedSubscriptions: DataSubscription[] = [];
  selectedSubscriptionsCount$: Observable<number>;
  alert$: Observable<AlertMessage>;
  bulkActionOptions: OptionMenuItem[] = [
    SubscriptionBulkAction.Delete
  ].map(o => new OptionMenuItem(o, o, true));

  showHelpModal = false;

  helpVideos: ExternalVideo[] = [
    {
      videoTitle: 'Setting Up a Data Exchange',
      vimeoId: '502638255/183deb720d',
      group: HelpCenterGroup.ConnectBi,
    },
  ];

  ngOnDestroy(): void {
      this._store.dispatch(userLeavesDataExchangePage());
  }

  ngOnInit() {
    this.getSubscriptions();
  }

  getSubscriptions() {
    this._store.dispatch(loadSubscriptions());
    this._store.dispatch(loadSuppliers());
    this._store.dispatch(loadRetailerAreaNames());

    this.subscriptions$ = this._store.pipe(
      select(selectSubscriptions),
    );

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectIsLoading),
    ).subscribe(val => this.isLoading = val);

    this.sort$ = this._store.pipe(
      select(selectSort),
      map(sort => [{...sort}]),
    );

    this.skip$ = this._store.pipe(
      select(selectSkip)
    );

    this.take$ = this._store.pipe(
      select(selectTake)
    );

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectAvailableCount)
    ).subscribe(count => this.count = count);

    this.selectedSubscriptionsCount$ = this._store.pipe(
      select(selectSelectedSubscriptionsLength)
    );

    this.alert$ = this._store.pipe(select(selectAlertMessage));
  }

  onSubscriptionsGridPageChange(event: PageChangeEvent): void {
    this._store.dispatch(pageChange({
      skip: event.skip,
      take: event.take,
    }));
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  onSubscriptionsGridSortChange(sortDescriptor: SortDescriptor[]) {
    this._store.dispatch(sortChange({ sort: sortDescriptor[0] }));
  }

  showSubscriptionInfoModal(subscription: DataSubscription): void {
    this._store.dispatch(showInfoModal({ subscription }));
  }

  onDeleteSubscription(subscription: DataSubscription): void {
    this._store.dispatch(singleDeleteClicked({ subscription }));
  }

  bulkActionSelected(bulkAction: OptionMenuItem) {
    if (bulkAction.data === SubscriptionBulkAction.Delete) {
      this._store.dispatch(bulkDeleteClicked());
    }
  }

  public handleAlertEvents(e: { type: string }) {
    if (e.type === AlertEvents.Closed) {
      this._store.dispatch(alertClosed());
    }
  }

  addSubscriptionClicked() {
    this._store.dispatch(addSubscriptionClicked());
  }

  onCreateNewSubscription(subscription: DataSubscriptionPayload) {
    this._store.dispatch(createSubscription({subscription}));
  }

  getKeyForSelectedSubscription(rowArgs: RowArgs): DataSubscription {
    return rowArgs.dataItem;
  }

  onSelectedSubscriptionsChange(selectedSubscriptions: DataSubscription[]): void {
    this._store.dispatch(subscriptionSelected(
      { subscriptions: selectedSubscriptions }
    ));
  }

}
