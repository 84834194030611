import { Component, OnInit } from '@angular/core';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { getCartTotal, getNumberOfItemsInCart } from '@app/modules/data-exchange/store/data-exchange.selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent
    extends DestroyableDirective
    implements OnInit {
    company$: Observable<string>;
    numberOfItems$: Observable<number>;
    cartTotal: number;

    constructor(private store: Store) {
        super();
    }

    ngOnInit() {
        this.company$ = this.store.select(AuthenticationSelectors.selectUserCompany);
        this.numberOfItems$ = this.store.select(getNumberOfItemsInCart);
        this.store
            .pipe(takeUntil(this.destroy$), select(getCartTotal))
            .subscribe((cartTotal) => (this.cartTotal = cartTotal));
    }
}
