export interface UncoveredBrandTempClassAlertResponse {
    alertType: PoAlertType;
    alertMessage: string;
    brandTempClassList: Array<string>;
}

export enum PoAlertType {
    Error = 'Error',
    Warning = 'Warning',
}
