import { IFormError } from '../models/iform-error';
import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function hasProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function getFormValidationErrors(form: any, parentKey?: string): Array<IFormError> {

  const result: Array<IFormError> = [];
  Object.keys(form.controls).forEach(key => {
    if (form.get(key).enabled) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      if (form.get(key) instanceof UntypedFormGroup) {
        result.push(...getFormValidationErrors(form.get(key), fullKey));
      } else if (form.get(key) instanceof UntypedFormArray) {
        result.push(...getFormValidationErrors(form.get(key), fullKey));
      } else {
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': fullKey,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      }
    }
  });
  return result;
}

export function prepPhone(phoneNumber: string): string {
  if (phoneNumber && !phoneNumber.startsWith('+')) { return `+1${phoneNumber}`; }
  return phoneNumber;
}


export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function mapItemsToCodeValue(items: any[]) {
  return items.map((element) => {
    return {
        code: element.code,
        codeValue: element.value + ' (' + element.code + ')',
        value: element.value,
    };
  });
}
