import { Injectable } from '@angular/core';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbsService {

  readonly key = 'KendoBreadCrumbs';

  get(): BreadCrumbItem[] {
    return JSON.parse(window.sessionStorage.getItem('this.key'));
  }

  set(breadCrumbs: string): void {
    window.sessionStorage.setItem(this.key, breadCrumbs);
  }

}
