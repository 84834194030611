<div class="d-flex align-items-center justify-content-center">
  <ng-container *ngIf="subscription?.isInCart; else esnNotInCart">
    <em class="fas fa-check-circle grid-icon in-cart" title="Added to Cart"></em>
  </ng-container>
  <ng-template #esnNotInCart>
    <kehe-phoenix-button  [spinner]="(registerInProgress$ | async) 
    && (((isBulkAction | async) === false && (registerRowID | async) === subscription?.esn)
    || ((isBulkAction | async) && isSelected(subscription?.esn) )
    )"
     [disabled]="(registerInProgress$ | async) && (registerRowID | async) !== subscription?.esn" 
     *ngIf="subscription?.status === esnSubscriptionStatus.Unregistered || subscription?.status === esnSubscriptionStatus.Canceled || subscription?.status === esnSubscriptionStatus.Expired" classAttr="btn-large btn-link" (click)="registerEsnSubscription(subscription)" title="Register">
    <em *ngIf="(registerInProgress$ | async) === false || (((isBulkAction | async) === false && (registerRowID | async) !== subscription?.esn)
    || ((isBulkAction | async) && !isSelected(subscription?.esn)) )" class="fas fa-plus-circle"></em>
    </kehe-phoenix-button>
    
    <kehe-phoenix-button  *ngIf="(subscription?.status === esnSubscriptionStatus.Registered || subscription?.status === esnSubscriptionStatus.PendingRenewal || subscription?.status === esnSubscriptionStatus.ExpiringSoon) && !thirdPartyUsers" classAttr="btn-large btn-link" (click)="cancelEsnSubscription(subscription)" title="Cancel Auto-Renew">
      <em class="fas fa-minus-circle"></em>
    </kehe-phoenix-button>
    <ng-container  *ngIf="(subscription?.status === esnSubscriptionStatus.Registered || subscription?.status === esnSubscriptionStatus.PendingRenewal || subscription?.status === esnSubscriptionStatus.ExpiringSoon) && thirdPartyUsers" classAttr="btn-large btn-link"  title="subscribed">
      <em class="fas fa-check-circle grid-icon in-cart"></em>
    </ng-container>
  </ng-template>
  <kehe-phoenix-button classAttr="btn-large btn-link" (click)="onManageRecipientsClick(subscription)" title="Manage Recipients" [disabled]="subscription?.status === esnSubscriptionStatus.Unregistered || subscription?.status === esnSubscriptionStatus.Expired">
    <em class="fas fa-users"></em>
  </kehe-phoenix-button>
</div>