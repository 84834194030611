import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DestroyableDirective } from '../../abstract/destroyable';
import {
  UserNotificationDeliveryMethod,
  UserNotificationType,
  NotificationListType,
  UserNotificationPreferences
} from '../../modules/user-notifications/model/user-notifications';
import { UserNotificationsFacade } from '../../modules/user-notifications/store/user-notifications.facade';
import { createNotificationTypeList } from '../../modules/user-notifications/utils/create-notification-type-list';
import { IKeheNavigationTab } from '@kehe/phoenix-navigation-panel';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

enum MyProfileTab {
  Notifications = 'Notifications'
}

@Component({
  selector: 'app-my-profile-page',
  templateUrl: './my-profile-page.component.html',
  styleUrls: ['./my-profile-page.component.scss']
})
export class MyProfilePageComponent extends DestroyableDirective  implements OnInit {

  public emailNotificationTypeList: Array<NotificationListType>;

  public inAppNotificationTypeList: Array<NotificationListType>;

  public emailMethod = UserNotificationDeliveryMethod.Email;
  public inAppMethod = UserNotificationDeliveryMethod.InApp;

  navigationTabs:  IKeheNavigationTab[] = [];
  currentTab: MyProfileTab;
  isPanelOpen = true;

  email$: Observable<string>;
  private notificationPreferences: UserNotificationPreferences;

  constructor(
    private store: Store,
    private userNotificationsFacade: UserNotificationsFacade
  ) {
    super();
    Object.values(MyProfileTab).forEach(tab => {
      this.navigationTabs.push({tab: tab});
    });
    this.currentTab = MyProfileTab.Notifications;
  }

  ngOnInit() {
    this.email$ = this.store.select(AuthenticationSelectors.selectUserEmail);
    this.userNotificationsFacade.getAllLoadedUserNotificationPreferences()
    .pipe(takeUntil(this.destroy$))
    .subscribe(preferences => {
      this.notificationPreferences = preferences;
      this.emailNotificationTypeList = createNotificationTypeList(preferences.email);
      this.inAppNotificationTypeList = createNotificationTypeList(preferences.inApp);
    });
  }

  onCheckboxChanged(isChecked: boolean, option: UserNotificationType, method: string): void {
    if (method === 'email' || method === 'inApp') {
      this.userNotificationsFacade.updateUserNotificationPreferences(
        { method, option, status: isChecked }
      );
    }
  }

  togglePanelOpen() {
    this.isPanelOpen = !this.isPanelOpen;
  }

  onTabSelected($event: MyProfileTab) {
    this.currentTab = $event;
  }
}
