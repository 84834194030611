import { ReportingState, reportingFeatureKey } from './reporting.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getReportingState = createFeatureSelector<ReportingState>(
  reportingFeatureKey
);

export const selectReportOptions = createSelector(
  getReportingState,
  (state: ReportingState) => state.reportOptions
);

export const selectSelectedReport = createSelector(
  getReportingState,
  (state: ReportingState) => state.selectedReport
);

export const selectReportsVisible = createSelector(
  getReportingState,
  (state: ReportingState) => state.reportsVisible
);

export const selectReportParameters = createSelector(
  getReportingState,
  (state: ReportingState) => state.reportParameters
);

export const selectUserGuid = createSelector(
  getReportingState,
  (state: ReportingState) => state.reportParameters.Requestor
);

export const selectAlertMessage = createSelector(
  getReportingState,
  (state: ReportingState) => state.alertMessage
);
