import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-add-draft-future-line-item-toast',
  templateUrl: './add-draft-future-line-item-toast.component.html',
  styleUrls: ['./add-draft-future-line-item-toast.component.scss']
})

export class AddDraftFutureLineItemToastComponent {
  @Input() orderType: OrderType;
  @Input() orderName: string;
  @Input() orderId: number;
  @Input() toastMessage: AddDraftFutureLineItemToastMessages;
  @Input() showItemName: string;
  @Input() showItemUrl: string;
  @Input() warningUp: string;
  @Input() warningDown: string;

  constructor() { }

}

export enum AddDraftFutureLineItemToastMessages {
  ItemAdded = 'ItemAdded',
  ShowOrderCreated = 'ShowOrderCreated'
}

export enum OrderType {
  Everyday = 'every-day',
  Show = 'show'
}
