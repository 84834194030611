import { IMessagesToRender } from './../models/IMessagesToRender';
import { IMessage } from './../models/IMessage';
import isSameDay from './isSameDay';

const addToMessagesToRender = (messagesToRender: Array<IMessagesToRender>, newMessage: IMessage): Array<IMessagesToRender> => {

  const dateToCompare =
    messagesToRender &&
    messagesToRender.length > 0 &&
    messagesToRender[messagesToRender.length - 1].messages &&
    messagesToRender[messagesToRender.length - 1].messages.length > 0 &&
    messagesToRender[messagesToRender.length - 1].messages[0] ?
    messagesToRender[messagesToRender.length - 1].messages[0].createdAt :
    '';

  if (!messagesToRender) {
    messagesToRender = [];
  }

  if (dateToCompare) {
    if (isSameDay(new Date(newMessage.createdAt), new Date(dateToCompare))) {
      messagesToRender[messagesToRender.length - 1].messages.push(newMessage);
    } else {
      messagesToRender.push({
        date: newMessage.dayToRender,
        messages: [newMessage]
      });
    }
  } else {
    messagesToRender.push({
      date: newMessage.dayToRender,
      messages: [newMessage]
    });
  }

  return messagesToRender;

};

export default addToMessagesToRender;
