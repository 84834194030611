import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

@Component({
  selector: 'app-help-center-page-show',
  templateUrl: './help-center-page-show.component.html',
  styleUrls: ['./help-center-page-show.component.scss']
})
export class HelpCenterPageShowComponent {

    supplierIsInShow: boolean;
    showChecked$: Observable<boolean>;

    constructor(
        private _store: Store
    ) {
        this.showChecked$ = this._store.select(ShowSelectors.selectHasShowBeenChecked);
        this._store.select(ShowSelectors.selectIsSupplierInShow).subscribe(val => this.supplierIsInShow = val);
    }

    public pdfUrl = environment.showImageBucket + '/HOH20 Sales Contacts.pdf';

}
