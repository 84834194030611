import { IChatRoom } from './../../models/IChatRoom';

const createChatRoomMutation = (chatRoom: IChatRoom): string => {

    return `mutation createChatRoomMutation {
      createChatRoom(input: {
        customerName: "${chatRoom.customerName}",
        customerNumber: "${chatRoom.customerNumber}",
        customerSupplierNumber: "${chatRoom.customerNumber}\|${chatRoom.supplierNumber}",
        supplierName: "${chatRoom.supplierName}",
        supplierNumber: "${chatRoom.supplierNumber}"}) {
          id
          customerSupplierNumber
          supplierName
          supplierNumber
          customerName
          customerNumber
        }
      }`;
};

export default createChatRoomMutation;
