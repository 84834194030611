import { userLeavesDataExchangePage } from './../../store/data-exchange.actions';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { takeUntil } from 'rxjs/operators';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag/index';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

@Component({
  selector: 'app-data-exchange-page',
  templateUrl: './data-exchange-page.component.html',
})
export class DataExchangePageComponent extends DestroyableDirective  implements OnInit, OnDestroy {

  constructor(private _store: Store) {
    super();
  }

  isBrokerFeatureEnabled: boolean;
  userType: string;

  ngOnDestroy(): void {
      this._store.dispatch(userLeavesDataExchangePage());
  }

  ngOnInit() {
    combineLatest([
      this._store.select(selectIsFeatureFlagOn(FeatureFlags.DataExchangeForBrokers.key)),
      this._store.select(AuthenticationSelectors.selectUserType),
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([isFeatureFlagEnabled, userType]) => {
      this.isBrokerFeatureEnabled = isFeatureFlagEnabled;
      this.userType = userType;
    });
  }

}
