import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { cartComponentDestroyed, cartPageChange, getItems, removeItemFromCart } from '@app/modules/data-exchange/store/data-exchange.actions';
import { getNumberOfItemsInCart, selectItems, selectSkipForCart, selectTakeForCart } from '@app/modules/data-exchange/store/data-exchange.selectors';
import { Store } from '@ngrx/store';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { Observable } from 'rxjs';
import { CartItem } from '../../models/cart-item';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnDestroy, OnInit {

  @Input() canDelete: boolean;
  items$: Observable<CartItem[]>;
  skip$: Observable<number>;
  take$: Observable<number>;
  count$: Observable<number>;

  constructor(private store: Store) { }

  ngOnDestroy(): void {
    this.store.dispatch(cartComponentDestroyed());
  }

  ngOnInit() {
    this.store.dispatch(getItems());
    this.items$ = this.store.select(selectItems);
    this.skip$ = this.store.select(selectSkipForCart);
    this.take$ = this.store.select(selectTakeForCart);
    this.count$ = this.store.select(getNumberOfItemsInCart);
  }

  removeItem(esn: string): void {
    this.store.dispatch(removeItemFromCart({ esn: esn }));
  }

  onPageChange(event: PageChangeEvent): void {
    this.store.dispatch(cartPageChange({
      skip: event.skip,
      take: event.take,
    }));
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

}
