import { createAction, props } from "@ngrx/store";
import { BreadCrumbItem } from "@progress/kendo-angular-navigation";

export const updateKendoBreadCrumbs = createAction(
    '[Supplier Detail Page] Update BreadCrumbs',
    props <{ item: BreadCrumbItem }>(),
);

export const removeKendoBreadCrumbs = createAction(
    '[Supplier Detail Page] Remove BreadCrumbs',
    props <{ item: BreadCrumbItem }>(),
);

export const clearKendoBreadCrumbs = createAction(
    '[Supplier Detail Page] Clear BreadCrumbs',
);

export const clickedKendoBreadCrumbs = createAction(
    '[Supplier Detail Page] Clicked BreadCrumbs',
    props <{ item: BreadCrumbItem, selectedTab?: string }>(),
);