import { Component, Input, OnInit } from '@angular/core';
import { Faq } from '../../models/faq';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DestroyableDirective } from '../../abstract/destroyable';
import { ExternalVideo } from '../../models/external-video';
import * as FaqActions from './faqs.actions';
import * as HelpSelectors from '@app/shared/state/help/help.selectors';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent extends DestroyableDirective  implements OnInit {

  @Input() faqOverride: Faq[];
  @Input() showTitle = true;
  public faqs: Faq[] = [];
  public filteredFaqs: Faq[];

  constructor(
    private _store: Store
  ) {
    super();
  }

  ngOnInit() {
    this._store.dispatch(FaqActions.componentLoaded());
    if (!this.faqOverride) {
      this._store.pipe(
        select(HelpSelectors.selectEverydayFaqs),
        takeUntil(this.destroy$)
      ).subscribe(val => {
        this.faqs = val;
        this.filteredFaqs = val;
      });
    } else {
      this.faqs = this.faqOverride;
      this.filteredFaqs = this.faqOverride;
    }
  }

  searchChanged($event: string): void {
    if ($event) {
      const searchTerms = $event.toLocaleLowerCase().split(' ');
      this.filteredFaqs = this.faqs.filter(f => searchTerms.some(s => f.question.toLocaleLowerCase().includes(s)));
    } else {
      this.filteredFaqs = [...this.faqs];
    }
  }

  playVideo(faq: Faq): void {
    this._store.dispatch(FaqActions.videoSelected({
      video: new ExternalVideo('Faq #' + faq.sequence, faq.videoId, faq.group),
    }));
  }
}
