import {
  UserAgreementRequestType,
  UserAgreementState
} from './user-agreement.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectUserAgreementState = createFeatureSelector<UserAgreementState>('userAgreement');

export const selectUserAgreementStatus = createSelector(
  selectUserAgreementState,
  state => state.userAgreementStatus
);

export const selectTermsAndConditionsPage = createSelector(
  selectUserAgreementState,
  state => state.termsAndConditionsPage
);

export const selectPrivacyStatementPage = createSelector(
  selectUserAgreementState,
  state => state.privacyStatementPage
);

export const selectIsLoading = (requestType: UserAgreementRequestType) => createSelector(
  selectUserAgreementState,
  state => state.isLoading[requestType]
);

export const selectShouldDisplayAlert = createSelector(
  selectUserAgreementState,
  state => state.shouldDisplayAlert
);

export const selectAlert = createSelector(
  selectUserAgreementState,
  state => state.alert
);
