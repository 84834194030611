<div *ngIf="!userType">
  <kehe-phoenix-loading></kehe-phoenix-loading>
</div>

<app-breadcrumbs title="Data Exchange" type="parent"></app-breadcrumbs>

<div *ngIf="userType" class="container">
  <ng-container *ngIf="isBrokerFeatureEnabled && userType === 'broker'">
    <app-esn-subscriptions></app-esn-subscriptions>
  </ng-container>
  <ng-container *ngIf="userType === 'supplier'">
    <app-subscriptions></app-subscriptions>
  </ng-container>
</div>
