import { on, createReducer } from "@ngrx/store";
import * as FaqApiActions from "@app/faq/faq.actions";
import { Faq } from "@app/models/faq";
import * as FaqActions from '@app/components/faqs/faqs.actions';
import * as ShowFaqActions from '@app/components/faqs-show/faqs-show.actions';
import * as TrainingVideoActions from '@app/components/training-videos/training-videos.actions';
import * as ShowTrainingVideoActions from '@app/components/training-videos-show/training-videos-show.actions';
import { ExternalVideo } from "@app/models/external-video";
import * as VideoPlayerModalActions from '@app/components/video-player-modal/video-player-modal.actions';

export const key = 'help';

export interface State {
    faqs: Faq[];
    selectedVideo: ExternalVideo,
}

export const initialState: State = {
    faqs: [],
    selectedVideo: null,
};

export const reducer = createReducer(
    initialState,
    on(FaqApiActions.getFaqsSuccess, (state, { faqs }) => {
        return {
            ...state,
            faqs
        };
    }),
    on(
        FaqActions.videoSelected, 
        ShowFaqActions.videoSelected,
        TrainingVideoActions.videoSelected,
        ShowTrainingVideoActions.videoSelected, 
        (state, params) => ({
            ...state,
            selectedVideo: params.video
      })),
      on(VideoPlayerModalActions.modalClosed, (state) => ({
        ...state,
        selectedVideo: null
      })),
);

export const getFaqs = (state: State) => state && state.faqs;
export const getSelectedVideo = (state: State) => state && state.selectedVideo;