import { Filter } from './../../../models/filter';
import { SortDescriptor } from '@progress/kendo-data-query';

export class DataExchangeFilter extends Filter {
  public sort: SortDescriptor;

  constructor() {
      super(true);
      this.sort = { field: 'reportNameDisplay', dir: 'asc' };
      this.skip = 0;
      this.take = 25;
  }
}
