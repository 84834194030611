import { createReducer, on } from '@ngrx/store';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import * as BreadCrumbsApiActions from '@app/breadcrumbs/breadcrumbs.actions';
import * as ProductDetailPageActions from '@app/modules/product-detail/pages/product-detail-page/product-detail-page.actions';
import * as ProductPageActions from '@app/modules/product-list/pages/product-page/product-page.actions';
import * as SupplierDetailPageActions from '@app/modules/supplier-detail/pages/supplier-detail-page/supplier-detail-page.actions';
import * as SupplierDetailApiActions from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as OrderDetailTopMenuActions from '@app/modules/orders-show/detail/components/order-detail-top-menu/order-detail-top-menu.actions';
import * as SupplierListPageActions from '@app/modules/supplier-list/pages/supplier-page/supplier-page.actions';

export const key = 'breadCrumbs';

export interface State {
    kendoBreadCrumbs: BreadCrumbItem[];
}

export const initialState: State = {
    kendoBreadCrumbs: [],
};

export const reducer = createReducer(
    initialState,
    on(BreadCrumbsApiActions.getBreadCrumbsSuccess, (state, { breadCrumbs }) => {
      return {
        ...state,
        kendoBreadCrumbs: [...state.kendoBreadCrumbs, ...breadCrumbs],
      }
    }),
    on(
      ProductDetailPageActions.updateKendoBreadCrumbs,
      ProductPageActions.updateKendoBreadCrumbs,
      SupplierDetailPageActions.updateKendoBreadCrumbs,
      OrderDetailTopMenuActions.updateKendoBreadCrumbs,
      (state, params) => {
        if (state.kendoBreadCrumbs.some(breadCrumb => breadCrumb.text === params.item.text)) {
          return {...state};
        } else {
          return {
            ...state,
            kendoBreadCrumbs: [...state.kendoBreadCrumbs, params.item],
          }
        }
      }
      ),
    on(
      OrderDetailTopMenuActions.clearKendoBreadCrumbs,
      ProductDetailPageActions.clearKendoBreadCrumbs,
      ProductPageActions.clearKendoBreadCrumbs,
      SupplierDetailPageActions.clearKendoBreadCrumbs,
      SupplierDetailApiActions.clearKendoBreadCrumbs,
      SupplierListPageActions.clearKendoBreadCrumbs,
      (state) => {
        return {
          ...state,
          kendoBreadCrumbs: [],
        }
      }
    ),
    on(SupplierDetailApiActions.replaceKendoBreadCrumbs, (state, params) => {
      return {
        ...state,
        kendoBreadCrumbs: [...params.items],
      };
    }),
    on(SupplierDetailPageActions.removeKendoBreadCrumbs, (state, params) => {
      const index = state.kendoBreadCrumbs.findIndex(e => e.text === params.item.text);
      if (index === -1) {
        return {...state};
      } else {
        return {
          ...state,
          kendoBreadCrumbs: [...state.kendoBreadCrumbs.slice(0, index), params.item],
        }
      }
    }),
);

export const getBreadCrumbs = (state: State) => state && state.kendoBreadCrumbs;