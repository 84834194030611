<div class="analytics-page-component">
  <div *ngIf="showDashboard" class="dashboard">
    <div class="d-flex justify-content-between">
      <h2>Analytics</h2>
      <kehe-phoenix-button
        name="help-button"
        classAttr="btn-medium btn-link"
        iconClassAttr="fa fa-question"
        (click)="showHelpModal = true;"
      >
      Help
      </kehe-phoenix-button>
    </div>
    <app-report [url]="dashboardUrl"
                [token]="(userToken$ | async)"
                [height]="dashboardHeight">
    </app-report>
  </div>
  <app-unauthorized-page *ngIf="!showDashboard"></app-unauthorized-page>
  <app-help-modal
    [show]="showHelpModal"
    (modalClose)="showHelpModal = false;"
    [videos]="helpVideos"
    [faqs]="faqs"
  >
  </app-help-modal>
</div>
