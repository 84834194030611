import { Component } from '@angular/core';

@Component({
  selector: 'app-mock-component',
  templateUrl: './mock-component.component.html',
  styleUrls: ['./mock-component.component.css']
})
export class MockComponentComponent {

  constructor() { }

}
