import { SupplierListItem } from './../../supplier-list/models/supplier-list-item';
import { ProductListItem } from './../../product-list/models/product-list-item';
import { DatePipe } from '@angular/common';
import { ReportName } from './../../reporting/models/enum/report-name.enum';
import { Supplier } from './../models/supplier';
import { DataSubscriptionPayload } from './../models/data-subscription-payload';
import { DayOfWeek, dayOfWeekAbbreviationMap } from './../models/enum/day-of-week';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { createReducer, on, Action } from '@ngrx/store';
import { AlertMessage } from './../../../models/alert-message';
import { DataExchangeFilter } from './../models/data-exchange-filter';
import { DataSubscription } from '../models/data-subscription';
import * as DataExchangeActions from './data-exchange.actions';
import { RetailerArea } from '../models/retailer-area';
import { Brand } from '../../product-detail/models/brand';
import { DataExchangeSupplierFilter } from '../models/data-exchange-supplier-filter';
import { IAuthorizeNetResponse } from '../checkout/models/iauthorize-net-response';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { CartItem } from '../checkout/models/cart-item';
import { EsnSubscription } from '../models/esn-subscription';
import { ManageRecipientsForm } from '../models/manage-recipients-form';
import { EsnSubscriptionStatus } from '../models/enum/esn-subscription-status';
import { EsnSubscriptionBillingFrequency } from '../models/enum/esn-subscription-billing-frequency';

export interface DataExchangeState {
  subscriptions: DataSubscription[];
  esnSubscriptions: EsnSubscription[];
  isLoading: boolean;
  availableCount: number;
  filter: DataExchangeFilter;
  suppliersByEmailFilter: DataExchangeSupplierFilter;
  alertMessage: AlertMessage;
  showAddSubscriptionModal: boolean;
  showDeleteSubscriptionModal: boolean;
  supplierOptions: Supplier[];
  supplierBrandList: Brand[];
  createSubscriptionLoading: boolean;
  deleteSubscriptionsLoading: boolean;
  showInfoModal: boolean;
  infoModalItem: DataSubscription;
  deleteModalItem: DataSubscription;
  retailerAreaNames: RetailerArea[];
  products: ProductListItem[];
  selectedSubscriptions: DataSubscription[];
  selectedSupplierSubscriptions: EsnSubscription[];
  isOrderProcessing: boolean;
  paymentTokenResponse: IAuthorizeNetResponse;
  transactionResponse: any;
  items: CartItem[];
  allItemsInCart: CartItem[];
  itemsToSkip: number;
  itemsToTake: number;
  esnSubscriptionToTakeActionOn: EsnSubscription;
  esn: string;
  showManageRecipientsModal: boolean;
  manageRecipientsBulkMode: boolean;
  isRecipientEmailListLoading: boolean;
  recipientEmailList: string[];
  manageRecipientsFormValue: ManageRecipientsForm;
  manageRecipientsFormUpdateInProgress: boolean;
  manageRecipientsUpdateErrorMessage: string;
  showSubscriptionOverviewModal: boolean;
  showCancelEsnSubscriptionModal: boolean;
  cancelEsnSubscriptionUpdateInProgress: boolean;
  cancelEsnSubscriptionErrorMessage: string;
  showFrequencyModal: boolean;
  billingFrequencySelected: EsnSubscriptionBillingFrequency;
  registerPreviouslyCanceledEsnProgress:boolean;
  bulkAction:boolean;
}

export const initializeState = (): DataExchangeState => {
  return {
    isLoading: false,
    subscriptions: new Array<DataSubscription>(),
    esnSubscriptions: [],
    availableCount: 0,
    filter: new DataExchangeFilter(),
    suppliersByEmailFilter: new DataExchangeSupplierFilter(),
    alertMessage: new AlertMessage(),
    showAddSubscriptionModal: false,
    supplierOptions: [],
    createSubscriptionLoading: false,
    supplierBrandList: [],
    showInfoModal: false,
    infoModalItem: null,
    deleteModalItem: null,
    retailerAreaNames: [],
    deleteSubscriptionsLoading: false,
    showDeleteSubscriptionModal: false,
    products: [],
    selectedSubscriptions: [],
    selectedSupplierSubscriptions: [],
    isOrderProcessing: false,
    paymentTokenResponse: null,
    transactionResponse: null,
    items: [],
    allItemsInCart: [],
    itemsToSkip: 0,
    itemsToTake: 25,
    esnSubscriptionToTakeActionOn: null,
    esn: null,
    showManageRecipientsModal: false,
    manageRecipientsBulkMode: false,
    isRecipientEmailListLoading: false,
    recipientEmailList: [],
    manageRecipientsFormValue: null,
    manageRecipientsFormUpdateInProgress: false,
    manageRecipientsUpdateErrorMessage: null,
    showSubscriptionOverviewModal: false,
    showCancelEsnSubscriptionModal: false,
    cancelEsnSubscriptionUpdateInProgress: false,
    cancelEsnSubscriptionErrorMessage: null,
    showFrequencyModal: false,
    billingFrequencySelected: null,
    registerPreviouslyCanceledEsnProgress:false,
    bulkAction:false,
  };
};

export const initialState = initializeState();

export const itemAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (item: any) => item.id
});

const rootReducer = createReducer(
  initialState,
  on(DataExchangeActions.loadSubscriptions, (state) => ({
    ...state,
    isLoading: true
  })),
  on(DataExchangeActions.loadSuppliersByEmail, (state) => ({
    ...state,
    isLoading: true
  })),
  on(DataExchangeActions.loadSubscriptionsSuccess, (state, params) => ({
    ...state,
    isLoading: false,
    subscriptions: populateViewProperties(params.subscriptions.data),
    availableCount: params.subscriptions.availableCount,
  })),
  on(DataExchangeActions.loadSupplierSubscriptionsSuccess, (state, params) => ({
    ...state,
    isLoading: false,
    esnSubscriptions: mapSuppliersToSubscriptions(params.suppliers, params.subscriptions),
    availableCount: params.availableCount
  })),
  on(DataExchangeActions.loadSubscriptionsFailure, (state, params) => ({
    ...state,
    isLoading: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: params.message
    }
  })),
  on(DataExchangeActions.pageChange, (state, params) => ({
    ...state,
    filter: {
      ...state.filter,
      skip: params.skip,
      take: params.take,
    }
  })),
  on(DataExchangeActions.supplierPageChange, (state, params) => ({
    ...state,
    suppliersByEmailFilter: {
      ...state.suppliersByEmailFilter,
      skip: params.skip,
      take: params.take,
    },
    selectedSupplierSubscriptions: [],
  })),
  on(DataExchangeActions.sortChange, (state, params) => ({
    ...state,
    filter: {
      ...state.filter,
      sort: {
        ...state.filter.sort,
        field: params.sort.field,
        dir: params.sort.dir,
      },
      pageIndex: 0,
    }
  })),
  on(DataExchangeActions.supplierSortChange, (state, params) => ({
    ...state,
    suppliersByEmailFilter: {
      ...state.suppliersByEmailFilter,
      sort: {
        ...state.suppliersByEmailFilter.sort,
        ...params.sort
      },
      pageIndex: 0
    }
  })),
  on(DataExchangeActions.addSubscriptionClicked, (state) => ({
    ...state,
    showAddSubscriptionModal: true
  })),
  on(DataExchangeActions.addSubscriptionModalDismissed, (state) => ({
    ...state,
    showAddSubscriptionModal: false
  })),
  on(DataExchangeActions.loadSupplierDetailsSuccess, (state, params) => ({
    ...state,
    supplierOptions: getAllowableSuppliers(
      params.userSuppliers,
      params.nonConnectBiSuppliers
    )
  })),
  on(DataExchangeActions.createSubscription, (state, params) => ({
    ...state,
    createSubscriptionLoading: true
  })),
  on(DataExchangeActions.createSubscriptionSuccess, (state, params) => ({
    ...state,
    showAddSubscriptionModal: false,
    createSubscriptionLoading: false,
    alertMessage: {
      type: AlertTypes.Success,
      message: `Successfully created a new data subscription for supplier ${params.subscription.supplier.name}.`
    }
  })),
  on(DataExchangeActions.createSubscriptionFailure, (state, params) => ({
    ...state,
    showAddSubscriptionModal: false,
    createSubscriptionLoading: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: getErrorMessage(params.message)
    }
  })),
  on(DataExchangeActions.singleDeleteClicked, (state, params) => ({
    ...state,
    showDeleteSubscriptionModal: true,
    deleteModalItem: params.subscription
  })),
  on(DataExchangeActions.bulkDeleteClicked, (state) => ({
    ...state,
    showDeleteSubscriptionModal: true
  })),
  on(DataExchangeActions.deleteModalDismissed, (state) => ({
    ...state,
    showDeleteSubscriptionModal: false,
    deleteModalItem: null
  })),
  on(DataExchangeActions.deleteSubscriptions, (state) => ({
    ...state,
    deleteSubscriptionsLoading: true
  })),
  on(DataExchangeActions.deleteSubscriptionsSuccess, (state, params) => {
    const num = params.deletedSubscriptions.length;
    return {
      ...state,
      showDeleteSubscriptionModal: false,
      deleteModalItem: null,
      deleteSubscriptionsLoading: false,
      alertMessage: {
        type: AlertTypes.Success,
        message: `Successfully deleted ${num} subscription${num > 1 ? 's' : ''}.`
      }
    };
  }),
  on(DataExchangeActions.deleteSubscriptionsFailure, (state, params) => ({
    ...state,
    showDeleteSubscriptionModal: false,
    deleteModalItem: null,
    deleteSubscriptionsLoading: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: `Oops! There was a problem deleting your subscription(s): ${params.message}`
    }
  })),
  on(DataExchangeActions.alertClosed, (state) => ({
    ...state,
    alertMessage: new AlertMessage()
  })),
  on(DataExchangeActions.showInfoModal, (state, params) => ({
    ...state,
    showInfoModal: true,
    infoModalItem: params.subscription
  })),
  on(DataExchangeActions.infoModalDismissed, (state) => ({
    ...state,
    showInfoModal: false,
    infoModalItem: null
  })),
  on(DataExchangeActions.loadRetailerAreaNamesSuccess, (state, params) => ({
    ...state,
    retailerAreaNames: params.retailerAreaNames,
  })),
  on(DataExchangeActions.loadRetailerAreaNamesFailure, (state, params) => {
    return state;
  }),
  on(DataExchangeActions.subscriptionSelected, (state, params) => ({
    ...state,
    selectedSubscriptions: [...params.subscriptions],
  })),
  on(DataExchangeActions.esnSubscriptionSelected, (state, params) => ({
    ...state,
    selectedSupplierSubscriptions: buildEsnSubscriptionList(params.esns, state.esnSubscriptions),
  })),
  on(DataExchangeActions.selectAllToggled, (state, params) => ({
    ...state,
    subscriptions: state.subscriptions.map(subscription => ({
      ...subscription,
      isSelected: params.isSelected
    })),
  })),
  on(DataExchangeActions.singleCheckboxToggled, (state, params) => ({
    ...state,
    subscriptions: state.subscriptions.map(subscription => {
      if (subscription.id === params.id) {
        subscription.isSelected = !subscription.isSelected;
      }
      return subscription;
    }),
  })),
  on(DataExchangeActions.loadBrandsForSupplier, (state, params) => ({
    ...state,
    supplierBrandList: []
  })),
  on(DataExchangeActions.loadBrands, (state, params) => ({
    ...state,
    supplierBrandList: []
  })),
  on(DataExchangeActions.loadBrandsForSupplierSuccess, (state, params) => ({
    ...state,
    supplierBrandList: params.brands
  })),
  on(DataExchangeActions.loadBrandsForSupplierFailure, (state, params) => ({
    ...state,
    supplierBrandList: []
  })),
  on(DataExchangeActions.loadProducts, (state, params) => ({
    ...state,
    supplierBrandList: [],
    products: []
  })),
  on(DataExchangeActions.loadProductsSuccess, (state, params) => ({
    ...state,
    supplierBrandList: params.brands,
    products: params.products
  })),
  on(DataExchangeActions.loadProductsFailure, (state, params) => ({
    ...state,
    supplierBrandList: [],
    products: []
  })),
  on(DataExchangeActions.getItemsSuccess, (state, params) => ({
    ...state,
    items: [...params.itemsToDisplay],
    allItemsInCart: [...params.allItems]
  })),
  on(DataExchangeActions.cartPageChange, (state, params) => ({
    ...state,
    itemsToSkip: params.skip,
    itemsToTake: params.take,
  })),
  on(DataExchangeActions.addItemToCart, (state, params) => ({
    ...state,
    esnSubscriptionToTakeActionOn: params.subscription,
  })),
  on(DataExchangeActions.addItemToCartSuccess, (state, params) => ({
    ...state,
    items: [...params.items],
    esnSubscriptionToTakeActionOn: null,
  })),
  on(DataExchangeActions.removeItemFromCartSuccess, (state, params) => ({
    ...state,
    items: [...params.items],
    billingFrequencySelected: params.items.length === 0 ? initialState.billingFrequencySelected : state.billingFrequencySelected,
  })),
  on(DataExchangeActions.cartComponentDestroyed, (state) => ({
    ...state,
    items: initialState.items,
    itemsToSkip: initialState.itemsToSkip,
    itemsToTake: initialState.itemsToTake,
  })),
  on(DataExchangeActions.submitOrder, (state) => ({
    ...state,
    isOrderProcessing: true,
  })),
  on(DataExchangeActions.submitOrderResponseReceived, (state, params) => ({
    ...state,
    paymentTokenResponse: params.paymentTokenResponse
  })),
  on(DataExchangeActions.submitOrderResponseError, (state) => ({
    ...state,
    isOrderProcessing: false,
  })),
  on(DataExchangeActions.processTransactionResponseSuccess, (state, params) => ({
    ...state,
    transactionResponse: {
      ...state.transactionResponse,
      ...params.transactionResponse,
    },
    isOrderProcessing: initialState.isOrderProcessing,
    paymentTokenResponse: initialState.paymentTokenResponse,
  })),
  on(DataExchangeActions.processTransactionResponseError, (state, params) => ({
    ...state,
    transactionResponse: {
      ...state.transactionResponse,
      ...params.transactionResponse,
    },
    isOrderProcessing: initialState.isOrderProcessing,
    paymentTokenResponse: initialState.paymentTokenResponse,
  })),
  on(DataExchangeActions.clearCartAfterSuccessfulTransaction, (state) => ({
    ...state,
    items: initialState.items,
    allItemsInCart: initialState.allItemsInCart,
    billingFrequencySelected: initialState.billingFrequencySelected,
  })),
  on(DataExchangeActions.userEntersCheckoutPage, (state) => ({
    ...state,
    paymentTokenResponse: initialState.paymentTokenResponse,
    transactionResponse: initialState.transactionResponse,
  })),
  on(DataExchangeActions.userLeavesDataExchangePage, (state) => ({
    ...state,
    paymentTokenResponse: initialState.paymentTokenResponse,
    transactionResponse: initialState.transactionResponse,
    selectedSupplierSubscriptions: [],
  })),
  on(DataExchangeActions.manageRecipientsButtonClicked, (state, params) => ({
    ...state,
    showManageRecipientsModal:  true,
    esnSubscriptionToTakeActionOn: params.subscription,
    manageRecipientsFormValue: {
      ...state.manageRecipientsFormValue,
      emails: params.subscription.emails,
      day: params.subscription.day,
    }
  })),
  on(DataExchangeActions.bulkManageRecipientsClicked, (state) => ({
    ...state,
    showManageRecipientsModal:  true,
    manageRecipientsBulkMode: true,
  })),
  on(DataExchangeActions.manageRecipientsSetFormForBulkIfOneSelected, (state, params) => ({
    ...state,
    manageRecipientsFormValue: {
      ...state.manageRecipientsFormValue,
      emails: params.esnSubscription.emails,
      day: params.esnSubscription.day,
    }
  })),
  on(
    DataExchangeActions.loadAvailableRecipientEmails,
    DataExchangeActions.loadAvailableRecipientEmailsForBulk, (state) => ({
    ...state,
    isRecipientEmailListLoading: true,
  })),
  on(DataExchangeActions.loadAvailableRecipientEmailsSuccess, (state, params) => ({
    ...state,
    isRecipientEmailListLoading: false,
    recipientEmailList: params.emails,
  })),
  on(DataExchangeActions.manageRecipientsModalCancelClicked, (state) => ({
    ...state,
    showManageRecipientsModal: initialState.showManageRecipientsModal,
    manageRecipientsBulkMode: initialState.manageRecipientsBulkMode,
    isRecipientEmailListLoading: initialState.isRecipientEmailListLoading,
    recipientEmailList: initialState.recipientEmailList,
    manageRecipientsFormValue: initialState.manageRecipientsFormValue,
    manageRecipientsFormUpdateInProgress: initialState.manageRecipientsFormUpdateInProgress,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    manageRecipientsUpdateErrorMessage: initialState.manageRecipientsUpdateErrorMessage,
  })),
  on(DataExchangeActions.manageRecipientsModalCloseClicked, (state) => ({
    ...state,
    showManageRecipientsModal: initialState.showManageRecipientsModal,
    manageRecipientsBulkMode: initialState.manageRecipientsBulkMode,
    isRecipientEmailListLoading: initialState.isRecipientEmailListLoading,
    recipientEmailList: initialState.recipientEmailList,
    manageRecipientsFormValue: initialState.manageRecipientsFormValue,
    manageRecipientsFormUpdateInProgress: initialState.manageRecipientsFormUpdateInProgress,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    manageRecipientsUpdateErrorMessage: initialState.manageRecipientsUpdateErrorMessage,
  })),
  on(DataExchangeActions.manageRecipientsFormValueChange, (state, params) => ({
    ...state,
    manageRecipientsFormValue:  params.formValue,
  })),
  on(
    DataExchangeActions.manageRecipientsUpdateClick,
    DataExchangeActions.manageRecipientsUpdateForBulkClick, (state) => ({
    ...state,
    manageRecipientsFormUpdateInProgress:  true,
  })),
  on(DataExchangeActions.manageRecipientsUpdateSuccess, (state) => ({
    ...state,
    showManageRecipientsModal: initialState.showManageRecipientsModal,
    manageRecipientsBulkMode: initialState.manageRecipientsBulkMode,
    isRecipientEmailListLoading: initialState.isRecipientEmailListLoading,
    recipientEmailList: initialState.recipientEmailList,
    manageRecipientsFormValue: initialState.manageRecipientsFormValue,
    manageRecipientsFormUpdateInProgress: initialState.manageRecipientsFormUpdateInProgress,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    manageRecipientsUpdateErrorMessage: initialState.manageRecipientsUpdateErrorMessage,
    selectedSupplierSubscriptions: [],
  })),
  on(DataExchangeActions.manageRecipientsUpdateError, (state, params) => ({
    ...state,
    manageRecipientsFormUpdateInProgress: false,
    manageRecipientsUpdateErrorMessage: params.errorMessage,
  })),
  on(DataExchangeActions.whatsIncludedInMySubscriptionClick, (state) => ({
    ...state,
    showSubscriptionOverviewModal:  true,
  })),
  on(DataExchangeActions.dimissSubscriptionOverviewModal, (state) => ({
    ...state,
    showSubscriptionOverviewModal: false,
  })),
  on(DataExchangeActions.closeSuccessfulTransactionAlert, (state) => ({
    ...state,
    transactionResponse: initialState.transactionResponse,
  })),
  on(DataExchangeActions.cancelEsnSubscriptionClicked, (state, params) => ({
    ...state,
    showCancelEsnSubscriptionModal:  true,
    esnSubscriptionToTakeActionOn: params.subscription,
  })),
  on(DataExchangeActions.bulkCancelClicked, (state) => ({
    ...state,
    showCancelEsnSubscriptionModal:  true,
  })),
  on(DataExchangeActions.cancelEsnSubscriptionModalCancelClicked, (state) => ({
    ...state,
    showCancelEsnSubscriptionModal: initialState.showCancelEsnSubscriptionModal,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    cancelEsnSubscriptionUpdateInProgress: initialState.cancelEsnSubscriptionUpdateInProgress,
    cancelEsnSubscriptionErrorMessage: initialState.manageRecipientsUpdateErrorMessage,
  })),
  on(DataExchangeActions.cancelEsnSubscriptionModalCloseClicked, (state) => ({
    ...state,
    showCancelEsnSubscriptionModal: initialState.showCancelEsnSubscriptionModal,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    cancelEsnSubscriptionUpdateInProgress: initialState.cancelEsnSubscriptionUpdateInProgress,
    cancelEsnSubscriptionErrorMessage: initialState.manageRecipientsUpdateErrorMessage,
  })),
  on(
    DataExchangeActions.cancelEsnSubscriptionModalConfirmClick,
    DataExchangeActions.cancelEsnSubscriptionsModalConfirmClick, (state) => ({
      ...state,
      cancelEsnSubscriptionUpdateInProgress: true,
  })),
  on(DataExchangeActions.cancelEsnSubscriptionConfirmSuccess, (state) => ({
    ...state,
    showCancelEsnSubscriptionModal: initialState.showCancelEsnSubscriptionModal,
    cancelEsnSubscriptionUpdateInProgress: initialState.cancelEsnSubscriptionUpdateInProgress,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    cancelEsnSubscriptionErrorMessage: initialState.manageRecipientsUpdateErrorMessage,
    registerPreviouslyCanceledEsnProgress:  initialState.registerPreviouslyCanceledEsnProgress,
    selectedSupplierSubscriptions: [],
  })),
  on(DataExchangeActions.cancelEsnSubscriptionConfirmError, (state, params) => ({
    ...state,
    cancelEsnSubscriptionUpdateInProgress: initialState.cancelEsnSubscriptionUpdateInProgress,
    cancelEsnSubscriptionErrorMessage: params.errorMessage,
  })),
  on(DataExchangeActions.registerPreviouslyCanceledEsn, (state, params) => ({
    ...state,
    registerPreviouslyCanceledEsnProgress: true,
    esnSubscriptionToTakeActionOn: params.subscription,
    esn: params.subscription.esn,
   
  })),
  on(DataExchangeActions.registerThirdPartyUser, (state, params) => ({
    ...state,
    registerPreviouslyCanceledEsnProgress: true,
    esnSubscriptionToTakeActionOn: params.subscription,
    esn: params.subscription.esn,
   
  })),
  on(DataExchangeActions.handleBulkThirdPartyRegister, (state, params) => ({
    ...state,
    registerPreviouslyCanceledEsnProgress: true,
    esnSubscriptionToTakeActionOn: params.subscription,
    esn: params.subscription.esn,
   
  })),

  on(DataExchangeActions.bulkReactivateSubscriptions, (state, params) => ({
    ...state,
    registerPreviouslyCanceledEsnProgress: true,
    bulkAction: true
  })),

  on(DataExchangeActions.upsertEsnSubscriptionSuccess, (state, params) => ({
    ...state,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    registerPreviouslyCanceledEsnProgress:  initialState.registerPreviouslyCanceledEsnProgress,
    selectedSupplierSubscriptions: [],
    esn: initialState.esn,
    bulkAction: false
  })),
  on(DataExchangeActions.upsertEsnSubscriptionError, (state, params) => ({
    ...state,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    registerPreviouslyCanceledEsnProgress:  initialState.registerPreviouslyCanceledEsnProgress,
    esn: initialState.esn,
    bulkAction: false
  })),
  on(DataExchangeActions.thirdPartyTransactionSuccess, (state, params) => ({
    ...state,
    transactionResponse: {
      ...state.transactionResponse,
      ...params.transactionResponse,
    },
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    registerPreviouslyCanceledEsnProgress:  initialState.registerPreviouslyCanceledEsnProgress,
    selectedSupplierSubscriptions: [],
    esn: initialState.esn,
  })),
  on(DataExchangeActions.thirdPartyTransactionError, (state, params) => ({
    ...state,
    transactionResponse: {
      ...state.transactionResponse,
      ...params.transactionResponse,
    },
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
    registerPreviouslyCanceledEsnProgress:  initialState.registerPreviouslyCanceledEsnProgress,
    esn: initialState.esn,
  })),
  on(DataExchangeActions.showFrequencyModal, state => ({
    ...state,
    showFrequencyModal: true,
  })),
  on(DataExchangeActions.frequencyModalCancelClicked, state => ({
    ...state,
    showFrequencyModal: false,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
  })),
  on(DataExchangeActions.frequencyModalCloseClicked, state => ({
    ...state,
    showFrequencyModal: false,
    esnSubscriptionToTakeActionOn: initialState.esnSubscriptionToTakeActionOn,
  })),
  on(DataExchangeActions.frequencyModalAddClicked, (state, params) => ({
    ...state,
    billingFrequencySelected: params.frequency,
    showFrequencyModal: false,
  })),
);

export function reducer(state: DataExchangeState | undefined, action: Action) {
  return rootReducer(state, action);
}

export const dataExchangeFeatureKey = 'dataExchange';

export function populateViewProperties(subscriptions: DataSubscriptionPayload[]): DataSubscription[] {
  return subscriptions.map(subscription => ({
    ...subscription,
    daysView: convertDaysArray(subscription.days),
    isSelected: false,
    supplierName: subscription.supplier.name,
    reportNameDisplay: {
      class: 'report-name',
      text: ReportName.toString(subscription.reportName),
      link: '/data-exchange/'
    },
    dateCreatedView: new DatePipe('en-US').transform(subscription.created, 'MM/dd/yyyy')
  }));
}

function getAllowableSuppliers(
  allUserSuppliers: Supplier[],
  nonConnectBiSuppliers: SupplierListItem[]
): Supplier[] {
  const nonConnectBiEsns = new Set(nonConnectBiSuppliers.map(s => s.esn));

  if (allUserSuppliers.hasOwnProperty('data')) {
    return allUserSuppliers['data'].filter(s => !nonConnectBiEsns.has(s.esn));
  } else {
    return allUserSuppliers.filter(s => !nonConnectBiEsns.has(s.esn));
  }
}

function convertDaysArray(days: DayOfWeek[]): string {
  return days.map(day => dayOfWeekAbbreviationMap.get(day)).join(', ');
}

function getErrorMessage(input: string): string {
  return input.includes('Object already exists') ?
    'Looks like you already have this subscription. Please make a different selection.' :
    `Oops! There was an error creating your subscription: ${input}`;
}

// move to selector?
function mapSuppliersToSubscriptions(suppliers: Supplier[], subscriptions: EsnSubscription[]): EsnSubscription[] {
  return suppliers.map(supplier => {
    const foundSubscription = subscriptions.find(subscription => subscription.esn === supplier.esn);
    return {
      ...foundSubscription,
      supplierName: supplier.name,
      esn: supplier.esn,
      status: !foundSubscription ? EsnSubscriptionStatus.Unregistered : foundSubscription.status,
    };
  });
}

function buildEsnSubscriptionList(esns: string[], esnSubscriptions: EsnSubscription[]): EsnSubscription[] {
  return esns.map(esn => {
    const foundSubscription = esnSubscriptions.find(esnSubscription => esnSubscription.esn === esn);
    return {
      ...foundSubscription,
    };
  });
}

