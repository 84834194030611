import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // global search clear text
  private _searchClearText = false;
  get searchClearText(): boolean {
    return this._searchClearText;
  }
  set searchClearText(value: boolean) {
    this._searchClearText = value;
  }

  constructor() { }

}
