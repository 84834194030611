export enum OrderDetailShowActions {
  LoadProductsStart = '[Order Detail Show] Load Products Start',
  loadMoreProductsStart = '[Order Detail Show] Load Products More Start',


  SetSidePanelSelectedTab = '[Order Detail Show] Set Side Panel Selected Tab',

  ToggleSidePanel = '[Order Detail Show] Toggle Side Panel',

  SetProductsSearchText = '[Order Detail Show] Set Products Search Text',

  ChangeSelectedTab = '[Order Detail Show] Change Selected Tab',

  LoadBookProducts = '[Order Detail Show] Load Book Products',
  PageChange = '[Order Detail Show] Page Change',

  DeleteProductBookSuccess = '[Order Detail Show] Delete Product Book Success',
  DeleteProductBookFail = '[Order Detail Show] Delete Product Book Fail',


  // Delete Bulk Product Books
  CloseBulkDeleteBookProductsModal = '[Order Detail Show] Close Bulk Delete Book Products Modal',
  BulkDeleteBookProducts = '[Order Detail Show] Bulk Delete Book Products',
  BulkDeleteBookProductsSuccess = '[Order Detail Show] Bulk Delete Book Products Success',
  BulkDeleteBookProductsFail = '[Order Detail Show] Bulk Delete Book Products Fail',

  // Order Header
  SetOrderDetailsId = '[Order Detail Show] Set Order Details Id',
  LoadOrderHeaderStart = '[Order Detail Show] Load Order Header Start',
  LoadOrderHeaderSuccess = '[Order Detail Show] Load Order Header Success',
  LoadOrderHeaderFail = '[Order Detail Show] Load Order Header Fail',
  RetryLoadOrderHeaderStart = '[Order Detail Show] Retry Load Order Header Start',
  MessageRetailer = '[Order Detail Show] Message Retailer',
  OpenCloneOrderModal = '[Order Detail Show] Open Clone Order Modal',
  CloseCloneOrderModal = '[Order Detail Show] Close Clone Order Modal',
  SubmitCloneOrderModal = '[Order Detail Show] Submit Clone Order Modal',
  SubmitCloneOrderModalError = '[Order Detail Show] Submit Clone Order Modal Error',
  SubmitCloneOrderModalSuccess = '[Order Detail Show] Submit Clone Order Modal Success',
  GetCloneOrderRedisDocument = '[Order Detail Show] Get Clone Order Redis Document',
  GetCloneOrderRedisDocumentSuccess = '[Order Detail Show] Get Clone Order Redis Document Success',
  SaveCloneOrderRedisKey = '[Order Detail Show] Save Clone Order Redis Key',

  // Books Header
  SetBooksHeaderSelectedTab = '[Order Detail Show] Set Books Header Selected Tab',

  // Delete
  DeleteOrderModalSuccess = '[Order Detail Show] Delete Order Modal Success',
  DeleteOrderModalFail = '[Order Detail Show] Delete Order Modal Fail',
  CloseDeleteOrderModal = '[Order Detail Show] Close Delete Order Modal',
  SubmitDeleteOrderModal = '[Order Detail Show] Submit Delete Order Modal',
  OpenDeleteOrderModal = '[Order Detail Show] Open Delete Order Modal',

  // Clear Book
  ClearBook = '[Order Detail Show] Clear Book',
  ClearBookSuccess = '[Order Detail Show] Clear Book Success',
  ClearBookFailure = '[Order Detail Show] Clear Book Failure',
  OpenClearBookModal = '[Order Detail Show] Open Clear Book Modal',
  CloseClearBookModal = '[Order Detail Show] Close Clear Book Modal',

  // Copy Book
  CopyBook = '[Order Detail Show] Copy Book',
  CopyBookSuccess = '[Order Detail Show] Copy Book Success',
  CopyBookFailure = '[Order Detail Show] Copy Book Failure',
  OpenCopyBookModal = '[Order Detail Show] Open Copy Book Modal',
  CloseCopyBookModal = '[Order Detail Show] Close Copy Book Modal',

  // ECM
  SyncOrderDetailShow = '[Order Detail Show] Sync Order Detail Show',
  SyncOrderDetailShowForProductListSuccess = '[Order Detail Show] Sync Order Detail Show For Product List Success',
  SyncOrderDetailShowForOrderHeaderSuccess = '[Order Detail Show] Sync Order Detail Show For Order Header Success',

  SyncOrderDetailShowError = '[Order Detail Show] Sync Order Detail Show Error',

  // Submit
  SubmitOrderSuccess = '[Order Detail Show] Submit Order Success',
  SubmitOrderFail = '[Order Detail Show] Submit Order Fail',
  SubmitCounteredOrder = '[Order Detail Show] Submit Countered Order',
  SubmitCounteredOrderSuccess = '[Order Detail Show] Submit Countered Order Success',
  SubmitCounteredOrderFail = '[Order Detail Show] Submit Countered Order Fail',

  // Counter
  DeclineOrder = '[Order Detail Show] Decline Order',
  DeclineOrderSuccess = '[Order Detail Show] Decline Order Success',
  DeclineOrderFail = '[Order Detail Show] Decline Order Fail',
  CounterOrder = '[Order Detail Show] Counter Order',
  CounterOrderSuccess = '[Order Detail Show] Counter Order Success',
  CounterOrderFail = '[Order Detail Show] Counter Order Fail',

  // Counter Order Modal
  ChangeCounterOrderModalVisibility = '[Order Detail Show] Change Counter Order Modal Visibility',

  // Decline Counter Order Modal
  ChangeDeclineCounterOrderModalVisibility = '[Order Detail Show] Change Decline Counter Order Modal Visibility',
  DeclineCounterOrderModal = '[Order Detail Show] Decline Counter Order Modal',

  // Order Notes Modal
  SaveOrderNotes = '[Order Detail Show] Save Order Notes',
  SaveOrderNotesSuccess = '[Order Detail Show] Save Order Notes Success',
  SaveOrderNotesError = '[Order Detail Show] Save Order Notes Error',
  OpenOrderNotesModal = '[Order Detail Show] Open Order Notes Modal',

  // Order Updating State
  ResetOrderUpdatingState = '[Order Detail Show] Reset Order Updating State',

  // Ep Modal
  ChangeEpModalVisibility = '[Order Detail Show] Change Ep Modal Visibility',
  ChangeEpModalDeals = '[Order Detail Show] Change Ep Modal Deals',
  ChangeEpModalDealsSuccess = '[Order Detail Show] Change Ep Modal Deals Success',
  ChangeEpModalDealsFailure = '[Order Detail Show] Change Ep Modal Deals Failure',

  // Quick View
  OpenProductsQuickViewModal = '[Order Detail Show] Open Products Quick View Modal',

  // Alerts
  CloseErrorAlert = '[Order Detail Show] Close Error Alert',
  CloseCustomAlert = '[Order Detail Show] Close Custom Alert',

  // Help
  OpenHelpModal = '[Order Detail Show] Open Help Modal',

  // Callback Order Modal
  CallbackOrder = '[Order Detail Show] Callback Order',
  CallbackOrderSuccess = '[Order Detail Show] Callback Order Success',
  CallbackOrderError = '[Order Detail Show] Callback Order Error',
  OpenCallbackOrderModal = '[Order Detail Show] Open Callback Order Modal',
}
