<kehe-phoenix-modal
  *ngIf="esnsToCancelCount$ | async as esnsToCancelCount"
  name="cancelEsnSubscriptionModal"
  modalTitle="Cancel Auto-Renew"
  [show]="true"
  (modalEvent)="handleModalEvent($event)"
  size="sm"
>
  <div body>
    <kehe-phoenix-alert *ngIf="errorMessage$ | async as errorMessage" [show]="true" type="error" [message]="errorMessage" [stick]="true" [hideCloseIcon]="true"></kehe-phoenix-alert>
    <p>
      <strong>Are you sure you want to cancel the auto-renewal of your subscription{{esnsToCancelCount > 1 ? 's' : ''}}?</strong>
    </p>
    <p>
      You have chosen to cancel auto-renew for <strong *ngIf="esnsToCancelCount > 1; else single">{{esnsToCancelCount}}</strong><ng-template #single>this</ng-template> supplier{{esnsToCancelCount > 1 ? 's' : ''}}. You will continue to have access to the supplier{{esnsToCancelCount > 1 ? 's' : ''}} information until the end date. However, your subscription{{esnsToCancelCount > 1 ? 's' : ''}} will not renew.
    </p>
  </div>


  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button
      name="cancel"
      classAttr="btn-link btn-medium"
      (click)="onCancelClick()"
    >Cancel</kehe-phoenix-button>
    <kehe-phoenix-button
      name="update"
      classAttr="btn-delete btn-medium"
      [disabled]="updateInProgress$ | async"
      [spinner]="updateInProgress$ | async"
      (click)="onConfirmClick(esnsToCancelCount)"
    >Confirm</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
