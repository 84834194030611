import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PermissionsService } from '@app/services/permissions.service';
import { EsnSubscriptionStatus } from '../../models/enum/esn-subscription-status';
import { EsnSubscription } from '../../models/esn-subscription';
import { addItemToCart, cancelEsnSubscriptionClicked, manageRecipientsButtonClicked, registerPreviouslyCanceledEsn, registerThirdPartyUser } from '../../store/data-exchange.actions';
import { Observable } from 'rxjs';
import { selectCurrentEsn, selectregisterPreviouslyCanceledEsn, selectregisterPreviouslyCanceledEsnrow, isBulkAction } from '../../store/data-exchange.selectors';
import { ResourceActions } from '../../../../constants/resource-actions';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-esn-subscription-actions-bar',
  templateUrl: './esn-subscription-actions-bar.component.html',
  styleUrls: ['./esn-subscription-actions-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EsnSubscriptionActionsBarComponent implements OnInit {
  registerInProgress$: Observable<boolean>;
  registerRow$: Observable<any>;
  registerRowID: Observable<any>;
  isBulkAction: Observable<any>;
  @Input()
  subscription: EsnSubscription;
  @Input()
  selectedEsns$: string[];
  esnSubscriptionStatus = EsnSubscriptionStatus;
  requiredResourceAction: ResourceActions;
  thirdPartyUsers: boolean = false;

  constructor(
    private store: Store,
    private _permSvc: PermissionsService,
  ) { }

  ngOnInit(): void {
    this.registerInProgress$ = this.store.select(selectregisterPreviouslyCanceledEsn);
    this.registerRow$ = this.store.select(selectregisterPreviouslyCanceledEsnrow);
    this.registerRowID = this.store.select(selectCurrentEsn);
    this.isBulkAction = this.store.select(isBulkAction);
    if (this._permSvc.userHasAction(ResourceActions.ThirdPartyOfRecord)) {
      this.thirdPartyUsers = true;
    }
  }

  registerEsnSubscription(subscription: EsnSubscription): void {
    this.isBulkAction = this.store.select(isBulkAction);;
    this.registerRowID = this.store.select(selectCurrentEsn);
    if (subscription.status === this.esnSubscriptionStatus.Unregistered
      || subscription.status === this.esnSubscriptionStatus.Expired) {
      if (this.thirdPartyUsers) {
        this.store.dispatch(registerThirdPartyUser({ subscription }));
      }
      else {
        this.store.dispatch(addItemToCart({ subscription }));
      }
    } else if (subscription.status === this.esnSubscriptionStatus.Canceled) {
      const todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      if (subscription.endDate < todaysDate) {
        this.store.dispatch(addItemToCart({ subscription }));
      }
      else {
        this.store.dispatch(registerPreviouslyCanceledEsn({ subscription }));
      }


    }
  }

  onManageRecipientsClick(subscription: EsnSubscription): void {
    this.store.dispatch(manageRecipientsButtonClicked({ subscription }));
  }

  cancelEsnSubscription(subscription: EsnSubscription): void {
    this.store.dispatch(cancelEsnSubscriptionClicked({ subscription }));
  }
  isSelected(currentEsn: String): Boolean {
    return this.selectedEsns$.some( (esn) => esn == currentEsn );
  }
}
