<kehe-phoenix-modal
  *ngIf="showModal"
  name="createSubscriptionModal"
  modalTitle="Subscribe to Reports"
  [show]="showModal"
  (modalEvent)="handleModalEvent($event)"
  size="md"
>
  <div body class="modal-body">
    <div class="container">
      <div class="row mb-3">
        <div class="col-sm-9" id="report-type-col">
          <label>Report Type</label>
            <kendo-dropdownlist
              [data]="reportOptions"
              valueField="value"
              textField="label"
              [(ngModel)]="selectedReport"
              (valueChange)="onReportChanged()"
            ></kendo-dropdownlist>
        </div>
      </div>

      <ng-container [ngSwitch]="selectedReport?.value">
        <app-supplier-dc-brand-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.BrandItemLocation"
        >
        </app-supplier-dc-brand-filter>

        <app-inventory-stock-status-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.InventoryStockStatus"
        >
        </app-inventory-stock-status-filter>

        <app-item-recap-hd-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.Item360RecapHd"
        >
        </app-item-recap-hd-filter>

        <app-chain-sales-brand-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.EnterpriseSupplierChainSalesbyBrand"
        >
        </app-chain-sales-brand-filter>

        <app-kehe-full-pod-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.KeheFullPodVendor"
        >
        </app-kehe-full-pod-filter>

        <app-chains-stores-item-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.ChainsAndStoresThatOrderedAnItem"
        >
        </app-chains-stores-item-filter>

        <app-inbound-fill-rate-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.InboundFillRate"
        >
        </app-inbound-fill-rate-filter>

        <app-inventory-at-risk-filter
          #subscriptionFilter
          (filterChanged)="onFilterChanged($event)"
          *ngSwitchCase="reportName.InventoryAtRisk"
        >
        </app-inventory-at-risk-filter>
      </ng-container>

      <hr>

      <div class="row">
        <div class="col-sm-6">
          <h4>Weekly Recurrence</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="select-days-label">Select Day</div>
          <div class="d-sm-inline-flex">
            <ng-container *ngFor="let day of dayOptions">
              <input #dayOfTheWeek type="radio" kendoRadioButton [value]="day.id" [(ngModel)]="selectedDay" (change)="daySelected()"/>
              <kendo-label class="k-radio-label" [for]="dayOfTheWeek" [text]="day.text"></kendo-label>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div footer class="footer">
    <kehe-phoenix-button
      class="cancel-button"
      name="cancel"
      classAttr="btn-link btn-medium"
      (click)="cancelClicked()"
    >
      Cancel
    </kehe-phoenix-button>
    <kehe-phoenix-button
      name="submitSubscription"
      classAttr="btn-primary btn-medium"
      [disabled]="!isFormValid()"
      (click)="onSubmit()"
      [spinner]="createSubscriptionLoading$ | async"
    >
      Subscribe
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
