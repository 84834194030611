import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { DistributionCenterEffects } from './distribution-center.effects';
import { DistributionCenterService } from './distribution-center.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([DistributionCenterEffects]),
  ],
  providers: [DistributionCenterService]
})
export class DistributionCenterModule { }
