
export enum EnterpriseProductSort {
    default = 0,
    upc = 1,
    supplierName = 2,
    brandName = 3,
    corporateProductNumber = 4,
    size = 5,
    familyGroup = 6,
    goodBetterBestName = 7,
    status = 8,
    categoryManagerName = 9,
    packSize = 10,
    retailTemperature = 11,
    daysAtStatus = 12,
    assignedTo = 13,
    newProductWorkflowStatus = 14,
    priority = 15,
    createdBy = 16,
    daysInStatusCount = 12,
    created= 18
}

export enum EnterpriseProductSortOrder {
    ascending = 0,
    descending = 1,
}

export enum ListTabs {
    draft = 'Draft',
    active = 'Active'
}

export enum ProductStatus {
    Active = 'active',
    PendingDiscontinuation = 'pending discontinuation',
    Seasonal  = 'seasonal',
    ActiveSeasonal = 'active - seasonal',
    PendingCreation = 'pending creation',
    Draft = 'draft',
    KeHEReviewCM = 'kehe review - cm',
    KeHEReviewEDS  = 'kehe review - eds',
    SupplierReview = 'supplier review',
    PendingActivation = 'pending activation'
}

export enum ProductActions {
    Clone = 'Clone',
    Delete = 'Delete'
}
