<div class="row">
    <div class="col-6">
      <h2>Data Exchange</h2>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <kehe-phoenix-button
        class="mr-2"
        name="help-button"
        classAttr="btn-medium btn-link"
        iconClassAttr="fa fa-question"
        (click)="showHelpModal = true;"
      >Help</kehe-phoenix-button>
      <kehe-phoenix-button
        name="add-subscription"
        classAttr="btn-primary btn-medium"
        (click)="addSubscriptionClicked()"
      >Add</kehe-phoenix-button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <kehe-phoenix-alert
        (alertEvent)="handleAlertEvents($event)"
        [message]="(alert$ | async)?.message"
        [show]="(alert$ | async)?.message !== null"
        [type]="(alert$ | async)?.type"
        stick="true"
      ></kehe-phoenix-alert>
    </div>
  </div>
  <div class="row mt-3 mb-2">
    <div class="col">
      <kehe-phoenix-menu-button
        [menuOptions]="bulkActionOptions"
        [enabled]="(selectedSubscriptionsCount$ | async) > 0"
        menuTitle="Bulk Actions"
        (optionSelected)="bulkActionSelected($event)">
      ></kehe-phoenix-menu-button>
    </div>
  </div>
  <div class="row kehe-grid no-scroll p-0 w-100">
    <div class="col">
      <kendo-grid
        [data]="subscriptions$ | async"
        [sortable]="{allowUnsort: false, mode: 'single'}" 
        [sort]="sort$ | async"
        (sortChange)="onSubscriptionsGridSortChange($event)"
        [selectable]="{checkboxOnly: true, mode: 'multiple'}"
        [kendoGridSelectBy]="getKeyForSelectedSubscription"
        [(selectedKeys)]="selectedSubscriptions"
        (selectedKeysChange)="onSelectedSubscriptionsChange($event)"
      >
        <kendo-grid-checkbox-column showSelectAll="true" [width]="40"></kendo-grid-checkbox-column>
        <kendo-grid-column field="reportNameDisplay" title="Report Type" [headerClass]="'kehe-grid-header-hover'">
          <ng-template kendoGridCellTemplate let-subscription>
            <div class="link-cell" (click)="showSubscriptionInfoModal(subscription)">{{subscription?.reportNameDisplay?.text}}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="supplierName" title="Supplier" [headerClass]="'kehe-grid-header-hover'"></kendo-grid-column>
        <kendo-grid-column field="recurrence" title="Recurrence" [width]="110" [headerClass]="'kehe-grid-header-hover'"></kendo-grid-column>
        <kendo-grid-column field="daysView" title="Day" [width]="110" [headerClass]="'kehe-grid-header-hover'"></kendo-grid-column>
        <kendo-grid-column field="dateCreatedView" title="Created" [width]="110" [headerClass]="'kehe-grid-header-hover'"></kendo-grid-column>
        <kendo-grid-column [width]="75" class="d-flex justify-content-center">
          <ng-template kendoGridCellTemplate let-subscription>
            <kehe-phoenix-button classAttr="btn-large btn-destructive-link" (click)="onDeleteSubscription(subscription)" title="Delete">
              <em class="fas fa-trash-alt"></em>
            </kehe-phoenix-button>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div *ngIf="!isLoading" class="custom-no-results-msg">
            <em class="fas fa-seedling" aria-hidden="true"></em>
            <div class="custom-msg-container">
              <div class="no-results">No Subscriptions</div>
              <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="addSubscriptionClicked()">Add Subscription</kehe-phoenix-button>
            </div>
          </div>
          <div *ngIf="isLoading">
            <kehe-phoenix-loading></kehe-phoenix-loading>
          </div>
        </ng-template>     
      </kendo-grid>
      <kendo-datapager *ngIf="!isLoading && count > 0"
        [pageSize]="take$ | async"
        [skip]="skip$ | async"
        [total]="count"
        [buttonCount]="4"
        [pageSizeValues]="[25, 50, 100, 250]"
        (pageChange)="onSubscriptionsGridPageChange($event)"
      ></kendo-datapager>
    </div>
  </div>
  <app-create-subscription-modal (subscribeClicked)="onCreateNewSubscription($event)"></app-create-subscription-modal>
  <app-subscription-info-modal></app-subscription-info-modal>
  <app-delete-subscription-modal></app-delete-subscription-modal>

<app-help-modal
    [show]="showHelpModal"
    (modalClose)="showHelpModal = false;"
    [videos]="helpVideos"
    [showReportIssue]="false"
></app-help-modal>
