import { channels } from './../create-subscription-filters/kehe-full-pod-filter/kehe-full-pod-filter.component';
import { loadBrands, loadProducts } from './../../store/data-exchange.actions';
import { Brand } from './../../../product-detail/models/brand';
import { itemRecapTypeOptions } from './../../models/enum/item-recap-type';
import { valuationMethods } from './../create-subscription-filters/chains-stores-item-filter/chains-stores-item-filter.component';
import { selectRetailerAreaNames, selectSupplierBrandList, selectProducts } from './../../store/data-exchange.selectors';
import { RetailerArea } from './../../models/retailer-area';
import { SubscriptionFilter } from './../../models/subscription-filter';
import { Store, select } from '@ngrx/store';
import { SubscriptionFilterKey } from './../../models/enum/subscription-filter-key';
import { CamelCaseToSentenceCasePipe } from './../../../../utilities/camel-case-to-sentence-case.pipe';
import { DataSubscription } from './../../models/data-subscription';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ReportName } from '../../../../modules/reporting/models/enum/report-name.enum';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';
import { DistributionCenter } from '@app/distribution-center/distribution-center';

export const numFilterValuesForPreview = 8;

@Component({
  selector: 'app-subscription-info',
  templateUrl: './subscription-info.component.html',
  styleUrls: ['./subscription-info.component.scss'],
  providers: [CamelCaseToSentenceCasePipe]
})
export class SubscriptionInfoComponent implements OnInit {

  numFilterValuesForPreview = numFilterValuesForPreview;
  private _show: boolean;

  labelSubstitutions = new Map([
    [SubscriptionFilterKey.RetailerArea, 'Retailer Area'],
    [SubscriptionFilterKey.BrandCode, 'Brand'],
    [SubscriptionFilterKey.Upc, 'Product'],
  ]);

  @Input() subscription: DataSubscription;

  @Input() set show(value: boolean) {
    this._show = value;
    if (this._show) {
      this.reset();
      this.dispatchFilterLoadAction();
    }
  }

  get show(): boolean {
    return this._show;
  }

  dcList: DistributionCenter[];
  retailerAreas: RetailerArea[];
  brands: Brand[] = [];
  products: any[] = [];

  constructor(
    private camelCaseToSentenceCase: CamelCaseToSentenceCasePipe,
    private _store: Store,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.reset();
    this._store.pipe(
      select(DistributionCenterSelectors.selectDistributionCenterList)
    ).subscribe(dcs => {
      this.dcList = dcs;
      this.ref.markForCheck();
    });

    this._store.pipe(
      select(selectRetailerAreaNames),
    ).subscribe(names => {
      this.retailerAreas = names;
      this.ref.markForCheck();
    });

    this._store.pipe(
      select(selectSupplierBrandList)
    ).subscribe(brands => {
      this.brands = brands;
      this.ref.markForCheck();
    });

    this._store.pipe(
      select(selectProducts)
    ).subscribe(products => {
      this.products = products;
      this.ref.markForCheck();
    });
  }

  createLabelName(filter: SubscriptionFilter): string {
    const label = this.labelSubstitutions.has(filter.key) ?
      this.labelSubstitutions.get(filter.key) :
      this.camelCaseToSentenceCase.transform(SubscriptionFilterKey.toString(filter.key));
    return filter.value.length > 1 ? `${label}s` : label;
  }

  transformFilterValues(filter: SubscriptionFilter) {
    const readableNames = filter.value.map(filterVal => {
      switch (filter.key) {
        case SubscriptionFilterKey.Dc:
          return this.getDcValue(filterVal, this.dcList);
        case SubscriptionFilterKey.RetailerArea:
          return this.retailerAreas.find(ra => ra.id.toString() === filterVal).name;
        case SubscriptionFilterKey.ValuationMethod:
          return valuationMethods.find(vm => vm.value === filterVal).label;
        case SubscriptionFilterKey.Item360RecapType:
          return itemRecapTypeOptions.find(t => t.value === filterVal).label;
        case SubscriptionFilterKey.BrandCode:
          const matchingBrand = this.brands.find(brand => brand.code === filterVal);
          return !!matchingBrand ? matchingBrand.name : filterVal;
        case SubscriptionFilterKey.Upc:
          const matchingProduct = this.products.find(product => product.upc === filterVal);
          return !!matchingProduct ? matchingProduct.itemDesc : filterVal;
        case SubscriptionFilterKey.Channel:
          return channels.find(c => c.value === filterVal).label;
        default:
          return filterVal;
      }
    });

    return !filter.expanded && readableNames.length > numFilterValuesForPreview ?
      (readableNames.sort().slice(0, numFilterValuesForPreview).join(', ') + ',...') :
      readableNames.sort().join(', ');
  }

  getDcValue(dcNumber: string, dcList: DistributionCenter[]): string {
    const matchingDc = dcList.find(dc => dc.number === dcNumber);
    return !!matchingDc ? matchingDc.name.replace('Kehe Dist - ', '') : dcNumber;
  }

  shouldHideFilter(): boolean {
    return ([
      ReportName.BrandItemLocation,
      ReportName.EnterpriseSupplierChainSalesbyBrand,
      ReportName.KeheFullPodVendor
    ].includes(this.subscription.reportName) && this.brands.length === 0) ||
    (this.subscription.reportName === ReportName.ChainsAndStoresThatOrderedAnItem && this.products.length === 0);
  }

  dispatchFilterLoadAction() {
    if (!!this.subscription && this.subscription.reportName === ReportName.ChainsAndStoresThatOrderedAnItem) {
      this._store.dispatch(loadProducts({ supplier: this.subscription.supplier }));
    }
    if (!!this.subscription && [
      ReportName.BrandItemLocation,
      ReportName.EnterpriseSupplierChainSalesbyBrand,
      ReportName.KeheFullPodVendor
    ].includes(this.subscription.reportName)) {
      const dcs = this.subscription.filter.find(f => f.key === SubscriptionFilterKey.Dc).value;
      this._store.dispatch(loadBrands({
        esn: this.subscription.supplier.esn,
        twoDigitDcs: dcs
      }));
    }
  }

  reset() {
    this.brands = [];
    if (!!this.subscription) {
      this.subscription.filter.forEach(filter => {
        filter.expanded = false;
      });
    }
  }
}
