<div class="checkout-form">
  <div class="mw-100">
    <div class="row">
      <div class="col">
        <kehe-credit-card-info 
          [prod]="isProduction"
          [acceptedPaymentTypes]="supplierPaymentTypes"
          [process]="isOrderProcessing$ | async"
          [credentials]="merchantCredentials$ | async" 
          (formValid)="formValid($event)"
          (result)="getTokenResult($event)"
        ></kehe-credit-card-info>
      </div>
  </div>
</div>