<div class="order">
  <div class="kehe-grid no-scroll p-0 w-100">
    <kendo-grid [data]="items$ | async">
      <kendo-grid-column field="Item" width="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="info-data">
            <div class="font-weight-bold">{{dataItem.supplierName}} ({{dataItem.esn}})</div>
            <div>{{dataItem.itemName}}</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Description" width="60">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="font-weight-bold">{{dataItem.itemDescription}}</div>
          <div>{{dataItem.date}}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="quantity" width="20" title="Qty">
      </kendo-grid-column>
      <kendo-grid-column field="price" title="Price Per (EA)" width="30">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{dataItem.price | currency }}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="price" title="Total Price" width="30">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{dataItem.price | currency }}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column width="15" *ngIf="canDelete">
        <ng-template kendoGridCellTemplate let-dataItem>
          <em class="fas fa-trash-alt trash-icon" (click)="removeItem(dataItem.esn)" title="Delete"></em>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridNoRecordsTemplate>
        <div class="custom-no-results-msg">
          <em class="fas fa-seedling" aria-hidden="true"></em>
          <div class="custom-msg-container">
            <h4>No Subscriptions in your Cart</h4>
            <div class="no-results d-block">1
              <span class="no-results-directions">Add subscriptions to cart from <a routerLink="/data-exchange">Data Exchange</a> page</span>
            </div>
            <div class="no-results d-block">2
              <span class="no-results-directions">Proceed to checkout</span>
            </div>
          </div>
        </div>
      </ng-template>   
    </kendo-grid>
    <kendo-datapager *ngIf="count$ | async as count"
      [pageSize]="take$ | async"
      [skip]="skip$ | async"
      [total]="count"
      [buttonCount]="4"
      [pageSizeValues]="[25, 50, 100, 250]"
      (pageChange)="onPageChange($event)"
    ></kendo-datapager>
  </div>
</div>
