import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { PhoenixManageProgressPanelFacadeService } from '@kehe/phoenix-top-bar';
import { ManageProgressActions } from '../../../modules/promotional-roadmap/store/promotional-roadmap.actions';

@Injectable({
  providedIn: 'root'
})
export class ManageProgressEffects {
  constructor(
    private _actions$: Actions,
    private _manageProgressFacade: PhoenixManageProgressPanelFacadeService
  ) {}

  // Used to trigger manage progress call for the first time to update the user syncing icon
  triggerGetManageProgressItems$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          // Add ngRx actions that would trigger a manage progress get call
          ManageProgressActions.triggerManageProgress
        ),
        tap(() => this._manageProgressFacade.requestItems())
      ),
    { dispatch: false }
  );
}
