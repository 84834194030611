import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CreateSubscriptionFilterComponent } from '../../../models/create-subscription-filter-component';
import { SubscriptionFilterChangedEvent } from '../../../models/subscription-filter-changed-event';
import { Supplier } from '../../../models/supplier';
import { selectSupplierOptions } from '../../../store/data-exchange.selectors';

@Component({
  selector: 'app-inventory-at-risk-filter',
  templateUrl: './inventory-at-risk-filter.component.html',
  styleUrls: []
})
export class InventoryAtRiskFilterComponent implements OnInit, CreateSubscriptionFilterComponent {

  supplierOptions$: Observable<Supplier[]>;
  selectedSupplier: Supplier = null;

  @Output()
  filterChanged = new EventEmitter<SubscriptionFilterChangedEvent>();

  constructor(private _store: Store) { }

  ngOnInit() {
    this.supplierOptions$ = this._store.pipe(select(selectSupplierOptions));
  }

  reset() {
    this.selectedSupplier = null;
    this.publishFilterChangedEvent();
  }

  onSupplierSelected() {
    this.publishFilterChangedEvent();
  }

  isFormValid(): boolean {
    return !!this.selectedSupplier;
  }

  publishFilterChangedEvent(validState = true): void {
    const formValid = this.isFormValid() && validState;

    this.filterChanged.emit({
      filtersValid: formValid,
      selectedSupplier: this.selectedSupplier,
      filters: [],
    });
  }
}
