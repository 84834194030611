import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumbItem, BreadCrumbModule } from '@progress/kendo-angular-navigation';

/**
 * Implementation copied from connect common web components
 * and adapted for use with kendo breadcrumbs
 */

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BreadCrumbModule,
  ]
})
export class BreadCrumbsComponent implements OnChanges {
  @Input()
  type: string;

  @Input()
  title: string;
  breadCrumbsArr: BreadCrumb[];
  breadCrumbItems: BreadCrumbItem[];
  bcStore: Storage = window.sessionStorage;

  constructor(private router: Router) { }

  ngOnChanges() {
    // Find existing breadcrumbs or create a new array
    this.breadCrumbsArr = JSON.parse(this.bcStore.getItem('breadCrumbs')) || [];
    this.processBreadcrumbArray({
      title: this.title,
      isRoot: this.type && this.type === 'parent',
      url: this.router.url
    });
  }

  processBreadcrumbArray(bcObject: BreadCrumb) {
    // If root, create a new array with root as the first item
    if (bcObject.isRoot) {
      this.breadCrumbsArr = [bcObject];
    } else {
      // Otherwise check for a matching url in previous breadcrumbs
      const previousIndex = this.breadCrumbsArr
        .findIndex((bcO: any) => bcO.url === bcObject.url);
      // If there is, remove everything after (and including) the match
      if (previousIndex !== -1) {
        this.breadCrumbsArr = this.breadCrumbsArr.slice(0, previousIndex);
      }
      // Add current URL to breadcrumbs
      this.breadCrumbsArr.push(bcObject);
    }
    this.setBreadCrumbs();
  }

  public setBreadCrumbs(): void {
    this.breadCrumbItems = [];
    this.bcStore.setItem('breadCrumbs', JSON.stringify(this.breadCrumbsArr));
    this.breadCrumbsArr.forEach(b => {
      this.breadCrumbItems.push({
        text: b.title,
        title: b.title,
      });
    });
  }

  public onItemClick(item: BreadCrumbItem): void {
    // event from kendo
    const option = this.breadCrumbsArr.find(o => o.title === item.title);
    if (option) {
      this.router.navigate([option.url]);
    }
  }
}

class BreadCrumb {
  title: string;
  isRoot: boolean;
  url: string;
}


