<div class="report-page-container">

  <div class="row">
    <div class="col-sm-12">
      <kehe-phoenix-alert
        (alertEvent)="handleAlertEvents($event)"
        [message]="(alert$ | async)?.message"
        [show]="(alert$ | async)?.message !== null"
        [type]="(alert$ | async)?.type"
        stick="true"
      >
      </kehe-phoenix-alert>
    </div>
  </div>

  <div class="row title">
    <div class="col-sm-6">
      <h2>Reporting</h2>
    </div>

    <div class="col-sm-6">
      <div class="d-flex flex-row-reverse align-items-center">
        <kehe-phoenix-button
          name="help-button"
          classAttr="btn-medium btn-link"
          iconClassAttr="fa fa-question"
          (click)="showHelpModal = true;"
        >
        Help
        </kehe-phoenix-button>
      </div>
    </div>
  </div>

  <app-help-modal
    [show]="showHelpModal"
    (modalClose)="showHelpModal = false;"
    [videos]="helpVideos"
  ></app-help-modal>

  <div class="row">
    <div class="col d-flex flex-row-reverse">
        <div class="w-50">
          <kendo-label text="Report"></kendo-label>
          <kendo-dropdownlist
            [data]="reportOptions$ | async"
            valueField = "value"
            textField = "label"
            (valueChange)="reportSelectionChanged($event)">
          </kendo-dropdownlist>

        </div>
    </div>
  </div>

  <div class="row report-viewer-row">
    <div class="col-sm-12" *ngIf="reportsVisible$ | async">
      <div class="report">
        <div class="iframe-container">
          <iframe
            title="embedded-report"
            [src]="reportUrl"
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="(reportsVisible$ | async) === false">
    <div class="col-sm-12">
      <div class="report">
        <div class="instructions-container">
          <div class="fas fa-seedling seedling"></div>
          <h3>No Report Selected</h3>
          <ol class="steps-list">
            <li>Select your report in the upper right</li>
            <li>Start getting reports</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
