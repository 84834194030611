import { ShowModel, ShowStatus } from "@app/models/show-models";
import { createSelector } from "@ngrx/store";
import { cloneDeep, uniqBy } from "lodash";
import { DateUtils } from '@kehe/phoenix-utils';
import { State } from "../index";
import * as fromShow from './show.reducer';
import { selectShowIdFromUrl } from '@app/shared/state/router/router.selectors';

const selectShowState = (state: State) => state.show;
const selectShows = createSelector(
    selectShowState,
    fromShow.getShows,
);
export const selectHasShowBeenChecked = createSelector(
    selectShowState,
    fromShow.getHasShowBeenChecked,
);
export const selectSliderElements = createSelector(
    selectShowState,
    fromShow.getSliderElements,
);
export const selectOrderVersions = createSelector(
    selectShowState,
    fromShow.getOrderVersions,
);
export const selectGhostOrders = createSelector(
    selectShowState,
    fromShow.getGhostOrders,
);
export const selectAllCustomers = createSelector(
    selectShowState,
    fromShow.getAllShowCustomers
);

const selectLiveAndPostShows = createSelector(
    selectShows,
    (shows) => shows?.filter((show) => show.status === ShowStatus.Live || show.status === ShowStatus.PostShow)
);

export const selectSortedEligibleShows = createSelector(
    selectLiveAndPostShows,
    (liveAndPostShows) => {
        return liveAndPostShows.sort((a, b) => {
            if (a.eventTypeId === b.eventTypeId) {
                return a.endDate > b.endDate ? 1 : -1;
            }
            return a.eventTypeId > b.eventTypeId ? 1 : -1;
        });
    }
);

export const selectAreEligibleShowsAvailable = createSelector(
    selectSortedEligibleShows,
    (shows) => !!shows?.length
);

export const selectFirstEligibleShowId = createSelector(
    selectSortedEligibleShows,
    (shows) => shows?.length ? shows[0].uniqueId : null
);

export const selectEligibleShowsWithNegotiationsEnabled = createSelector(
    selectSortedEligibleShows,
    (shows) => shows?.length ? shows?.filter((show) => show.disableNegotiations === false) : null
);

export const selectPriorityEventToDisplay = createSelector(
    selectEligibleShowsWithNegotiationsEnabled,
    (shows) => {
      if (!shows?.length) return null;
  
      const clonedShows = cloneDeep(shows);
  
      const sortedShows = clonedShows?.sort((a, b) => {
        return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
      });
  
      return sortedShows[0];
    }
);

export const selectSelectedShow = createSelector(
    selectSortedEligibleShows,
    selectShowIdFromUrl,
    (shows, showId): ShowModel => {
        if (!shows?.length) return null;
        return [...shows].find((show) => show.uniqueId === showId);
    }
);

export const selectCustomersFromSelectedShow = createSelector(
    selectSelectedShow,
    (show) => show?.customers?.length ? show?.customers : null
);

export const selectSelectedShowHasWaves = createSelector(
    selectSelectedShow,
    (show) => show?.hasWaves
);


export const selectCurrentShow = createSelector(
    selectSortedEligibleShows,
    (sortedEligibleShows) => sortedEligibleShows.length ? sortedEligibleShows[0] : undefined
);

export const selectCurrentShowId = createSelector(
    selectCurrentShow,
    (currentShow) => currentShow?.uniqueId ?? null
);

export const selectCurrentShowName = createSelector(
    selectCurrentShow,
    (currentShow) => currentShow?.name
);

export const selectIsSupplierInShow = createSelector(
    selectCurrentShow,
    (currentShow) => currentShow?.status === ShowStatus.Live
);

export const selectIsSupplierInPostShow = createSelector(
    selectCurrentShow,
    (currentShow) => {
      const currentDate = DateUtils.create(new Date());
      const postShowEndDate = currentShow && DateUtils.create(currentShow.endDate).add(currentShow.postShowPeriodDays, 'days');
      return !!currentShow && currentShow.status === ShowStatus.PostShow && currentDate.isBefore(postShowEndDate);
    }
);

export const selectSuppliers = createSelector(
    selectLiveAndPostShows,
    (liveAndPostShows) => uniqBy(liveAndPostShows.map(show => show.suppliers), 'esn')[0] || []
);

export const selectIsShowNegotiationDisabled = createSelector(
    selectSelectedShow,
    (selectedShow): boolean => {
        return Boolean(selectedShow?.disableNegotiations);
    }
);
