import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectEventsBannerDescription } from './events-banner.selectors';

@Component({
  selector: 'app-events-banner',
  templateUrl: './events-banner.component.html',
  styleUrls: ['./events-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsBannerComponent {
  @Input() showId: string;
  eventsBannerDescription$ = this._store.select(selectEventsBannerDescription);

  constructor (
    private _store: Store,
    private _router: Router,
  ) { }

  onManageOrdersBtnClick() {
    this._router.navigate(['/show',this.showId, 'orders'])
  }
}
