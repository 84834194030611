import { Component, OnInit } from '@angular/core';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { getItems } from '../../../store/data-exchange.actions';
import { getCartTotal } from '../../../store/data-exchange.selectors';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { PermissionsService } from '@app/services/permissions.service';
import { ResourceActions } from '@app/constants/resource-actions';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag/index';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent extends DestroyableDirective implements OnInit {

  isFeatureFlagEnabled = false;
  userType: string;
  cartTotal: number;
  thirdPartyUsers: boolean = false;

  constructor(
    private store: Store,
    private _permSvc: PermissionsService,
    ) {
    super();

  }

  ngOnInit() {
    combineLatest([
      this.store.select(selectIsFeatureFlagOn(FeatureFlags.DataExchangeForBrokers.key)),
      this.store.select(AuthenticationSelectors.selectUserType),
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([ff, userType]) => {
      this.isFeatureFlagEnabled = ff;
      this.userType = userType;
    });

    this.store.dispatch(getItems());
    this.store.pipe(
      takeUntil(this.destroy$),
      select(getCartTotal),
    ).subscribe(cartTotal => this.cartTotal = cartTotal);

    if (this._permSvc.userHasAction(ResourceActions.ThirdPartyOfRecord)) {
      this.thirdPartyUsers = true;
    }
  }


}
