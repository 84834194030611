import { FaqsComponent } from './../../components/faqs/faqs.component';
import { RouterModule } from '@angular/router';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { HelpModalComponent } from './../../components/help-modal/help-modal.component';
import { VimeoVideoComponent } from './../../components/vimeo-video-player/vimeo-video-player.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoenixSearchModule } from '@kehe/phoenix-search';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixCardBlocksModule } from '@kehe/phoenix-card-blocks';

@NgModule({
  declarations: [
    VimeoVideoComponent,
    HelpModalComponent,
    FaqsComponent,
  ],
  imports: [
    CommonModule,
    PhoenixModalModule,
    RouterModule,
    PhoenixSearchModule,
    PhoenixButtonModule,
    PhoenixCardBlocksModule,
  ],
  exports: [
    VimeoVideoComponent,
    HelpModalComponent,
    FaqsComponent,
  ]
})
export class HelpModule { }
