import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DestroyableDirective } from '../../abstract/destroyable';
import { select, Store } from '@ngrx/store';
import {
    AlertEvents,
    IAlertEvent
} from '@kehe/phoenix-notifications';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    getAnnouncementsUrl,
    getReportsUrl,
    getVendorManagementUrl
} from '../../utilities/url-utility';
import { ResourceActions } from '../../constants/resource-actions';
import { FeaturesV1 } from '../../constants/features';
import { PermissionsService } from '@app/services/permissions.service';
import { Constants } from '@app/constants/constants';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag/index';
import * as SupplierSelectors from '@app/shared/state/supplier/supplier.selectors';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import * as ComponentSelectors from './home-page.component.selectors';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends DestroyableDirective implements OnInit {
    public loadingSupplierList$: Observable<boolean>;
    public reportsLinkTitle: string;
    public reportsSecondaryLinkTitle: string;
    public reportsMessage: string;
    public outOfComplianceDocuments: any[];
    public canShowOutOfComplianceDocumentsBanner = true;
    public showOutOfComplianceDocumentsModal = false;
    public hasSupplierProductV2: boolean;
    public hasBusinessIntelligenceAnalytics: boolean;
    public supplierIsInShow: boolean;
    public hasOnly1DraftSupplier = false;
    public draftSupplierESN: string;
    public showAnnouncements$ = this._store.select(selectIsFeatureFlagOn(FeatureFlags.ViewAnnouncements.key));

    constructor(
        private _store: Store,
        private permSvc: PermissionsService,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.hasSupplierProductV2 = this.permSvc.userHasAction(
            ResourceActions.SupplierProductV2
        );
        this.hasBusinessIntelligenceAnalytics = this.permSvc.userHasAction(
            ResourceActions.SupplierBiDataRead
        );
        this._store
            .select(ShowSelectors.selectIsSupplierInShow)
            .subscribe((val) => (this.supplierIsInShow = val));
        this._store
            .select(SupplierSelectors.selectDoesUserHaveAccessToSingleDraftEsn)
            .subscribe((val) => (this.hasOnly1DraftSupplier = val));
        this.loadingSupplierList$ = this._store.select(
            SupplierSelectors.selectIsLoadingSuppliers
        );
        this._store
            .select(ComponentSelectors.selectFirstDraftSupplierEsn)
            .subscribe((val) => (this.draftSupplierESN = val));

        this._store
            .pipe(
                select(SupplierSelectors.selectOutOfComplianceDocuments),
                takeUntil(this.destroy$)
            )
            .subscribe((val) => {
                this.outOfComplianceDocuments = val;
            });
        this._store
            .select(SupplierSelectors.selectSupplierEsns)
            .pipe(takeUntil(this.destroy$))
            .subscribe((esns) => {
                this.canShowOutOfComplianceDocumentsBanner = !esns.includes(
                    Constants.SupplierV1DummyESN
                );
            });

        combineLatest([
            this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(ResourceActions.SupplierBiDataRead)),
            this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(FeaturesV1.Reports)),
            this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(ResourceActions.SSRSReportsBaseRead)) ||
            this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(
                    ResourceActions.SSRSReportsEnhancedRead
                )),
            this._store.select(AuthenticationSelectors.selectDoesUserHaveAction(ResourceActions.SupplierAnalyticsRead)),
        ]).subscribe(
            ([
                biPilotEnabled,
                reportsEnabled,
                userHasSsrsAccess,
                userHasDataExchangeAccess
            ]) => {
                this.reportsMessage =
                    'Understand your customers and business better by keeping up to date with recent analytics.';
                // TODO: set reportsSecondaryLinkTitle and route
                this.reportsSecondaryLinkTitle = null;

                if (biPilotEnabled) {
                    this.reportsLinkTitle = 'View Analytics';
                } else if (!biPilotEnabled && reportsEnabled) {
                    this.reportsLinkTitle = 'View Reports';
                } else if (!biPilotEnabled && !reportsEnabled) {
                    this.reportsLinkTitle = null;
                    this.reportsMessage =
                        'Please reach out to your Category Manager to learn more about our reporting options!';
                }
            }
        );
    }

    goToAnalytics($event?: any): void {
        if (this.reportsLinkTitle === 'View Reports') {
            window.open(getReportsUrl(), '_blank');
        } else {
            this.router.navigate(['/analytics']);
        }
    }

    goToProducts() {
        this.router.navigate(['/products']);
    }

    goToAnnouncements() {
        window.open(getAnnouncementsUrl(), '_blank');
    }

    goToVendorManagement() {
        window.open(getVendorManagementUrl(), '_blank');
    }

    goToDocuments() {
        this.router.navigate(['/resources/view']);
    }

    onComplianceDocumentsAlertEvent(event: IAlertEvent) {
        switch (event.type) {
            case AlertEvents.Action1:
                this.showOutOfComplianceDocumentsModal = true;
                break;
            case AlertEvents.Action2:
                this.goToVendorManagement();
                break;
        }
    }

    onComplianceDocumentsModalSubmit(): void {
        this.goToVendorManagement();
    }

    onComplianceDocumentsModalEvent() {
        this.showOutOfComplianceDocumentsModal = false;
    }
}
