<ng-container *ngIf="faqs.length > 0">
  <div class="faq-title" *ngIf="showTitle">
    <h3>FAQ's</h3>
    <kehe-phoenix-search placeholder="Search FAQ's" (valueChanged)="searchChanged($event)" style="width: 300px; margin-bottom: 8px;"></kehe-phoenix-search>
  </div>
  <div *ngFor="let faq of filteredFaqs">
    <kehe-phoenix-collapsible-card [cardTitle]="faq.question">
      <ng-template #cardContent>
        <div class="card-content">
          <p [innerHTML]="faq.answer"></p>
          <kehe-phoenix-button *ngIf="faq.videoId" name="play-button" classAttr="btn-default btn-medium"
            iconClassAttr="fa fa-play" (click)="playVideo(faq)">
            Start Video
          </kehe-phoenix-button>
        </div>
      </ng-template>
    </kehe-phoenix-collapsible-card>
  </div>
</ng-container>

