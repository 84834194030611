const listChatRoomByCustomerSupplierNumber = (customerNumber: string, supplierNumber: string): string => {
    const customerSupplierNumber = `"${customerNumber}|${supplierNumber}"`;

    return `
    query MyQuery {
      chatRoomByCustomerSupplierNumber(customerSupplierNumber: ${customerSupplierNumber}) {
        items {
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          updatedAt
          createdAt
          clientNumberUnread
        }
      }
    }`;
};

export default listChatRoomByCustomerSupplierNumber;
