import { SupplierListItem } from './../../supplier-list/models/supplier-list-item';
import { ProductListItem } from './../../product-list/models/product-list-item';
import { RetailerArea } from './../models/retailer-area';
import { Supplier } from './../models/supplier';
import { DataSubscriptionPayload } from './../models/data-subscription-payload';
import { DataSubscription } from '../models/data-subscription';
import { Payload } from './../../../models/payload';
import { createAction, props } from '@ngrx/store';
import { Brand } from '../../product-detail/models/brand';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IAuthorizeNetResponse } from '../checkout/models/iauthorize-net-response';
import { CartItem } from '../checkout/models/cart-item';
import { EsnSubscription } from '../models/esn-subscription';
import { ManageRecipientsForm } from '../models/manage-recipients-form';
import { EsnSubscriptionBillingFrequency } from '../models/enum/esn-subscription-billing-frequency';

export const loadSuppliers = createAction(
  '[Data Exchange] Load Suppliers'
);

export const loadSuppliersSuccess = createAction(
  '[Data Exchange] Load Suppliers Success',
  props<{ suppliers: Supplier[] }>()
);

export const loadSuppliersByEmail = createAction(
  '[Data Exchange] Load Suppliers By Email'
);
export const loadSuppliersByEmailSuccess = createAction(
  '[Data Exchange] Load Suppliers By Email Success',
  props<{ suppliers: Supplier[], availableCount: number }>()
);
export const loadSupplierSubscriptions = createAction(
  '[Data Exchange] Load Supplier Subscriptions',
  props<{ esns: string[], ebn: string }>()
);
export const loadSupplierSubscriptionsSuccess = createAction(
  '[Data Exchange] Load Supplier Subscriptions Success',
  props<{ suppliers: Supplier[], subscriptions: EsnSubscription[], availableCount: number }>()
);

export const loadSupplierDetailsSuccess = createAction(
  '[Data Exchange] Load Supplier Details Success',
  props<{ userSuppliers: Supplier[], nonConnectBiSuppliers: SupplierListItem[] }>()
);

export const loadSupplierDetailsFailure = createAction(
  '[Data Exchange] Load Supplier Details Failure',
  props<{ message: string }>()
);

export const loadSuppliersFailure = createAction(
  '[Data Exchange] Load Suppliers Failure',
  props<{ message: string }>()
);

export const loadSubscriptions = createAction(
  '[Data Exchange] Load Subscriptions'
);

export const loadSubscriptionsSuccess = createAction(
  '[Data Exchange] Load Subscriptions Success',
  props<{ subscriptions: Payload<Array<DataSubscriptionPayload>> }>()
);

export const loadSubscriptionsFailure = createAction(
  '[Data Exchange] Load Subscriptions Failure',
  props<{ message: string}>()
);

export const loadSuppliersByEmailFailure = createAction(
  '[Data Exchange] Load Suppliers By Email Failure',
  props<{ message: string}>()
);

export const deleteModalDismissed = createAction(
  '[Data Exchange] Delete Modal Dismissed'
);

export const singleDeleteClicked = createAction(
  '[Data Exchange] Single Delete Clicked',
  props<{ subscription: DataSubscription }>()
);

export const deleteSubscriptions = createAction(
  '[Data Exchange] Delete Subscriptions',
  props<{ ids: number[] }>()
);

export const bulkDeleteClicked = createAction(
  '[Data Exchange] Bulk Delete Clicked',
);

export const bulkRegisterClicked = createAction(
  '[Data Exchange] Bulk Register Clicked',
);

export const bulkThirdPartyRegisterClicked = createAction(
  '[Data Exchange] Bulk Register for Third Party Users Clicked',
);

export const bulkAddItemsToCart = createAction(
  '[Data Exchange Effects] Add Items to Cart on Bulk Register Clicked',
);

export const bulkReactivateSubscriptions = createAction(
  '[Data Exchange Effects] Reactivate Subscriptions on Bulk Register Clicked',
);

export const bulkManageRecipientsClicked = createAction(
  '[Data Exchange] Bulk Manage Recipients Clicked',
);

export const bulkCancelClicked = createAction(
  '[Data Exchange] Bulk Cancel Clicked',
);

export const deleteSelectedSubscriptions = createAction(
  '[Data Exchange] DeleteSelectedSubscriptions',
);

export const deleteSubscriptionsSuccess = createAction(
  '[Data Exchange] Delete Subscriptions Success',
  props<{ deletedSubscriptions: DataSubscriptionPayload[] }>()
);

export const deleteSubscriptionsFailure = createAction(
  '[Data Exchange] Delete Subscriptions Failure',
  props<{ message: string }>()
);

export const pageChange = createAction(
  '[Data Exchange] Page Change',
  props<{ skip: number, take: number }>()
);

export const supplierPageChange = createAction(
  '[Data Exchange] Supplier Page Change',
  props<{ skip: number, take: number }>()
);

// subscriptions grid: sort
export const sortChange = createAction(
  '[Data Exchange] Sort Change',
  props<{ sort: SortDescriptor }>()
);

export const supplierSortChange = createAction(
  '[Data Exchange] Supplier By Email Sort Change',
  props<{ sort: SortDescriptor }>()
);


export const addSubscriptionClicked = createAction(
  '[Data Exchange] Add Subscription Clicked'
);

export const addSubscriptionModalDismissed = createAction(
  '[Data Exchange] Add Subscription Modal Dismissed'
);

export const createSubscription = createAction(
  '[Data Exchange] Create Subscription',
  props<{ subscription: DataSubscriptionPayload }>()
);

export const createSubscriptionSuccess = createAction(
  '[Data Exchange] Create Subscription Success',
  props<{ subscription: DataSubscriptionPayload }>()
);

export const createSubscriptionFailure = createAction(
  '[Data Exchange] Create Subscription Failure',
  props<{ message: string}>()
);

export const alertClosed = createAction(
  '[Data Exchange] Alert Closed'
);

export const showInfoModal = createAction(
  '[Data Exchange] Show Info Modal',
  props<{ subscription: DataSubscription }>()
);

export const infoModalDismissed = createAction(
  '[Data Exchange] Info Modal Dismissed'
);

export const loadRetailerAreaNames = createAction(
  '[Data Exchange] Load Retailer Area Names'
);

export const loadRetailerAreaNamesSuccess = createAction(
  '[Data Exchange] Load Retailer Area Names Success',
  props<{ retailerAreaNames: RetailerArea[] }>()
);

export const loadRetailerAreaNamesFailure = createAction(
  '[Data Exchange] Load Retailer Area Names Failure',
  props<{ message: string }>()
);

export const singleCheckboxToggled = createAction(
  '[Data Exchange] Single Checkbox Toggled',
  props<{ id: number }>() // The ID of the subscription whose checkbox was toggled
);

export const selectAllToggled = createAction(
  '[Data Exchange] Select All Toggled',
  props<{ isSelected: boolean }>()
);

export const subscriptionSelected = createAction(
  '[Data Exchange] Subscription Selected',
  props<{ subscriptions: DataSubscription[] }>()
);

export const esnSubscriptionSelected = createAction(
  '[Data Exchange] ESN Subscription Selected By Broker',
  props<{ esns: string[] }>()
);


export const loadBrandsForSupplier = createAction(
  '[Data Exchange] Load Brands for Supplier',
  props<{esn: string}>()
);

export const loadBrandsForSupplierSuccess = createAction(
  '[Data Exchange] Load Brands for Supplier Success',
  props<{brands: Brand[]}>()
);

export const loadBrandsForSupplierFailure = createAction(
  '[Data Exchange] Load Brands for Supplier Failure',
  props<{message: string}>()
);

export const loadBrands = createAction(
  '[Data Exchange] Load Brands',
  props<{esn: string, twoDigitDcs: string[]}>()
);

export const loadProducts = createAction(
  '[Data Exchange] Load Products',
  props<{supplier: Supplier}>()
);

export const loadProductsSuccess = createAction(
  '[Data Exchange] Load Products Success',
  props<{products: ProductListItem[], brands: Brand[]}>()
);

export const loadProductsFailure = createAction(
  '[Data Exchange] Load Products Failure',
  props<{message: string}>()
);

/////////////////////////////////////

export const getItems = createAction(
  '[Order Component] Get Items',
);

export const getItemsSuccess = createAction(
  '[Order Component] Get Items Success',
  props<{ allItems: CartItem[], itemsToDisplay: CartItem[]}>()
);

export const getItemsError = createAction(
  '[Order Component] Get Items Error',
  props<{error: any}>()
);

export const addItemToCart = createAction(
  '[Data Exchange Page] Broker Adds Item to Cart',
  props<{ subscription: EsnSubscription }>()
);

export const addItemToCartSuccess = createAction(
  '[Data Exchange Page] Broker Adds Item to Cart Success',
  props<{items: CartItem[], toastHeader: string}>()
);


export const userLeavesDataExchangePage = createAction(
  '[Checkout Page] User Enters Page'
);

export const removeItemFromCart = createAction(
  '[Cart Page] Broker Removes Item From Cart',
  props<{esn: string}>()
);

export const removeItemFromCartSuccess = createAction(
  '[Cart Page] Remove Item From Cart',
  props<{items: CartItem[]}>()
);

export const userEntersCheckoutPage = createAction(
  '[Checkout Page] User Enters Page'
);

export const registerThirdPartyUser = createAction(
  '[Data Exchange] Register Button Clicked For Third Party User',
  props<{subscription: EsnSubscription}>()
);

export const handleBulkThirdPartyRegister = createAction(
  '[Data Exchange] Register Button Clicked For Third Party User',
  props<{subscription: EsnSubscription}>()
);


export const submitOrder = createAction(
  '[Checkout Page] Submit Order',
);

export const submitOrderResponseReceived = createAction(
  '[Checkout Page] Submit Order Response Received',
  props<{paymentTokenResponse: IAuthorizeNetResponse}>()
);

export const submitOrderResponseSuccess = createAction(
  '[Checkout Page] Submit Order Response Success',
);

export const submitOrderResponseError = createAction(
  '[Checkout Page] Submit Order Response Error',
);

export const processTransactionResponseSuccess = createAction(
  '[Checkout Page] Process Transaction Response Success',
  props<{transactionResponse: any}>()
);

export const processTransactionResponseError = createAction(
  '[Checkout Page] Process Transaction Response Error',
  props<{transactionResponse: any}>()
);

export const clearCartAfterSuccessfulTransaction = createAction(
  '[Checkout Page] Cart Cleared'
);

export const cartPageChange = createAction(
  '[Order Component] User Clicks Page Number',
  props<{skip: number, take: number}>()
);

export const cartComponentDestroyed = createAction(
  '[Order Component] On Destroy',
);

export const manageRecipientsButtonClicked = createAction(
  '[Data Exchange] Manage Recipients Button Clicked',
  props<{subscription: EsnSubscription}>()
);

export const loadAvailableRecipientEmails = createAction(
  '[Manage Recipients Modal] Manage Recipients Load Emails',
);

export const loadAvailableRecipientEmailsForBulk = createAction(
  '[Manage Recipients Modal] Manage Recipients Load Emails For Managing In Bulk',
);

export const loadAvailableRecipientEmailsSuccess = createAction(
  '[Manage Recipients Modal] Manage Recipients Load Emails Success',
  props<{emails: string[]}>()
);

export const loadAvailableRecipientEmailsError = createAction(
  '[Manage Recipients Modal] Manage Recipients Load Emails Error',
);

export const manageRecipientsModalCancelClicked = createAction(
  '[Manage Recipients Modal] Manage Recipients Cancel Button Clicked',
);

export const manageRecipientsModalCloseClicked = createAction(
  '[Manage Recipients Modal] Manage Recipients Close Button Clicked',
);

export const manageRecipientsFormValueChange = createAction(
  '[Manage Recipients Modal] Manage Recipients Form Value Changed',
  props<{formValue: ManageRecipientsForm}>()
);

export const manageRecipientsSetFormForBulkIfOneSelected = createAction(
  '[Manage Recipients Modal] Manage Recipients Set Form When Only One Is Selected For Bulk Action',
  props<{esnSubscription: EsnSubscription}>()
);

export const manageRecipientsUpdateClick = createAction(
  '[Manage Recipients Modal] Manage Recipients Update Clicked',
);

export const manageRecipientsUpdateForBulkClick = createAction(
  '[Manage Recipients Modal] Manage Recipients In Bulk Update Clicked',
);

export const manageRecipientsUpdateSuccess = createAction(
  '[Manage Recipients Modal] Manage Recipients Update Success',
);

export const manageRecipientsUpdateError = createAction(
  '[Manage Recipients Modal] Manage Recipients Update Error',
  props<{errorMessage: string}>()
);

export const whatsIncludedInMySubscriptionClick = createAction(
  '[Data Exchange Page] Whats Included In My Subscription Clicked',
);

export const dimissSubscriptionOverviewModal = createAction(
  '[Subscription Overview Modal] User Dismisses Modal',
);

export const closeSuccessfulTransactionAlert = createAction(
  '[Data Exchange Page] User Closes Successful Transaction Alert',
);

export const cancelEsnSubscriptionClicked = createAction(
  '[Data Exchange Page] Cancel ESN Subscription Button Clicked',
  props<{subscription: EsnSubscription}>()
);

export const cancelEsnSubscriptionModalCancelClicked = createAction(
  '[Cancel ESN Subscription Modal] Cancel Button Clicked',
);

export const cancelEsnSubscriptionModalCloseClicked = createAction(
  '[Cancel ESN Subscription Modal] Close Button Clicked',
);

export const cancelEsnSubscriptionModalConfirmClick = createAction(
  '[Cancel ESN Subscription Modal] Confirm Single ESN Subscription Click',
);

export const cancelEsnSubscriptionsModalConfirmClick = createAction(
  '[Cancel ESN Subscription Modal] Confirm Multiple ESN Subscriptions Click',
);

export const cancelEsnSubscriptionConfirmSuccess = createAction(
  '[Cancel ESN Subscription Modal] Cancel ESN Subscription Confirm Success',
);

export const cancelEsnSubscriptionConfirmError = createAction(
  '[Cancel ESN Subscription Modal] Cancel ESN Subscription Confirm Error',
  props<{errorMessage: string}>()
);

export const registerPreviouslyCanceledEsn = createAction(
  '[Data Exchange] Register Button Clicked For Previously Canceled Subscription',
  props<{subscription: EsnSubscription}>()
);

export const upsertEsnSubscriptionSuccess = createAction(
  '[Data Exchange API] Upsert ESN Subscription Success',
);

export const upsertEsnSubscriptionError = createAction(
  '[Data Exchange API] Upsert ESN Subscription Error',
  props<{errorMessage: string}>()
);

export const thirdPartyTransactionSuccess = createAction(
  '[Data Exchange API] Third Party Transaction Response Success',
  props<{transactionResponse: any}>()
);

export const thirdPartyTransactionError = createAction(
  '[Data Exchange API] Third Party Transaction Response Error',
  props<{transactionResponse: any}>()
);

export const showFrequencyModal = createAction(
  '[Data Exchange Effects] Show Frequency Modal'
);

export const frequencyModalCancelClicked = createAction(
  '[Choose Frequency Modal] Cancel Button Clicked'
);

export const frequencyModalCloseClicked = createAction(
  '[Choose Frequency Modal] Close Icon Clicked'
);

export const frequencyModalAddClicked = createAction(
  '[Choose Frequency Modal] Add Button Clicked',
  props<{frequency: EsnSubscriptionBillingFrequency}>()
);

export const maximumCartLimitExceeded = createAction(
  '[Data Exchange Effects] Maximum Cart Limit Size of 50 Exceeded',
);




