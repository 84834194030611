export enum PaymentTokenErrorCodes {
    INCLUDE_ACCEPT_JS_CDN =  'E_WC_01',
    HTTPS_REQUIRED = 'E_WC_02',
    ACCEPT_JS_NOT_LOADED = 'E_WC_03',
    MISSING_REQUIRED_FIELDS = 'E_WC_04',
    INVALID_CREDIT_CARD_NUMBER = 'E_WC_05',
    INVALID_CARD_EXPIRY_MONTH = 'E_WC_06',
    INVALID_CARD_EXPIRY_YEAR = 'E_WC_07',
    CARD_EXPIRY_NOT_IN_FUTURE = 'E_WC_08',
    INVALID_API_LOGIN_ID = 'E_WC_10',
    INVALID_FINGERPRINT = 'E_WC_13',
    ENCRYPTION_FAILED = 'E_WC_14',
    INVALID_CARD_CVV = 'E_WC_15',
    INVALID_ZIP_CODE = 'E_WC_16',
    INVALID_CARD_HOLDER_NAME = 'E_WC_17',
    CLIENT_KEY_REQUIRED = 'E_WC_18',
    AN_ERROR_OCCURRED = 'E_WC_19',
    INVALID_AUTHENTICATION = 'E_WC_21',
    BOTH_CARD_AND_BANK_INFO_PROVIDED = 'E_WC_23',
    INVALID_BANK_ACCOUNT_NUMBER = 'E_WC_24',
    INVALID_BANK_ROUTING_NUMBER = 'E_WC_25',
    INVALID_BANK_ACCOUNT_HOLDER_NAME = 'E_WC_26',
    INVALID_BANK_ACCOUNT_TYPE = 'E_WC_27',
}
