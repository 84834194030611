import { createFeatureSelector, createSelector } from '@ngrx/store';

import { recentAnnouncementsFeatureKey, RecentAnnouncementsState } from './recent-announcements.reducer';
import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncementsState } from '../interfaces/recent-announcements-state.interface';

export const getRecentAnnouncementsState = createFeatureSelector<RecentAnnouncementsState>(
  recentAnnouncementsFeatureKey
);

export const selectAnnouncementRecentLiveCount = createSelector(
  getRecentAnnouncementsState,
  (state: RecentAnnouncementsState): IAnnouncementsRecentLiveCount => state.recentLiveCount
);

export const selectRecentAnnouncementsState = createSelector(
  getRecentAnnouncementsState,
  ({
     loading,
     recentAnnouncementsError,
     recentAnnouncements
  }: RecentAnnouncementsState): IRecentAnnouncementsState => {
    return {
      loading,
      err: recentAnnouncementsError,
      list: recentAnnouncements,
    }
  }
);
