<kehe-phoenix-modal
  name="subscriptionOverviewModal"
  modalTitle="Subscription Overview"
  [show]="true"
  (modalEvent)="dismissModal()"
  size="sm"
>
  <div body>
    <p><strong>The following reports are included in the subscription:</strong></p>
    <ul>
      <li>Brand Item Location</li>
      <li>Chains and Stores that Ordered an Item</li>
      <li>Enterprise Supplier Chain Sales by Brand</li>
      <li>Inbound Fill Rate</li>
      <li>Inventory at Risk</li>
      <li>Inventory Stock Status</li>
      <li>Item 360 Recap HD</li>
      <li>KeHE Full POD Vendor</li>
    </ul>
  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button
      name="data-exchange-okay-btn"
      classAttr="btn-primary btn-medium"
      (click)="dismissModal()"
    >Okay</kehe-phoenix-button>
  </div>

</kehe-phoenix-modal>