import { selectRetailerAreaNames } from './../../../store/data-exchange.selectors';
import { select, Store } from '@ngrx/store';
import { SubscriptionFilterChangedEvent } from './../../../models/subscription-filter-changed-event';
import { SupplierDcBrandFilterComponent } from './../supplier-dc-brand-filter/supplier-dc-brand-filter.component';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CreateSubscriptionFilterComponent } from '../../../models/create-subscription-filter-component';
import { SubscriptionFilter } from '../../../models/subscription-filter';
import { RetailerArea } from '../../../models/retailer-area';
import { SubscriptionFilterKey } from '../../../models/enum/subscription-filter-key';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chain-sales-brand-filter',
  templateUrl: './chain-sales-brand-filter.component.html',
  styleUrls: []
})
export class ChainSalesBrandFilterComponent extends DestroyableDirective  implements OnInit, CreateSubscriptionFilterComponent {

  retailerAreaNameOptions: RetailerArea[] = [];
  selectedRetailerAreaList: RetailerArea[] = [];
  supplierDcBrandState: SubscriptionFilterChangedEvent = {
    filtersValid: false,
    selectedSupplier: null,
    filters: []
  };

  @ViewChild('suppierDcBrandFilter', { static: true }) supplierDcBrandFilter: SupplierDcBrandFilterComponent;

  @Output()
  filterChanged = new EventEmitter<SubscriptionFilterChangedEvent>();

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectRetailerAreaNames),
    ).subscribe(names => {
      this.retailerAreaNameOptions = names;
    });
  }

  onSupplierDcBrandChanged(event: SubscriptionFilterChangedEvent): void {
    this.supplierDcBrandState = event;
    this.publishFilterChangedEvent();
  }

  onChainGroupSelected() {
    this.publishFilterChangedEvent();
  }

  selectAllRetailerAreas(): void {
    if (this.selectedRetailerAreaList.length === this.retailerAreaNameOptions.length) { return; }
    this.selectedRetailerAreaList = this.retailerAreaNameOptions;
    this.onChainGroupSelected();
  }

  reset(): void {
    this.selectedRetailerAreaList = [];
    this.supplierDcBrandState.filtersValid = false;
    this.supplierDcBrandFilter.reset();
  }

  isFormValid(): boolean {
    return this.supplierDcBrandState.filtersValid &&
    !!this.selectedRetailerAreaList &&
    this.selectedRetailerAreaList.length > 0;
  }

  getFilters(): SubscriptionFilter[] {
    return this.supplierDcBrandState.filters.concat([
      { key: SubscriptionFilterKey.RetailerArea, value: this.selectedRetailerAreaList.map(ra => ra.id.toString())}
    ]);
  }

  publishFilterChangedEvent(): void {
    const formValid = this.isFormValid();

    this.filterChanged.emit({
      filtersValid: formValid,
      selectedSupplier: this.supplierDcBrandState.selectedSupplier,
      filters: formValid ? this.getFilters() : [],
    });
  }

  tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

}
