import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EsnSubscriptionStatus } from '../../models/enum/esn-subscription-status';

@Component({
  selector: 'app-esn-subscription-status-tag',
  templateUrl: './esn-subscription-status-tag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EsnSubscriptionStatusTagComponent implements OnChanges {

  @Input()
  status: EsnSubscriptionStatus;

  color: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.status && changes.status.currentValue) {
      switch (changes.status.currentValue) {
        case EsnSubscriptionStatus.Unregistered:
        case EsnSubscriptionStatus.Expired:
          this.color = '#E2E2E2';
          break;
        case EsnSubscriptionStatus.Registered:
          this.color = '#D5E48F';
          break;
        case EsnSubscriptionStatus.Canceled:
          this.color = '#FAD981';
          break;
        case EsnSubscriptionStatus.PendingRenewal:
          this.color = '#C2D5E4';
          break;
        case EsnSubscriptionStatus.ExpiringSoon:
          this.color = '#F7B288';
          break;
      }
    }
  }

}
