import { analyticsDashboardFaqs } from './analytics-dashboard-faqs';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { DestroyableDirective } from '../../abstract/destroyable';
import { Observable } from 'rxjs';
import { ResourceActions } from '../../constants/resource-actions';
import { PermissionsService } from '@app/services/permissions.service';
import { Environment } from '../../../environments/environment-enum';
import { ExternalVideo } from '../../models/external-video';
import { HelpCenterGroup } from '../../modules/reporting/models/enum/help-center-group';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

// Endpoint to use when the dashboard is not deployed to AAS
export const nonAasEndpoint = 'embedreport';
// Endpoint to use when the dashboard is deployed to AAS
export const aasEndpoint = 'customdata';

export const analyticsDashboardRoute = 'analytics';
@Component({
  selector: 'app-analytics-page',
  templateUrl: './analytics-page.component.html',
  styleUrls: ['./analytics-page.component.scss']
})
export class AnalyticsPageComponent extends DestroyableDirective  implements OnInit {

  public userToken$: Observable<string>;
  public dashboardHeight: any;
  public showDashboard: boolean;
  public dashboardUrl: string;
  showHelpModal = false;

  // Environments where the dashboard is deployed to AAS
  aasEnvironments: Environment[] = [
    Environment.PROD,
  ];

  headerHeight = 50;
  navbarHeight = 40;
  titleHeight = 30;
  marginsHeight = 40;

  helpVideos: ExternalVideo[] = [
    {
      videoTitle: 'How to Read and Filter the Analytics Dashboard',
      vimeoId: '502636740/1fea7258cf',
      group: HelpCenterGroup.ConnectBi,
    },
    {
      videoTitle: 'How to Create a Sales Summary',
      vimeoId: '502636293/54f2cd28fc',
      group: HelpCenterGroup.ConnectBi,
    },
  ];

  faqs = analyticsDashboardFaqs;

  constructor(
    public _store: Store,
    private _permSvc: PermissionsService,
  ) {
    super();
  }

  ngOnInit() {
    const endpoint = this.aasEnvironments.includes(environment.current) ? aasEndpoint : nonAasEndpoint;
    this.dashboardUrl = `${environment.powerbiReportApi}/${endpoint}?reportId=${environment.analyticsDashboardId}`;
    this.dashboardHeight = window.innerHeight - this.headerHeight - this.navbarHeight - this.titleHeight - this.marginsHeight;
    this.userToken$ = this._store.select(AuthenticationSelectors.selectToken);
    this.showDashboard = this._permSvc.userHasAction(ResourceActions.SupplierBiDataRead);
  }

}
