import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { FaqService } from "./faq.service";
import * as FaqComponentActions from '@app/components/faqs/faqs.actions';
import * as FaqShowComponentActions from '@app/components/faqs-show/faqs-show.actions';
import * as FaqApiActions from './faq.actions';
import { of } from "rxjs";

@Injectable()
export class FaqEffects {

    constructor(
        private actions$: Actions,
        private faqService: FaqService,
    ) {
    }

    getFaqs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                FaqComponentActions.componentLoaded,
                FaqShowComponentActions.componentLoaded,
            ),
            exhaustMap(() => this.faqService.getFaqs().pipe(
                map(faqs => FaqApiActions.getFaqsSuccess({ faqs })),
                catchError(() => of(FaqApiActions.getFaqsFailure()))
            ))
        )
    });
}
