
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as MessagesSelectors from './messages.selectors';
import * as actions from './messages.actions';
import { IChatRoom } from '../models/IChatRoom';
import { ISupplier } from '../models/ISupplier';
import { ICustomer } from '../models/ICustomer';

@Injectable()
export class MessagesFacade {


  constructor(
    private _store: Store,
  ) {
  }

  selectEnabledChats$ = this._store.select(MessagesSelectors.selectEnabledChats);
  selectHeaderChats$ = this._store.select(MessagesSelectors.selectHeaderChats);
  selectHeaderChatsFiltered$ = this._store.select(MessagesSelectors.selectHeaderChatsFiltered);
  selectUnreadCount$ = this._store.select(MessagesSelectors.selectUnreadCount);
  selectCustomers$ = this._store.select(MessagesSelectors.selectCustomers);
  selectSuppliers$ = this._store.select(MessagesSelectors.selectSuppliers);
  selectNewChatOpen$ = this._store.select(MessagesSelectors.selectNewChatOpen);


  openChat(customerNumber: string,
    supplierNumber: string,
    customerName: string,
    supplierName: string
    ) {
    this._store.dispatch(actions.openChat({
      customerNumber,
      supplierNumber,
      customerName,
      supplierName,
    }));
  }

  closeChat(chatId: string) {
    this._store.dispatch(actions.closeChat({ chatId }));
  }

  toggleCollapseChat(chatRoom: IChatRoom) {
    this._store.dispatch(actions.toggleCollapseChat({ chatRoom }));
  }

  sendMessage(message: string, chatId: string, userFullName: string, userEmail: string) {
    this._store.dispatch(actions.sendMessage({message, chatId, userFullName, userEmail}));
  }

  setUserFullName(userFullName: string) {
    this._store.dispatch(actions.setUserFullName({ userFullName }));
  }

  setUserEmail(userEmail: string) {
    this._store.dispatch(actions.setUserEmail({ userEmail }));
  }

  setSuppliers(suppliers: Array<ISupplier>) {
    this._store.dispatch(actions.setSuppliers({ suppliers }));
  }

  setCustomers(customers: Array<ICustomer>) {
    this._store.dispatch(actions.setCustomers({ customers }));
  }

  subscribeToChatRoomsByCustomer() {
    this._store.dispatch(actions.subscribeToChatRoomsByCustomer());
  }

  getMoreMessages(chatRoom: IChatRoom) {
    this._store.dispatch(actions.getMoreMessages({ chatRoom }));
  }

  createNewChat() {
    this._store.dispatch(actions.createNewChat());
  }

  closeNewChat() {
    this._store.dispatch(actions.closeNewChat());
  }

  saveDraftMessageChat(draftMessage: string, chatId: string, ) {
    this._store.dispatch(actions.saveDraftMessageChat({ chatId, draftMessage }));
  }

  setFilterHeaderChatsText(text: string) {
    this._store.dispatch(actions.setFilterHeaderChatsText({ text }));
  }

}

