import { MarketingAllowanceCode } from './../models/marketing-allowance-code';
import { SupplierListFilter } from './../../supplier-list/models/supplier-list-filter';
import { formatDate } from '@angular/common';
import {
  getBillingFrequency,
  getEsnSubscriptionsForCart,
  getNumberOfItemsInCart,
  selectCancelEsnSubscriptionsRequest,
  selectEsnSubscriptionToTakeActionOn,
  selectFilter,
  selectManageRecipientsUpdateInBulkRequest,
  selectManageRecipientsUpdateRequest,
  selectPaymentTokenResponse,
  selectPaymentTransactionRequest,
  selectThirdPartyRequest,
  selectThirdPartyBulkRequest,
  selectSelectedSubscriptions,
  selectSelectedSupplierSubscriptions,
  selectSkipForCart,
  selectSuppliersByEmailFilter,
  selectTakeForCart,
  selectUpsertEsnSubscriptionsRequest,
} from './data-exchange.selectors';
import { DataExchangeService } from './../services/data-exchange.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import * as DataExchangeActions from './data-exchange.actions';
import { map, switchMap, catchError, mergeMap, withLatestFrom, tap, filter, exhaustMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { SupplierService } from '../../supplier-list/services/supplier.service';
import { ShoppingCartService } from '../checkout/services/shopping-cart.service';
import { AddToCartToastComponent } from '../components/add-to-cart-toast/add-to-cart-toast.component';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { EsnSubscriptionStatus } from '../models/enum/esn-subscription-status';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

@Injectable()
export class DataExchangeEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _dataExchangeService: DataExchangeService,
    private _supplierService: SupplierService,
    private _shoppingCartService: ShoppingCartService,
    private _PhoenixToastService: PhoenixToastService,
  ) { }

  triggerSubscriptionRefresh$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        DataExchangeActions.pageChange,
        DataExchangeActions.sortChange,
        DataExchangeActions.createSubscriptionSuccess,
        DataExchangeActions.deleteSubscriptionsSuccess,
      ),
      map(() => DataExchangeActions.loadSubscriptions())
    )
  );

  triggerSupplierRefresh$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        DataExchangeActions.supplierPageChange,
        DataExchangeActions.supplierSortChange,
        DataExchangeActions.manageRecipientsUpdateSuccess,
        DataExchangeActions.cancelEsnSubscriptionConfirmSuccess,
        DataExchangeActions.upsertEsnSubscriptionSuccess,
        DataExchangeActions.thirdPartyTransactionSuccess,

      ),
      map(() => DataExchangeActions.loadSuppliersByEmail())
    )
  );

  loadSubscriptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadSubscriptions),
      withLatestFrom(this._store.pipe(select(selectFilter))),
      switchMap(([action, selectedFilter]) => {
        return this._dataExchangeService.getSubscriptions(selectedFilter).pipe(
          map(subscriptions => DataExchangeActions.loadSubscriptionsSuccess({ subscriptions })),
          catchError((error) => of(
            DataExchangeActions.loadSubscriptionsFailure({ message: error.message })
          ))
        );
      })
    )
  );

  loadSuppliers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadSuppliers),
      withLatestFrom(this._store.select(AuthenticationSelectors.selectUserEmail)),
      switchMap(([action, userEmail]) => {
        return this._dataExchangeService.getEsnListByUserApi(userEmail).pipe(
          map(supplierList => {
            const suppliers = supplierList.data.map(supplier => ({
              name: supplier.name,
              esn: supplier.esn
            }));
            return DataExchangeActions.loadSuppliersSuccess({ suppliers });
          }),
          catchError(error => of(
            DataExchangeActions.loadSuppliersFailure({ message: error.message })
          ))
        );
      })
    )
  );

  loadSuppliersByEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadSuppliersByEmail),
      withLatestFrom(
        this._store.pipe(select(selectSuppliersByEmailFilter)),
        this._store.select(AuthenticationSelectors.selectUserEmail),
      ),
      switchMap(([action, suppliersByEmailFilter, email]) => {
        return this._dataExchangeService.getSuppliersByEmail(suppliersByEmailFilter, email).pipe(
          map(suppliers => {
            return DataExchangeActions.loadSuppliersByEmailSuccess({
              suppliers: suppliers.data,
              availableCount: suppliers.availableCount
            });
          }),
          catchError((error) => of(
            DataExchangeActions.loadSuppliersByEmailFailure({ message: error.message })
          ))
        );
      })
    )
  );

  loadSupplierSubscriptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadSuppliersByEmailSuccess),
      withLatestFrom(
        this._store.select(selectSuppliersByEmailFilter),
        this._store.select(AuthenticationSelectors.selectUserNumber),
      ),
      switchMap(([action, filters, userNumber]) => {
        return this._dataExchangeService.getSuppliersSubscriptions(
          action.suppliers.map(supplier => supplier.esn),
          userNumber,
          filters
        ).pipe(
          map(subscriptions => {
            return DataExchangeActions.loadSupplierSubscriptionsSuccess({
              suppliers: action.suppliers,
              subscriptions: subscriptions.data,
              availableCount: action.availableCount
            });
          })
        );
      })
    )
  );

  loadSuppliersSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadSuppliersSuccess),
      switchMap(action => {
        return this._supplierService.getSupplierList(getSupplierListFilter()).pipe(
          map(payload => {
            return DataExchangeActions.loadSupplierDetailsSuccess({
              userSuppliers: action.suppliers,
              nonConnectBiSuppliers: payload.data
            });
          }),
          catchError(error => of(
            DataExchangeActions.loadSupplierDetailsFailure({ message: error.message })
          ))
        );
      })
    )
  );

  createSubscription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.createSubscription),
      switchMap(action => {
        return this._dataExchangeService.createSubscription(action.subscription).pipe(
          map(subscription => DataExchangeActions.createSubscriptionSuccess({ subscription })),
          catchError(error => of(
            DataExchangeActions.createSubscriptionFailure({ message: error.error.errorMessages[0] })
          ))
        );
      })
    )
  );

  loadRetailerAreaNames$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadRetailerAreaNames),
      switchMap(action => {
        return this._dataExchangeService.getRetailerAreaNames().pipe(
          map(retailerAreaNames => DataExchangeActions.loadRetailerAreaNamesSuccess({ retailerAreaNames })),
          catchError(error => of(
            DataExchangeActions.loadRetailerAreaNamesFailure({ message: error.message })
          ))
        );
      })
    )
  );

  // note: loadBrandsForSupplier is not dispatched anywhere so not changing the service here as part of the DPI integration work
  // another note: since action is never dispatched and this effect references a service in vipc that is being removed, commenting out for now
  // loadBrandsForSupplier$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(DataExchangeActions.loadBrandsForSupplier),
  //     switchMap(action => {
  //       return this._vendorService.getBrandListByEsnApi(action.esn).pipe(
  //         map(brands => {
  //           return DataExchangeActions.loadBrandsForSupplierSuccess({
  //             brands: brands.map(brand => ({
  //               name: brand.Name,
  //               code: brand.Code
  //             }))
  //           });
  //         }),
  //         catchError(error => of(
  //           DataExchangeActions.loadBrandsForSupplierFailure({ message: error.message })
  //         ))
  //       );
  //     })
  //   )
  // );

  loadBrands$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadBrands),
      switchMap(action => {
        return this._dataExchangeService.getBrandsBySupplier(action.esn).pipe(
          map(brands => {
            return DataExchangeActions.loadBrandsForSupplierSuccess({
              brands: brands.map(brand => ({
                name: brand.name,
                code: brand.code
              }))
            });
          }),
          catchError(error => of(
            DataExchangeActions.loadBrandsForSupplierFailure({ message: error.message })
          ))
        );
      })
    )
  );

  loadProducts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadProducts),
      switchMap(action => {
        return this._dataExchangeService.getBrandsBySupplier(action.supplier.esn).pipe(
          mergeMap(brandResponse => {
            const brands = brandResponse.map(r => ({
              name: r.name,
              code: r.code
            }));
            return this._dataExchangeService.getProductsBySupplier(action.supplier.esn).pipe(
              map(productsResponse => {
                return DataExchangeActions.loadProductsSuccess({
                  brands,
                  products: productsResponse
                });
              })
            );
          }),
          catchError(error => of(
            DataExchangeActions.loadProductsFailure({ message: error.message })
          ))
        );
      })
    )
  );

  deleteSelectedSubscriptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.deleteSelectedSubscriptions),
      withLatestFrom(this._store.pipe(select(selectSelectedSubscriptions))),
      switchMap(([action, subscriptions]) =>
        of(DataExchangeActions.deleteSubscriptions({
          ids: subscriptions.map(s => s.id)
        }))
      )
    )
  );

  deleteSubscriptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.deleteSubscriptions),
      switchMap(action => {
        return this._dataExchangeService.deleteSubscriptions(action.ids).pipe(
          map(deletedSubscriptions => DataExchangeActions.deleteSubscriptionsSuccess({ deletedSubscriptions })),
          catchError(error => of(
            DataExchangeActions.deleteSubscriptionsFailure({ message: error.message })
          ))
        );
      })
    )
  );

  handleBulkRegisterAction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.bulkRegisterClicked),
      withLatestFrom(this._store.select(selectSelectedSupplierSubscriptions)),
      switchMap(([, selectedEsnSubscriptions]) => {
        const status = selectedEsnSubscriptions[0].status;
        const ensEndDate = selectedEsnSubscriptions[0].endDate;
        const todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        if (status === EsnSubscriptionStatus.Unregistered || ensEndDate < todaysDate) {
          return of(DataExchangeActions.bulkAddItemsToCart());
        } else if (status === EsnSubscriptionStatus.Canceled) {
          return of(DataExchangeActions.bulkReactivateSubscriptions());
        }
      })
    )
  );

  handleBulkThirdPartyRegister$ = createEffect(() =>
  this._actions$.pipe(
    ofType(DataExchangeActions.bulkThirdPartyRegisterClicked),
    withLatestFrom(this._store.pipe(select(selectThirdPartyBulkRequest))),
    switchMap(([, thirdPartyRequest]) => {
      return this._shoppingCartService.thirdPartyTransaction(thirdPartyRequest).pipe(
        map((txResponse) => DataExchangeActions.thirdPartyTransactionSuccess({ transactionResponse: { success: true } })),
        catchError(response => of(DataExchangeActions.thirdPartyTransactionError({ transactionResponse: { success: false } })))
      );
    })
  )
);

  addItemsToCart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        DataExchangeActions.addItemToCart,
        DataExchangeActions.bulkAddItemsToCart,
        DataExchangeActions.frequencyModalAddClicked,
      ),
      withLatestFrom(
        this._store.select(getEsnSubscriptionsForCart),
        this._store.select(getBillingFrequency),
        this._store.select(getNumberOfItemsInCart),
      ),
      switchMap(([, cartItems, frequency, numberOfItemsInCart]) => {
        if (numberOfItemsInCart + cartItems.length > 50) {
          return of(DataExchangeActions.maximumCartLimitExceeded())
        }
        if (!frequency) {
          return of(DataExchangeActions.showFrequencyModal());
        }
        return this._shoppingCartService.addItemsToCart(cartItems).pipe(
          map((itemsInCart) => DataExchangeActions.addItemToCartSuccess({
            items: itemsInCart,
            toastHeader: cartItems.length === 1 ?
              `${cartItems[0].supplierName}` :
              `${cartItems.length} Suppliers Added`
          })),
        );
      })
    )
  );

  displayToast$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.addItemToCartSuccess),
      tap((action) => {
        const toastNotification = this._PhoenixToastService.showToastNotification({
          content: AddToCartToastComponent,
          cssClass: 'kehe-toast-notification',
          hideAfter: 3000,
          animation: { type: 'fade', duration: 500 },
        });
        toastNotification.content.instance.toastHeader = action.toastHeader;
      })
    ),
    { dispatch: false },
  );

  displayCartSizeExceededToast$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.maximumCartLimitExceeded),
      tap(() => this._PhoenixToastService.showErrorToast('Unable to add to cart due to 25 ESN limit per transaction')),
    ),
    { dispatch: false },
  );

  removeItemFromCart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.removeItemFromCart),
      switchMap((action) => {
        return this._shoppingCartService.removeItemFromCart(action.esn).pipe(
          map((itemsInCart) => DataExchangeActions.removeItemFromCartSuccess({ items: itemsInCart })),
        );
      })
    )
  );

  submitOrderResponseReceived$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.submitOrderResponseReceived),
      withLatestFrom(this._store.pipe(select(selectPaymentTokenResponse))),
      switchMap(([, paymentTokenResponse]) => {
        if (paymentTokenResponse.succeeded) {
          return of(DataExchangeActions.submitOrderResponseSuccess());
        } else {
          return of(DataExchangeActions.submitOrderResponseError());
        }
      })
    )
  );

  submitOrderResponseSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.submitOrderResponseSuccess),
      withLatestFrom(this._store.pipe(select(selectPaymentTransactionRequest))),
      switchMap(([, paymentTxRequest]) => {
        return this._shoppingCartService.processTransaction(paymentTxRequest).pipe(
          map((txResponse) => DataExchangeActions.processTransactionResponseSuccess({ transactionResponse: { success: true } })),
          catchError(() => of(DataExchangeActions.processTransactionResponseError({ transactionResponse: { success: false } })))
        );
      })
    )
  );

  registerThirdPartyUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.registerThirdPartyUser),
      withLatestFrom(this._store.pipe(select(selectThirdPartyRequest))),
      switchMap(([, thirdPartyRequest]) => {
        return this._shoppingCartService.thirdPartyTransaction(thirdPartyRequest).pipe(
          map((txResponse) => DataExchangeActions.thirdPartyTransactionSuccess({ transactionResponse: { success: true } })),
          catchError(response => of(DataExchangeActions.thirdPartyTransactionError({ transactionResponse: { success: false } })))
        );
      })
    )
  );

  processTransactionResponseSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.processTransactionResponseSuccess),
      switchMap(() => {
        return this._shoppingCartService.clearCart().pipe(
          map(() => DataExchangeActions.clearCartAfterSuccessfulTransaction()),
        );
      })
    )
  );

  getItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.getItems),
      withLatestFrom(this._store.select(selectSkipForCart), this._store.select(selectTakeForCart)),
      switchMap(([action, skip, take]) => this._shoppingCartService.getItems(skip, take).pipe(
        map((result) => DataExchangeActions.getItemsSuccess({ allItems: result.allItemsInCart, itemsToDisplay: result.itemsToDisplay })),
        catchError((error) => [DataExchangeActions.getItemsError({ error })])
      ))
    )
  );

  refreshCart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        DataExchangeActions.cartPageChange,
        DataExchangeActions.addItemToCartSuccess,
      ),
      map(() => DataExchangeActions.getItems())
    )
  );

  refreshCartAfterRemovingItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.removeItemFromCartSuccess),
      withLatestFrom(
        this._store.select(selectSkipForCart),
        this._store.select(selectTakeForCart),
        this._store.select(getNumberOfItemsInCart)
      ),
      switchMap(([, skip, take, total]) => {
        if (skip === total - 1) { return of(DataExchangeActions.cartPageChange({ skip: skip - take, take: take })); }
        return of(DataExchangeActions.getItems());
      })
    )
  );

  loadAvailableRecipientEmails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadAvailableRecipientEmails),
      withLatestFrom(this._store.pipe(select(selectEsnSubscriptionToTakeActionOn))),
      switchMap(([, selectedEsnSubscription]) => {
        return this._dataExchangeService.getListOfEmailsForSupplier(selectedEsnSubscription.esn).pipe(
          map(emails => DataExchangeActions.loadAvailableRecipientEmailsSuccess({ emails })),
          catchError(error => of(
            DataExchangeActions.loadAvailableRecipientEmailsError()
          ))
        );
      })
    )
  );

  setFormValueForBulkManageRecipients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.bulkManageRecipientsClicked),
      withLatestFrom(this._store.select(selectSelectedSupplierSubscriptions)),
      filter(([, subscriptions]) => subscriptions && subscriptions.length === 1),
      switchMap(([, subscriptions]) => of(DataExchangeActions.manageRecipientsSetFormForBulkIfOneSelected({
        esnSubscription: subscriptions[0]
      })))
    )
  );

  loadAvailableRecipientEmailsForBulk$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.loadAvailableRecipientEmailsForBulk),
      withLatestFrom(this._store.pipe(select(selectSelectedSupplierSubscriptions))),
      switchMap(([, selectedEsnSubscriptions]) => {
        const callsToMake$: Observable<string[]>[] = [];
        selectedEsnSubscriptions.forEach(subscription => {
          callsToMake$.push(this._dataExchangeService.getListOfEmailsForSupplier(subscription.esn));
        });
        return forkJoin(callsToMake$).pipe(
          map(result => {
            const uniqueEmails = result.reduce((previousValue, currentValue) => {
              return previousValue.filter(email => currentValue.includes(email));
            });
            return DataExchangeActions.loadAvailableRecipientEmailsSuccess({ emails: uniqueEmails });
          }),
          catchError(error => of(
            DataExchangeActions.loadAvailableRecipientEmailsError()
          ))
        );
      })
    )
  );

  saveManageRecipientsForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.manageRecipientsUpdateClick),
      withLatestFrom(
        this._store.select(selectEsnSubscriptionToTakeActionOn),
        this._store.select(selectManageRecipientsUpdateRequest),
      ),
      switchMap(([action, esnSubscription, request]) => {
        return this._dataExchangeService.updateSubscriptionRecipients(esnSubscription.customerProfileId, request).pipe(
          map(() => DataExchangeActions.manageRecipientsUpdateSuccess()),
          catchError(response => of(
            DataExchangeActions.manageRecipientsUpdateError({ errorMessage: response.error.errorMessages[0] })
          ))
        );
      })
    )
  );

  saveManageRecipientsBulkForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.manageRecipientsUpdateForBulkClick),
      withLatestFrom(this._store.select(selectManageRecipientsUpdateInBulkRequest)),
      switchMap(([action, requests]) => {
        const callsToMake$: Observable<string[]>[] = [];
        requests.forEach(request => {
          callsToMake$.push(this._dataExchangeService.updateSubscriptionRecipients(request.customerProfileId, request));
        });
        return forkJoin(callsToMake$).pipe(
          map(() => DataExchangeActions.manageRecipientsUpdateSuccess()),
          catchError(response => of(
            DataExchangeActions.manageRecipientsUpdateError({ errorMessage: response.error.errorMessages[0] })
          ))
        );
      })
    )
  );

  cancelEsnSubscription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.cancelEsnSubscriptionModalConfirmClick),
      withLatestFrom(this._store.select(selectCancelEsnSubscriptionsRequest)),
      switchMap(([, cancelRequest]) => {
        return this._dataExchangeService.cancelEsnSubscriptions(cancelRequest).pipe(
          map(() => DataExchangeActions.cancelEsnSubscriptionConfirmSuccess()),
          catchError(response => of(DataExchangeActions.cancelEsnSubscriptionConfirmError({ errorMessage: response.error.errorMessages[0] })))
        );
      })
    )
  );

  cancelEsnSubscriptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataExchangeActions.cancelEsnSubscriptionsModalConfirmClick),
      withLatestFrom(this._store.select(selectCancelEsnSubscriptionsRequest)),
      switchMap(([, cancelRequest]) => {
        return this._dataExchangeService.cancelEsnSubscriptions(cancelRequest).pipe(
          map(() => DataExchangeActions.cancelEsnSubscriptionConfirmSuccess()),
          catchError(response => of(
            DataExchangeActions.cancelEsnSubscriptionConfirmError({ errorMessage: response.error.errorMessages[0] })
          ))
        );
      })
    )
  );

  upsertEsnSubscriptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        DataExchangeActions.registerPreviouslyCanceledEsn,
        DataExchangeActions.bulkReactivateSubscriptions,
      ),
      withLatestFrom(this._store.select(selectUpsertEsnSubscriptionsRequest)),
      exhaustMap(([, upsertRequest]) => {
        return this._dataExchangeService.upsertEsnSubscriptions(upsertRequest).pipe(
          map(() => DataExchangeActions.upsertEsnSubscriptionSuccess()),
          catchError(response => of(DataExchangeActions.upsertEsnSubscriptionError({ errorMessage: response.message })))
        );
      })
    )
  );
}

function getSupplierListFilter(): SupplierListFilter {
  const listFilter = new SupplierListFilter();
  listFilter.pageCount = 15000;
  listFilter.marketingAllowanceCode = MarketingAllowanceCode.NonConnectBi;
  return listFilter;
}
