import { Component } from '@angular/core';

@Component({
  selector: 'app-mock-suggestive-search-v2',
  templateUrl: './mock-suggestive-search-v2.component.html',
})
export class MockSuggestiveSearchV2Component {

  constructor() { }

  clearText(): void {}
}
