<div class="analytics-page-component">
  <div *ngIf="showDashboard" class="dashboard">
    <div class="d-flex justify-content-between">
      <h2>Order Projection Dashboard</h2>
    </div>
    <app-report [url]="dashboardUrl"
                [token]="(userToken$ | async)"
                [height]="dashboardHeight">
    </app-report>
  </div>
  <app-unauthorized-page *ngIf="!showDashboard"></app-unauthorized-page>
</div>
