import { ExternalVideo } from './../models/external-video';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PermissionsService } from '@app/services/permissions.service';
import { HelpCenterGroup } from '../modules/reporting/models/enum/help-center-group';
import { Constants } from '@app/constants/constants';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  supplierIsInShow: boolean;
  videos: ExternalVideo[] = [
    {
      videoTitle: 'CONNECT Supplier Portal Overview',
      vimeoId: '502642438/0f5d5d42a3',
      group: HelpCenterGroup.ConnectBi,
    },
    {
      videoTitle: `${Constants.KeHE_CONNECT_BI} Overview`,
      vimeoId: '502643424/c5f9235f63',
      group: HelpCenterGroup.ConnectBi,
    },
    {
      videoTitle: 'How to Understand Your Supplier Spend',
      vimeoId: '502641038/cb74f9a345',
      group: HelpCenterGroup.ConnectBi,
    },
    {
      videoTitle: `How to Use ${Constants.KeHE_CONNECT_BI} to Drive Profitable Growth`,
      vimeoId: '510418951/1dffa7b004',
      group: HelpCenterGroup.ConnectBi,
    },
    {
        videoTitle: 'Overview Video',
        vimeoId: '543188252/a3cae26951',
        group: HelpCenterGroup.Show,
    },
    {
        videoTitle: 'How to Respond to Proposed Orders',
        vimeoId: '543188352/66e8b3812d',
        group: HelpCenterGroup.Show,
    },
    {
        videoTitle: 'How to Add Items to an Order',
        vimeoId: '460977819/e6375b3e58',
        group: HelpCenterGroup.Show,
    },
    {
        videoTitle: 'How to Add MCB & Extra Performance',
        vimeoId: '543188411/9a77a34bdb',
        group: HelpCenterGroup.Show,
    },
    {
        videoTitle: 'How to Update Notification Settings',
        vimeoId: '460977509/a60a084505',
        group: HelpCenterGroup.Show,
    },
  ].map(info => ({
    ...info,
    background: 'https://i.vimeocdn.com/video/896046219_640.webp'
  }));

  constructor(
    private _store: Store,
    private _permissionsService: PermissionsService
  ) {
    this._store.select(ShowSelectors.selectIsSupplierInShow).subscribe(val => this.supplierIsInShow = val);
  }

  public getCurrentVideos(): ExternalVideo[] {
    return this.videos.filter(video =>
      this.showFilter(video) ||
      this.connectBiFilter(video)
    );
  }

  private showFilter(video: ExternalVideo): boolean {
    return this.supplierIsInShow && video.group === HelpCenterGroup.Show;
  }

  private connectBiFilter(video: ExternalVideo): boolean {
    // If a user has access to any part of Connect BI, show the Connect BI Videos
    return this._permissionsService.userHasConnectBiAccess() && video.group === HelpCenterGroup.ConnectBi;
  }
}
