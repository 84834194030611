import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { KeheModalEventsTypes } from '@kehe/phoenix-modal';
import { Store } from '@ngrx/store';
import { EsnSubscriptionBillingFrequency } from '../../models/enum/esn-subscription-billing-frequency';
import {
  frequencyModalAddClicked,
  frequencyModalCancelClicked,
  frequencyModalCloseClicked,
} from '../../store/data-exchange.actions';

@Component({
  selector: 'app-choose-frequency-modal',
  templateUrl: './choose-frequency-modal.component.html',
  styleUrls: ['./choose-frequency-modal.component.scss']
})
export class ChooseFrequencyModalComponent {

  form = new UntypedFormGroup({
    frequency: new UntypedFormControl(null, Validators.required)
  });

  constructor(private store: Store) { }

  handleModalEvent(event): void {
    switch (event.type) {
      case KeheModalEventsTypes.Close:
        this.store.dispatch(frequencyModalCloseClicked());
        break;
      default:
    }
  }

  onViewTermsAndConditionsClick(): void {
    window.open('https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/D4B-TermsAndConditions.html', '_blank','left=100,top=100,width=800,height=800,toolbar=1,resizable=0');
  }

  onCancelClick(): void {
    this.store.dispatch(frequencyModalCancelClicked());
  }

  onAddClick(frequency: EsnSubscriptionBillingFrequency): void {
    this.store.dispatch(frequencyModalAddClicked({ frequency }));
  }

}
