import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DistributionCenterResponse } from './distribution-center';

@Injectable()
export class DistributionCenterService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.dcApiUrl;
   }

  getList(): Observable<DistributionCenterResponse[]> {
    const url = `${this._url}/distributioncenterlist`;
    return this.httpClient.get<DistributionCenterResponse[]>(url);
  }
}
