import { APIService,
         Message,
         ChatRoom,
         OnCreateMessageByChatRoomIdSubscription,
         OnUpdateChatRoomByCustomerNumberSubscription,
         ModelSortDirection,
         ChatRoomByCustomerNumberQuery,
         ListChatRoomsQuery} from './API.service';
import { IChatRoom } from './../models/IChatRoom';
import { IMessage } from './../models/IMessage';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import listChatRoomsByCustomerNumberAndSupplierNumberQuery from './graphql/listChatRooms';
import createChatRoomMutation from './graphql/createChatRoom';
import listChatRoomByCustomerSupplierNumber from './graphql/listChatRoomByCustomerSupplierNumber';
import { GraphQLResult } from 'aws-amplify/api';

@Injectable()
export class MessagesService {
  private amplifyUrl: string;
  private amplifyApiKey: string;


  constructor(
    private http: HttpClient,
    private api: APIService
  ) {
    this.amplifyUrl = environment.amplifyUrl;
    this.amplifyApiKey = environment.amplifyApiKey;
  }

  listChatRooms(customerNumber: string, supplierNumber: string): Observable<any> {

    const query = listChatRoomsByCustomerNumberAndSupplierNumberQuery(customerNumber, supplierNumber);
    const body = {
      query,
      operationName: 'ListChatRooms',
      variables: null
    };

    // Update w/ Amplify
    return this.http.post<any>(this.amplifyUrl, body, {headers: {
      'x-api-key': this.amplifyApiKey
    }});
  }


  listChatRoomByCustomerSupplierNumberQuery(customerNumber: string, supplierNumber: string): Observable<any> {

    const query = listChatRoomByCustomerSupplierNumber(customerNumber, supplierNumber);
    const body = {
      query,
      operationName: 'MyQuery',
      variables: null
    };

    // Update w/ Amplify
    return this.http.post<any>(this.amplifyUrl, body, {headers: {
      'x-api-key': this.amplifyApiKey
    }});
  }

  getChatRoomByCustomerSupplierNumber(customerNumber: string, supplierNumber: string): Observable<any> {
    // const query = getChatRoomByCustomerSupplierNumberQuery(customerNumber, supplierNumber);
    // const body = {
    //   query,
    //   operationName: 'getChatRoomByCustomerSupplierNumber',
    //   variables: null
    // };

    // return this.http.post<any>(this.amplifyUrl, body, {headers: {
    //   'x-api-key': this.amplifyApiKey
    // }});

    const customerSupplierNumber = `${customerNumber}|${supplierNumber}`;
    return from(this.api.ChatRoomByCustomerSupplierNumber(customerSupplierNumber));
  }

  messagesByChatRoom(chatRoomId: string, limit: number, nextToken: string = null): Observable<any> {
    return from(this.api.MessagesByChatRoom(chatRoomId, null, ModelSortDirection.DESC, null, limit, nextToken));
  }

  sendMessage(message: IMessage): Observable<Message> {

    // const query = createMessageMutation(message);
    // const body = {
    //   query,
    //   operationName: 'createMessageMutation',
    //   variables: null
    // };

    // return this.http.post<any>(this.amplifyUrl, body, {headers: {
    //   'x-api-key': this.amplifyApiKey
    // }});
    return from(this.api.CreateMessage({
      chatRoomID: message.chatRoomID,
      clientType: message.clientType,
      content: message.content,
      senderID: message.senderID,
      senderName: message.senderName,
      userEmail: message.userEmail,
      userName: message.userName,
      localID: message.localID
    }));
  }

  createChatRoom(chatRoom: IChatRoom): Observable<any> {

    const query = createChatRoomMutation(chatRoom);
    const body = {
      query,
      operationName: 'createChatRoomMutation',
      variables: null
    };

    return this.http.post<any>(this.amplifyUrl, body, {headers: {
      'x-api-key': this.amplifyApiKey
    }});
  }

  updateChatRoom(messageId: string, chatRoomID: string, customerNumber: string): Observable<ChatRoom> {

    // const query = updateChatRoomMutation(messageId, chatRoomID);
    // const body = {
    //   query,
    //   operationName: 'updateChatRoomMutation',
    //   variables: null
    // };

    // return this.http.post<any>(this.amplifyUrl, body, {headers: {
    //   'x-api-key': this.amplifyApiKey
    // }});

    return from(this.api.UpdateChatRoom({
      id: chatRoomID,
      lastMessageID: messageId,
      clientNumberUnread: customerNumber
    }));
  }


  getChatRoomByCustomerNumber(customerNumber: string):
  Observable<ChatRoomByCustomerNumberQuery> {
    return from(this.api.ChatRoomByCustomerNumber(customerNumber, null, ModelSortDirection.DESC, null, 10));
  }

  getChatRoomBySuppliers(supplierNumber: string):
  Observable<ListChatRoomsQuery> {
    return from(this.api.ChatRoomBySupplierNumber(supplierNumber, null, ModelSortDirection.DESC));
  }

  subscribeCreateMessageByChatRoomId(chatRoomID: string):
    Observable<GraphQLResult<OnCreateMessageByChatRoomIdSubscription>> {
    return from(this.api.OnCreateMessageByChatRoomIdListener(chatRoomID));
  }

  subscribeChatRoomByCustomer(customer: string):
    Observable<GraphQLResult<OnUpdateChatRoomByCustomerNumberSubscription>> {
    return from(this.api.OnUpdateChatRoomByCustomerNumberListener(customer));
  }

  subscribeChatRoomBySupplier(supplier: string):
    Observable<GraphQLResult<OnUpdateChatRoomByCustomerNumberSubscription>> {
    return from(this.api.OnUpdateChatRoomBySupplierNumberListener(supplier));
  }

  getUnreadsBySupplierNumber(supplierNumber: string):
  Observable<ChatRoomByCustomerNumberQuery> {
    return from(this.api.ChatRoomByClientNumberUnread(supplierNumber));
  }

  updateChatRoomWithMessageRead(chatRoomID: string): Observable<ChatRoom> {

    return from(this.api.UpdateChatRoom({
      id: chatRoomID,
      clientNumberUnread: null
    }));
  }
}
