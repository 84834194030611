<div class="profile-page-container">
  <kehe-phoenix-navigation-panel
    [tabs]="navigationTabs"
    [activeTab]="currentTab"
    [open]="isPanelOpen"
    (tabSelected)="onTabSelected($event)"
    (toggled)="togglePanelOpen()"
  ></kehe-phoenix-navigation-panel>
  <div class="pl-5 pt-5">
    <h3>Show Order Notifications Settings</h3>
      <h4 class="sub-title">Email Notifications</h4>
      <span class="d-block">Email notifications are defaulted to OFF. You can select if you want to receive for all proposed, accepted, countered or declined deals.</span>
      <label>Select which notification type you would like to receive emails for:</label>
      <div *ngFor="let notificationType of emailNotificationTypeList" class="ml-3 mb-2">
        <input type="checkbox" kendoCheckBox #emailNotifications
          [checked]="notificationType.isChecked"
          (change)="onCheckboxChanged(!notificationType.isChecked, notificationType.value, emailMethod)"
        />
        <kendo-label class="k-checkbox-label" [for]="emailNotifications" [text]="notificationType.label"></kendo-label>
      </div>
      <h4 class="sub-title">In-App Notifications</h4>
      <span class="d-block">In-app notifications are defaulted to OFF. You can select if you want to receive for all proposed, accepted, countered or declined deals.</span>
      <label>Select which notification type you would like to receive in-app notifications for:</label>
      <div *ngFor="let notificationType of inAppNotificationTypeList" class="ml-3 mb-2">
        <input type="checkbox" kendoCheckBox #inAppNotifications
          [checked]="notificationType.isChecked"
          (change)="onCheckboxChanged(!notificationType.isChecked, notificationType.value, inAppMethod)"
        />
        <kendo-label class="k-checkbox-label" [for]="inAppNotifications" [text]="notificationType.label"></kendo-label>
      </div>
      <div class="mt-3">
        <strong>For further support, please call (866) 239-7348.</strong>
      </div>
  </div>
</div>
