import { Store } from '@ngrx/store';
import {
  NewPreference,
  UserNotificationPreferences
} from '../model/user-notifications';
import {
  clearUserNotificationsAction,
  getUserNotificationsAction,
  updateUserNotificationPreferencesAction,
  clearAllUserNotificationsAction,
  toggleClearNotificationsModalAction,
  getUserNotificationsCountAction
} from './user-notifications.actions';
import { Observable } from 'rxjs';
import {
  selectAllUserNotificationPreferences,
  selectUserNotificationCount,
  selectClearNotificationsModalOpen
} from './user-notifications.selectors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsFacade {
  constructor(
    private store: Store
  ) {}

  getAllLoadedUserNotificationPreferences(): Observable<UserNotificationPreferences> {
    return this.store.select(selectAllUserNotificationPreferences);
  }

  updateUserNotificationPreferences(
    newPreference: NewPreference
  ): void {
    this.store.dispatch(updateUserNotificationPreferencesAction({ newPreference }));
  }

  loadUserNotifications(): void {
    this.store.dispatch(getUserNotificationsAction());
  }

  loadUserNotificationsCount(): void {
    this.store.dispatch(getUserNotificationsCountAction());
  }

  getUserNotificationCount(): Observable<number> {
    return this.store.select(selectUserNotificationCount);
  }

  isClearNotificationsModalOpen(): Observable<boolean> {
    return this.store.select(selectClearNotificationsModalOpen);
  }

  clearUserNotifications(notificationIds: number[]): void {
    this.store.dispatch(clearUserNotificationsAction({ notificationIds }));
  }

  clearAllUserNotifications(): void {
    this.store.dispatch(clearAllUserNotificationsAction());
  }

  toggleClearNotificationsModal(): void {
    this.store.dispatch(toggleClearNotificationsModalAction());
  }
}
