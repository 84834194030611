import { TitleCasePipe } from "@angular/common";
import { createSelector } from "@ngrx/store";
import { State } from "../index";
import * as fromDistributionCenter from "./distribution-center.reducer";

const selectDistributionCenterState = (state: State) => state.distributionCenter;
export const selectDistributionCenters = createSelector(
    selectDistributionCenterState,
    fromDistributionCenter.getDistributionCenters,
);

export const selectDistributionCenterList = createSelector(
    selectDistributionCenters,
    (distributionCenters) => {
      const titleCasePipe = new TitleCasePipe();
      return distributionCenters.map(dc => {
        return  {
          ...dc,
          name: titleCasePipe.transform(dc.name),
        }
      }).sort(function (a, b) {
        return +a.number - +b.number;
      });
    }
);

export const selectDistributionCenterListWithTrimmedName = createSelector(
  selectDistributionCenterList,
  (distributionCenters) => distributionCenters.map(dc => {
      return {
          ...dc,
          name: dc.name.substring(dc.name.lastIndexOf('-') + 1).trim(),
      }
  })
);

