import { createAction, props } from '@ngrx/store';
import { CreateOrderDto } from '../../models/create-order-modal.dto';
import { CreateOrderResponse } from '../../models/create-order-response';
import { OrdersListShowActions } from './enum.actions';
import { ShowParticipation } from '../../../../../../app/models/show-participation';

export const getShowReports = createAction(
  OrdersListShowActions.getShowReports
);

export const downloadShowReport = createAction(
  OrdersListShowActions.DownloadShowReport
);

export const downloadShowReportSuccess = createAction(
  OrdersListShowActions.DownloadShowReportSuccess,
  props<{ responseMessage?: string }>()
);

export const downloadShowReportError = createAction(
  OrdersListShowActions.DownloadShowReportError,
  props<{ error: any }>()
);

export const downloadShowReportHistoryError = createAction(
  OrdersListShowActions.DownloadHistoryShowReportError
  );

export const openCreateOrderModal = createAction(
  OrdersListShowActions.OpenCreateOrderModal
);

export const closeCreateOrderModal = createAction(
  OrdersListShowActions.CloseCreateOrderModal
);

export const submitCreateOrderModal = createAction(
  OrdersListShowActions.SubmitCreateOrderModal,
  props<{createOrderDto: CreateOrderDto}>()
);

export const submitCreateOrderModalSuccess = createAction(
  OrdersListShowActions.SubmitCreateOrderModalSuccess,
  props<{createOrderResponse: CreateOrderResponse}>()
);

export const submitCreateOrderModalError = createAction(
  OrdersListShowActions.SubmitCreateOrderModalError
);

export const setShowDownloadReportModal = createAction(
  OrdersListShowActions.SetShowDownloadReportModal,
  props<{ showReportModal: boolean }>()
);

export const downloadShowReportHistory = createAction(
  OrdersListShowActions.DownloadShowReportHistory,
  props<{ showId: string }>()
);

export const downloadShowReportHistorySuccess = createAction(
  OrdersListShowActions.DownloadShowReportHistorySuccess
);

export const getShowsHistory = createAction(
  OrdersListShowActions.getShowsHistory
);

export const getShowsHistorySuccess = createAction(
  OrdersListShowActions.getShowsHistorySuccess,
  props<{ payload: ShowParticipation[]}>()
);

export const getShowsHistoryError = createAction(
  OrdersListShowActions.getShowsHistoryError
);

export const orderVersionsLoadedSuccess = createAction(
  '[Orders List Show] Order Versions Loaded Success',
  props<{ orders: { masterVersionNumber: number, orderId: number }[] }>()
);
