import { IMessagesToRender } from './../models/IMessagesToRender';
import { IMessage } from './../models/IMessage';
import isSameDay from './isSameDay';

const groupMessagesByDay = (messages: Array<IMessage>): Array<IMessagesToRender> => {
  const messagesToRender: Array<IMessagesToRender> = [];

  let dateToCompare = '';
  messages.forEach((message: IMessage) => {
    if (dateToCompare) {
      if (isSameDay(new Date(message.createdAt), new Date(dateToCompare))) {
        messagesToRender[messagesToRender.length - 1].messages.push(message);
      } else {
        dateToCompare = message.createdAt;
        messagesToRender.push({
          date: message.dayToRender,
          messages: [message]
        });
      }
    } else {
      dateToCompare = message.createdAt;
      messagesToRender.push({
        date: message.dayToRender,
        messages: [message]
      });
    }
  });

  return messagesToRender;

};

export default groupMessagesByDay;
