import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { BiReportService } from './services/bi-report.service';
import { ReportConfig } from './services/report.config';
import * as pbi from 'powerbi-client';
import { IEmbedConfiguration } from 'embed';

@Component({
  selector: 'app-report',
  templateUrl: './bi-report.component.html'
})
export class BiReportComponent implements OnInit {

  @ViewChild('reportContainer', { static: true }) container: ElementRef;

  @Input() id: string;

  @Input() type: string;

  @Input() url: string;

  @Input() token: string;

  @Input() height: number;

  constructor(public reportService: BiReportService, public reportConfig: ReportConfig) { }

  ngOnInit() {
    this.reportConfig.buildConfig(this.id, this.url, this.type, this.token, this.height);
    this.generateBiReport();
  }


  generateBiReport() {
    this.reportService.getReportFromServer().subscribe(config => {
      if (config) {
        this.embedReport(this.reportConfig.type, config.Id, config.EmbedUrl,
          config.EmbedToken.token, this.container.nativeElement as HTMLElement);
      }
    },
      error => {
        // this.sendError('Token Expired - Authetication Failed');
      });
  }

  embedReport(type: string, reportId: string, embedUrl: string, accessToken: string, container: HTMLElement): void {
    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    // Read embed application token from textbox
    // Get models. models contains enums that can be used.
    // const models = window['powerbi-client'].models;
    const models = pbi.models;
    // We give All permissions to demonstrate switching between View and Edit mode and saving report.
    const permissions = models.Permissions.All;

    // Embed configuration used to describe the what and how to embed.
    // This object is used when calling powerbi.embed.
    // This also includes settings and options such as filters.
    // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
    const config: IEmbedConfiguration = {
      type: type,
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: reportId,
      permissions: permissions,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true
      },
      uniqueId: 'powerbi-report',
    };

    // Embed the report and display it within the div container.
    const report = powerbi.embed(container, config);

    // Report.off removes a given event handler if it exists.
    report.off('loaded');

    // Report.on will add an event handler which prints to Log window.
    report.on('loaded', function () {
      console.log('Loaded');
    });

    // Report.off removes a given event handler if it exists.
    report.off('rendered');

    // Report.on will add an event handler which prints to Log window.
    report.on('rendered', function () {
      console.log('Rendered');
    });

    report.on('error', function (event) {
      console.log(event.detail);
      report.off('error');
    });

    report.off('saved');
    report.on('saved', function (event) {
      console.log(event.detail);
      /*         if (event.detail.saveAs) {
                console.log('In order to interact with the new report, create a new token and load the new report');
              } */
    });

  }
}
