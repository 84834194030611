<div class="messages-page">
  <div class="chat-message-position">
    <div *ngFor="let enabledChat of enabledChats; let i= index" [id]="'drawer-' + enabledChat.id">
      <app-chat-drawer
        [chatRoom]="enabledChat"
        [messagesToRender]="enabledChatsMessages[i]"
        [source]="source"
        (closeChat)="closeChat($event)"
        (sendMessage)="sendMessage($event)"
        (saveDraftMessage)="saveDraftMessage($event)"
        (toggleCollapse)="toggleCollapse($event)"
        (getMoreMessages)="getMoreMessages($event)">
      </app-chat-drawer>
    </div>
    <app-chat-drawer-new
      *ngIf="(selectNewChatOpen$ | async)"
      (closeChat)="closeNewChat($event)"
      [suppliers]="selectSuppliers$ | async"
      [customers]="selectCustomers$ | async"
      (openChat)="openChat($event)"
      >
    </app-chat-drawer-new>
  </div>
</div>
