import { createAction, props } from '@ngrx/store';
import { OrdersListShowActions } from './enum.actions';
import { OrderVersionsAndName } from '../../models/ecm-order-version';


export const syncOrdersListShow = createAction(
  OrdersListShowActions.SyncOrdersListShow,
  props<{ outOfSyncOrders: OrderVersionsAndName }>()
);

export const setOrdersListOutOfSync = createAction(
  OrdersListShowActions.SetOrdersListOutOfSync,
  props<{ outOfSyncOrders: string[] }>()
);

export const syncOrdersListShowError = createAction(
  OrdersListShowActions.SyncOrdersListShowError,
);

export const syncOrdersListShowSuccess = createAction(
  OrdersListShowActions.SyncOrdersListShowSuccess,
  props<{ recentlyCreatedOrders: string[] }>()
);

export const removeOrdersRecentlyCreatedGhost = createAction(
  '[Orders List Show] Remove Orders Recently Created Ghost',
  props<{ ordersId: string[] }>()
);
