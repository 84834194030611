import { switchMap, map, catchError } from 'rxjs/operators';
import { ReportingService } from './../services/reporting.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ReportingActions from './reporting.actions';
import { of } from 'rxjs';

@Injectable()
export class ReportingEffects {
  constructor(
    private _actions$: Actions,
    private _reportingService: ReportingService
  ) {}

  loadPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ReportingActions.loadPage),
      map(() => ReportingActions.loadUserGuid())
    )
  );

  loadUserGuid$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ReportingActions.loadUserGuid),
      switchMap((action) => {
        return this._reportingService.getUserGuid().pipe(
          map((userGuid: string) => ReportingActions.loadUserGuidSuccess({userGuid})),
          catchError((error) => of(
            ReportingActions.loadUserGuidFailure({ message: error.message })
          ))
        );
      })
    )
  );
}
