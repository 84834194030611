import { takeUntil } from 'rxjs/operators';
import { KeheModalEventsTypes } from '@kehe/phoenix-modal';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DataSubscription } from '../../models/data-subscription';
import {
  selectDeleteModalItem,
  selectDeleteSubscriptionsLoading,
  selectSelectedSubscriptionsLength,
  selectShowDeleteSubscriptionModal,
} from '../../store/data-exchange.selectors';
import { deleteModalDismissed, deleteSelectedSubscriptions, deleteSubscriptions } from '../../store/data-exchange.actions';
import { DestroyableDirective } from '@app/abstract/destroyable';

@Component({
  selector: 'app-delete-subscription-modal',
  templateUrl: './delete-subscription-modal.component.html',
  styleUrls: ['./delete-subscription-modal.component.scss']
})
export class DeleteSubscriptionModalComponent extends DestroyableDirective  implements OnInit {

  showModal$: Observable<boolean>;
  deleteSubscriptionLoading$: Observable<boolean>;
  subscription: DataSubscription;
  selectedItemCount: number;

  constructor(
    private _store: Store
  ) { super(); }

  ngOnInit() {
    this.showModal$ = this._store.pipe(select(selectShowDeleteSubscriptionModal));
    this.deleteSubscriptionLoading$ = this._store.pipe(select(selectDeleteSubscriptionsLoading));
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedSubscriptionsLength)
    ).subscribe(cnt => {
      this.selectedItemCount = cnt;
    });
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectDeleteModalItem)
    ).subscribe(subscription => {
      this.subscription = subscription;
    });
  }

  onDeleteClicked() {
    if (!!this.subscription) {
      this._store.dispatch(deleteSubscriptions({
        ids: [this.subscription.id]
      }));
    } else {
      this._store.dispatch(deleteSelectedSubscriptions());
    }
  }

  cancelClicked() {
    this._store.dispatch(deleteModalDismissed());
  }

  getPluralCharacter(): string {
    if (!this.subscription && !!this.selectedItemCount && this.selectedItemCount > 1) {
      return 's';
    } else {
      return '';
    }
  }

  handleModalEvent(event) {
    switch (event.type) {
      case KeheModalEventsTypes.Close:
        this._store.dispatch(deleteModalDismissed());
        break;
      default:
    }
  }
}
