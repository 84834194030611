import { gapVoidFaqs } from './gap-void-faqs';
import { Faq } from './../../models/faq';
import { nonAasEndpoint } from './../analytics-page/analytics-page.component';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { DestroyableDirective } from '../../abstract/destroyable';
import { Observable } from 'rxjs';
import { ResourceActions } from '../../constants/resource-actions';
import { PermissionsService } from '@app/services/permissions.service';
import { ExternalVideo } from '../../models/external-video';
import { HelpCenterGroup } from '../../modules/reporting/models/enum/help-center-group';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

export const gapVoidDashboardRoute = 'gap-void';
@Component({
  selector: 'app-gap-void-page',
  templateUrl: './gap-void-page.component.html',
  styleUrls: ['./gap-void-page.component.scss']
})
export class GapVoidPageComponent extends DestroyableDirective  implements OnInit {

  public userToken$: Observable<string>;
  public dashboardHeight: any;
  public showDashboard: boolean;
  public dashboardUrl: string;
  showHelpModal = false;

  headerHeight = 50;
  navbarHeight = 40;
  titleHeight = 30;
  marginsHeight = 40;

  helpVideos: ExternalVideo[] = [
    {
      videoTitle: 'How to Identify Item Gaps',
      vimeoId: '502639412/2bfb11cb16',
      group: HelpCenterGroup.ConnectBi,
    },
    {
      videoTitle: 'How to Identify Item Voids',
      vimeoId: '502640308/f0de15d5e8',
      group: HelpCenterGroup.ConnectBi,
    },
  ];

  faqs: Faq[] = gapVoidFaqs;

  constructor(
    public _store: Store,
    private _permSvc: PermissionsService,
  ) {
    super();
  }

  ngOnInit() {
    this.dashboardUrl = `${environment.powerbiReportApi}/${nonAasEndpoint}?reportId=${environment.gapVoidDashboardId}`;
    this.dashboardHeight = window.innerHeight - this.headerHeight - this.navbarHeight - this.titleHeight - this.marginsHeight;
    this.userToken$ = this._store.select(AuthenticationSelectors.selectToken);
    this.showDashboard = this._permSvc.userHasAction(ResourceActions.SupplierBiDataRead);
  }

}
