import { Component } from '@angular/core';

import { DestroyableDirective } from '../../abstract/destroyable';
import { Router} from '@angular/router';
import { KeheErrorConfig, KeheErrorMessages, KeheErrorSizes } from '@kehe/phoenix-errors';

@Component({
  selector: 'app-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss']
})
export class UnauthorizedPageComponent extends DestroyableDirective {

  permissionsConfig = new KeheErrorConfig(
    KeheErrorSizes.LG,
    null,
    'fas fa-lock',
    KeheErrorMessages.NoPermission,
    null
  );

  constructor(
    public router: Router,
  ) {
    super();
  }

  goToHome() {
    this.router.navigate(['/']);
  }

}
