<div class="summary-box">
  <div class="row">
    <div class="col-sm-12">
      <h4>{{company$ | async}}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-3">
          <span>LINES IN ORDER</span>
        </div>
        <div class="col-sm-9">
          <span>{{numberOfItems$ | async}}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-6">
          <span>SUBTOTAL</span>
        </div>
        <div class="col-sm-6">
          <span class="h6 font-weight-bold">{{cartTotal | currency}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <span>TOTAL</span>
        </div>
        <div class="col-sm-6">
          <span class="h6 font-weight-bold">{{cartTotal | currency}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
