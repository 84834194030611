import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExternalVideo } from '../../models/external-video';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardItemComponent implements OnInit {

  @Input() video: ExternalVideo;
  @Output() playAction: EventEmitter<ExternalVideo> = new EventEmitter();

  public bgStyle = 'none';

  ngOnInit() {
    if (this.video && this.video.background) {
      this.bgStyle = 'linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),  url(' + this.video.background + ')';
    }
  }

  playClicked() {
    this.playAction.emit(this.video);
  }
}
