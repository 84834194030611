import { Report } from './../models/report';
import { createAction, props } from '@ngrx/store';

export const loadPage = createAction(
  '[Reporting] Load Page'
);

export const loadUserGuid = createAction(
  '[Reporting] Load User GUID'
);

export const loadUserGuidSuccess = createAction(
  '[Reporting] Load User GUID Success',
  props<{userGuid: string}>()
);

export const loadUserGuidFailure = createAction(
  '[Reporting] Load User GUID Failure',
  props<{message: string}>()
);

export const selectDefault = createAction(
  '[Reporting] Select Default'
);

export const selectReport = createAction(
  '[Reporting] Select Report',
  props<{selectedReport: Report}>()
);

export const alertClosed = createAction(
  '[Reporting] Alert Closed'
);
