import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiPowerbiEmbedService } from './api-powerbi-embed.service';
import { ReportConfig } from './report.config';

@Injectable()
export class BiReportService {

  public reportInfo: any;

  constructor(private apiPowerBiService: ApiPowerbiEmbedService, private reportConfig: ReportConfig) { }

  getReportFromServer(): Observable<any> {
    const observableData = new Observable((observer) => {
        this.apiPowerBiService.getPowerBiReportEmbed().subscribe((value) => {
          // Handle success
          observer.next(value.data);
          observer.complete();
        }, (error) => {
          observer.next(null);
          observer.complete();
        });
    });
    return observableData;
  }

}
