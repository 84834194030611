import { SafeHtml } from '@angular/platform-browser';
import { AlertMessage } from '../../../models/alert-message';

export enum UserAgreementRequestType {
  Read,
  Write,
  Asset
}

export enum UserAgreementStatus {
  Unknown,
  UserHasAgreed,
  UserHasNotAgreed
}

export interface UserAgreementState {
  userAgreementStatus: UserAgreementStatus;
  termsAndConditionsPage: SafeHtml;
  privacyStatementPage: SafeHtml;
  isLoading: { [key in UserAgreementRequestType]: boolean };
  shouldDisplayAlert: boolean;
  alert?: AlertMessage;
}

export const userAgreementInitialState: UserAgreementState = {
  userAgreementStatus: UserAgreementStatus.Unknown,
  termsAndConditionsPage: undefined,
  privacyStatementPage: undefined,
  isLoading: initIsLoading(),
  shouldDisplayAlert: false
};

export function initIsLoading(isLoading: boolean = false): { [key in UserAgreementRequestType]: boolean } {
  const isLoadingDefault = {} as { [key in UserAgreementRequestType]: boolean };
  Object.keys(UserAgreementRequestType).forEach(requestType => isLoadingDefault[requestType] = isLoading);
  return isLoadingDefault;
}
