import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HelpSelectors from '@app/shared/state/help/help.selectors';
import * as Actions from './video-player-modal.actions';

@Component({
  selector: 'app-video-player-modal',
  templateUrl: './video-player-modal.component.html',
})
export class VideoPlayerModalComponent {

  selectedVideo$ = this._store.select(HelpSelectors.selectSelectedVideo);

  constructor(
    private _store: Store
  ) { }

  handleKeheModalEvents(): void {
    this._store.dispatch(Actions.modalClosed());
  }
}
