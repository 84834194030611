import { ExternalVideo } from './../models/external-video';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HelpCenterGroup } from '../modules/reporting/models/enum/help-center-group';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

@Injectable({
  providedIn: 'root'
})
export class VideoShowService {

  supplierIsInShow: boolean;
  videos: ExternalVideo[] = [
    {
      videoTitle: 'Initiate Show Order',
      vimeoId: '718389991/ea02c36ba7',
      group: HelpCenterGroup.Show,
      background: 'https://i.vimeocdn.com/video/896046219_640.webp'
    },
    {
      videoTitle: 'Find Show Orders',
      vimeoId: '718389970/82e4153ada',
      group: HelpCenterGroup.Show,
      background: 'https://i.vimeocdn.com/video/896046219_640.webp'
    },
    {
      videoTitle: 'Respond',
      vimeoId: '718390006/9b8899f556',
      group: HelpCenterGroup.Show,
      background: 'https://i.vimeocdn.com/video/896046219_640.webp'
    },
  ];

  constructor(
    private _store: Store,
  ) {
    this._store.select(ShowSelectors.selectIsSupplierInShow).subscribe(val => this.supplierIsInShow = val);
  }

  public getCurrentVideos(): ExternalVideo[] {
    return this.videos;
  }
}
