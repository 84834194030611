<div class="help-container">
    <h2>Help Center</h2>
    <h3>Contact Us for Support</h3>
    <p class="contactus">
      If you have questions regarding your items, pricing or promotions please contact KeHE Vendor Connections Service Desk.
    </p>
    <div class="row kehe-data-section">
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 col-sm-2 col-xl-1 kehe-data-label">Phone</div>
          <div class="col kehe-data-value">1(800) 809-8514, M-F 9am to 5pm EST</div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-2 col-xl-1 kehe-data-label">Fax</div>
          <div class="col kehe-data-value">1(800) 816-0313</div>
        </div>    
        <div class="row">
          <div class="col-12 col-sm-2 col-xl-1  kehe-data-label">Email</div>
          <div class="col kehe-data-value"><a href="mailto:vendorsupport@kehe.com" class="contactus underline">vendorsupport&#64;kehe.com</a></div>
        </div>
      </div>
    </div>    
    <ng-container *ngIf="showChecked$ | async">
      <div class="training-videos">
        <app-training-videos></app-training-videos>
      </div>
      <div class="faqs">
          <app-faqs *ngIf="isEnabledForCurrentShow"></app-faqs>
      </div>
    </ng-container>
</div>
