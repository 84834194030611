import { createAction, props } from '@ngrx/store';
import {
  UserNotificationPreferencesRecord,
  UserNotificationRecord
} from '../model/service/user-notifications';
import {
  NewPreference
} from '../model/user-notifications';
import { Payload } from '../../../models/payload';
import { HttpErrorResponse } from '@angular/common/http';

export const getUserNotificationPreferencesSuccessAction = createAction(
  '[User Notifications] Get Notification Preferences Success',
  props<{ record: UserNotificationPreferencesRecord }>()
);

export const updateUserNotificationPreferencesAction = createAction(
  '[User Notifications] Update Notification Preferences',
  props<{ newPreference: NewPreference }>()
);

export const updateUserNotificationPreferencesSuccessAction = createAction(
  '[User Notifications] Update Notification Preferences Success',
  props<{ record: UserNotificationPreferencesRecord }>()
);

export const getUserNotificationsAction = createAction(
  '[User Notifications] Get User Notifications',
);

export const getUserNotificationsSuccessAction = createAction(
  '[User Notifications] Get User Notifications Success',
  props<{ response: Payload<UserNotificationRecord[]> }>()
);

export const getUserNotificationsCountAction = createAction(
  '[User Notifications] Get User Notifications Count',
);

export const getUserNotificationsCountSuccessAction = createAction(
  '[User Notifications] Get User Notifications Count Success',
  props<{ response: number }>()
);

export const clearUserNotificationsAction = createAction(
  '[User Notifications] Clear User Notifications',
  props<{ notificationIds: number[] }>()
);

export const clearUserNotificationsSuccessAction = createAction(
  '[User Notifications] Clear User Notifications Success',
  props<{ notificationIds: number[] }>()
);

export const userNotificationsErrorAction = createAction(
  '[User Notifications] Clear User Notifications Error',
  props<{ err: HttpErrorResponse }>()
);

export const clearAllUserNotificationsAction = createAction(
  '[User Notifications] Clear All User Notifications'
);

export const clearAllUserNotificationsSuccessAction = createAction(
  '[User Notifications] Clear All User Notifications Success'
);

export const toggleClearNotificationsModalAction = createAction(
  '[User Notifications] Toggle Clear Notifications Modal',
);
