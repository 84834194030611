import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class DashboardService {

   constructor(
      private _httpClient: HttpClient,
   ) {}

   public getOutOfComplianceDocuments(): Observable<Array<any>> {
      const url = `${environment.v1ConnectApi}dashboardv2/outofcompliance`;
      return this._httpClient.get<any>(url);
   }
}
