import { IMessage } from './../models/IMessage';
import { IChatRoom } from './../models/IChatRoom';
import { ISupplier } from '../models/ISupplier';
import { ICustomer } from '../models/ICustomer';

export interface MessagesState {
  enabledChats: Array<IChatRoom>;
  headerChats: Array<IChatRoom>;
  headerChatsFiltered: Array<IChatRoom>;
  messageBeingSend: IMessage;
  chatMessageBeingSend: IChatRoom;
  userFullName: string;
  userEmail: string;
  suppliers: Array<ISupplier>;
  customers: Array<ICustomer>;
  unreadCount: number;
  unreadIds: Array<string>;
  newChatOpen: boolean;
  filterHeaderChatsText: string;
}


export const messagesInitialState: MessagesState = {
  enabledChats: [],
  headerChats: [],
  headerChatsFiltered: [],
  messageBeingSend: {} as IMessage,
  chatMessageBeingSend: {} as IChatRoom,
  userFullName: '',
  userEmail: '',
  suppliers: [],
  customers: [],
  unreadCount: 0,
  unreadIds: [],
  newChatOpen: false,
  filterHeaderChatsText: ''
};
