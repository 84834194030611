import { IMessage } from './../../models/IMessage';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss']
})

export class ChatBubbleComponent {

  @Input()
  message: IMessage = {} as IMessage;

  constructor(
  ) {}


}
