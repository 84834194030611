<div class="video-card-container" [ngClass]="{'background': video?.background }"
  [ngStyle]="{'background-image': bgStyle}">
  <div class="video-content">
    <div class="play-button">
      <kehe-phoenix-button name="play-button" classAttr="btn-default btn-medium" iconClassAttr="fa fa-play"
        (click)="playClicked()">
        Start Video
      </kehe-phoenix-button>
    </div>
    <div class="video-title">
      {{ video?.videoTitle }}
    </div>
  </div>
</div>