import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiReportComponent } from './bi-report.component';
import { BiReportService } from './services/bi-report.service';
import { ReportConfig } from './services/report.config';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    BiReportComponent
  ],
  exports: [
    BiReportComponent
  ],
  providers: [
    BiReportService,
    ReportConfig
  ],
})
export class BiReportModule { }
