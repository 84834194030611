export interface UserNotificationEntity {
  created: Date;
  email: string;
  linkText: string;
  linkUrl: string;
  message: string;
  notificationId: number;
  origin: UserNotificationOrigin;
  subtitle: string;
  title: string;
}

export enum UserNotificationType {
  SalesOrderAccepted = 'salesOrderAccepted',
  SalesOrderCountered = 'salesOrderCountered',
  SalesOrderProposed = 'salesOrderProposed',
  SalesOrderDeclined = 'salesOrderRejected',
  SalesOrderSubmitted = 'salesOrderSubmitted'
}

export enum UserNotificationDeliveryMethod {
  Email = 'email',
  InApp = 'inApp'
}

export enum UserNotificationOrigin {
  None,
  Retailer,
  Supplier,
  Enterprise
}

export interface UserNotificationPreferencesByMethod {
  [UserNotificationType.SalesOrderAccepted]: boolean;
  [UserNotificationType.SalesOrderCountered]: boolean;
  [UserNotificationType.SalesOrderProposed]: boolean;
  [UserNotificationType.SalesOrderDeclined]: boolean;
  [UserNotificationType.SalesOrderSubmitted]: boolean;
}

export interface UserNotificationPreferences {
    [UserNotificationDeliveryMethod.Email]: UserNotificationPreferencesByMethod;
    [UserNotificationDeliveryMethod.InApp]: UserNotificationPreferencesByMethod;
}

export class NotificationListType {
  constructor(
    public value: UserNotificationType,
    public label: string,
    public isChecked: boolean,
  ) {}
}

export interface NewPreference {
  method: string;
  option: UserNotificationType;
  status: boolean;
}
