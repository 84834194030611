import { combineLatest } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { ExternalVideo } from './../../models/external-video';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Faq } from '../../models/faq';
import { select, Store } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import * as DeviceSelectors from '@app/shared/state/device/device.selectors';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {

  @Input() videos: ExternalVideo[];
  @Input() faqs: Faq[];
  @Input() show: boolean;
  @Input() showReportIssue = true;
  @Input() helpCenterButtonText = 'Help Center';
  @Input() isShowMode = false;
  @Output() modalClose = new EventEmitter();

  reportAnIssueLink = environment.connectBiFeedbackUrl;

  browserStrings: Map<string, string> = new Map([
    ['Chrome', 'Google%20Chrome'],
    ['Firefox', 'Firefox'],
    ['MS-Edge-Chromium', 'Microsoft%20Edge'],
    ['MS-Edge', 'Microsoft%20Edge'],
    ['IE', 'Internet%20Explorer'],
    ['Safari', 'Safari'],
  ]);

  constructor(
    private _router: Router,
    private _store: Store
  ) { }

  ngOnInit() {
    combineLatest([
      this._store.select(AuthenticationSelectors.selectUserEmail),
      this._store.select(AuthenticationSelectors.selectUserFirstName),
      this._store.select(AuthenticationSelectors.selectUserLastName),
      this._store.pipe(select(DeviceSelectors.selectDeviceInfo)),
    ]).subscribe(([
      email,
      firstName,
      lastName,
      deviceInfo,
    ]) => {
      if (!!email && !!firstName && !!lastName && !!deviceInfo) {
        const browser = this.browserStrings.has(deviceInfo.browser) ?
        this.browserStrings.get(deviceInfo.browser) : 'Other';
        this.reportAnIssueLink = `${environment.connectBiFeedbackUrl}?` +
          `whatIs=${firstName}%20${lastName}&` +
          `whatEmail=${email}&` +
          `whatEmail6=${email}&` +
          `whatWeb=${browser}`;
      }
    });
  }

  handleKeheModalEvents(event) {
    this.modalClose.emit();
  }

  onHelpCenterClick() {
    this.removeBodyStyle();
    if (this.isShowMode) {
      this._router.navigate(['/show/help']);
    } else {
      this._router.navigate(['/help']);
    }
  }

  removeBodyStyle() {
    const elm = document.getElementsByTagName('body');
    if (elm) {
      const typ = document.createAttribute('style');
      typ.value = 'overflow: auto';
      elm[0].attributes.setNamedItem(typ);
    }
  }
}
