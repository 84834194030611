import { Environment } from './environment-enum';
export const environment = {
    current: Environment.UAT,
    production: false,
    showProfile: false,
    ga: null,
    googleAnalyticsTrackingId: 'G-R1ZMDPJRXF',
    googleTagManager: 'GTM-NNTMHCXM',
    splitIoKey: '31sn5keqg75c46ll1g1143jghnbiosj8ka9q',
    web: 'https://uatconnectsupplier.kehe.com',
    api: 'https://uat-api.kehe.com',
    bimlApi: 'https://uat-biml-api.kehe.com',
    identityApi: 'https://uat-connect-identity-server.kehe.com',
    logglyEndpoint: 'https://logs-01.loggly.com/inputs/',
    logglyName: 'connect-supplier-uat',
    powerbiReportApi:
        'https://uat-connect-powerbi-embed-auth.kehe.com/api/powerbiembed',
    ssrsReportUrlPrefix: 'https://uatreports.kehe.com/reportview/index',
    analyticsDashboardId: '628e7d51-8f50-4a98-a69a-148f113b2235',
    gapVoidDashboardId: 'a2691422-b8a9-48e5-869e-455ba60f99b4',
    orderProjectionDashboardId: '4efeed36-534a-4012-b929-736fecd04894',
    name: 'uat',
    priceChangePolicy:
        'https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/PriceChangePolicy.html',
    aboutLinePricing:
        'https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/Pricing+Group+Guidelines.pdf',
    vipcApi: 'https://api.kehe.com/uat/',
    v1ConnectApi: 'https://uatconnect.kehe.com/services/api/',
    v1Connect: 'https://uatconnect.kehe.com',
    v1EsnListByUserApi:
        'https://uatpromotions.kehe.com/services/api/esnsforuser',
    dcApiUrl: 'https://uat-api.kehe.com/distribution-centers',
    usersMicroservice: 'https://uat-api.kehe.com/users/',
    v1BrandListByEsnApi: 'https://uatdata.kehe.com/services/api/Brands',
    v2BrandListByEsnApi: 'https://uat-api.kehe.com/vendors/',
    v2PriceChangeApi: 'https://uat-api.kehe.com/price-changes',
    salesOrdersEnterprise: 'https://uat-api.kehe.com/sales-orders/api/supplier',
    customerEnterprise: 'https://uat-api.kehe.com/customers',
    salesOrdersLineItemsEnterprise:
        'https://uat-api.kehe.com/sales-orders/api/supplier-line-items',
    productSupplierApi: 'https://uat-api.kehe.com/products/supplier',
    newProducts: 'https://uat-api.kehe.com/products/new-products',
    customerProductsApi: 'https://uat-api.kehe.com/customer-products/api',
    productApi: 'https://uat-api.kehe.com/products',
    productEnterprise: 'https://uat-api.kehe.com/products/enterprise',
    supplierEnterprise: 'https://uat-api.kehe.com/suppliers/api/enterprise',
    showsSupplier: 'https://uat-api.kehe.com/shows/supplier',
    mapApiKey:
        'pk.eyJ1IjoiaW5hbi1rZWhlIiwiYSI6ImNrYTc4dnRuZzAweWEyeHBtN3BuZmVjZDcifQ._-Zme4v8wolktP6lIfxung',
    showImageBucket:
        'https://e1-prod-connect-show-public-images.s3.amazonaws.com',
    apiFutureOrdersSupplier: 'https://uat-api.kehe.com/future-orders/supplier',
    apiFutureOrdersImpersonatedSupplier:
        'https://uat-api.kehe.com/future-orders/impersonated-supplier',
    apiFutureOrdersReport: 'https://uat-api.kehe.com/future-orders/report',
    showOrders: 'https://uat-api.kehe.com/sales-orders/api',
    connectBiFeedbackUrl: 'https://form.jotform.com/203095578403053',
    supplierApi: 'https://uat-api.kehe.com/suppliers',
    purchasingOptionsApi: 'https://uat-api.kehe.com/purchasing-options',
    smartyStreetsKey: '17106106048628953',
    amplifyUrl:
        'https://eqjbb2xtxbegnfmto2rjsuqcwq.appsync-api.us-east-1.amazonaws.com/graphql',
    amplifyApiKey: 'da2-tnuk4ojag5cm7kul72o6zupviu',
    brandApi: 'https://uat-api.kehe.com/brand',
    brokerEnterprise: 'https://uat-api.kehe.com/brokers/enterprise',
    vendorGroupId: 24,
    vendorAdminGroupId: 168,
    vendorManagerGroupId: 26,
    vendorReportsGroupId: 69,
    supplierOnboardingGroupId: 184,
    supplierOnboardingRedirectGroupId: 158,
    connectSupplierGroupId: 155,
    v1TokenAPI: 'https://uat-connect-order-management-api.kehe.com/api/',
    resourcesApi: 'https://uat-api.kehe.com/resources',
    announcementsApi: 'https://uat-api.kehe.com/announcements/api/supplier',
    vendorBrokerManagementApi: 'https://uat-api.kehe.com/vendor-broker-mgmt/',
    ixoneApi: 'https://uat-api.kehe.com/ix-one/api',
    roadmapsApi: 'https://uat-api.kehe.com/roadmaps',
    manageProgressApi: 'https://uat-api.kehe.com/manage-progress/api',
    pendoApiKey: "4ed635b7-ec35-43bb-742b-ebeda67ffd43",
};
