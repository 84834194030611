<div class="container">
  <div class="row mb-3">
    <div class="col-sm-12">
      <kendo-label>Supplier</kendo-label>
      <kendo-combobox
        name="select-supplier"
        id="select-supplier"
        placeholder="Select Supplier"
        [data]="supplierOptions$ | async"
        valueField="esn"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [(ngModel)]="selectedSupplier"
        (ngModelChange)="onSupplierSelected()"
      ></kendo-combobox>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-12">
      <div class="d-flex">
        <kendo-label>DC</kendo-label>
        <div class="ml-auto">
          <app-select-all (clicked)="selectAllDcs()"></app-select-all>
        </div>
      </div>
      <kendo-multiselect 
        name="select-dc"
        id="select-dc"
        placeholder="Select DC(s)"
        [data]="dcOptions"
        valueField="id"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [autoClose]="false"
        [(ngModel)]="selectedDcList"
        [tagMapper]="tagMapper"
        (ngModelChange)="onDcSelected()"
      ></kendo-multiselect>
    </div>
  </div>
</div>
