import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payload } from '../../../models/payload';
import { ProductListFilter } from '../models/product-list-filter';
import { ProductListItem } from '../models/product-list-item';
import { environment } from '../../../../environments/environment';
import { retry } from 'rxjs/operators';
import { ListTabs } from '../enum/product-list.enum';
import { IProductFilterCheckItem } from '../models/iproduct-filter-item';
import { isNullOrUndefined } from '@app/utilities/common-utils';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private _apiProdutSupplierUrl: string;
  private _apiProductUrl: string;
  private _apiNewProductUrl: string;

  constructor(private httpClient: HttpClient) {
    this._apiProdutSupplierUrl = environment.productSupplierApi;
    this._apiProductUrl = environment.productApi;
    this._apiNewProductUrl = environment.newProducts;
  }

  get(
    productListFilter: ProductListFilter
  ): Observable<Payload<Array<ProductListItem>>> {
    let params = new HttpParams()
      .set('search', productListFilter.search)
      .set('pagecount', productListFilter.pageCount.toString())
      .set('pageindex', productListFilter.pageIndex.toString())
      .set('orderdirection', productListFilter.orderDirection.toString())
      .set('sortname', productListFilter.sortName.toString())
      .set(
        'includeavailablecount',
        productListFilter.includeAvailableCount.toString()
      );
      params = this.applyFilters(productListFilter, params);
    const apiUrl = this.getRequestUrl(this.getSelectedTab(productListFilter));
    return this.httpClient.get<any>(apiUrl, { params: params }).pipe(retry(2));
  }

  private getSelectedTab(productListFilter) {
    return productListFilter.selectedTab && productListFilter.selectedTab.text ? productListFilter.selectedTab.text : '';
  }

  private getRequestUrl(selectedTab: any) {
    if (selectedTab === ListTabs.draft) {
      return this._apiProductUrl + '/new-products/supplier';
    }
    return this._apiProdutSupplierUrl;
  }

  private applyFilters(productListFilter, params) {
    productListFilter.status.filter(f => f.checked).forEach(status => {
      params = params.append('statuses', status.value);
    });

    productListFilter.newProductworkflowStatus.filter(f => f.checked).forEach(status => {
      params = params.append('newproductworkflowstatuses', status.value);
    });

    productListFilter.scoreCard.filter(f => f.checked).forEach(scoreCard => {
      params = params.append('scorecards', scoreCard.value);
    });

    productListFilter.tempClass.filter(f => f.checked).forEach(tempClass => {
      params = params.append('temperatures', tempClass.value);
    });

    if (productListFilter.suppliers.length) {
      productListFilter.suppliers.forEach((item) => {
       params = params.append('suppliers', item['value']);
      });
    }

    if (productListFilter.brands.length) {
      productListFilter.brands.forEach((item) => {
        params = params.append('brands', item['code']);
      });
    }

    if (productListFilter.priceGroups.length) {
      productListFilter.priceGroups.forEach((item) => {
        params = params.append('pricegroups', item['value']);
      });
    }

    if (productListFilter.categoryManagers.length) {
      productListFilter.categoryManagers.forEach((item) => {
        params = params.append('categorymanagers', item['value']);
      });
    }
    if (productListFilter.createdBy.length) {
      productListFilter.createdBy.forEach((item) => {
        params = params.append('createdby', item['value']);
      });
    }

    if (!isNullOrUndefined(productListFilter.createdFrom)) {
      params = params.append('createdFrom', productListFilter.createdFrom);
    }
    if (!isNullOrUndefined(productListFilter.createdTo)) {
      params = params.append('createdTo', productListFilter.createdTo);
    }
    return params;
  }
  public getProductFilterCheckItemPayload(filterPath: string, url: string = null) {
    return this.httpClient
      .get<Payload<Array<IProductFilterCheckItem>>>((url ? url : this._apiProdutSupplierUrl) + filterPath)
      .pipe(retry(2));
  }
  public getProductFilterCheckItemPayloadWithParams(filterPath: string, param?: string, isActive?: boolean) {

    let httpParams = new HttpParams();
    if (param) {
      httpParams = new HttpParams().set('query', param);
    }

    return this.httpClient
      .get<Payload<Array<IProductFilterCheckItem>>>(this._apiProdutSupplierUrl + filterPath, { params: httpParams })
      .pipe(retry(2));
  }

  public getNewProductWorkFlowStatuses(): Observable<Payload<Array<IProductFilterCheckItem>>> {
    return this.getProductFilterCheckItemPayload('/filter/supplier/newproductworkflowstatuses', this._apiNewProductUrl);
  }

  public getDraftProductFilterCheckItemPayloadWithParams(filterPath: string, param?: string) {
    let httpParams = new HttpParams();
    if (param) {
      httpParams = new HttpParams().set('query', param);
    }
    return this.httpClient
      .get<Payload<Array<IProductFilterCheckItem>>>(this._apiNewProductUrl + filterPath, { params: httpParams })
      .pipe(retry(2));
  }
  public getStatuses(): Observable<Payload<Array<IProductFilterCheckItem>>> {
    return this.getProductFilterCheckItemPayload('/filter/statuses');
  }

  public getScoreCards(): Observable<Payload<Array<IProductFilterCheckItem>>> {
    return this.getProductFilterCheckItemPayload('/filter/scorecards');
  }

  public getTempClasses(): Observable<Payload<Array<IProductFilterCheckItem>>> {
    return this.getProductFilterCheckItemPayload('/filter/temperatures');
  }

  public getSuppliers(supplier?: string, isActivetab?: boolean): Observable<Payload<Array<IProductFilterCheckItem>>> {
    if (isActivetab) {
      return this.getProductFilterCheckItemPayloadWithParams('/filter/suppliers', supplier);
    } else {
      return this.getDraftProductFilterCheckItemPayloadWithParams('/filter/supplier/suppliers', supplier);
    }
  }

  public getBrands(brand?: string, isActivetab?: boolean): Observable<Payload<Array<IProductFilterCheckItem>>> {
    if (isActivetab) {
      return this.getProductFilterCheckItemPayloadWithParams('/filter/brands', brand);
    } else {
      return this.getDraftProductFilterCheckItemPayloadWithParams('/filter/supplier/brands', brand);
    }
  }

  public getPriceGroups(priceGroup?: string): Observable<Payload<Array<IProductFilterCheckItem>>> {
    return this.getProductFilterCheckItemPayloadWithParams('/filter/pricegroups', priceGroup);
  }

  public getCategoryManagers(categoryManager?: string): Observable<Payload<Array<IProductFilterCheckItem>>> {
    return this.getProductFilterCheckItemPayloadWithParams('/filter/categorymanagers', categoryManager);
  }

  public getCreatedByList(createdBy?: string): Observable<Payload<Array<IProductFilterCheckItem>>> {
    return this.getDraftProductFilterCheckItemPayloadWithParams('/filter/createdBy', createdBy);
  }

  public deleteProduct(upc: string): Observable<any> {
    return this.httpClient
      .delete<any>(
        `${this._apiNewProductUrl}/${upc}`);
  }

  public loadQuickFilterCount(): Observable<any> {
    return this.httpClient.get<any>(`${this._apiNewProductUrl}/supplier/counts`)
    .pipe(retry(2));
  }
}
