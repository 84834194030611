import { createAction, props } from '@ngrx/store';
import { OrderDetailShowActions } from './enum.actions';


export const syncOrderDetailShow = createAction(
  OrderDetailShowActions.SyncOrderDetailShow,
);

export const syncOrderDetailShowForProductListSuccess = createAction(
  OrderDetailShowActions.SyncOrderDetailShowForProductListSuccess,
  props<{
    orderId: number,
    masterVersionNumber: number
  }>()
);

export const syncOrderDetailShowForOrderHeaderSuccess = createAction(
  OrderDetailShowActions.SyncOrderDetailShowForOrderHeaderSuccess,
  props<{
    orderId: number,
    masterVersionNumber: number
  }>()
);

export const syncOrderDetailShowError = createAction(
  OrderDetailShowActions.SyncOrderDetailShowError,
);

export const orderVersionsLoadedSuccess = createAction(
  '[Order Detail Show] Order Versions Loaded Success',
  props<{ orders: { masterVersionNumber: number, orderId: number }[] }>()
);

