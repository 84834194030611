import { Component } from '@angular/core';
import { DestroyableDirective } from '../../../../abstract/destroyable';
import { GoogleTagManagerFacadeService } from '@kehe/promotional-roadmap-lib';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-promotional-roadmap-page',
  templateUrl: './promotional-roadmap-page.component.html'
})
export class PromotionalRoadmapPageComponent extends DestroyableDirective {
  constructor(
    private _gtmFacade: GoogleTagManagerFacadeService,
    private _gtmService: GoogleTagManagerService
  ) {
    super();
    this._gtmFacade.pushTagEvent
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this._gtmService.pushTag(event);
      });
  }
}
