import {createAction, props} from '@ngrx/store';
import {OnboardingTabStatus} from '../models/onboarding-tab-status';
import {Signer} from '../../supplier-detail/models/signer';
import {SupplierDetail} from '../../supplier-detail/models/supplier-detail';
import {User} from '../../supplier-detail/models/user';
import {OnboardingTabs} from '../enums/onboarding-tabs.enum';
import { UserDetails } from '../../supplier-detail/models/user-details';

export enum SupplierOnboardingActions {
  ShowValidateModal = '[Supplier Onboarding] Show Validate Modal',
  HideValidateModal = '[Supplier Onboarding] Hide Validate Modal',
  LoadOnboardingSupplierStatusSuccess = '[Supplier Onboarding] Load Onboarding Supplier Status Success',
  LoadOnboardingSupplierStatusFailure = '[Supplier Onboarding] Load Onboarding Supplier Status Failure',
  ValidateTab = '[Supplier Onboarding] Validate Tab',
  ValidateTabSuccess = '[Supplier Onboarding] Validate Tab Success',
  ValidateTabFailure = '[Supplier Onboarding] Validate Tab Failure',
  CanBeValidated = '[Supplier Onboarding] Can Be Validated',
  GeneralTabIsUnderReview = '[Supplier Onboarding] General Tab Is Under Review',
}

export const noOpAction = createAction(
  '[Supplier Onboarding] No Op'
);

export const showValidateModal = createAction(
    SupplierOnboardingActions.ShowValidateModal
);

// Onboarding Status
export const loadOnboardingTabV2Status = createAction(
  '[Supplier Onboarding] Load Onboarding Tab V2 Status',
  props<{ esn: string }>()
);

export const loadOnboardingTabV2StatusSuccess = createAction(
  '[Supplier Onboarding] Load Onboarding Tab V2 Status Success',
  props<{ tabStatuses: OnboardingTabStatus[] }>()
);

export const loadOnboardingTabV2StatusError = createAction(
  '[Supplier Onboarding] Load Onboarding Tab V2 Status Error',
);
// Onboarding Status

export const loadOnboardingSupplierStatusSuccess = createAction(
    SupplierOnboardingActions.LoadOnboardingSupplierStatusSuccess,
    props<{ tabStatuses: OnboardingTabStatus[] }>()
);

export const loadOnboardingSupplierStatusFailure = createAction(
    SupplierOnboardingActions.LoadOnboardingSupplierStatusFailure
);

export const hideValidateModal = createAction(
    SupplierOnboardingActions.HideValidateModal
);

export const validateTab = createAction(
    SupplierOnboardingActions.ValidateTab,
    props<{ tab: OnboardingTabs }>()
);

export const validateTabSuccess = createAction(
    SupplierOnboardingActions.ValidateTabSuccess,
    props<{ tabStatus: OnboardingTabStatus }>()
);

export const validateTabFailure = createAction(
    SupplierOnboardingActions.ValidateTabFailure
);

// Revision
export const showRevisionNotes = createAction(
  '[Supplier Onboarding] Show Revision Notes',
  props<{ onboardingStatus: OnboardingTabStatus }>()
);
export const closeRevisionNotes = createAction(
  '[Supplier Onboarding] Close Revision Notes',
);
// Revision

// Docusign
export const confirmDocusignSigner = createAction(
  '[Supplier Onboarding] Confirm Docusign Signer',
  props<{ signer: Signer }>()
);
export const cancelDocusignSigner = createAction(
  '[Supplier Onboarding] Cancel Docusign Signer',
);
export const sendDocusignEmail = createAction(
  '[Supplier Onboarding] Send Docusign Email',
);
export const sendDocusignEmailSuccess = createAction(
  '[Supplier Onboarding] Send Docusign Email Success',
  props<{ supplier: SupplierDetail }>()
);
export const sendDocusignEmailError = createAction(
  '[Supplier Onboarding] Send Docusign Email Error',
);
// Docusign

// User
export const showAddUserModal = createAction(
  '[Supplier Onboarding] Show Add User Modal',
);
export const closeAddUserModal = createAction(
  '[Supplier Onboarding] Close Add User Modal',
);
export const showDeleteUserModal = createAction(
  '[Supplier Onboarding] Show Delete User Modal',
  props<{ user: User }>()
);
export const closeDeleteUserModal = createAction(
  '[Supplier Onboarding] Close Delete User Modal',
);
export const getUsers = createAction(
  '[Supplier Onboarding] Get Users',
);
export const getUsersSuccess = createAction(
  '[Supplier Onboarding] Get Users Success',
  props<{ users: User[] }>()
);
export const getUsersError = createAction(
  '[Supplier Onboarding] Get Users Error',
);
export const addUser = createAction(
  '[Supplier Onboarding] Add User',
  props<{ user: User }>()
);
export const updateUser = createAction(
	'[Supplier Onboarding] Update User',
	props<{ user: User }>()
);
export const checkEmailV1Success = createAction(
  '[Supplier Onboarding] Check Email V1 Success',
  props<{ user: User }>()
);
export const createUserInV1 = createAction(
  '[Supplier Onboarding] Create User In V1',
  props<{ user: User }>()
);
export const associateV2Permissions = createAction(
  '[Supplier Onboarding] Associate V2 Permissions',
  props<{ user: User }>()
);
export const createUserInV2 = createAction(
  '[Supplier Onboarding] Create User In V2',
  props<{ user: User }>()
);
export const addUserSuccess = createAction(
  '[Supplier Onboarding] Add User Success',
  props<{ user: User }>()
);
export const addUserError = createAction(
  '[Supplier Onboarding] Add User Error',
  props<{ errorMessage: string }>()
);
export const updateUserSuccess = createAction(
	'[Supplier Onboarding] Update User Success',
	props<{ user: User }>()
);
export const updateUserError = createAction(
	'[Supplier Onboarding] Update User Error',
	props<{ errorMessage: string; }>()
);

export const deleteUser = createAction(
  '[Supplier Onboarding] Delete User',
  props<{ user: User }>(),
);
export const deleteUserSuccess = createAction(
  '[Supplier Onboarding]: Delete User Success',
  props<{ user: User }>(),
)
export const deleteUserError = createAction(
  '[Supplier Onboarding]: Delete User Error',
  props<{ user: User, error: any }>(),
);
export const loadUserStatusSuccess = createAction(
  '[Supplier Onboarding] Load User Status Success',
  props<{ users: UserDetails[] }>()
);
export const loadUserStatusError = createAction(
  '[Supplier Onboarding] Load User Status Error',
);
export const loadUsers = createAction(
  '[Supplier Onboarding] Load Supplier Users',
  props<{ esn: string }>()
);
export const loadUsersSuccess = createAction(
  '[Supplier Onboarding] Load Supplier Users Success',
  props<{ users: User[] }>()
);
export const loadUsersError = createAction(
  '[Supplier Onboarding] Load Supplier Users Error',
);
// User


// Resend Invitation
export const resendInvitation = createAction(
  '[Supplier Onboarding] Resend Invitation',
  props<{ user: User }>()
);
export const resendInvitationSuccess = createAction(
  '[Supplier Onboarding] Resend Invitation Success',
  props<{ user: User }>()
);
export const resendInvitationError = createAction(
  '[Supplier Onboarding] Resend Invitation Error',
  props<{ user: User, error: any }>()
);
// Resend Invitation
