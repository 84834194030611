<kehe-phoenix-modal
  name="subscriptionInfoModal"
  modalTitle="Subscription Details"
  [show]="showModal$ | async"
  (modalEvent)="handleModalEvent($event)"
  size="md"
>
  <div body>
    <app-subscription-info
      [subscription]="subscription$ | async"
      [show]="showModal$ | async"
    >
    </app-subscription-info>
  </div>
  <div footer class="footer">
    <kehe-phoenix-button
      name="okayBtn"
      classAttr="btn-primary btn-medium"
      (click)="onOkayClicked()"
    >
      Okay
    </kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
