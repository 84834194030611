import { createSelector } from "@ngrx/store";
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import { selectShowIdFromUrl } from '@app/shared/state/router/router.selectors';

export const selectDisplayOrdersListSidePanel = createSelector(
    ShowSelectors.selectSortedEligibleShows,
    (shows) => shows?.length > 1
);

export const selectIsShowIdFromUrlValid = createSelector(
  ShowSelectors.selectSortedEligibleShows,
  selectShowIdFromUrl,
  ShowSelectors.selectHasShowBeenChecked,
  (shows, showId, hasCheckedShowEligibility) => {
    if (hasCheckedShowEligibility && !shows?.length && showId === 'no-shows') {
      return true;
    }
    
    return !!shows?.some((show) => show.uniqueId === showId)
  }
);

export const selectSuppliersFromSelectedShow = createSelector(
  ShowSelectors.selectSelectedShow,
  (show) => show?.suppliers?.length ? show?.suppliers : null
);
