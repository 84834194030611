import { Injectable } from '@angular/core';
import {
  UserAgreementRequestType,
  UserAgreementStatus
} from './user-agreement.state';
import { Store } from '@ngrx/store';
import {
  loadPrivacyStatementPageAction,
  loadTermsAndConditionsPageAction,
  loadUserAgreementStatusAction,
  setUserAgreementAction
} from './user-agreement.actions';
import { Observable } from 'rxjs';
import {
  selectAlert,
  selectIsLoading,
  selectPrivacyStatementPage,
  selectShouldDisplayAlert,
  selectTermsAndConditionsPage,
  selectUserAgreementStatus
} from './user-agreement.selectors';
import { SafeHtml } from '@angular/platform-browser';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserAgreementFacade {
  constructor(
    private store: Store
  ) {}

  loadUserAgreementStatus(): void {
    this.store.dispatch(loadUserAgreementStatusAction());
  }

  getLoadedUserAgreementStatus(): Observable<UserAgreementStatus> {
    return this.store.select(selectUserAgreementStatus);
  }

  setUserAgreementStatus(): void {
    this.store.dispatch(setUserAgreementAction());
  }

  loadTermsAndConditionsPage(): void {
    this.store.dispatch(loadTermsAndConditionsPageAction());
  }

  getLoadedTermsAndConditionsPage(): Observable<SafeHtml> {
    return this.store.select(selectTermsAndConditionsPage);
  }

  loadPrivacyStatementPage(): void {
    this.store.dispatch(loadPrivacyStatementPageAction());
  }

  getLoadedPrivacyStatementPage(): Observable<SafeHtml> {
    return this.store.select(selectPrivacyStatementPage);
  }

  isLoading(requestType: UserAgreementRequestType): Observable<boolean> {
    return this.store.select(selectIsLoading(requestType));
  }

  shouldDisplayAlert(): Observable<boolean> {
    return this.store.select(selectShouldDisplayAlert);
  }

  getAlertType(): Observable<AlertTypes> {
    return this.store.select(selectAlert).pipe(map(alert => !!alert ? alert.type : undefined));
  }

  getAlertMessage(): Observable<string> {
    return this.store.select(selectAlert).pipe(map(alert => !!alert ? alert.message : undefined));
  }
}
