import { VideoService } from './../../services/video.service';
import { Component, OnInit } from '@angular/core';
import { ExternalVideo } from '../../models/external-video';
import { Store } from '@ngrx/store';
import * as Actions from './training-videos.actions';

@Component({
    selector: 'app-training-videos',
    templateUrl: './training-videos.component.html',
    styleUrls: ['./training-videos.component.scss']
})
export class TrainingVideosComponent implements OnInit {

    visibleRecords = 4;
    pageSize = 8;
    filteredVideos: ExternalVideo[];
    videos: ExternalVideo[];

    constructor(
        private _store: Store,
        private _videoService: VideoService,
    ) { }

    videoSelected(video: ExternalVideo): void {
        this._store.dispatch(Actions.videoSelected({ video }));
    }

    ngOnInit() {
        this.videos = this._videoService.getCurrentVideos();
        this.showVideos();
    }

    showVideos(): void {
        this.filteredVideos = this.videos.slice(0, this.visibleRecords);
    }

    get showLoadMoreBtn(): boolean {
        return this.visibleRecords < this.videos.length;
    }

    get loadMoreText(): string {
        let nextPageSize = this.pageSize;
        const difference = this.videos.length - this.visibleRecords;
        if (difference < nextPageSize) {
            nextPageSize = difference;
        }
        return `Load ${nextPageSize} more of ${difference}`;
    }

    loadMore(): void {
        this.visibleRecords = this.visibleRecords + this.pageSize;
        this.showVideos();
    }
}
