import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { OrderShowBannerTypes } from '../../../shared/models/eorder-show-banner-types';
import { OrderListShowTabId } from '../../models/eorder-list-show-tab-id';
import { ISortOption } from '../../../shared/models/ISortOptions';
import { ShowOrderCount } from '../../models/show-order-count';
import { OrdersListShowActions } from './enum.actions';
import { ShowEligibleCustomer } from '@app/models/show-models';


export const changeSelectedTab = createAction(
  OrdersListShowActions.ChangeSelectedTab,
  props<{selectedTab: OrderListShowTabId}>()
);

export const handleQueryParams = createAction(
  OrdersListShowActions.HandleQueryParams,
  props<{
    params: Params
   }>()
);

export const setBanner = createAction(
  OrdersListShowActions.SetBanner,
  props<{
    bannerType: OrderShowBannerTypes,
    apiResponse: any
   }>()
);

export const closeBanner = createAction(
  OrdersListShowActions.CloseBanner
);

export const changeSearchText = createAction(
  OrdersListShowActions.ChangeSearchText,
  props<{searchText: string}>()
);

export const resetAllFilters = createAction(
  OrdersListShowActions.ResetAllFilters
);


export const changeSorting = createAction(
  OrdersListShowActions.ChangeSorting,
  props<{selectedSort: ISortOption}>()
);

export const getShowOrderCountsSuccess = createAction(
  OrdersListShowActions.GetShowOrderCountsSuccess,
  props<{ counts: ShowOrderCount[] }>()
);

export const getShowOrderCountsError = createAction(
  OrdersListShowActions.GetShowOrderCountsError
);

export const getShowOrderCountsForImpersonatedUserSuccess = createAction(
  OrdersListShowActions.GetShowOrderCountsForImpersonatedUserSuccess,
  props<{ counts: ShowOrderCount[] }>()
);

export const getShowOrderCountsForImpersonatedUserError = createAction(
  OrdersListShowActions.GetShowOrderCountsForImpersonatedUserError
);

export const getShowOrderCountsOnTabChangeSuccess = createAction(
  OrdersListShowActions.GetShowOrderCountsOnTabChangeSuccess,
  props<{ counts: ShowOrderCount[] }>()
);

export const getShowOrderCountsOnTabChangeError = createAction(
  OrdersListShowActions.GetShowOrderCountsOnTabChangeError
);

export const getShowOrderCountsOnTabChangeForImpersonatedUserSuccess = createAction(
  OrdersListShowActions.GetShowOrderCountsOnTabChangeForImpersonatedUserSuccess,
  props<{ counts: ShowOrderCount[] }>()
);

export const getShowOrderCountsOnTabChangeForImpersonatedUserError = createAction(
  OrdersListShowActions.GetShowOrderCountsOnTabChangeForImpersonatedUserError
);
export const esnSearchEnterClicked = createAction(
  OrdersListShowActions.EsnSearchEnterClicked,
  props<{ esnSearch: string }>()
);

export const esnSearchValueChanged = createAction(
  OrdersListShowActions.EsnSearchValueChanged,
  props<{ esnSearch: string }>()
);

export const getShowCustomersSuccess = createAction(
  OrdersListShowActions.GetShowCustomersSuccess,
  props<{ showId: string, showCustomers: ShowEligibleCustomer[] }>()
);

export const getShowCustomersFailure = createAction(
  OrdersListShowActions.GetShowCustomersFailure,
);

export const getShowCustomersOnTabChangeSuccess = createAction(
  OrdersListShowActions.GetShowCustomersOnTabChangeSuccess,
  props<{ showId: string, showCustomers: ShowEligibleCustomer[] }>()
);

export const getShowCustomersOnTabChangeFailure = createAction(
  OrdersListShowActions.GetShowCustomersOnTabChangeFailure,
);