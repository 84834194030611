import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AnnouncementsListRouterLinks } from '../announcements-list/enums/announcements-list-router-links.enum';
import { recentAnnouncementsInitialized } from './recent-announcements.actions';
import { IAnnouncementsRecentLiveCount } from './interfaces/announcements-recent-live-count.interface';
import { selectAnnouncementRecentLiveCount } from './store/recent-announcements.selectors';

@Component({
  selector: 'app-recent-announcements',
  templateUrl: './recent-announcements.component.html',
  styleUrls: [ './recent-announcements.component.scss' ]
})
export class RecentAnnouncementsComponent implements OnInit {
  recentLiveCount$: Observable<IAnnouncementsRecentLiveCount> = this.store.select(selectAnnouncementRecentLiveCount);

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(recentAnnouncementsInitialized());
  }

  goToAnnouncements(): void {
    this.router.navigate([ AnnouncementsListRouterLinks.List ]);
  }
}
