import { Faq } from "@app/models/faq";
import { createAction, props } from "@ngrx/store";

export const getFaqsSuccess = createAction(
    '[FAQ API] Get FAQs Success',
    props<{ faqs: Faq[] }>(),
);
export const getFaqsFailure = createAction(
    '[FAQ API] Get FAQs Failure'
);
export const getShowFaqsSuccess = createAction(
    '[FAQ API] Get Show FAQs Success',
    props<{ showFaqs: Faq[] }>(),
);
export const getShowFaqsFailure = createAction(
    '[FAQ API] Get Show FAQs Failure'
);