import { ResourceActions } from '../constants/resource-actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

@Injectable({
   providedIn: 'root'
})
export class PermissionsService {

   private _userActions: string[];

   constructor(
      private _store: Store,
   ) {
      this._store.select(AuthenticationSelectors.selectUserActions).subscribe(actions => {
         this._userActions = actions;
      });
   }

   public userHasAction(action: string): boolean {
      return this._userActions && this._userActions.includes(action);
   }

   userHasConnectBiAccess(): boolean {
    return this.userHasAction(ResourceActions.SupplierBiDataRead) ||
      this.userHasAction(ResourceActions.SupplierAnalyticsRead) ||
      this.userHasAction(ResourceActions.SSRSReportsBaseRead) ||
      this.userHasAction(ResourceActions.SSRSReportsEnhancedRead);
   }



}
