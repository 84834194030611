import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { exhaustMap, filter, map, tap, withLatestFrom } from "rxjs/operators";
import * as AuthenticationActions from '@app/authentication/authentication.actions';
import * as BreadCrumbsApiActions from '@app/breadcrumbs/breadcrumbs.actions';
import * as BreadCrumbsSelectors from "@app/shared/state/breadcrumbs/breadcrumbs.selectors";
import * as AnnouncementsListActions from "@app/modules/announcements/announcements-list/announcements-list.actions";
import * as ProductDetailPageActions from '@app/modules/product-detail/pages/product-detail-page/product-detail-page.actions';
import * as SupplierDetailPageActions from '@app/modules/supplier-detail/pages/supplier-detail-page/supplier-detail-page.actions';
import * as ProductPageActions from '@app/modules/product-list/pages/product-page/product-page.actions';
import * as SupplierDetailApiActions from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as OrderDetailTopMenuActions from '@app/modules/orders-show/detail/components/order-detail-top-menu/order-detail-top-menu.actions';
import { BreadCrumbsService } from "./breadcrumbs.service";
import { of } from "rxjs";

@Injectable()
export class BreadcrumbsEffects {

    constructor(
        private actions$: Actions, 
        private router: Router,
        private store: Store,
        private breadCrumbsService: BreadCrumbsService,
    ) {}

    getBreadCrumbs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            exhaustMap(() => of(this.breadCrumbsService.get()).pipe(
                filter(breadCrumbs => !!breadCrumbs),
                map(breadCrumbs => BreadCrumbsApiActions.getBreadCrumbsSuccess({ breadCrumbs })),
            )),
        )
    })

    navigateToRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AnnouncementsListActions.clickedKendoBreadCrumbs,
                ProductDetailPageActions.clickedKendoBreadCrumbs,
                SupplierDetailPageActions.clickedKendoBreadCrumbs,
                OrderDetailTopMenuActions.clickedKendoBreadCrumbs,
            ),
            filter(action => action.selectedTab === null || action.selectedTab === undefined),
            tap(() => console.log('debug: navigateToRoute')),
            tap(action => this.router.navigate([action.item.title]))
        )
    }, { dispatch: false });

    navigateToRouteWithNavigationExtras$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AnnouncementsListActions.clickedKendoBreadCrumbs,
                ProductDetailPageActions.clickedKendoBreadCrumbs,
                SupplierDetailPageActions.clickedKendoBreadCrumbs,
                OrderDetailTopMenuActions.clickedKendoBreadCrumbs,
            ),
            filter(action => !!action.selectedTab),
            tap(() => console.log('debug: navigateToRouteWithNavigationExtras')),
            tap(action => this.router.navigate([action.item.title, {queryParams: { 'selectedTab': action.selectedTab }}])),
        )
    }, { dispatch: false });

    setBreadCrumbsInLocalStorage = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                SupplierDetailPageActions.removeKendoBreadCrumbs,
                OrderDetailTopMenuActions.updateKendoBreadCrumbs,
                ProductDetailPageActions.updateKendoBreadCrumbs,
                ProductPageActions.updateKendoBreadCrumbs,
                SupplierDetailPageActions.updateKendoBreadCrumbs,
                SupplierDetailApiActions.replaceKendoBreadCrumbs,
            ),
            withLatestFrom(this.store.select(BreadCrumbsSelectors.selectKendoBreadCrumbs)),
            tap(([action, breadCrumbs]) => this.breadCrumbsService.set(JSON.stringify(breadCrumbs))),
        )
    }, { dispatch: false });

}