import { createAction, props } from "@ngrx/store";
import { ISupplier } from '@app/modules/messages/models/ISupplier';

export const getOutOfComplianceDocumentsSuccess = createAction(
    '[Supplier API] Get Out Of Compliance Documents Success',
    props<{ outOfComplianceDocuments: any[] }>()
);

export const getOutOfComplianceDocumentsFailed = createAction(
    '[Supplier API] Get Out Of Compliance Documents Failed',
);

export const loadSuppliersForUserSuccess = createAction(
    '[Supplier API] Load Suppliers For User Success',
    props<{ suppliers: ISupplier[] }>()
);

export const loadSuppliersForUserFailed = createAction(
    '[Supplier API] Load Suppliers For User Failed',
);

export const loadDraftEsnsSuccess = createAction(
    '[Supplier API] Load Draft ESNs Success',
    props<{ esns: string[] }>()
);

export const loadDraftEsnsFailed = createAction(
    '[Supplier API] Load Draft ESNs Failed'
);

export const loadSuppliersSuccess = createAction(
    '[Supplier API] Load v2 Suppliers Success',
    props<{ suppliers: any[] }>()
);
export const loadSuppliersFailed = createAction(
    '[Supplier API] Load v2 Suppliers Failed'
);
