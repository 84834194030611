import { environment } from './../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private _http: HttpClient
  ) { }

  getUserGuid(): Observable<string> {
    return this._http.get(
      `${environment.v1ConnectApi}User/Info/userguid`
    ) as Observable<string>;
  }
}
