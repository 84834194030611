import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as AuthenticationActions from '@app/authentication/authentication.actions';
import { DistributionCenterService } from "./distribution-center.service";
import { exhaustMap, map } from "rxjs/operators";
import * as DistributionCenterApiActions from "./distribution-center.actions";
import { DistributionCenter } from "./distribution-center";

@Injectable()
export class DistributionCenterEffects {

    constructor(
        private actions$: Actions, 
        private distributionCenterService: DistributionCenterService
    ) {}

    getDistributionCenters$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            exhaustMap(() => this.distributionCenterService.getList()),
            map(distributionCenters => distributionCenters.map((dc): DistributionCenter => {
                return {
                    id: dc.Id,
                    abbreviation: dc.Abbreviation,
                    address1: dc.Address1,
                    address2: dc.Address2,
                    city: dc.City,
                    name: dc.Name,
                    number: dc.Number,
                    state: dc.State,
                    zip: dc.Zip,
                    country: dc.Country,
                    isNtsActive: dc.IsNtsActive,
                    created: dc.Created,
                    createdBy: dc.CreatedBy,
                    isActive: dc.IsActive,
                    lastUpdated: dc.LastUpdated,
                    lastUpdatedBy: dc.LastUpdatedBy,
                }
            })),
            map(distributionCenters => DistributionCenterApiActions.getDistributionCenterListSuccess({ distributionCenters }))
        )
    });
}