import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { userAgreementReducer } from './user-agreement.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserAgreementEffects } from './user-agreement.effects';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';

@NgModule({
  declarations: [
    SanitizeHtmlPipe
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('userAgreement', userAgreementReducer),
    EffectsModule.forFeature([UserAgreementEffects]),
  ],
  providers: [
    SanitizeHtmlPipe
  ]
})
export class UserAgreementStoreModule {}
