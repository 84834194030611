import { IMessagesToRender } from './../../models/IMessagesToRender';
import { IChatRoom } from './../../models/IChatRoom';
import {
  AfterViewChecked,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { ELastActionChat } from '../../models/ELastActionChat';
import { EStatusChatRoom } from '../../models/EStatusChatRoom';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-chat-drawer',
  templateUrl: './chat-drawer.component.html',
  styleUrls: ['./chat-drawer.component.scss'],
  animations: [
    trigger('drawerStatus', [
      state('Opened', style({
        'height': '458px'
      })),
      state('Collapsed', style({
        'height': '60px'
      })),
      transition('* <=> *', animate(200)),
    ]),
  ],
})

export class ChatDrawerComponent implements OnInit, AfterViewChecked, OnChanges {
  @ViewChild('scrollToBottom') private chatBody: ElementRef;

  @Input()
  chatRoom: IChatRoom;

  @Input()
  source: string;

  @Input()
  messagesToRender: Array<IMessagesToRender>;

  @Output()
  closeChat: EventEmitter<{charId: string}> = new EventEmitter();

  @Output()
  sendMessage: EventEmitter<{textMessage: string, chatId: string}> = new EventEmitter();

  @Output()
  toggleCollapse: EventEmitter<{chatRoom: IChatRoom}> = new EventEmitter();

  @Output()
  getMoreMessages: EventEmitter<{chatRoom: IChatRoom}> = new EventEmitter();

  @Output()
  saveDraftMessage: EventEmitter<{draftMessage: string, chatId: string}> = new EventEmitter();

  MAX_TEXT_MESSAGE_LENGTH = 750;
  textMessage = '';

  public inputForm: UntypedFormGroup;

  public scrolledToBottom = false;

  public status = EStatusChatRoom.opened;

  recipient: string;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const draftMessage = (this.chatRoom && this.chatRoom.draftMessage) ? this.chatRoom.draftMessage : '';

    this.inputForm = new UntypedFormGroup({
      textMessage: new UntypedFormControl(draftMessage, [
        Validators.maxLength(this.MAX_TEXT_MESSAGE_LENGTH),
      ]),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messagesToRender && changes.messagesToRender.currentValue && !changes.messagesToRender.firstChange) {
      this.scrolledToBottom = false;
      this.changeDetector.detectChanges();
      this.handleScroll();
    }
    this.status = this.chatRoom.status;
  }

  ngAfterViewChecked() {
    this.handleScroll();
  }

  handleScroll() {
    switch (this.chatRoom && this.chatRoom.lastActionChat) {
      case ELastActionChat.receivedMessage:
      case ELastActionChat.sentMessage:
      case ELastActionChat.loadMessages:
        return this.scrollToBottom();
      case ELastActionChat.loadMoreMessages:
        return;
      default:
        return this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    try {
      if (!this.scrolledToBottom && this.chatRoom.status === EStatusChatRoom.opened) {
        this.chatBody.nativeElement.scrollTop = this.chatBody.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.log(err);
    }
  }

  submit() {
    const textMessage = this.inputForm.controls.textMessage.value;
    if (textMessage.trim().length > 0) {
      this.sendMessage.emit({textMessage: textMessage, chatId: this.chatRoom.id});
      this.inputForm.controls.textMessage.setValue('');
    }
  }

  onScroll($event) {
    this.scrolledToBottom = true;
    // if ($event.target.scrollTop < 200 &&
    //     this.chatRoom.nextTokenMessages &&
    //     !this.chatRoom.loadingMoreMessages &&
    //     !this.scrolledToElement) {
    //   this.getMoreMessages.emit({chatRoom: this.chatRoom})
    // }
  }

  closeChatRoom(event, chatId) {
    event.stopPropagation();
    this.closeChat.emit(chatId);
  }

  toggleCollapseChat(chatRoom) {
    this.toggleCollapse.emit(chatRoom);
  }

  onBlurMethod() {
    const textMessage = this.inputForm.controls.textMessage.value;
    this.saveDraftMessage.emit({
      draftMessage: textMessage,
      chatId: this.chatRoom.id
    });
  }
}
