import { createReducer, on } from '@ngrx/store';
import * as SupplierApiActions from '@app/supplier/supplier.actions';
import { ISupplier } from '@app/modules/messages/models/ISupplier';

export const key = 'supplier';

export interface State {
  outOfComplianceDocuments: any[];
  suppliers: ISupplier[],
  isLoadingDraftSupplierEsns: boolean;
  draftSupplierEsns: string[];
  isLoadingSuppliers: boolean;
  enterpriseSuppliers: any[];
}

export const initialState: State = {
  outOfComplianceDocuments: [],
  suppliers: [],
  isLoadingDraftSupplierEsns: false,
  draftSupplierEsns: [],
  isLoadingSuppliers: true,
  enterpriseSuppliers: [],
};

export const reducer = createReducer(
    initialState,
    on(SupplierApiActions.getOutOfComplianceDocumentsSuccess, (state, params) => ({
      ...state,
      outOfComplianceDocuments: params.outOfComplianceDocuments.filter(item => !item.Esn.startsWith('04')),
    })),
    on(SupplierApiActions.loadSuppliersForUserSuccess, (state, { suppliers }) => ({
      ...state,
      suppliers,
    })),
    on(SupplierApiActions.loadDraftEsnsSuccess, (state, { esns }) => ({
      ...state,
      draftSupplierEsns: esns || [],
      isLoadingDraftSupplierEsns: false,
      isLoadingSuppliers: true,
    })),
    on(SupplierApiActions.loadDraftEsnsFailed, (state) => ({
      ...state,
      isLoadingDraftSupplierEsns: false,
    })),
    on(SupplierApiActions.loadSuppliersSuccess, (state, { suppliers }) => ({
      ...state,
      isLoadingSuppliers: false,
      enterpriseSuppliers: suppliers,
    })),
);

export const getOutOfComplianceDocuments = (state: State) => state && state.outOfComplianceDocuments;
export const getSuppliers = (state: State) => state && state.suppliers;
export const getIsLoadingDraftSupplierEsns = (state: State) => state && state.isLoadingDraftSupplierEsns;
export const getDraftSupplierEsns = (state: State) => state && state.draftSupplierEsns;
export const getIsLoadingSuppliers = (state: State) => state && state.isLoadingSuppliers;
export const getEnterpriseSuppliers = (state: State) => state && state.enterpriseSuppliers;