import {
    UserNotificationType,
    UserNotificationPreferencesByMethod,
    NotificationListType
  } from '../model/user-notifications';

export function createNotificationTypeList(preferencesByMethod: UserNotificationPreferencesByMethod) {
    const preferencesList: Array<NotificationListType> = [];
    Object.keys(preferencesByMethod).sort().forEach((type: UserNotificationType) => {
            switch (type) {
                case UserNotificationType.SalesOrderAccepted:
                    preferencesList.push(new NotificationListType(type, 'Accepted', preferencesByMethod[type]));
                    break;
                case UserNotificationType.SalesOrderCountered:
                   preferencesList.push(new NotificationListType(type, 'Countered', preferencesByMethod[type]));
                   break;
                case UserNotificationType.SalesOrderProposed:
                   preferencesList.push(new NotificationListType(type, 'Proposed', preferencesByMethod[type]));
                   break;
                case UserNotificationType.SalesOrderDeclined:
                   preferencesList.push(new NotificationListType(type, 'Declined', preferencesByMethod[type]));
                   break;
                case UserNotificationType.SalesOrderSubmitted:
                   preferencesList.push(new NotificationListType(type, 'Submitted', preferencesByMethod[type]));
                   break;
                default:
                    break;
            }
        });

    return preferencesList;

}
