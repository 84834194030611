export enum SupplierDetailTabs {
  gettingStarted = 'Getting Started',
  users = 'Users',
  broker = 'Broker',
  locations = 'Locations',
  general = 'General',
  contacts = 'Contacts',
  documents = 'Documents',
  purchasingOptions = 'Purchasing Options',
  legalSignatures = 'Legal & Signatures',
  purchasingInfo = 'Purchasing Info',
}
