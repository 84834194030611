<div class="container">
  <div class="row mb-3">
    <div class="col-sm-12">
      <kendo-label>Supplier</kendo-label>
      <kendo-combobox
        name="select-supplier"
        id="select-supplier"
        placeholder="Select Supplier"
        [data]="supplierOptions$ | async"
        valueField="esn"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [(ngModel)]="selectedSupplier"
        (ngModelChange)="onSupplierSelected()"
      ></kendo-combobox>
    </div>
  </div>
</div>
