<kehe-phoenix-loading *ngIf="loadingSupplierList$ | async"></kehe-phoenix-loading>
<div
    class="home-page-component"
    *ngIf="(loadingSupplierList$ | async) === false"
>
    <kehe-phoenix-alert
        [show]="
            canShowOutOfComplianceDocumentsBanner &&
            outOfComplianceDocuments?.length > 0
        "
        type="warning"
        message="You have required compliance documents that are missing or will expire in the next 30 days. Please upload the necessary documents."
        action1="View"
        action2="Upload"
        (alertEvent)="onComplianceDocumentsAlertEvent($event)"
    >
    </kehe-phoenix-alert>

    <kehe-phoenix-modal
        size="lg"
        [show]="showOutOfComplianceDocumentsModal"
        name="compliance-documents-modal"
        modalTitle="Required Compliance Documents"
        (modalEvent)="onComplianceDocumentsModalEvent()"
    >
        <div body>
            <p>
                The following required compliance documents are missing or will
                expire in the next 30 days. Please upload the necessary
                documents.
            </p>
            <div class="compliance-documents-grid-container">
                <div class="kehe-grid no-scroll p-0 w-100">
                    <kendo-grid [data]="outOfComplianceDocuments">
                        <kendo-grid-column
                            field="VendorName"
                            title="Supplier Name"
                        ></kendo-grid-column>
                        <kendo-grid-column
                            field="Esn"
                            title="ESN"
                            [width]="85"
                        ></kendo-grid-column>
                        <kendo-grid-column
                            field="documentType"
                            title="Document Type"
                        >
                            <ng-template kendoGridCellTemplate let-document>
                                <div>{{ document.DocumentType }}</div>
                                <div
                                    *ngIf="document?.AssociatedUpcs"
                                    style="font-size: 0.65rem"
                                >
                                    {{ document.AssociatedUpcs }}
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            field="ExpirationDate"
                            title="Expires"
                            [width]="85"
                        >
                            <ng-template kendoGridCellTemplate let-document>
                                {{
                                    document.ExpirationDate | date: 'MM/dd/yyyy'
                                }}
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
        <div footer>
            <kehe-phoenix-button
                classAttr="btn-link btn-medium"
                (click)="onComplianceDocumentsModalEvent()"
                >Close</kehe-phoenix-button
            >
            <kehe-phoenix-button
                classAttr="btn-primary btn-medium"
                (click)="onComplianceDocumentsModalSubmit()"
                >Upload</kehe-phoenix-button
            >
        </div>
    </kehe-phoenix-modal>

    <div
        *ngIf="
            !supplierIsInShow &&
            !hasOnly1DraftSupplier
        "
        class="show-announcements"
    >
        <kehe-phoenix-welcome-banner [disableAnimation]="true">
            <ng-template #titleBlock>
                Welcome to KeHE CONNECT Supplier<em class="trademark-logo far fa-registered"></em>!
            </ng-template>
            <ng-template #subheaderBlock>
              <p class="font-size-14">
                Creating a unique and unified experience just for you.
              </p>
            </ng-template>
          </kehe-phoenix-welcome-banner>
    </div>

    <div *ngIf="supplierIsInShow" class="show-announcements row">
        <div class="col-lg-12">
            <app-show-announcements></app-show-announcements>
        </div>
    </div>
    <div *ngIf="!hasOnly1DraftSupplier">
        <div class="notifications-cards">
        </div>

        <div class="announcements-container" *ngIf="!supplierIsInShow">
          <div class="col-lg-12" *ngIf="(showAnnouncements$ | async) === false; else announcementsTiles">
            <h3>Announcements</h3>
            <h4>Welcome to CONNECT Supplier</h4>
            <p class="announcements-content">
              Get the latest on new updates to CONNECT by checking the
              announcements page!
            </p>
            <p>
              <span
                (click)="goToAnnouncements()"
                class="announcements-link"
              >View Announcements
                  <em class="fas fa-chevron-right"></em>
              </span>
            </p>
          </div>

          <ng-template #announcementsTiles>
            <app-recent-announcements></app-recent-announcements>
          </ng-template>
        </div>

        <div class="card-tile-container">
            <div class="kehe-card-banner">
                <div class="card-link-title">
                    Resources
                </div>
                <div
                    class="card-link-image"
                    style="
                        background-image: url('https://s3.amazonaws.com/kehe-connect/prod/public_common/supplier-documents.png');
                        background-position: center center;
                    "
                ></div>
                <div class="card-link-subtitle">
                    <p>
                        Stay current and download the most recent important
                        Documents, Newsletters, Calendars, Trainings, Contracts
                        and Publications.
                    </p>
                </div>
                <div class="card-link-actions">
                    <kehe-phoenix-button
                        classAttr="btn-link btn-medium"
                        iconRightClassAttr="fa fa-chevron-right"
                        (click)="goToDocuments()"
                    >
                        View Resources
                    </kehe-phoenix-button>
                </div>
            </div>
            <div *ngIf="hasSupplierProductV2" class="kehe-card-banner">
                <div class="card-link-title">Manage Your Products</div>
                <div
                    class="card-link-image"
                    style="
                        background-image: url('https://s3.amazonaws.com/kehe-connect/prod/public_common/trending-videos.png');
                        background-position: center center;
                    "
                ></div>
                <div class="card-link-subtitle">
                    <p>
                        Managing your products just got easier! Check out the
                        NEW Experience with many more features to come.
                    </p>
                </div>
                <div class="card-link-actions">
                    <kehe-phoenix-button
                        classAttr="btn-link btn-medium"
                        iconRightClassAttr="fa fa-chevron-right"
                        (click)="goToProducts()"
                    >
                        View Products
                    </kehe-phoenix-button>
                </div>
            </div>
        </div>
    </div>
</div>
