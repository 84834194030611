import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-to-cart-toast',
  templateUrl: './add-to-cart-toast.component.html',
})

@Input()
export class AddToCartToastComponent {
  toastHeader: string;
  constructor() { }
}
