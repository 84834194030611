<kehe-phoenix-delete-modal
  *ngIf="showModal$ | async"
  name="deleteSubscriptionModal"
  modalTitle="Delete Subscription{{getPluralCharacter()}}"
  [isDeleteInProgress]="deleteSubscriptionLoading$ | async"
  (modalEvent)="handleModalEvent($event)"
  (cancelClicked)="cancelClicked()"
  (deleteClicked)="onDeleteClicked()"
  size="md"
>
  <div *ngIf="!!subscription">
    <p class="single-delete-question">Are you sure you want to delete this subscription?</p>
    <app-subscription-info
      [subscription]="subscription"
      [show]="showModal$ | async"
    >
    </app-subscription-info>
  </div>
  <div *ngIf="!subscription">
    <p class="multi-delete-question">Are you sure you want to delete {{selectedItemCount}} subscription{{getPluralCharacter()}}?</p>
  </div>
</kehe-phoenix-delete-modal>
