import { Action, createReducer, on } from '@ngrx/store';

import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncement } from '../interfaces/recent-announcement.interface';
import {
  recentAnnouncementsTilesInitialized
} from '../components/recent-announcements-tiles/recent-announcements-tiles.actions';
import {
  loadRecentAnnouncementsTilesError,
  loadRecentAnnouncementsTilesSuccess,
  loadRecentLiveCountError,
  loadRecentLiveCountSuccess
} from './recent-announcements.actions';

export class RecentAnnouncementsState {
  loading: boolean;
  recentLiveCount: IAnnouncementsRecentLiveCount;
  recentAnnouncements: IRecentAnnouncement[];
  recentAnnouncementsError: string;
}

export const initializeState = (): RecentAnnouncementsState => ({
  loading: false,
  recentLiveCount: null,
  recentAnnouncements: [],
  recentAnnouncementsError: null
});

export const initialState = initializeState();

const rootReducer = createReducer(
  initialState,

  on(loadRecentLiveCountSuccess, (state, {recentLiveCount}) => ({
    ...state,
    recentLiveCount,
  })),

  on(loadRecentLiveCountError, state => ({
    ...state,
    recentLiveCount: null
  })),

  on(recentAnnouncementsTilesInitialized, state => ({
    ...state,
    loading: true,
    recentAnnouncements: null,
    recentAnnouncementsError: null
  })),

  on(loadRecentAnnouncementsTilesSuccess, (state, { announcements }) => ({
    ...state,
    loading: false,
    recentAnnouncements: announcements
  })),

  on(loadRecentAnnouncementsTilesError, (state, { msg }) => ({
    ...state,
    loading: false,
    recentAnnouncements: [],
    recentAnnouncementsError: msg
  }))
);

export function reducer(state: RecentAnnouncementsState | undefined, action: Action) {
  return rootReducer(state, action);
}

export const recentAnnouncementsFeatureKey = 'recentAnnouncements';
