import { createActionGroup, emptyProps } from "@ngrx/store";

// Renaming of this action group would break the code, this needs to be the same as the action group in
// projects\promotional-roadmap-lib\src\lib\store\promotional-roadmap.actions.ts within the promotional roadmap lib
export const ManageProgressActions = createActionGroup({
  source: 'Promotional Roadmap',
  events: {
    'Trigger Manage Progress': emptyProps()
  }
});
