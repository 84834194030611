import { ActionReducerMap } from "@ngrx/store";
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as fromAuthentication from "./authentication/authentication.reducer";
import * as fromBreadCrumbs from './breadcrumbs/breadcrumbs.reducer';
import * as fromDevice from './device/device.reducer';
import * as fromDistributionCenter from './distribution-center/distribution-center.reducer';
import * as fromHelp from './help/help.reducer';
import * as fromFeatureFlag from "./feature-flag/feature-flag.reducer";
import * as fromShow from './show/show.reducer';
import * as fromSupplier from './supplier/supplier.reducer';

export const routerStoreKey = 'router';

export interface State {
    [routerStoreKey]: RouterReducerState,
    [fromAuthentication.authKey]: fromAuthentication.State;
    [fromBreadCrumbs.key]: fromBreadCrumbs.State,
    [fromDevice.deviceKey]: fromDevice.State,
    [fromDistributionCenter.distributionCenterKey]: fromDistributionCenter.State,
    [fromHelp.key]: fromHelp.State,
    [fromFeatureFlag.featureFlagKey]: fromFeatureFlag.State;
    [fromShow.key]: fromShow.State;
    [fromSupplier.key]: fromSupplier.State;
}

export const reducers: ActionReducerMap<State> = {
    [routerStoreKey]: routerReducer,
    [fromAuthentication.authKey]: fromAuthentication.reducer,
    [fromBreadCrumbs.key]: fromBreadCrumbs.reducer,
    [fromDevice.deviceKey]: fromDevice.reducer,
    [fromDistributionCenter.distributionCenterKey]: fromDistributionCenter.reducer,
    [fromHelp.key]: fromHelp.reducer,
    [fromFeatureFlag.featureFlagKey]: fromFeatureFlag.reducer,
    [fromShow.key]: fromShow.reducer,
    [fromSupplier.key]: fromSupplier.reducer,
};