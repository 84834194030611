import { on, createReducer } from "@ngrx/store";
import * as DistributionCenterActions from "@app/distribution-center/distribution-center.actions";
import { DistributionCenter } from "@app/distribution-center/distribution-center";

export const distributionCenterKey = 'distributionCenter';

export interface State {
    distributionCenters: DistributionCenter[];
}

export const initialState: State = {
    distributionCenters: [],
};

export const reducer = createReducer(
    initialState,
    on(DistributionCenterActions.getDistributionCenterListSuccess, (state, { distributionCenters }) => {
        return {
            ...state,
            distributionCenters
        };
    }),
);

export const getDistributionCenters = (state: State) => state && state.distributionCenters;
