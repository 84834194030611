import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

@Component({
    selector: 'app-help-center-page',
    templateUrl: './help-center-page.component.html',
    styleUrls: ['./help-center-page.component.scss']
})
export class HelpCenterPageComponent {
    supplierIsInShow: boolean;
    showChecked$: Observable<boolean>;
    isEnabledForCurrentShow: boolean;

    constructor(
        private _store: Store
    ) {
        this.showChecked$ = this._store.select(ShowSelectors.selectHasShowBeenChecked);
        this._store.select(ShowSelectors.selectIsSupplierInShow).subscribe(val => this.supplierIsInShow = val);

        this.isEnabledForCurrentShow = true;
    }

    public pdfUrl = environment.showImageBucket + '/HOH20 Sales Contacts.pdf';

}
