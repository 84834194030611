<div class="announcements-container">
  <div class="announcements-header">
    <div class="announcements-header-info">
      <h3>Announcements</h3>
      <ng-container *ngTemplateOutlet="info; context: (recentLiveCount$ | async)"></ng-container>
    </div>

    <kehe-phoenix-button classAttr="btn btn-link btn-medium"
                 (click)="goToAnnouncements()"
                 iconRightClassAttr="fa fa-chevron-right"
    >
      View All Announcements
    </kehe-phoenix-button>
  </div> 

  <div class="announcements-content">
    <app-recent-announcements-tiles></app-recent-announcements-tiles>
  </div>
</div>

<ng-template #info let-count="count" let-numberOfWeeks="numberOfWeeks">
  <span *ngIf="count && numberOfWeeks; else emptyInfo">{{count}} New Announcements in last {{numberOfWeeks}} weeks</span>
  <ng-template #emptyInfo>
    <span>--</span>
  </ng-template>
</ng-template>
