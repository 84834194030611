import { createReducer, on, Action } from '@ngrx/store';
import * as SupplierListActions from './supplier-list.actions';
import { SupplierListItem } from '../models/supplier-list-item';
import { SupplierListFilter } from '../models/supplier-list-filter';
import { ITagDataCell } from '@app/models/itag-data-cell';
import { SupplierStatus } from '../../supplier-detail/models/supplier-status';

export class SupplierListState {
  isLoading: boolean;
  supplierList: Array<SupplierListItem>;
  availableCount: number;
  supplierListFilter: SupplierListFilter;
  displayFilterPanel: boolean;
  supplierListFilterChips: Array<any>;
}

export const initializeState = (): SupplierListState => {
  return {
    isLoading: false,
    supplierList: new Array<SupplierListItem>(),
    availableCount: 0,
    supplierListFilter: new SupplierListFilter(),
    displayFilterPanel: false,
    supplierListFilterChips: [],
  };
};

export const initialState = initializeState();

const rootReducer = createReducer(
  initialState,
  on(SupplierListActions.loadSupplierList, (state) => ({
    ...state,
    isLoading: true,
    statusList: [],
  })),
  on(SupplierListActions.loadSupplierListSuccess, (state, params) => ({
    ...state,
    isLoading: false,
    supplierList: prepareSupplierList(params.suppliers.data),
    availableCount: params.suppliers.availableCount
  })),
  on(SupplierListActions.supplierListPageChange, (state, params) => ({
    ...state,
    supplierListFilter: {
      ...state.supplierListFilter,
      pageCount: params.pageCount,
      pageIndex: params.pageIndex,
      skip: params.skip,
    }
  })),
  on(SupplierListActions.openSupplierFilter, (state) => ({
    ...state,
    displayFilterPanel: true
  })),
  on(SupplierListActions.closeSupplierFilter, (state) => ({
    ...state,
    displayFilterPanel: false
  })),
  on(SupplierListActions.applyFilter, (state, params) => ({
    ...state,
    supplierListFilterChips: createFilterChipsList(params.supplierListFilter, ...state.supplierListFilterChips),
    supplierListFilter: {
      ...state.supplierListFilter,
      status: params.supplierListFilter.status,
      supplierDevManager: params.supplierListFilter.supplierDevManager,
      categoryManager: params.supplierListFilter.categoryManager,
      pageIndex: 0,
      skip: 0,
    },
    displayFilterPanel: false
  })),
  on(SupplierListActions.supplierListSearchChange, (state, params) => ({
    ...state,
    supplierListFilter: {
      ...state.supplierListFilter,
      search: params.search,
      pageIndex: 0,
      skip: 0,
    }
  })),
  on(SupplierListActions.supplierListSortChange, (state, params) => ({
    ...state,
    supplierListFilter: {
      ...state.supplierListFilter,
      sortName: params.sortName,
      orderDirection: params.orderDirection,
      pageIndex: 0,
      skip: 0,
    }
  })),
  on(SupplierListActions.supplierListFilterChipClicked, (state, params) => {
    const supplierListfilter = {...state.supplierListFilter};
    supplierListfilter[params.key] = initialState.supplierListFilter[params.key];

    return {
      ...state,
      supplierListFilter: supplierListfilter,
      supplierListFilterChips: removeFilterChip(params.key, ...state.supplierListFilterChips),
    };
  }),
  on(SupplierListActions.SupplierListFilterChipsClearAll, (state) => ({
    ...state,
    supplierListFilter: {
      ...state.supplierListFilter,
      pageIndex: 0,
      skip: 0,
      status: null,
      categoryManager: null,
      supplierDevManager: null,
    },
    supplierListFilterChips: [],
  })),
);

export function reducer(state: SupplierListState | undefined, action: Action) {
  return rootReducer(state, action);
}

function prepareSupplierList(list: SupplierListItem[]): SupplierListItem[] {

  list?.forEach(supplier => {
    let supplierLink = `/supplier/${supplier.esn}`;

    if (supplier.status === SupplierStatus.active) {
      supplierLink = `${supplierLink}/general`;
    } else if (supplier.status === SupplierStatus.draft) {
      supplierLink = `${supplierLink}/gettingStarted`;
    }

    supplier.link = {
      link: `${supplierLink}`,
      text: supplier.esn
    };
    supplier.statusTag = {
      classAttr: 'large',
      tag: {
        text: supplier.status,
        bgColor: supplier.status === 'Active' ? '#D5E48F' : '#E2E2E2',
      },
    } as ITagDataCell;
    prepareSupplierRelatedNames(supplier);
  });

  return list;
}

function prepareSupplierRelatedNames(supplier) {
  if (supplier.categoryManagerFirstName && supplier.categoryManagerLastName) {
    supplier.categoryManagerFullName = `${supplier.categoryManagerFirstName} ${supplier.categoryManagerLastName}`;
  } else if (supplier.categoryManagerFirstName) {
    supplier.categoryManagerFullName = supplier.categoryManagerFirstName;
  } else if (supplier.categoryManagerLastName) {
    supplier.categoryManagerFullName = supplier.categoryManagerLastName;
  }
  if (supplier.supplierDevelopmentManagerFirstName && supplier.supplierDevelopmentManagerLastName) {
    supplier.supplierDevelopmentManagerFullName = `${supplier.supplierDevelopmentManagerFirstName} ${supplier.supplierDevelopmentManagerLastName}`;
  } else if (supplier.supplierDevelopmentManagerFirstName) {
    supplier.supplierDevelopmentManagerFullName = supplier.supplierDevelopmentManagerFirstName;
  } else if (supplier.supplierDevelopmentManagerLastName) {
    supplier.supplierDevelopmentManagerFullName = supplier.supplierDevelopmentManagerLastName;
  }
}

function createFilterChipsList(supplierListFilter: SupplierListFilter, ...supplierListFilterChips) {
  const chips = [];
  if (supplierListFilter.status) {
    chips.push({text: supplierListFilter.status, extraPayload: { key: 'status' }});
  }
  if (supplierListFilter.categoryManager && supplierListFilter.categoryManager.value) {
    chips.push({text: supplierListFilter.categoryManager.label, extraPayload: { key: 'categoryManager' }});
  }
  if (supplierListFilter.supplierDevManager && supplierListFilter.supplierDevManager.value) {
    chips.push({text: supplierListFilter.supplierDevManager.label, extraPayload: { key: 'supplierDevManager' }});
  }
  return chips;
}

function removeFilterChip(keyToRemove, ...supplierListFilterChips) {
  const index = supplierListFilterChips.findIndex(filterChip => filterChip.extraPayload.key === keyToRemove);
  if (index === -1) { return supplierListFilterChips; }
  supplierListFilterChips.splice(index, 1);
  return supplierListFilterChips;
}
