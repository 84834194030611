<div class="container">
  <div class="row mb-3">
    <div class="col-sm-12">
      <kendo-label>Supplier</kendo-label>
      <kendo-combobox
        name="select-supplier"
        id="select-supplier"
        placeholder="Select Supplier"
        [data]="supplierOptions$ | async"
        valueField="esn"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [(ngModel)]="selectedSupplier"
        (ngModelChange)="onSupplierSelected()"
      ></kendo-combobox>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-12">
      <kendo-formfield>
        <kendo-label>Inbound Fill Rate Upper Limit</kendo-label>
        <kendo-numerictextbox
          name="inbound-fill-rate-limit"
          placeholder="Number from 0 to 1"
          required
          [min]="min"
          [max]="max"
          [spinners]="false"
          [(ngModel)]="inboundFillRateUpperLimit"
          (focus)="onFillRateFocus()"
          (blur)="onFillRateLimitChanged()"
        ></kendo-numerictextbox>
        <kendo-formerror *ngIf="!inboundFillRateUpperLimit">Value is required</kendo-formerror>
        <kendo-formerror *ngIf="inboundFillRateUpperLimit && (inboundFillRateUpperLimit <= min || inboundFillRateUpperLimit > max)">Must be a number between 0 and 1</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
</div>
