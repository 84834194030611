<div class="checkout-page">
  <div class="my-3">
    <kehe-phoenix-alert type="information" [show]="false" [message]="alertMessage" [stick]="true" [hideCloseIcon]="true"> </kehe-phoenix-alert>
    <kehe-phoenix-alert *ngIf="errorMessage$ | async as errorMessage" type="error" [show]="true" [messageAsHtml]="errorMessage" [hideCloseIcon]="true"></kehe-phoenix-alert>
  </div>

  <ng-container>
    <div class="row mb-3 justify-content-end">
      <div class="col-12 col-sm-8">
        <app-breadcrumbs title="Payment" type="child"></app-breadcrumbs>
      </div>
      <div class="col-sm-4 text-right">
        <kehe-phoenix-button classAttr="btn-medium btn-secondary mr-2" (click)="backToOrder()">
          Back to Order
        </kehe-phoenix-button>
        <kehe-phoenix-button 
          classAttr="btn-medium btn-primary" 
          (click)="submitOrder()" 
          [disabled]="!isCheckoutFormValid || termsForm?.get('acceptedTerms')?.value === false || isOrderProcessing" 
          [spinner]="isOrderProcessing"
        >Submit Order</kehe-phoenix-button>
      </div>
    </div>
  </ng-container>
    
  <app-order-summary></app-order-summary>
  <app-checkout-form (formValidity)="formValidityChange($event)"></app-checkout-form>
  <div [formGroup]="termsForm" class="mb-3 terms">
    <p>THE <a href="javascript:void(0)" onclick="window.open('https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/D4B-TermsAndConditions.html', '_blank','left=100,top=100,width=800,height=800,toolbar=1,resizable=0')">TERMS OF DATA EXCHANGE FOR BROKER SUBSCRIPTION SERVICES</a> GOVERN THIS TRANSACTION. PLEASE CLICK ON THE LINK AND READ THROUGH THE TERMS CAREFULLY.</p>
    <input type="checkbox" formControlName="acceptedTerms" kendoCheckBox #acceptTerms />
    <kendo-label class="k-checkbox-label" [for]="acceptTerms" text="By clicking this box, I acknowledge and accept the following: (1) I authorize payment for the Data Exchange for Brokers Subscription Services for each ESN for which I have selected, which shall auto-renew in accordance with the Subscription Term Option I have selected, unless terminated in accordance with the Terms of Data Exchange for Broker Subscription Services; and (2) I have read, accept, and agree to comply with the Terms of Data Exchange for Broker Subscription Services."></kendo-label>
  </div>
  <ng-container>
    <h4>Order Detail</h4>
    <app-order [canDelete]="false"></app-order>
  </ng-container>
</div>
