import { HelpCenterGroup } from './../../modules/reporting/models/enum/help-center-group';
import { Faq } from './../../models/faq';

export const gapVoidFaqs: Faq[] = [
  {
    question: `Why are the Gap & Void dashboard's sales values different
      from the Analytics dashboard's sales values?`,
    answer: `The Gap & Void dashboard's sales value includes only SKUs
      that are currently "active" in the distribution centers.  The Analytics
      dashboard's includes both "active" and "discontinued" SKU sales values
      during the last 52 weeks.`
  },
  {
    question: 'What does POD mean?',
    answer: `POD is the distinct count of SKU-Store combinations available at KeHE (e.g Points of Distribution).`,
  },
  {
    question: 'What does COGS mean?',
    answer: `COGS is the dollar amount shipped to retailers at KeHE's supplier cost plus the freight cost to
      the DC (e.g Cost of Goods Sold, Shipped Delivered Jobber).`,
  },
  {
    question: 'What is the POD Status Slicer used for?',
    answer: `The POD Status Slicer is used to look at the store distribution for any item selected. (1) In Store
      selects all the stores that carry a selected item, (0) not in Store selects all the opportunity
      stores for a selected item.`,
  },
  {
    question: 'What does CURRENT mean?',
    answer: `Current is the existing Cost of Goods Sold for the last 52 weeks and current
      Points of Distribution for the last 52 weeks.`,
  },
  {
    question: 'What does VOID mean?',
    answer: `Void means the item is not in distribution in all the stores within that retailer.`,
  },
  {
    question: 'What does GAP mean?',
    answer: `Gap means the item is not present in any of the stores within that retailer.`,
  },
  {
    question: 'What does OPPORTUNITY mean?',
    answer: `Opportunity $ is the summation of Gap $ and Void $.
      Opportunity POD is the summation of Gap POD and Void POD.
      OPPORTUNITY = VOID + GAP.`,
  },
  {
    question: 'What is the limit for number of map data points?',
    answer: `The map visual has a limitation of 3,500 data points.`,
  },
].map(item => ({
  ...item,
  id: 0,
  videoId: null,
  sequence: 0,
  version: 1,
  isDeleted: false,
  group: HelpCenterGroup.ConnectBi
}));
