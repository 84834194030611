<ng-container *ngIf="selectedVideo$ | async as selectedVideo">
  <kehe-phoenix-modal name="keheModal" [modalTitle]="selectedVideo?.videoTitle" [show]="selectedVideo" (modalEvent)="handleKeheModalEvents()">
    <div body *ngIf="selectedVideo" class="video-modal-content">
      <app-vimeo-video-player *ngIf="selectedVideo?.vimeoId" [videoId]="selectedVideo?.vimeoId"></app-vimeo-video-player>
    </div>
    <div footer>
      <kehe-phoenix-button classAttr="btn-medium btn-primary" (click)="handleKeheModalEvents()">Close</kehe-phoenix-button>
    </div>
  </kehe-phoenix-modal>
</ng-container>
