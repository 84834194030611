<div class="show-announcements">
  <app-events-banner *ngIf="areEligibleShowsAvailable$ | async" [showId]="firstEligibleShowId$ | async"></app-events-banner>
  <div class="show-announcements-header">
    <h3>Announcements</h3>
    <kehe-phoenix-button (click)="goToAnnouncements()" classAttr="btn btn-link btn-medium" iconRightClassAttr="fa fa-chevron-right">View All Announcements</kehe-phoenix-button>
  </div>

  <div *ngIf="sliderElements$ | async as sliderElements" class="carousel-container" [ngStyle]="{ 'max-width': carouselMaxWidth + 'px' }">
    <kehe-phoenix-image-carousel [sliderElements]="sliderElements" (imageClickLog)="imageClickLog($event)">
    </kehe-phoenix-image-carousel>
  </div>
</div>

<kehe-phoenix-video-player-modal *ngIf="showIntroModal" [selectedVideo]="introVideo" (modalEvent)="modalEvent($event)">
</kehe-phoenix-video-player-modal>
