import { itemRecapTypeOptions } from '../../../models/enum/item-recap-type';

import { Supplier } from './../../../models/supplier';
import { Observable } from 'rxjs';
import { IDropdownOption } from '@app/models/idropdown-option';
import { SubscriptionFilterChangedEvent } from './../../../models/subscription-filter-changed-event';
import { CreateSubscriptionFilterComponent } from './../../../models/create-subscription-filter-component';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectSupplierOptions } from '../../../store/data-exchange.selectors';
import { SubscriptionFilter } from '../../../models/subscription-filter';
import { SubscriptionFilterKey } from '../../../models/enum/subscription-filter-key';

@Component({
  selector: 'app-item-recap-hd-filter',
  templateUrl: './item-recap-hd-filter.component.html',
  styleUrls: []
})
export class ItemRecapHdFilterComponent implements OnInit, CreateSubscriptionFilterComponent {

  supplierOptions$: Observable<Supplier[]>;
  typeOptions = itemRecapTypeOptions;
  selectedSupplier: Supplier = null;
  selectedType: IDropdownOption = this.typeOptions[0];

  @Output()
  filterChanged = new EventEmitter<SubscriptionFilterChangedEvent>();

  constructor(private _store: Store) { }

  ngOnInit() {
    this.supplierOptions$ = this._store.pipe(select(selectSupplierOptions));
  }

  reset() {
    this.selectedSupplier = null;
    this.publishFilterChangedEvent();
  }

  onSupplierSelected() {
    this.publishFilterChangedEvent();
  }

  onTypeChanged() {
    this.publishFilterChangedEvent();
  }

  isFormValid(): boolean {
    return !!this.selectedSupplier && !!this.selectedType;
  }

  getFilters(): SubscriptionFilter[] {
    return [
      { key: SubscriptionFilterKey.Item360RecapType, value: [this.selectedType.value] },
    ];
  }

  publishFilterChangedEvent(): void {
    const formValid = this.isFormValid();

    this.filterChanged.emit({
      filtersValid: formValid,
      selectedSupplier: this.selectedSupplier,
      filters: formValid ? this.getFilters() : [],
    });
  }

}
