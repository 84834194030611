import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixAnnouncementsModule } from '@kehe/phoenix-announcements';

import {
  RecentAnnouncementsTilesComponent
} from './components/recent-announcements-tiles/recent-announcements-tiles.component';
import { RecentAnnouncementsComponent } from './recent-announcements.component';
import { RecentAnnouncementsEffects } from './store/recent-announcements.effects';
import { recentAnnouncementsFeatureKey, reducer } from './store/recent-announcements.reducer';
import { RecentAnnouncementsService } from './services/recent-announcements.service';

@NgModule({
  declarations: [
    RecentAnnouncementsComponent,
    RecentAnnouncementsTilesComponent
  ],
  exports: [
    RecentAnnouncementsComponent
  ],
  providers: [
    RecentAnnouncementsService
  ],
  imports: [
    CommonModule,
    PhoenixButtonModule,
    PhoenixAnnouncementsModule,
    StoreModule.forFeature(recentAnnouncementsFeatureKey, reducer),
    EffectsModule.forFeature([RecentAnnouncementsEffects]),
  ]
})
export class RecentAnnouncementsModule { }
