import { GoogleAnalyticsService } from './../../services/googleAnalytics.service';
import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { SliderElement } from '@kehe/phoenix-carousel';
import { DestroyableDirective } from '../../abstract/destroyable';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { ShowModel } from '../../models/show-models';
import { Router } from '@angular/router';
import { AnnouncementsListRouterLinks } from '../../modules/announcements/announcements-list/enums/announcements-list-router-links.enum';
import * as DeviceSelectors from '@app/shared/state/device/device.selectors';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

@Component({
  selector: 'app-show-announcements',
  templateUrl: './show-announcements.component.html',
  styleUrls: ['./show-announcements.component.scss']
})
export class ShowAnnouncementsComponent extends DestroyableDirective  implements OnInit, AfterViewInit {

  sliderElements$ = this._store.select(ShowSelectors.selectSliderElements);
  currentShow: ShowModel;
  showIntroModal = false;
  isMobile: boolean;
  carouselMaxWidth = 1170;
  introVideo = {
    title: 'Welcome to Show',
    vimeoId: '425253557/d4d0b4cf6e',
  };
  areEligibleShowsAvailable$ = this._store.select(ShowSelectors.selectAreEligibleShowsAvailable);
  firstEligibleShowId$ = this._store.select(ShowSelectors.selectFirstEligibleShowId);

  constructor(
    private _store: Store,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router
  ) {
    super();
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateMaxWidth();
  }

  ngOnInit() {
    this._store.select(ShowSelectors.selectCurrentShow)
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.currentShow = val;
      });

    this._store.select(DeviceSelectors.selectIsMobile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => this.isMobile = val);

    this.calculateMaxWidth();
  }

  ngAfterViewInit() {
    if (!!this.currentShow) {
      const seen = localStorage.getItem('show-welcome-' + this.currentShow.uniqueId);
      if (!seen) {
        // this.showIntroModal = this.isMobile !== true;
        this.showIntroModal = false; // set to false until we update the video
      }
    }
  }

  calculateMaxWidth() {
    this.carouselMaxWidth = window.innerWidth < 1217 ? window.innerWidth - 47 : 1170;
  }

  modalEvent($event?: any): void {
    localStorage.setItem('show-welcome-' + this.currentShow.uniqueId, 'true');
    this.showIntroModal = false;
  }

  goToAnnouncements() {
    this.router.navigate([ AnnouncementsListRouterLinks.List ]);
  }

  imageClickLog($event: SliderElement): void {
    this.googleAnalyticsService.eventEmitter(
      'show',
      `carousel-banner-click-${$event.imageUrl}`,
      `${$event.imageUrl}`,
      1
    );
  }

  get formattedShowDate(): string {

    if (this.currentShow) {
      const start = this.currentShow.startDate;
      const end = this.currentShow.endDate;

      const startMonth = moment(start).format('MMM');
      const endMonth = moment(end).format('MMM');
      const sameMonth = startMonth === endMonth;

      const startYear = moment(start).format('YYYY');
      const endYear = moment(end).format('YYYY');
      const sameYear = startYear === endYear;

      const startRange = `${startMonth} ${moment(start).format('D')}${sameYear ? '' : (', ' + startYear)}`;
      const endRange = `${sameMonth ? '' : endMonth} ${moment(end).format('D')}, ${endYear}`;

      return `${startRange} - ${endRange}`;
    }
    return null;
  }
}
