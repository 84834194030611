import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { dimissSubscriptionOverviewModal } from '../../store/data-exchange.actions';

@Component({
    selector: 'app-subscription-overview-modal',
    templateUrl: './subscription-overview-modal.component.html',
  })
  export class SubscriptionOverviewModalComponent {

    constructor(private _store: Store) { }

    dismissModal() {
      this._store.dispatch(dimissSubscriptionOverviewModal());
    }

  }
