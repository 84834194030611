import { getShowIdFromUrl } from './user-notifications.reducer';
import {
  userNotificationCardAdapter,
  UserNotificationsState
} from './user-notifications.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

const selectUserNotificationsState = createFeatureSelector<UserNotificationsState>('userNotifications');

export const selectAllUserNotificationPreferences = createSelector(
  selectUserNotificationsState,
  state => state.preferences
);

export const selectAllUserNotificationCards = createSelector(
  selectUserNotificationsState,
  state =>
    userNotificationCardAdapter
    .getSelectors()
    .selectAll(state.displayCardState)
);
export const selectFormattedUserNotificationCards = createSelector(
  selectAllUserNotificationCards,
  ShowSelectors.selectSortedEligibleShows,
  (notifications, eligibleShows) => {
    return notifications?.map(card => {
      const clone = Object.assign({}, card);
      const showId = getShowIdFromUrl(card?.linkUrl);
      const showName = eligibleShows?.find((show) => show?.uniqueId === showId)?.name;
      if (showName?.length) {
        clone.tag = {
          text: showName,
          bgColor: '#FFFFFF',
          borderColor: '#BBBBBB'
        }
      }
      return clone;
    }) || [];
  }
);

export const selectUserNotificationCount = createSelector(
  selectUserNotificationsState,
  state => state.notificationCount
);

export const selectClearNotificationsModalOpen = createSelector(
  selectUserNotificationsState,
  state => state.clearNotificationsModalOpen
);
