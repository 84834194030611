import { createSelector } from "@ngrx/store";
import { State } from "../index";
import * as fromSupplier from './supplier.reducer';
import { SupplierStatus } from "@app/modules/supplier-detail/models/supplier-status";

const selectSupplierState = (state: State) => state.supplier;
export const selectOutOfComplianceDocuments = createSelector(
    selectSupplierState,
    fromSupplier.getOutOfComplianceDocuments,
)
export const selectSuppliers = createSelector(
    selectSupplierState,
    fromSupplier.getSuppliers,
);
export const selectIsLoadingDraftSupplierEsns = createSelector(
    selectSupplierState,
    fromSupplier.getIsLoadingDraftSupplierEsns,
);
export const selectDraftSupplierEsns = createSelector(
    selectSupplierState,
    fromSupplier.getDraftSupplierEsns,
);
export const selectIsLoadingSuppliers = createSelector(
    selectSupplierState,
    fromSupplier.getIsLoadingSuppliers,
);
export const selectEnterpriseSuppliers = createSelector(
    selectSupplierState,
    fromSupplier.getEnterpriseSuppliers,
);

export const selectSupplierEsns = createSelector(
    selectSuppliers,
    (suppliers) => suppliers && suppliers.map(supplier => supplier.esn)
);

export const selectDoesUserHaveAccessToSingleDraftEsn = createSelector(
    selectDraftSupplierEsns,
    selectEnterpriseSuppliers,
    (draftSupplierEsn, suppliers) => suppliers && (draftSupplierEsn.length === 1 && suppliers.every(x => x.status === SupplierStatus.draft))
);

export const selectDoesUserHaveAccessToMultipleDraftEsns = createSelector(
    selectDraftSupplierEsns,
    selectEnterpriseSuppliers,
    (draftSupplierEsn, suppliers) => suppliers && (draftSupplierEsn.length > 1 && suppliers.every(x => x.status === SupplierStatus.draft))
)
  