import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { IAnnouncementsRecentLiveCount } from '../interfaces/announcements-recent-live-count.interface';
import { IRecentAnnouncement } from '../interfaces/recent-announcement.interface';
import { RecentAnnouncementsService } from '../services/recent-announcements.service';
import { recentAnnouncementsInitialized } from '../recent-announcements.actions';
import {
  recentAnnouncementsTilesInitialized
} from '../components/recent-announcements-tiles/recent-announcements-tiles.actions';
import {
  loadRecentAnnouncementsTilesError,
  loadRecentAnnouncementsTilesSuccess,
  loadRecentLiveCountError,
  loadRecentLiveCountSuccess
} from './recent-announcements.actions';

@Injectable()
export class RecentAnnouncementsEffects {
  constructor(private actions$: Actions, private announcementsService: RecentAnnouncementsService) {
  }

  loadRecentLiveCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recentAnnouncementsInitialized),
      switchMap((action) => {
        return this.announcementsService.getRecentLiveCount()
          .pipe(
            map((recentLiveCount: IAnnouncementsRecentLiveCount) =>
              loadRecentLiveCountSuccess({ recentLiveCount })),
            catchError(() => of(loadRecentLiveCountError()))
          );
      })
    )
  );

  loadRecentAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recentAnnouncementsTilesInitialized),
      switchMap((action) => {
        return this.announcementsService.getRecentLiveAnnouncements()
          .pipe(
            map((announcements: IRecentAnnouncement[]) =>
              loadRecentAnnouncementsTilesSuccess({ announcements })),
            catchError(response =>
              of(loadRecentAnnouncementsTilesError({ msg: response?.error?.message ?? 'Something went wrong' })))
          );
      })
    )
  );
}
