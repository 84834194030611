import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { ClearNotificationsModalComponent } from './components/clear-notifications-modal/clear-notifications-modal.component';
import { userNotificationReducer } from './store/user-notifications.reducer';
import { UserNotificationsEffects } from './store/user-notifications.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [
    ClearNotificationsModalComponent
  ],
  imports: [
    CommonModule,
    PhoenixModalModule,
    PhoenixButtonModule,
    StoreModule.forFeature('userNotifications', userNotificationReducer),
    EffectsModule.forFeature([UserNotificationsEffects])
  ],
  exports: [
    ClearNotificationsModalComponent
  ]
})
export class UserNotificationsStoreModule {}
