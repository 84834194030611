import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payload } from '../../../models/payload';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class UserAgreementService {

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  getUserAgreementStatus(): Observable<Payload<boolean>> {
    return this.httpClient.get<Payload<boolean>>(`${environment.api}/users/agreement/check`);
  }

  setUserAgreement(): Observable<HttpResponse<Object>> {
    return this.httpClient.post<HttpResponse<Object>>(`${environment.api}/users/agreement`, {});
  }

  getKeheStaticPage(url: string) {
    return this.httpClient.get(url,
      {
        headers: new HttpHeaders().set('Accept', 'text/html'),
        responseType: 'text'
      }
    );
  }
}
