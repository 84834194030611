<div class="tile" (click)="tileClicked()">
    <div class="row">
      <div class="col-sm-9">
        <h3>{{ title }}</h3>
      </div>
      <div class="col-sm-3 text-right">
        <span class="value">{{ count }}</span>
      </div>
    </div>
  </div>
