import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';
import * as Player from '@vimeo/player/dist/player.js';

@Component({
  selector: 'app-vimeo-video-player',
  templateUrl: './vimeo-video-player.component.html'
})

export class VimeoVideoComponent implements AfterViewInit {
  @ViewChild('vimeoVideoContainer', { static: true }) vimeoVideoContainer;
  @Input() videoId: string;
  @Input() width = null;
  public player: Player;

  ngAfterViewInit() {
    this.player = new Player(this.vimeoVideoContainer.nativeElement, {
      url: 'https://vimeo.com/' + this.videoId,
      width: this.width,
      responsive: this.width === null,
    });
  }

}
