import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupplierPageComponent } from './pages/supplier-page/supplier-page.component';
const routes: Routes = [
  {
    path: 'supplier',
    component: SupplierPageComponent,
  },
  {
    path: 'draft-supplier',
    component: SupplierPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierListRoutingModule { }
