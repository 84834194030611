import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CartItem } from '../models/cart-item';
import { PaymentTransactionRequest, ThirdPartyTransactionRequest } from '../models/payment-transaction-request';


@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private url: string;
  private readonly localStorageKey = 'd4b_subscriptions_cart';

  constructor(private http: HttpClient) {
    this.url = `${environment.bimlApi}/api-broker-analytics/esn-subscription`;
  }

  getItems(skip: number, take: number): Observable<any> {
    const cartItems = this.getItemsFromLocalStorage();
    return of({ allItemsInCart: cartItems, itemsToDisplay: cartItems.slice(skip, skip + take) });
  }

  addItemsToCart(items: CartItem[]) {
    const itemsInCart = this.getItemsFromLocalStorage();
    items.forEach(item => {
      if (this.isItemAlreadyInCart(itemsInCart, item.esn)) { return of(itemsInCart); }
      itemsInCart.push(item);
    });
    this.setItemsInLocalStorage(itemsInCart);
    return of(itemsInCart);
  }

  removeItemFromCart(esnToRemove: string) {
    let itemsInCart = this.getItemsFromLocalStorage();
    itemsInCart = this.removeItemFromCartByEsn(itemsInCart, esnToRemove);
    this.setItemsInLocalStorage(itemsInCart);
    return of(itemsInCart);
  }

  processTransaction(request: PaymentTransactionRequest ): Observable<any> {
    return this.http.post<any>(this.url, request);
  }
  
  thirdPartyTransaction(request: ThirdPartyTransactionRequest ): Observable<any> {
    return this.http.post<any>(this.url, request);
  }

  clearCart(): Observable<boolean> {
    localStorage.setItem(this.localStorageKey, null);
    return of(true);
  }

  private getItemsFromLocalStorage(): CartItem[] {
    const cartItems = JSON.parse(localStorage.getItem(this.localStorageKey));
    if (cartItems === null || cartItems === undefined) { return []; }
    return cartItems;
  }

  private setItemsInLocalStorage(items: CartItem[]): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(items));
  }

  private isItemAlreadyInCart(items: CartItem[], esnToAdd: string): boolean {
    const indexOfEsn = items.findIndex(item => item.esn === esnToAdd);
    return indexOfEsn === -1 ? false : true;
  }

  private removeItemFromCartByEsn(items: CartItem[], esnToRemove: string): CartItem[] {
    const indexToRemove = items.findIndex(item => item.esn === esnToRemove);
    if (indexToRemove < 0) { return items; }
    const preIndex = items.slice(0, indexToRemove);
    const postIndex = items.slice(indexToRemove + 1);
    return preIndex.concat(postIndex);
  }

}
