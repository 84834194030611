import { IDropdownOption } from '@app/models/idropdown-option';

export enum ItemRecapType {
  OnHand = 'OnHand',
  OutOfStock = 'OutOfStock',
  Purchases = 'Purchases',
  Sales = 'Sales',
  OutstandingPo = 'OutstandingPo'
}

export const itemRecapTypeOptions: IDropdownOption[] = [
  {
    value: ItemRecapType.OnHand,
    label: 'On Hand'
  },
  {
    value: ItemRecapType.OutOfStock,
    label: 'Out of Stock'
  },
  {
    value: ItemRecapType.OutstandingPo,
    label: 'Outstanding PO'
  },
  {
    value: ItemRecapType.Purchases,
    label: 'Purchases'
  },
  {
    value: ItemRecapType.Sales,
    label: 'Sales'
  },
];
