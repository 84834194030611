import { createAction, props } from "@ngrx/store";
import { DeviceInfo } from "ngx-device-detector";

export const getDeviceInfoSuccess = createAction(
    '[Device Detector API] Get Device Info Success', 
    props<{ 
        deviceInfo: DeviceInfo,
        isDesktop: boolean,
        isMobile: boolean,
        isTablet: boolean,
    }>()
);