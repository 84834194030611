export enum DayOfWeek {
  All = '',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export const dayOfWeekAbbreviationMap = new Map([
  [DayOfWeek.All, ''],
  [DayOfWeek.Monday, 'Mon'],
  [DayOfWeek.Tuesday, 'Tues'],
  [DayOfWeek.Wednesday, 'Wed'],
  [DayOfWeek.Thursday, 'Thurs'],
  [DayOfWeek.Friday, 'Fri'],
  [DayOfWeek.Saturday, 'Sat'],
  [DayOfWeek.Sunday, 'Sun'],
]);
