import {
  UserNotificationDeliveryMethod,
  UserNotificationEntity,
  UserNotificationType,
  UserNotificationPreferences
} from '../model/user-notifications';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { UserNotificationCard } from '@kehe/phoenix-top-bar';

export interface UserNotificationsState {
  preferences: UserNotificationPreferences;
  entityState: EntityState<UserNotificationEntity>;
  displayCardState: EntityState<UserNotificationCard>;
  notificationCount: number;
  clearNotificationsModalOpen: boolean;
}

export const userNotificationAdapter = createEntityAdapter<UserNotificationEntity>({
  selectId: entity => entity.notificationId
});

export const userNotificationCardAdapter = createEntityAdapter<UserNotificationCard>({
  selectId: card => card.notificationId
});

export const userNotificationsInitialState: UserNotificationsState = {
  preferences: initNotificationPreferences(),
  entityState: userNotificationAdapter.getInitialState(),
  displayCardState: userNotificationCardAdapter.getInitialState(),
  notificationCount: 0,
  clearNotificationsModalOpen: false
};

function initNotificationPreferences(): UserNotificationPreferences {
  const methodPreferences = {} as { [key in UserNotificationDeliveryMethod]: { [_key in UserNotificationType]: boolean } };
  const typePreferences = {} as { [key in UserNotificationType]: boolean };
  Object.values(UserNotificationType).forEach(type => typePreferences[type] = false);
  Object.values(UserNotificationDeliveryMethod).forEach(method => methodPreferences[method] = typePreferences);
  return methodPreferences;
}
