import { MessagesState } from './messages.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EStatusChatRoom } from '../models/EStatusChatRoom';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import { cloneDeep } from 'lodash';

export const getMessagesState = createFeatureSelector<MessagesState>('messagesState');

export const selectEnabledChats = createSelector(
  getMessagesState,
  (state: MessagesState) => {
    return state.enabledChats.filter(chats => chats.status !== EStatusChatRoom.closed);
  }
);

export const selectHeaderChats = createSelector(
  getMessagesState,
  (state: MessagesState) => state.headerChats
);

export const selectHeaderChatsFiltered = createSelector(
  getMessagesState,
  (state: MessagesState) => state.headerChatsFiltered
);

export const selectMessageBeingSend = createSelector(
  getMessagesState,
  (state: MessagesState) => state.messageBeingSend
);

export const selectchatMessageBeingSend = createSelector(
  getMessagesState,
  (state: MessagesState) => state.chatMessageBeingSend
);

export const selectSuppliers = createSelector(
  getMessagesState,
  (state: MessagesState) => state.suppliers
);

export const selectCustomers = createSelector(
  getMessagesState,
  (state: MessagesState) => state.customers
);

export const selectUserFullName = createSelector(
  getMessagesState,
  (state: MessagesState) => state.userFullName
);

export const selectUserEmail = createSelector(
  getMessagesState,
  (state: MessagesState) => state.userEmail
);

export const selectUnreadCount = createSelector(
  getMessagesState,
  (state: MessagesState) => state.unreadCount
);

export const selectNewChatOpen = createSelector(
  getMessagesState,
  (state: MessagesState) => state.newChatOpen
);

export const selectEarliestShowStartDate = createSelector(
  ShowSelectors.selectSortedEligibleShows,
  (shows) => {
    if (!shows?.length) return null;

    const clonedShows = cloneDeep(shows);

    const sortedShows = clonedShows?.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    const earliestShowStartDate = new Date(sortedShows[0].startDate);
    earliestShowStartDate.setHours(-5);
    const earliestShowStartDateString = earliestShowStartDate.toISOString();

    return earliestShowStartDateString;
  }
)
