import { HelpModule } from './../help/help.module';
import { ReportingEffects } from './store/reporting.effects';
import { EffectsModule } from '@ngrx/effects';
import { reportingFeatureKey, reducer as reportingReducer } from './store/reporting.reducer';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingPageComponent } from './pages/reporting-page/reporting-page.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { LabelModule } from '@progress/kendo-angular-label';

export const ssrsReportsRoute = 'reporting';
@NgModule({
  declarations: [
    ReportingPageComponent,
  ],
  imports: [
    CommonModule,
    HelpModule,
    StoreModule.forFeature(
      reportingFeatureKey,
      reportingReducer
    ),
    EffectsModule.forFeature([ReportingEffects]),
    PhoenixAlertsModule,
    PhoenixButtonModule,
    DropDownListModule,
    LabelModule
  ]
})
export class ReportingModule { }
