import { ListTabs } from '../../../../product-list/enum/product-list.enum';
import { ProductListFilter } from './../../../../product-list/models/product-list-filter';
import { ProductService } from './../../../../product-list/services/product.service';
import { DataExchangeService } from '../../../services/data-exchange.service';
import { CreateSubscriptionFilterComponent } from './../../../models/create-subscription-filter-component';
import { select, Store } from '@ngrx/store';
import { SubscriptionFilterChangedEvent } from './../../../models/subscription-filter-changed-event';
import { Supplier } from './../../../models/supplier';
import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { IDropdownOption } from '@app/models/idropdown-option';
import { ValuationMethod } from '../../../models/enum/valuation-method';
import { Brand } from '../../../../../modules/product-detail/models/brand';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { selectRetailerAreaNames, selectSupplierOptions } from '../../../store/data-exchange.selectors';
import { map, takeUntil } from 'rxjs/operators';
import { SubscriptionFilter } from '../../../models/subscription-filter';
import { ProductListItem } from '../../../../../modules/product-list/models/product-list-item';
import { RetailerArea } from '../../../models/retailer-area';
import { SubscriptionFilterKey } from '../../../models/enum/subscription-filter-key';

export const valuationMethods: IDropdownOption[] = [
  {label: 'Quantity (in eaches)', value: ValuationMethod.QuantityInEaches},
  {label: 'Vendor Cost', value: ValuationMethod.VendorCost},
  {label: 'List Wholesale', value: ValuationMethod.ListWholesale},
];

@Component({
  selector: 'app-chains-stores-item-filter',
  templateUrl: './chains-stores-item-filter.component.html',
  styleUrls: []
})
export class ChainsStoresItemFilterComponent extends DestroyableDirective  implements OnInit, CreateSubscriptionFilterComponent {

  valuationMethods: IDropdownOption[] = valuationMethods;

  supplierOptions$: Observable<Supplier[]>;
  brandOptions: Brand[] = [];
  productOptions: ProductListItem[] = [];
  retailerAreaNameOptions: RetailerArea[] = [];

  selectedSupplier: Supplier = null;
  selectedBrands: Brand[] = [];
  selectedProductList: ProductListItem[] = [];
  selectedValuationMethod: IDropdownOption = this.valuationMethods[0];
  selectedRetailerAreaList: RetailerArea[] = [];

  isBrandsLoading = false;
  isProductsLoading = false;

  @Output()
  filterChanged = new EventEmitter<SubscriptionFilterChangedEvent>();

  constructor(
    private _store: Store,
    private dataExchangeService: DataExchangeService,
    private _productService: ProductService,
    private ref: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.supplierOptions$ = this._store.pipe(select(selectSupplierOptions));
    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectRetailerAreaNames))
    .subscribe(result => this.retailerAreaNameOptions = result);
  }

  reset() {
    this.selectedSupplier = null;
    this.selectedBrands = [];
    this.publishFilterChangedEvent();
  }

  isFormValid(): boolean {
    return !!this.selectedSupplier &&
      !!this.selectedBrands &&
      this.selectedBrands.length > 0 &&
      this.selectedProductList.length > 0 &&
      !!this.selectedValuationMethod &&
      this.selectedRetailerAreaList.length > 0;
  }

  getFilters(): SubscriptionFilter[] {
    return [
      { key: SubscriptionFilterKey.BrandCode, value: this.selectedBrands.map(brand => brand.code) },
      { key: SubscriptionFilterKey.RetailerArea, value: this.selectedRetailerAreaList.map(ra => ra.id.toString()) },
      { key: SubscriptionFilterKey.Upc, value: this.selectedProductList.map(p => p.upc) },
      { key: SubscriptionFilterKey.ValuationMethod, value: [this.selectedValuationMethod.value] }
    ];
  }

  publishFilterChangedEvent(): void {
    const formValid = this.isFormValid();

    this.filterChanged.emit({
      filtersValid: formValid,
      selectedSupplier: this.selectedSupplier,
      filters: formValid ? this.getFilters() : [],
    });
  }

  onValuationMethodChanged() {
    this.publishFilterChangedEvent();
  }

  onSupplierSelected() {
    this.updateBrand();
    this.updateProduct();
    this.publishFilterChangedEvent();
  }

  brandEnabled() {
    return !!this.selectedSupplier;
  }

  productEnabled() {
    return !!this.brandEnabled() && !!this.selectedBrands && this.selectedBrands.length > 0;
  }

  updateBrand() {
    this.selectedBrands = [];

    if (this.brandEnabled()) {
      this.isBrandsLoading = true;
      this.dataExchangeService.getBrandsBySupplier(
        this.selectedSupplier.esn
      ).pipe(
        takeUntil(this.destroy$),
        map(brands => this.mapBrandsResponse(brands))
      ).subscribe(brands => {
        this.isBrandsLoading = false;
        this.brandOptions = brands;
        this.ref.markForCheck();
      },
      (error) => {
        this.isBrandsLoading = false;
      });
    }
  }

  updateProduct() {
    this.selectedProductList = [];

    if (this.productEnabled()) {
      this.isProductsLoading = true;
      this.dataExchangeService.getProductsBySupplier(
        this.selectedSupplier.esn
      ).pipe(
        takeUntil(this.destroy$)
      ).subscribe(products => {
        this.isProductsLoading = false;
        const options = products;
        this.productOptions = options;
        this.ref.markForCheck();
      },
      (error) => {
        this.isProductsLoading = false;
      });
    }
  }

  onBrandSelected() {
    this.updateProduct();
    this.publishFilterChangedEvent();
  }

  onProductSelected() {
    this.publishFilterChangedEvent();
  }

  onChainGroupSelected() {
    this.publishFilterChangedEvent();
  }

  selectAllBrands(): void {
    if (!this.brandEnabled() || this.selectedBrands.length === this.brandOptions.length) { return; }
    this.selectedBrands = this.brandOptions;
    this.onBrandSelected();
  }

  selectAllProducts(): void {
    if (!this.productEnabled() || this.selectedProductList.length === this.productOptions.length) { return; }
    this.selectedProductList = this.productOptions;
    this.onProductSelected();
  }

  selectAllRetailerAreas(): void {
    if (this.selectedRetailerAreaList.length === this.retailerAreaNameOptions.length) { return; }
    this.selectedRetailerAreaList = this.retailerAreaNameOptions;
    this.onChainGroupSelected();
  }

  getProductListFilter(): ProductListFilter {
    const filter = new ProductListFilter();
    filter.pageCount = 750;
    filter.brands = this.selectedBrands.map(brand => ({
      value: brand.name,
      code: brand.code
    }));
    filter.selectedTab = { text: ListTabs.active};
    filter.suppliers = [{ value: this.selectedSupplier.name, code: this.selectedSupplier.esn }];
    return filter;
  }

  tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  private mapBrandsResponse(brands: any[]): Brand[] {
    return brands.map(brand => {
      return <Brand>{ name: brand.name, code: brand.code };
    });
  }

}
