import { AlertTypes } from '@kehe/phoenix-notifications';
import { AlertMessage } from './../../../models/alert-message';
import { reportMappings, ReportMapping, ReportName } from './../models/enum/report-name.enum';
import { ReportParameters } from './../models/report-parameters';
import { selectReport, selectDefault, loadUserGuidSuccess, loadUserGuidFailure, alertClosed } from './reporting.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { Report } from './../models/report';

export interface ReportingState {
  reportOptions: ReportMapping[];
  selectedReport: Report;
  reportsVisible: boolean;
  reportParameters: ReportParameters;
  alertMessage: AlertMessage;
}

export const initializeState = (): ReportingState => {


  return {
    reportOptions: reportMappings,
    selectedReport: null,
    reportsVisible: false,
    reportParameters: {
      Requestor: ''
    },
    alertMessage: new AlertMessage()
  };
};

export const initialState = initializeState();

const reportingReducer = createReducer(
  initialState,
  on(selectReport, (state, params) => ({
    ...state,
    selectedReport: params.selectedReport,
    reportsVisible: true,
    alertMessage: getInfoNotice(params.selectedReport.type)
  })),
  on(alertClosed, (state) => ({
    ...state,
    alertMessage: new AlertMessage()
  })),
  on(selectDefault, (state, params) => ({
    ...state,
    reportsVisible: false,
    alertMessage: new AlertMessage()
  })),
  on(loadUserGuidSuccess, (state, params) => ({
    ...state,
    reportParameters: {
      ...state.reportParameters,
      Requestor: params.userGuid
    }
  })),
  on(loadUserGuidFailure, (state, params) => {
    console.log(`Error loading user GUID: ${params.message}`);
    return state;
  })
);

export function reducer(state: ReportingState | undefined, action: Action) {
  return reportingReducer(state, action);
}

export const reportingFeatureKey = 'reporting';

export function getInfoNotice(report: ReportName): AlertMessage {
  let refreshTimeMessage = 'Data is refreshed daily by 9:00AM CST.';
  const blankMessage = 'If the report is blank please try back later.';

  const reportsToShowBlankMessage = [
    ReportName.ChainsAndStoresThatOrderedAnItem,
    ReportName.InventoryStockStatus,
    ReportName.InventoryAtRisk
  ];
  if (ReportName.InventoryStockStatus === report) {
    refreshTimeMessage = 'Data is refreshed daily by 7:30AM CST.';
  }

  if (ReportName.ChainsAndStoresThatOrderedAnItem === report) {
    refreshTimeMessage = 'Data is refreshed daily by 8:30AM CST.';
  }

  if (ReportName.GapVoidSales === report) {
    return {
      type: AlertTypes.Information,
      message: 'Data is refreshed weekly on Mondays.'
    };
  }

  if (reportsToShowBlankMessage.includes(report)) {
    return {
      type: AlertTypes.Information,
      message: `${refreshTimeMessage} ${blankMessage}`
    };
  } else {
    return {
      type: AlertTypes.Information,
      message: `${refreshTimeMessage}`
    };
  }

}
