const listChatRoomsByCustomerNumberAndSupplierNumberQuery = (customerNumber: string, supplierNumber: string): string => {
    const filter = `{ and: [
      {customerNumber: { eq: "${customerNumber}" }},
      {supplierNumber: { eq: "${supplierNumber}" }},
    ]}`;

    return `query ListChatRooms {
      listChatRooms(filter: ${filter}, limit: 10) {
        items {
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          updatedAt
          createdAt
          messages {
            items {
              id
              senderID
              senderName
              createdAt
              content
							clientType
              userName
              userEmail
            }
          }
        }
      }
    }`;
};

export default listChatRoomsByCustomerNumberAndSupplierNumberQuery;
