/* eslint-disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { generateClient, GraphQLResult } from 'aws-amplify/api';
import { graphqlOperation } from "@aws-amplify/api-graphql";

const client = generateClient();

export type CreateChatRoomInput = {
  id?: string | null;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  lastMessageID?: string | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
};

export type ModelChatRoomConditionInput = {
  customerName?: ModelStringInput | null;
  customerNumber?: ModelStringInput | null;
  supplierName?: ModelStringInput | null;
  supplierNumber?: ModelStringInput | null;
  customerSupplierNumber?: ModelStringInput | null;
  lastMessageID?: ModelIDInput | null;
  updatedAt?: ModelStringInput | null;
  clientNumberUnread?: ModelStringInput | null;
  and?: Array<ModelChatRoomConditionInput | null> | null;
  or?: Array<ModelChatRoomConditionInput | null> | null;
  not?: ModelChatRoomConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ChatRoom = {
  __typename: "ChatRoom";
  id?: string;
  customerName?: string;
  customerNumber?: string;
  supplierName?: string;
  supplierNumber?: string;
  customerSupplierNumber?: string;
  messages?: ModelMessageConnection;
  lastMessageID?: string | null;
  lastMessage?: Message;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt?: string;
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection";
  items?: Array<Message | null> | null;
  nextToken?: string | null;
};

export type Message = {
  __typename?: "Message";
  id?: string;
  createdAt?: string | null;
  localID?: string | null;
  content?: string;
  senderID?: string;
  senderName?: string;
  userName?: string;
  userEmail?: string;
  chatRoomID?: string;
  clientType?: string;
  chatRoom?: ChatRoom;
  updatedAt?: string;
};

export type UpdateChatRoomInput = {
  id: string;
  customerName?: string | null;
  customerNumber?: string | null;
  supplierName?: string | null;
  supplierNumber?: string | null;
  customerSupplierNumber?: string | null;
  lastMessageID?: string | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
};

export type DeleteChatRoomInput = {
  id: string;
};

export type CreateMessageInput = {
  id?: string | null;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
};

export type ModelMessageConditionInput = {
  createdAt?: ModelStringInput | null;
  localID?: ModelStringInput | null;
  content?: ModelStringInput | null;
  senderID?: ModelStringInput | null;
  senderName?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  userEmail?: ModelStringInput | null;
  chatRoomID?: ModelIDInput | null;
  clientType?: ModelStringInput | null;
  and?: Array<ModelMessageConditionInput | null> | null;
  or?: Array<ModelMessageConditionInput | null> | null;
  not?: ModelMessageConditionInput | null;
};

export type UpdateMessageInput = {
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content?: string | null;
  senderID?: string | null;
  senderName?: string | null;
  userName?: string | null;
  userEmail?: string | null;
  chatRoomID?: string | null;
  clientType?: string | null;
};

export type DeleteMessageInput = {
  id: string;
};

export type CreateCustomerUserChatNotificationInput = {
  id?: string | null;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
};

export type ModelCustomerUserChatNotificationConditionInput = {
  userEmail?: ModelStringInput | null;
  customerNumber?: ModelStringInput | null;
  chatRoomsUnread?: ModelStringInput | null;
  lastChatRoomID?: ModelIDInput | null;
  unreadMessagesCount?: ModelIntInput | null;
  and?: Array<ModelCustomerUserChatNotificationConditionInput | null> | null;
  or?: Array<ModelCustomerUserChatNotificationConditionInput | null> | null;
  not?: ModelCustomerUserChatNotificationConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type CustomerUserChatNotification = {
  __typename: "CustomerUserChatNotification";
  id?: string;
  userEmail?: string;
  customerNumber?: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: ChatRoom;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateCustomerUserChatNotificationInput = {
  id: string;
  userEmail?: string | null;
  customerNumber?: string | null;
  chatRoomsUnread?: Array<string | null> | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
};

export type DeleteCustomerUserChatNotificationInput = {
  id: string;
};

export type CreateSupplierUserChatNotificationInput = {
  id?: string | null;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
};

export type ModelSupplierUserChatNotificationConditionInput = {
  userEmail?: ModelStringInput | null;
  esns?: ModelStringInput | null;
  chatRoomsUnread?: ModelStringInput | null;
  lastChatRoomID?: ModelIDInput | null;
  unreadMessagesCount?: ModelIntInput | null;
  and?: Array<ModelSupplierUserChatNotificationConditionInput | null> | null;
  or?: Array<ModelSupplierUserChatNotificationConditionInput | null> | null;
  not?: ModelSupplierUserChatNotificationConditionInput | null;
};

export type SupplierUserChatNotification = {
  __typename: "SupplierUserChatNotification";
  id?: string;
  userEmail?: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: ChatRoom;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateSupplierUserChatNotificationInput = {
  id: string;
  userEmail?: string | null;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
};

export type DeleteSupplierUserChatNotificationInput = {
  id: string;
};

export type ModelChatRoomFilterInput = {
  id?: ModelIDInput | null;
  customerName?: ModelStringInput | null;
  customerNumber?: ModelStringInput | null;
  supplierName?: ModelStringInput | null;
  supplierNumber?: ModelStringInput | null;
  customerSupplierNumber?: ModelStringInput | null;
  lastMessageID?: ModelIDInput | null;
  updatedAt?: ModelStringInput | null;
  clientNumberUnread?: ModelStringInput | null;
  and?: Array<ModelChatRoomFilterInput | null> | null;
  or?: Array<ModelChatRoomFilterInput | null> | null;
  not?: ModelChatRoomFilterInput | null;
};

export type ModelChatRoomConnection = {
  __typename: "ModelChatRoomConnection";
  items?: Array<ChatRoom | null> | null;
  nextToken?: string | null;
  scannedCount?: number | null;
  count?: number | null;
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  localID?: ModelStringInput | null;
  content?: ModelStringInput | null;
  senderID?: ModelStringInput | null;
  senderName?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  userEmail?: ModelStringInput | null;
  chatRoomID?: ModelIDInput | null;
  clientType?: ModelStringInput | null;
  and?: Array<ModelMessageFilterInput | null> | null;
  or?: Array<ModelMessageFilterInput | null> | null;
  not?: ModelMessageFilterInput | null;
};

export type ModelCustomerUserChatNotificationFilterInput = {
  id?: ModelIDInput | null;
  userEmail?: ModelStringInput | null;
  customerNumber?: ModelStringInput | null;
  chatRoomsUnread?: ModelStringInput | null;
  lastChatRoomID?: ModelIDInput | null;
  unreadMessagesCount?: ModelIntInput | null;
  and?: Array<ModelCustomerUserChatNotificationFilterInput | null> | null;
  or?: Array<ModelCustomerUserChatNotificationFilterInput | null> | null;
  not?: ModelCustomerUserChatNotificationFilterInput | null;
};

export type ModelCustomerUserChatNotificationConnection = {
  __typename: "ModelCustomerUserChatNotificationConnection";
  items?: Array<CustomerUserChatNotification | null> | null;
  nextToken?: string | null;
};

export type ModelSupplierUserChatNotificationFilterInput = {
  id?: ModelIDInput | null;
  userEmail?: ModelStringInput | null;
  esns?: ModelStringInput | null;
  chatRoomsUnread?: ModelStringInput | null;
  lastChatRoomID?: ModelIDInput | null;
  unreadMessagesCount?: ModelIntInput | null;
  and?: Array<ModelSupplierUserChatNotificationFilterInput | null> | null;
  or?: Array<ModelSupplierUserChatNotificationFilterInput | null> | null;
  not?: ModelSupplierUserChatNotificationFilterInput | null;
};

export type ModelSupplierUserChatNotificationConnection = {
  __typename: "ModelSupplierUserChatNotificationConnection";
  items?: Array<SupplierUserChatNotification | null> | null;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type CreateChatRoomMutation = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type UpdateChatRoomMutation = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type DeleteChatRoomMutation = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type CreateMessageMutation = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type UpdateMessageMutation = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type DeleteMessageMutation = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type CreateCustomerUserChatNotificationMutation = {
  __typename: "CustomerUserChatNotification";
  id: string;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomerUserChatNotificationMutation = {
  __typename: "CustomerUserChatNotification";
  id: string;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCustomerUserChatNotificationMutation = {
  __typename: "CustomerUserChatNotification";
  id: string;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSupplierUserChatNotificationMutation = {
  __typename: "SupplierUserChatNotification";
  id: string;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSupplierUserChatNotificationMutation = {
  __typename: "SupplierUserChatNotification";
  id: string;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSupplierUserChatNotificationMutation = {
  __typename: "SupplierUserChatNotification";
  id: string;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type GetChatRoomQuery = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type ListChatRoomsQuery = {
  __typename: "ModelChatRoomConnection";
  items?: Array<{
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null> | null;
  nextToken?: string | null;
  scannedCount?: number | null;
  count?: number | null;
};

export type GetMessageQuery = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type ListMessagesQuery = {
  __typename: "ModelMessageConnection";
  items?: Array<{
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetCustomerUserChatNotificationQuery = {
  __typename: "CustomerUserChatNotification";
  id: string;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCustomerUserChatNotificationsQuery = {
  __typename: "ModelCustomerUserChatNotificationConnection";
  items?: Array<{
    __typename: "CustomerUserChatNotification";
    id: string;
    userEmail: string;
    customerNumber: string;
    chatRoomsUnread?: Array<string | null> | null;
    latestChatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    lastChatRoomID?: string | null;
    unreadMessagesCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetSupplierUserChatNotificationQuery = {
  __typename: "SupplierUserChatNotification";
  id: string;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSupplierUserChatNotificationsQuery = {
  __typename: "ModelSupplierUserChatNotificationConnection";
  items?: Array<{
    __typename: "SupplierUserChatNotification";
    id: string;
    userEmail: string;
    esns?: Array<string | null> | null;
    chatRoomsUnread?: Array<string | null> | null;
    latestChatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    lastChatRoomID?: string | null;
    unreadMessagesCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ChatRoomByCustomerNumberQuery = {
  __typename: "ModelChatRoomConnection";
  items?: Array<{
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null> | null;
  nextToken?: string | null;
  scannedCount?: number | null;
  count?: number | null;
};

export type ChatRoomBySupplierNumberQuery = {
  __typename: "ModelChatRoomConnection";
  items?: Array<{
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null> | null;
  nextToken?: string | null;
  scannedCount?: number | null;
  count?: number | null;
};

export type ChatRoomByCustomerSupplierNumberQuery = {
  __typename: "ModelChatRoomConnection";
  items?: Array<{
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null> | null;
  nextToken?: string | null;
  scannedCount?: number | null;
  count?: number | null;
};

export type ChatRoomByClientNumberUnreadQuery = {
  __typename: "ModelChatRoomConnection";
  items?: Array<{
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null> | null;
  nextToken?: string | null;
  scannedCount?: number | null;
  count?: number | null;
};

export type MessagesByChatRoomQuery = {
  __typename: "ModelMessageConnection";
  items?: Array<{
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type CustomerChatNotificationsByUserEmailQuery = {
  __typename: "ModelCustomerUserChatNotificationConnection";
  items?: Array<{
    __typename: "CustomerUserChatNotification";
    id: string;
    userEmail: string;
    customerNumber: string;
    chatRoomsUnread?: Array<string | null> | null;
    latestChatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    lastChatRoomID?: string | null;
    unreadMessagesCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type SupplierChatNotificationsByUserEmailQuery = {
  __typename: "ModelSupplierUserChatNotificationConnection";
  items?: Array<{
    __typename: "SupplierUserChatNotification";
    id: string;
    userEmail: string;
    esns?: Array<string | null> | null;
    chatRoomsUnread?: Array<string | null> | null;
    latestChatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    lastChatRoomID?: string | null;
    unreadMessagesCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateMessageByChatRoomIdSubscription = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type OnUpdateChatRoomByCustomerSupplierNumberSubscription = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type OnCreateChatRoomByCustomerSupplierNumberSubscription = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type OnUpdateChatRoomByCustomerNumberSubscription = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type OnUpdateChatRoomBySupplierNumberSubscription = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type OnCreateChatRoomSubscription = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type OnUpdateChatRoomSubscription = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type OnDeleteChatRoomSubscription = {
  __typename: "ChatRoom";
  id: string;
  customerName: string;
  customerNumber: string;
  supplierName: string;
  supplierNumber: string;
  customerSupplierNumber: string;
  messages?: {
    __typename: "ModelMessageConnection";
    items?: Array<{
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  lastMessageID?: string | null;
  lastMessage?: {
    __typename: "Message";
    id: string;
    createdAt?: string | null;
    localID?: string | null;
    content: string;
    senderID: string;
    senderName: string;
    userName: string;
    userEmail: string;
    chatRoomID: string;
    clientType: string;
    chatRoom?: {
      __typename: "ChatRoom";
      id: string;
      customerName: string;
      customerNumber: string;
      supplierName: string;
      supplierNumber: string;
      customerSupplierNumber: string;
      lastMessageID?: string | null;
      updatedAt?: string | null;
      clientNumberUnread?: string | null;
      createdAt: string;
    } | null;
    updatedAt: string;
  } | null;
  updatedAt?: string | null;
  clientNumberUnread?: string | null;
  createdAt: string;
};

export type OnCreateMessageSubscription = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type OnUpdateMessageSubscription = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type OnDeleteMessageSubscription = {
  __typename: "Message";
  id: string;
  createdAt?: string | null;
  localID?: string | null;
  content: string;
  senderID: string;
  senderName: string;
  userName: string;
  userEmail: string;
  chatRoomID: string;
  clientType: string;
  chatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  updatedAt: string;
};

export type OnCreateCustomerUserChatNotificationSubscription = {
  __typename: "CustomerUserChatNotification";
  id: string;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCustomerUserChatNotificationSubscription = {
  __typename: "CustomerUserChatNotification";
  id: string;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCustomerUserChatNotificationSubscription = {
  __typename: "CustomerUserChatNotification";
  id: string;
  userEmail: string;
  customerNumber: string;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSupplierUserChatNotificationSubscription = {
  __typename: "SupplierUserChatNotification";
  id: string;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSupplierUserChatNotificationSubscription = {
  __typename: "SupplierUserChatNotification";
  id: string;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSupplierUserChatNotificationSubscription = {
  __typename: "SupplierUserChatNotification";
  id: string;
  userEmail: string;
  esns?: Array<string | null> | null;
  chatRoomsUnread?: Array<string | null> | null;
  latestChatRoom?: {
    __typename: "ChatRoom";
    id: string;
    customerName: string;
    customerNumber: string;
    supplierName: string;
    supplierNumber: string;
    customerSupplierNumber: string;
    messages?: {
      __typename: "ModelMessageConnection";
      nextToken?: string | null;
    } | null;
    lastMessageID?: string | null;
    lastMessage?: {
      __typename: "Message";
      id: string;
      createdAt?: string | null;
      localID?: string | null;
      content: string;
      senderID: string;
      senderName: string;
      userName: string;
      userEmail: string;
      chatRoomID: string;
      clientType: string;
      updatedAt: string;
    } | null;
    updatedAt?: string | null;
    clientNumberUnread?: string | null;
    createdAt: string;
  } | null;
  lastChatRoomID?: string | null;
  unreadMessagesCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateChatRoom(
    input: CreateChatRoomInput,
    condition?: ModelChatRoomConditionInput
  ): Promise<CreateChatRoomMutation> {
    const statement = `mutation CreateChatRoom($input: CreateChatRoomInput!, $condition: ModelChatRoomConditionInput) {
        createChatRoom(input: $input, condition: $condition) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateChatRoomMutation>response.data.createChatRoom;
  }
  async UpdateChatRoom(
    input: UpdateChatRoomInput,
    condition?: ModelChatRoomConditionInput
  ): Promise<UpdateChatRoomMutation> {
    const statement = `mutation UpdateChatRoom($input: UpdateChatRoomInput!, $condition: ModelChatRoomConditionInput) {
        updateChatRoom(input: $input, condition: $condition) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateChatRoomMutation>response.data.updateChatRoom;
  }
  async DeleteChatRoom(
    input: DeleteChatRoomInput,
    condition?: ModelChatRoomConditionInput
  ): Promise<DeleteChatRoomMutation> {
    const statement = `mutation DeleteChatRoom($input: DeleteChatRoomInput!, $condition: ModelChatRoomConditionInput) {
        deleteChatRoom(input: $input, condition: $condition) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteChatRoomMutation>response.data.deleteChatRoom;
  }
  async CreateMessage(
    input: CreateMessageInput,
    condition?: ModelMessageConditionInput
  ): Promise<CreateMessageMutation> {
    const statement = `mutation CreateMessage($input: CreateMessageInput!, $condition: ModelMessageConditionInput) {
        createMessage(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMessageMutation>response.data.createMessage;
  }
  async UpdateMessage(
    input: UpdateMessageInput,
    condition?: ModelMessageConditionInput
  ): Promise<UpdateMessageMutation> {
    const statement = `mutation UpdateMessage($input: UpdateMessageInput!, $condition: ModelMessageConditionInput) {
        updateMessage(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMessageMutation>response.data.updateMessage;
  }
  async DeleteMessage(
    input: DeleteMessageInput,
    condition?: ModelMessageConditionInput
  ): Promise<DeleteMessageMutation> {
    const statement = `mutation DeleteMessage($input: DeleteMessageInput!, $condition: ModelMessageConditionInput) {
        deleteMessage(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMessageMutation>response.data.deleteMessage;
  }
  async CreateCustomerUserChatNotification(
    input: CreateCustomerUserChatNotificationInput,
    condition?: ModelCustomerUserChatNotificationConditionInput
  ): Promise<CreateCustomerUserChatNotificationMutation> {
    const statement = `mutation CreateCustomerUserChatNotification($input: CreateCustomerUserChatNotificationInput!, $condition: ModelCustomerUserChatNotificationConditionInput) {
        createCustomerUserChatNotification(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          customerNumber
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerUserChatNotificationMutation>(
      response.data.createCustomerUserChatNotification
    );
  }
  async UpdateCustomerUserChatNotification(
    input: UpdateCustomerUserChatNotificationInput,
    condition?: ModelCustomerUserChatNotificationConditionInput
  ): Promise<UpdateCustomerUserChatNotificationMutation> {
    const statement = `mutation UpdateCustomerUserChatNotification($input: UpdateCustomerUserChatNotificationInput!, $condition: ModelCustomerUserChatNotificationConditionInput) {
        updateCustomerUserChatNotification(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          customerNumber
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerUserChatNotificationMutation>(
      response.data.updateCustomerUserChatNotification
    );
  }
  async DeleteCustomerUserChatNotification(
    input: DeleteCustomerUserChatNotificationInput,
    condition?: ModelCustomerUserChatNotificationConditionInput
  ): Promise<DeleteCustomerUserChatNotificationMutation> {
    const statement = `mutation DeleteCustomerUserChatNotification($input: DeleteCustomerUserChatNotificationInput!, $condition: ModelCustomerUserChatNotificationConditionInput) {
        deleteCustomerUserChatNotification(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          customerNumber
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerUserChatNotificationMutation>(
      response.data.deleteCustomerUserChatNotification
    );
  }
  async CreateSupplierUserChatNotification(
    input: CreateSupplierUserChatNotificationInput,
    condition?: ModelSupplierUserChatNotificationConditionInput
  ): Promise<CreateSupplierUserChatNotificationMutation> {
    const statement = `mutation CreateSupplierUserChatNotification($input: CreateSupplierUserChatNotificationInput!, $condition: ModelSupplierUserChatNotificationConditionInput) {
        createSupplierUserChatNotification(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          esns
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSupplierUserChatNotificationMutation>(
      response.data.createSupplierUserChatNotification
    );
  }
  async UpdateSupplierUserChatNotification(
    input: UpdateSupplierUserChatNotificationInput,
    condition?: ModelSupplierUserChatNotificationConditionInput
  ): Promise<UpdateSupplierUserChatNotificationMutation> {
    const statement = `mutation UpdateSupplierUserChatNotification($input: UpdateSupplierUserChatNotificationInput!, $condition: ModelSupplierUserChatNotificationConditionInput) {
        updateSupplierUserChatNotification(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          esns
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSupplierUserChatNotificationMutation>(
      response.data.updateSupplierUserChatNotification
    );
  }
  async DeleteSupplierUserChatNotification(
    input: DeleteSupplierUserChatNotificationInput,
    condition?: ModelSupplierUserChatNotificationConditionInput
  ): Promise<DeleteSupplierUserChatNotificationMutation> {
    const statement = `mutation DeleteSupplierUserChatNotification($input: DeleteSupplierUserChatNotificationInput!, $condition: ModelSupplierUserChatNotificationConditionInput) {
        deleteSupplierUserChatNotification(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          esns
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSupplierUserChatNotificationMutation>(
      response.data.deleteSupplierUserChatNotification
    );
  }
  async GetChatRoom(id: string): Promise<GetChatRoomQuery> {
    const statement = `query GetChatRoom($id: ID!) {
        getChatRoom(id: $id) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetChatRoomQuery>response.data.getChatRoom;
  }
  async ListChatRooms(
    filter?: ModelChatRoomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListChatRoomsQuery> {
    const statement = `query ListChatRooms($filter: ModelChatRoomFilterInput, $limit: Int, $nextToken: String) {
        listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          nextToken
          scannedCount
          count
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChatRoomsQuery>response.data.listChatRooms;
  }
  async GetMessage(id: string): Promise<GetMessageQuery> {
    const statement = `query GetMessage($id: ID!) {
        getMessage(id: $id) {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMessageQuery>response.data.getMessage;
  }
  async ListMessages(
    filter?: ModelMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMessagesQuery> {
    const statement = `query ListMessages($filter: ModelMessageFilterInput, $limit: Int, $nextToken: String) {
        listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMessagesQuery>response.data.listMessages;
  }
  async GetCustomerUserChatNotification(
    id: string
  ): Promise<GetCustomerUserChatNotificationQuery> {
    const statement = `query GetCustomerUserChatNotification($id: ID!) {
        getCustomerUserChatNotification(id: $id) {
          __typename
          id
          userEmail
          customerNumber
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerUserChatNotificationQuery>(
      response.data.getCustomerUserChatNotification
    );
  }
  async ListCustomerUserChatNotifications(
    filter?: ModelCustomerUserChatNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomerUserChatNotificationsQuery> {
    const statement = `query ListCustomerUserChatNotifications($filter: ModelCustomerUserChatNotificationFilterInput, $limit: Int, $nextToken: String) {
        listCustomerUserChatNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userEmail
            customerNumber
            chatRoomsUnread
            latestChatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            lastChatRoomID
            unreadMessagesCount
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerUserChatNotificationsQuery>(
      response.data.listCustomerUserChatNotifications
    );
  }
  async GetSupplierUserChatNotification(
    id: string
  ): Promise<GetSupplierUserChatNotificationQuery> {
    const statement = `query GetSupplierUserChatNotification($id: ID!) {
        getSupplierUserChatNotification(id: $id) {
          __typename
          id
          userEmail
          esns
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSupplierUserChatNotificationQuery>(
      response.data.getSupplierUserChatNotification
    );
  }
  async ListSupplierUserChatNotifications(
    filter?: ModelSupplierUserChatNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSupplierUserChatNotificationsQuery> {
    const statement = `query ListSupplierUserChatNotifications($filter: ModelSupplierUserChatNotificationFilterInput, $limit: Int, $nextToken: String) {
        listSupplierUserChatNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userEmail
            esns
            chatRoomsUnread
            latestChatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            lastChatRoomID
            unreadMessagesCount
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSupplierUserChatNotificationsQuery>(
      response.data.listSupplierUserChatNotifications
    );
  }
  async ChatRoomByCustomerNumber(
    customerNumber?: string,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelChatRoomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ChatRoomByCustomerNumberQuery> {
    const statement = `query ChatRoomByCustomerNumber($customerNumber: String, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelChatRoomFilterInput, $limit: Int, $nextToken: String) {
        chatRoomByCustomerNumber(customerNumber: $customerNumber, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          nextToken
          scannedCount
          count
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerNumber) {
      gqlAPIServiceArguments.customerNumber = customerNumber;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ChatRoomByCustomerNumberQuery>(
      response.data.chatRoomByCustomerNumber
    );
  }
  async ChatRoomBySupplierNumber(
    supplierNumber?: string,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelChatRoomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ChatRoomBySupplierNumberQuery> {
    const statement = `query ChatRoomBySupplierNumber($supplierNumber: String, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelChatRoomFilterInput, $limit: Int, $nextToken: String) {
        chatRoomBySupplierNumber(supplierNumber: $supplierNumber, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          nextToken
          scannedCount
          count
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (supplierNumber) {
      gqlAPIServiceArguments.supplierNumber = supplierNumber;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ChatRoomBySupplierNumberQuery>(
      response.data.chatRoomBySupplierNumber
    );
  }
  async ChatRoomByCustomerSupplierNumber(
    customerSupplierNumber?: string,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelChatRoomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ChatRoomByCustomerSupplierNumberQuery> {
    const statement = `query ChatRoomByCustomerSupplierNumber($customerSupplierNumber: String, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelChatRoomFilterInput, $limit: Int, $nextToken: String) {
        chatRoomByCustomerSupplierNumber(customerSupplierNumber: $customerSupplierNumber, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          nextToken
          scannedCount
          count
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerSupplierNumber) {
      gqlAPIServiceArguments.customerSupplierNumber = customerSupplierNumber;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ChatRoomByCustomerSupplierNumberQuery>(
      response.data.chatRoomByCustomerSupplierNumber
    );
  }
  async ChatRoomByClientNumberUnread(
    clientNumberUnread?: string,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelChatRoomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ChatRoomByClientNumberUnreadQuery> {
    const statement = `query ChatRoomByClientNumberUnread($clientNumberUnread: String, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelChatRoomFilterInput, $limit: Int, $nextToken: String) {
        chatRoomByClientNumberUnread(clientNumberUnread: $clientNumberUnread, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          nextToken
          scannedCount
          count
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientNumberUnread) {
      gqlAPIServiceArguments.clientNumberUnread = clientNumberUnread;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ChatRoomByClientNumberUnreadQuery>(
      response.data.chatRoomByClientNumberUnread
    );
  }
  async MessagesByChatRoom(
    chatRoomID?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MessagesByChatRoomQuery> {
    const statement = `query MessagesByChatRoom($chatRoomID: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelMessageFilterInput, $limit: Int, $nextToken: String) {
        messagesByChatRoom(chatRoomID: $chatRoomID, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (chatRoomID) {
      gqlAPIServiceArguments.chatRoomID = chatRoomID;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MessagesByChatRoomQuery>response.data.messagesByChatRoom;
  }
  async CustomerChatNotificationsByUserEmail(
    userEmail?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerUserChatNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomerChatNotificationsByUserEmailQuery> {
    const statement = `query CustomerChatNotificationsByUserEmail($userEmail: String, $sortDirection: ModelSortDirection, $filter: ModelCustomerUserChatNotificationFilterInput, $limit: Int, $nextToken: String) {
        customerChatNotificationsByUserEmail(userEmail: $userEmail, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userEmail
            customerNumber
            chatRoomsUnread
            latestChatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            lastChatRoomID
            unreadMessagesCount
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userEmail) {
      gqlAPIServiceArguments.userEmail = userEmail;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerChatNotificationsByUserEmailQuery>(
      response.data.customerChatNotificationsByUserEmail
    );
  }
  async SupplierChatNotificationsByUserEmail(
    userEmail?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSupplierUserChatNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SupplierChatNotificationsByUserEmailQuery> {
    const statement = `query SupplierChatNotificationsByUserEmail($userEmail: String, $sortDirection: ModelSortDirection, $filter: ModelSupplierUserChatNotificationFilterInput, $limit: Int, $nextToken: String) {
        supplierChatNotificationsByUserEmail(userEmail: $userEmail, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userEmail
            esns
            chatRoomsUnread
            latestChatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            lastChatRoomID
            unreadMessagesCount
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userEmail) {
      gqlAPIServiceArguments.userEmail = userEmail;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SupplierChatNotificationsByUserEmailQuery>(
      response.data.supplierChatNotificationsByUserEmail
    );
  }
  OnCreateMessageByChatRoomIdListener(
    chatRoomID?: string
  ): Promise<GraphQLResult<OnCreateMessageByChatRoomIdSubscription>> {
    const statement = `subscription OnCreateMessageByChatRoomId($chatRoomID: ID) {
        onCreateMessageByChatRoomId(chatRoomID: $chatRoomID) {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (chatRoomID) {
      gqlAPIServiceArguments.chatRoomID = chatRoomID;
    }
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Promise<
      GraphQLResult<OnCreateMessageByChatRoomIdSubscription>
    >;
  }

  OnUpdateChatRoomByCustomerSupplierNumberListener(
    customerSupplierNumber?: string
  ): Promise<
    GraphQLResult<OnUpdateChatRoomByCustomerSupplierNumberSubscription>
  > {
    const statement = `subscription OnUpdateChatRoomByCustomerSupplierNumber($customerSupplierNumber: String) {
        onUpdateChatRoomByCustomerSupplierNumber(customerSupplierNumber: $customerSupplierNumber) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerSupplierNumber) {
      gqlAPIServiceArguments.customerSupplierNumber = customerSupplierNumber;
    }
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Promise<
      GraphQLResult<OnUpdateChatRoomByCustomerSupplierNumberSubscription>
    >;
  }

  OnCreateChatRoomByCustomerSupplierNumberListener(
    customerSupplierNumber?: string
  ): Promise<
    GraphQLResult<OnCreateChatRoomByCustomerSupplierNumberSubscription>
  > {
    const statement = `subscription OnCreateChatRoomByCustomerSupplierNumber($customerSupplierNumber: String) {
        onCreateChatRoomByCustomerSupplierNumber(customerSupplierNumber: $customerSupplierNumber) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerSupplierNumber) {
      gqlAPIServiceArguments.customerSupplierNumber = customerSupplierNumber;
    }
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Promise<
      GraphQLResult<OnCreateChatRoomByCustomerSupplierNumberSubscription>
    >;
  }

  OnUpdateChatRoomByCustomerNumberListener(
    customerNumber?: string
  ): Promise<
    GraphQLResult<OnUpdateChatRoomByCustomerNumberSubscription>
  > {
    const statement = `subscription OnUpdateChatRoomByCustomerNumber($customerNumber: String) {
        onUpdateChatRoomByCustomerNumber(customerNumber: $customerNumber) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerNumber) {
      gqlAPIServiceArguments.customerNumber = customerNumber;
    }
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Promise<
      GraphQLResult<OnUpdateChatRoomByCustomerNumberSubscription>
    >;
  }

  OnUpdateChatRoomBySupplierNumberListener(
    supplierNumber?: string
  ): Promise<
    GraphQLResult<OnUpdateChatRoomBySupplierNumberSubscription>
  > {
    const statement = `subscription OnUpdateChatRoomBySupplierNumber($supplierNumber: String) {
        onUpdateChatRoomBySupplierNumber(supplierNumber: $supplierNumber) {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (supplierNumber) {
      gqlAPIServiceArguments.supplierNumber = supplierNumber;
    }
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Promise<
      GraphQLResult<OnUpdateChatRoomBySupplierNumberSubscription>
    >;
  }

  OnCreateChatRoomListener: Promise<
    GraphQLResult<OnCreateChatRoomSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnCreateChatRoom {
        onCreateChatRoom {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`
    )
  ) as unknown as Promise<GraphQLResult<OnCreateChatRoomSubscription>>;

  OnUpdateChatRoomListener: Promise<
    GraphQLResult<OnUpdateChatRoomSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnUpdateChatRoom {
        onUpdateChatRoom {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`
    )
  ) as unknown as Promise<GraphQLResult<OnUpdateChatRoomSubscription>>;

  OnDeleteChatRoomListener: Promise<
    GraphQLResult<OnDeleteChatRoomSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnDeleteChatRoom {
        onDeleteChatRoom {
          __typename
          id
          customerName
          customerNumber
          supplierName
          supplierNumber
          customerSupplierNumber
          messages {
            __typename
            items {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            nextToken
          }
          lastMessageID
          lastMessage {
            __typename
            id
            createdAt
            localID
            content
            senderID
            senderName
            userName
            userEmail
            chatRoomID
            clientType
            chatRoom {
              __typename
              id
              customerName
              customerNumber
              supplierName
              supplierNumber
              customerSupplierNumber
              lastMessageID
              updatedAt
              clientNumberUnread
              createdAt
            }
            updatedAt
          }
          updatedAt
          clientNumberUnread
          createdAt
        }
      }`
    )
  ) as unknown as Promise<GraphQLResult<OnDeleteChatRoomSubscription>>;

  OnCreateMessageListener: Promise<
    GraphQLResult<OnCreateMessageSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnCreateMessage {
        onCreateMessage {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<GraphQLResult<OnCreateMessageSubscription>>;

  OnUpdateMessageListener: Promise<
    GraphQLResult<OnUpdateMessageSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnUpdateMessage {
        onUpdateMessage {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<GraphQLResult<OnUpdateMessageSubscription>>;

  OnDeleteMessageListener: Promise<
    GraphQLResult<OnDeleteMessageSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnDeleteMessage {
        onDeleteMessage {
          __typename
          id
          createdAt
          localID
          content
          senderID
          senderName
          userName
          userEmail
          chatRoomID
          clientType
          chatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<GraphQLResult<OnDeleteMessageSubscription>>;

  OnCreateCustomerUserChatNotificationListener: Promise<
    GraphQLResult<OnCreateCustomerUserChatNotificationSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnCreateCustomerUserChatNotification {
        onCreateCustomerUserChatNotification {
          __typename
          id
          userEmail
          customerNumber
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<
    GraphQLResult<OnCreateCustomerUserChatNotificationSubscription>
  >;

  OnUpdateCustomerUserChatNotificationListener: Promise<
    GraphQLResult<OnUpdateCustomerUserChatNotificationSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnUpdateCustomerUserChatNotification {
        onUpdateCustomerUserChatNotification {
          __typename
          id
          userEmail
          customerNumber
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<
    GraphQLResult<OnUpdateCustomerUserChatNotificationSubscription>
  >;

  OnDeleteCustomerUserChatNotificationListener: Promise<
    GraphQLResult<OnDeleteCustomerUserChatNotificationSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnDeleteCustomerUserChatNotification {
        onDeleteCustomerUserChatNotification {
          __typename
          id
          userEmail
          customerNumber
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<
    GraphQLResult<OnDeleteCustomerUserChatNotificationSubscription>
  >;

  OnCreateSupplierUserChatNotificationListener: Promise<
    GraphQLResult<OnCreateSupplierUserChatNotificationSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnCreateSupplierUserChatNotification {
        onCreateSupplierUserChatNotification {
          __typename
          id
          userEmail
          esns
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<
    GraphQLResult<OnCreateSupplierUserChatNotificationSubscription>
  >;

  OnUpdateSupplierUserChatNotificationListener: Promise<
    GraphQLResult<OnUpdateSupplierUserChatNotificationSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnUpdateSupplierUserChatNotification {
        onUpdateSupplierUserChatNotification {
          __typename
          id
          userEmail
          esns
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<
    GraphQLResult<OnUpdateSupplierUserChatNotificationSubscription>
  >;

  OnDeleteSupplierUserChatNotificationListener: Promise<
    GraphQLResult<OnDeleteSupplierUserChatNotificationSubscription>
  > = client.graphql(
    graphqlOperation(
      `subscription OnDeleteSupplierUserChatNotification {
        onDeleteSupplierUserChatNotification {
          __typename
          id
          userEmail
          esns
          chatRoomsUnread
          latestChatRoom {
            __typename
            id
            customerName
            customerNumber
            supplierName
            supplierNumber
            customerSupplierNumber
            messages {
              __typename
              nextToken
            }
            lastMessageID
            lastMessage {
              __typename
              id
              createdAt
              localID
              content
              senderID
              senderName
              userName
              userEmail
              chatRoomID
              clientType
              updatedAt
            }
            updatedAt
            clientNumberUnread
            createdAt
          }
          lastChatRoomID
          unreadMessagesCount
          createdAt
          updatedAt
        }
      }`
    )
  ) as unknown as Promise<
    GraphQLResult<OnDeleteSupplierUserChatNotificationSubscription>
  >;
}
