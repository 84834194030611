import { IMessage } from './../models/IMessage';
import { IChatRoom } from './../models/IChatRoom';
import { IChatRoomGraphQL } from './../models/IChatRoomGraphQL';
import { Action, createReducer, on } from '@ngrx/store';
import * as MessagesActions from './messages.actions';
import {
  MessagesState,
  messagesInitialState,
} from './messages.state';
import { v4 as uuidv4 } from 'uuid';
import groupMessagesByDay from '../utils/groupMessagesByDay';
import formatDayToRender from '../utils/formatDayToRender';
import addToMessagesToRender from '../utils/addToMessagesToRender';
import { ELastActionChat } from '../models/ELastActionChat';
import { ISupplier } from '../models/ISupplier';
import { orderBy } from 'lodash';
import { ICustomer } from '../models/ICustomer';
import { EStatusChatRoom } from '../models/EStatusChatRoom';
import { filterChats } from './messages.utils';

const reducer = createReducer(
  messagesInitialState,
  on(MessagesActions.setUserFullName, (state, { userFullName }) => setUserFullName(state, userFullName)),
  on(MessagesActions.setUserEmail, (state, { userEmail }) => setUserEmail(state, userEmail)),
  on(MessagesActions.setSuppliers, (state, { suppliers }) => setSuppliers(state, suppliers)),
  on(MessagesActions.setCustomers, (state, { customers }) => setCustomers(state, customers)),

  on(MessagesActions.createNewChat, (state) => createNewChat(state)),
  on(MessagesActions.closeNewChat, (state) => closeNewChat(state)),


  on(MessagesActions.getChatRoomByCustomerNumberSuccess, (state, { chatRooms, earliestShowStartDate }) =>
    getChatRoomByCustomerNumberSuccess(state, chatRooms, earliestShowStartDate)),

  on(MessagesActions.openChat, (state, props) => openChat(state, props)),
  on(MessagesActions.openChatSuccessNew, (state, { chatRoom }) => openChatSuccessNew(state, chatRoom)),
  on(MessagesActions.getMessagesSuccess, (state, { messages, nextTokenMessages, chatRoom, userEmail }) =>
    getMessagesSuccess(state, messages, nextTokenMessages, chatRoom, userEmail)),

  on(MessagesActions.getMoreMessages, (state, { chatRoom }) =>
    getMoreMessages(state, chatRoom)),
  on(MessagesActions.getMoreMessagesSuccess, (state, { messages, nextTokenMessages, chatRoom }) =>
    getMoreMessagesSuccess(state, messages, nextTokenMessages, chatRoom)),

  on(MessagesActions.closeChat, (state, { chatId }) => closeChat(state, chatId)),

  on(MessagesActions.toggleCollapseChat, (state, { chatRoom }) => toggleCollapseChat(state, chatRoom)),

  // Fired on createChatRoom success
  on(MessagesActions.sendMessageWithChatAlreadyCreated, (state, { messageBeingSend, chatMessageBeingSend, newChatRoom }) =>
    sendMessageWithChatAlreadyCreated(state, messageBeingSend, chatMessageBeingSend, newChatRoom)),

  on(MessagesActions.receiveChatRoomUpdate, (state, { chatRooms }) =>
    receiveChatRoomUpdate(state, chatRooms)),

  on(MessagesActions.sendMessage, (state, { message, chatId, userFullName, userEmail }) =>
    sendMessage(state, message, chatId, userFullName, userEmail)),
  on(MessagesActions.sendMessageSuccess, (state) =>
    sendMessageSuccess(state)),

  on(MessagesActions.getUnreadsBySupplierNumberSuccess, (state, { unreads }) =>
    getUnreadsBySupplierNumberSuccess(state, unreads )),

  on(MessagesActions.saveDraftMessageChat, (state, { chatId, draftMessage }) =>
    saveDraftMessageChat(state, chatId, draftMessage)),

  on(MessagesActions.setFilterHeaderChatsText, (state, { text }) =>
    setFilterHeaderChatsText(state, text)
  )
);


/* Action handlers */
function setUserFullName(state: MessagesState, userFullName: string): MessagesState {
  return {
    ...state,
    userFullName
  };
}

function setUserEmail(state: MessagesState, userEmail: string): MessagesState {
  return {
    ...state,
    userEmail
  };
}


function setCustomers(state: MessagesState, customers: Array<ICustomer>): MessagesState {

  return {
    ...state,
    customers,
  };
}


function setSuppliers(state: MessagesState, suppliers: Array<ISupplier>): MessagesState {

  return {
    ...state,
    enabledChats: [],
    headerChats: [],
    headerChatsFiltered: [],
    suppliers,
    messageBeingSend: {} as IMessage,
    chatMessageBeingSend: {} as IChatRoom,
  };
}



function openChat(state: MessagesState, props): MessagesState {
  const enabledChatsClone = [ ...state.enabledChats ];
     // see if the chat was aready in enabledChatsClone but not opened
  const existingChatIndex = enabledChatsClone.findIndex(chat =>
    chat.supplierNumber === props.supplierNumber &&
    chat.customerNumber === props.customerNumber
  );

  if (existingChatIndex > -1) {
    if (enabledChatsClone[existingChatIndex].status !== EStatusChatRoom.closed) {
      return {
        ...state
      };
    }
  }

  enabledChatsClone.unshift({
    loading: true,
    status: EStatusChatRoom.opened,
    customerNumber: props.customerNumber,
    supplierNumber: props.supplierNumber,
    customerName: props.customerName,
    supplierName: props.supplierName
  });

  return {
    ...state,
    enabledChats: enabledChatsClone
  };
}

function openChatSuccessNew(state: MessagesState, chatRoom: IChatRoom): MessagesState {
  const enabledChatsClone = [ ...state.enabledChats ];
  enabledChatsClone[0] = {
    ...enabledChatsClone[0],
    ...chatRoom,
    id: uuidv4(),
    messages: [],
    loading: false
  };

  return {
    ...state,
    enabledChats: enabledChatsClone
  };
}



function getMoreMessages(state: MessagesState,
  chatRoom: IChatRoom
): MessagesState {

  const enabledChatsClone = [ ...state.enabledChats ];

  const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatRoom.id);
  if (chatIndex === -1) {
    return state;
  }

  enabledChatsClone[chatIndex].loadingMoreMessages = true;

  return {
    ...state,
    enabledChats: enabledChatsClone,
  };

}

function getMoreMessagesSuccess(state: MessagesState,
  messages: Array<IMessage>,
  nextTokenMessages: string,
  chatRoom: IChatRoom
): MessagesState {

  const enabledChatsClone = [ ...state.enabledChats ];

  const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatRoom.id);
  if (chatIndex === -1) {
    return state;
  }

  const reverseMessages: Array<IMessage> = [];

  messages.slice().reverse().forEach(message => reverseMessages.push({
    ...message,
    dayToRender: formatDayToRender(message.createdAt),
    isMeSender: message.userEmail === state.userEmail && message.clientType === 'Supplier'
  }));

  const allReverseMessages = [...reverseMessages, ...enabledChatsClone[chatIndex].messages];

  const messagesToRender = groupMessagesByDay(allReverseMessages);

  enabledChatsClone[chatIndex].messages = allReverseMessages;
  enabledChatsClone[chatIndex].messagesToRender = messagesToRender;
  enabledChatsClone[chatIndex].nextTokenMessages = nextTokenMessages;
  enabledChatsClone[chatIndex].loadingMoreMessages = false;
  enabledChatsClone[chatIndex].lastActionChat = ELastActionChat.loadMoreMessages;

  return {
    ...state,
    enabledChats: enabledChatsClone,
  };

}

function getMessagesSuccess(state: MessagesState,
                            messages: Array<IMessage>,
                            nextTokenMessages: string,
                            chatRoom: IChatRoomGraphQL,
                            userEmail: string): MessagesState {

  const enabledChatsClone = [ ...state.enabledChats ];
  const reverseMessages: Array<IMessage> = [];

  messages.slice().reverse().forEach(message => reverseMessages.push({
    ...message,
    dayToRender: formatDayToRender(message.createdAt),
    isMeSender: message.userEmail === userEmail && message.clientType === 'Supplier'
  }));

  const messagesToRender = groupMessagesByDay(reverseMessages);

  // see if the chat was aready in enabledChatsClone but not opened
  const existingChatIndex = enabledChatsClone.findIndex(chat =>
    chat.id ===  chatRoom.id
  );

  enabledChatsClone[0] = {
    ...chatRoom,
    messages: reverseMessages,
    status: EStatusChatRoom.opened,
    messagesToRender,
    nextTokenMessages,
    lastActionChat: ELastActionChat.loadMessages,
  };

  if (existingChatIndex > -1) {
    enabledChatsClone[0].draftMessage = enabledChatsClone[existingChatIndex].draftMessage;
    enabledChatsClone.splice(existingChatIndex, 1);
  }

  return {
    ...state,
    enabledChats: enabledChatsClone,

  };
}

function saveDraftMessageChat(state: MessagesState, chatId: string, draftMessage: string): MessagesState {

  const enabledChatsClone = [ ...state.enabledChats ];

  const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatId);
  if (chatIndex === -1) {
    return state;
  }

  enabledChatsClone[chatIndex].draftMessage = draftMessage;

  return {
    ...state,
    enabledChats: enabledChatsClone
  };
}

function closeChat(state: MessagesState, chatId: string): MessagesState {

  const enabledChatsClone = [ ...state.enabledChats ];

  const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatId);
  if (chatIndex === -1) {
    return state;
  }

  enabledChatsClone[chatIndex].status = EStatusChatRoom.closed;

  return {
    ...state,
    enabledChats: enabledChatsClone
  };
}

function sendMessageSuccess(state: MessagesState): MessagesState {

  return {
    ...state,
    messageBeingSend: {} as IMessage,
    chatMessageBeingSend: {} as IChatRoom
  };
}

function sendMessageWithChatAlreadyCreated(
  state: MessagesState, messageBeingSend: IMessage, chatMessageBeingSend: IChatRoom, newChatRoom: IChatRoom
): MessagesState {

  const enabledChatsClone = [ ...state.enabledChats ];

  const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatMessageBeingSend.id);
  if (chatIndex === -1) {
    return state;
  }

  enabledChatsClone[chatIndex].id = newChatRoom.id;

  return {
    ...state,
    enabledChats: enabledChatsClone,
  };
}

function receiveChatRoomUpdate(state: MessagesState, chatRooms: Array<IChatRoom>): MessagesState {
  if (chatRooms.length === 0) {
    return {...state};
  } else {
    const enabledChatsClone = [ ...state.enabledChats ];
    const headerChatsClone = [ ...state.headerChats ];
    let headerFilteredChatsClone = [ ...state.headerChatsFiltered ];

    let unreadCountClone = state.unreadCount;
    // get the ids of the unread messages
    const unreadIdsClone = [ ...state.unreadIds];
    chatRooms.forEach(chatRoom => {

      // check if this chatRoom is opened, and update with the new message
      const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatRoom.id && chat.status !== EStatusChatRoom.closed);
      if (chatIndex > -1) {
        let receivedMessage = {};
        // Check if its a clientNumberUnread update
        const messageIndex = enabledChatsClone[chatIndex].messages.findIndex(msg => msg.id === chatRoom.lastMessageID);
        if (messageIndex === -1) {
          // Check if I sent the last message
          const messageId = enabledChatsClone[chatIndex].messages.findIndex(msg => msg.localID === chatRoom.lastMessage.localID);

          if (messageId > -1) {
            receivedMessage = {
              ...enabledChatsClone[chatIndex].messages[messageId],
              sendingToServer: false,
              createdAt: chatRoom.lastMessage.createdAt,
              dayToRender: formatDayToRender(chatRoom.lastMessage.createdAt)
            };
            enabledChatsClone[chatIndex].messages[messageId] = receivedMessage;
            enabledChatsClone[chatIndex].messagesToRender = groupMessagesByDay(enabledChatsClone[chatIndex].messages);
          } else {
            receivedMessage = {
              ...chatRoom.lastMessage,
              isMeSender: chatRoom.lastMessage.userEmail === state.userEmail && chatRoom.lastMessage.clientType === 'Supplier',
              dayToRender: formatDayToRender(chatRoom.lastMessage.createdAt)
            };

            enabledChatsClone[chatIndex].messages.push(receivedMessage);
            enabledChatsClone[chatIndex].messagesToRender =
              addToMessagesToRender(enabledChatsClone[chatIndex].messagesToRender, receivedMessage);
            enabledChatsClone[chatIndex].lastActionChat = ELastActionChat.receivedMessage;
            if (enabledChatsClone[chatIndex].status === EStatusChatRoom.collapsed) {
              enabledChatsClone[chatIndex].clientNumberUnread = chatRoom.clientNumberUnread;
            }
          }
        } else {
          enabledChatsClone[chatIndex].clientNumberUnread = chatRoom.clientNumberUnread;
          if (chatRoom.clientNumberUnread !== enabledChatsClone[chatIndex].customerNumber) {
            const unreadIndex = unreadIdsClone.findIndex(unread => unread === chatRoom.id);
            if (unreadIndex > -1) {
              unreadCountClone--;
              unreadIdsClone.splice(unreadIndex, 1);
            }
          }
        }

      } else {
        // if it is a new message, check if should change the unread count
        if (chatRoom.clientNumberUnread === chatRoom.supplierNumber) {
          const unreadIndex = unreadIdsClone.findIndex(unread => unread === chatRoom.id);

          if (unreadIndex === -1) {
            unreadCountClone++;
            unreadIdsClone.push(chatRoom.id);
          }
        } else {
          const unreadIndex = unreadIdsClone.findIndex(unread => unread === chatRoom.id);
          if (unreadIndex > -1) {
            unreadCountClone--;
            unreadIdsClone.splice(unreadIndex, 1);
          }
        }
      }

      // update the header
      const headerChatIndex = headerChatsClone.findIndex(chat => chat.id === chatRoom.id);
      if (headerChatIndex > -1) {
        headerChatsClone.splice(headerChatIndex, 1);
      }

      headerChatsClone.unshift(chatRoom);

      const lowerCaseText = state.filterHeaderChatsText ? state.filterHeaderChatsText.toLowerCase() : '';
      if (lowerCaseText) {
        headerFilteredChatsClone = filterChats(headerChatsClone, lowerCaseText);
      } else {
        headerFilteredChatsClone = headerChatsClone;
      }
    });

    const separatedHeaderChatsClone = separateUnreadChats(headerChatsClone, unreadIdsClone);
    const separatedHeaderFilteredChatsClone = separateUnreadChats(headerFilteredChatsClone, unreadIdsClone);
    return {
      ...state,
      enabledChats: enabledChatsClone,
      headerChats: separatedHeaderChatsClone,
      headerChatsFiltered: separatedHeaderFilteredChatsClone,
      unreadIds: unreadIdsClone,
      unreadCount: unreadCountClone
    };
  }

}

function getChatRoomByCustomerNumberSuccess(state: MessagesState, chatRooms: Array<IChatRoom>, earliestShowStartDate: string): MessagesState {
  const filteredChatsAfterEarliestShowStartDate = chatRooms.filter(chat => new Date(chat.createdAt).getTime() >= new Date(earliestShowStartDate).getTime());
  const separatedHeaderChats = separateUnreadChats(filteredChatsAfterEarliestShowStartDate, state.unreadIds);
  return {
    ...state,
    headerChats: separatedHeaderChats,
    headerChatsFiltered: separatedHeaderChats
  };
}

function sendMessage(state: MessagesState, message: string, chatId: string, userFullName: string, userEmail: string): MessagesState {

  const enabledChatsClone = [ ...state.enabledChats ];

  const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatId);
  if (chatIndex === -1) {
    return state;
  }

  const newMessage: IMessage = {
    chatRoomID: chatId,
    content: message,
    senderID: enabledChatsClone[chatIndex].customerNumber,
    senderName: enabledChatsClone[chatIndex].customerName,
    clientType: 'Supplier',
    userEmail: userEmail,
    userName: userFullName,
    isMeSender: true,
    sendingToServer: true,
    localID: uuidv4(),
    createdAt: '' + new Date(),
    dayToRender: formatDayToRender('' + new Date())
  };

  enabledChatsClone[chatIndex].messages.push(newMessage);
  enabledChatsClone[chatIndex].messagesToRender = addToMessagesToRender(
    enabledChatsClone[chatIndex].messagesToRender,
    newMessage
  );

  enabledChatsClone[chatIndex].lastActionChat = ELastActionChat.sentMessage;

  return {
    ...state,
    enabledChats: enabledChatsClone,
    messageBeingSend: newMessage,
    chatMessageBeingSend: enabledChatsClone[chatIndex]
  };
}

function getUnreadsBySupplierNumberSuccess(
  state: MessagesState, unreads: Array<{items: Array<IChatRoom>, scannedCount: number}>
): MessagesState {
  let scannedCount = 0;
  let unreadIds = [];

  unreads.forEach(unread => {
    scannedCount += unread.scannedCount;
    unreadIds = unreadIds.concat(unread.items.map(item => item.id));
  });

  return {
    ...state,
    unreadCount: scannedCount,
    unreadIds: unreadIds
  };
}

function toggleCollapseChat(state: MessagesState, chatRoom: IChatRoom) {

  const enabledChatsClone = [ ...state.enabledChats ];

  const chatIndex = enabledChatsClone.findIndex(chat => chat.id === chatRoom.id);
  if (chatIndex === -1) {
    return state;
  }

  enabledChatsClone[chatIndex].status = enabledChatsClone[chatIndex].status === EStatusChatRoom.opened
    ? EStatusChatRoom.collapsed
    : EStatusChatRoom.opened;

  return {
    ...state,
    enabledChats: enabledChatsClone
  };
}

function createNewChat(state: MessagesState): MessagesState {
  return {
    ...state,
    newChatOpen: true
  };
}

function closeNewChat(state: MessagesState): MessagesState {
  return {
    ...state,
    newChatOpen: false
  };
}

function setFilterHeaderChatsText(state: MessagesState, text: string): MessagesState {
  const headerChatsClone = [ ...state.headerChats ];

  const lowerCaseText = text ? text.toLowerCase() : '';

  const filteredHeader = filterChats(headerChatsClone, lowerCaseText);

  return {
    ...state,
    filterHeaderChatsText: text,
    headerChatsFiltered: filteredHeader
  };
}

function separateUnreadChats(chatRooms: Array<IChatRoom>, unreadIds: Array<string> = []): Array<IChatRoom> {
  const unreadChats = chatRooms.filter((chat) => unreadIds.includes(chat.id));
  const readChats = chatRooms.filter((chat) => !unreadIds.includes(chat.id));

  const sortedUnreadChats = orderBy(unreadChats, ['updatedAt'], ['desc']);
  const sortedReadChats = orderBy(readChats, ['updatedAt'], ['desc']);

  return [ ...sortedUnreadChats, ...sortedReadChats ];
}

/* Reducer Export */
export function messagesReducer(state: MessagesState | undefined, action: Action) {
  return reducer(state, action);
}
