import { Action, createReducer, on } from '@ngrx/store';
import * as SupplierDetailActions from './supplier-detail.actions';
import { SupplierDetail } from '../models/supplier-detail';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';
import { AlertMessage } from '../../../models/alert-message';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { BrokerDetail } from '../models/broker-detail';
import * as SupplierOnboardingActions from '../../supplier-onboarding/store/supplier-onboarding.actions';
import { ChecklistNavigationTab } from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import { Contact } from '../../../models/contact';
import { BrokerOpenedModals } from '@app/modules/supplier-onboarding/enums/broker-opened-modal.enum';
import { UncoveredBrandTempClassAlertResponse } from '@kehe/purchasing-options-lib/lib/modules/purchasing-options/models/uncovered-brands-temp-class-alert-response';

export const supplierDetailStateKey = 'supplierDetail';

export class SupplierDetailState {
  supplierLoading: boolean;
  brokerLoading: boolean;
  detail: SupplierDetail;
  broker: BrokerDetail;
  currentTab: SupplierDetailTabs;
  editMode: boolean;
  supplierForm: any;
  supplierFormIsDirty: boolean;
  supplierFormIsValid: boolean;
  showLeaveUnsavedChangesModal: boolean;
  leaveUnsavedChangesDestinationURl: string;
  supplierFormIsSaving: boolean;
  showSubmitConfirmation: boolean;
  submitSupplierError: string;
  submitSupplierDocumentsRequiredError: boolean;
  displayTabPanel: boolean;
  alertMessage: AlertMessage;
  supplierValidatedTabs: ChecklistNavigationTab[];
  isSavingContact: boolean;
  newlyAddedContact: Contact;
  saveContactError: string;
  hasAnyPurchasingOptionsToReview: boolean;
  analystCalendarLink?: string;
  brokerOpenedModal: BrokerOpenedModals;
  shouldSaveAfterUpdateForm: boolean;
  shouldNotShowSuccessToast: boolean;
  isLoadSupplierBrokerError: boolean;
  poUncoveredBrandTempClassAlerts: UncoveredBrandTempClassAlertResponse[];
}

export const initializeState = (): SupplierDetailState => {
  return {
    supplierLoading: false,
    brokerLoading: false,
    detail: null,
    broker: null,
    currentTab: SupplierDetailTabs.gettingStarted,
    editMode: false,
    supplierForm: null,
    supplierFormIsDirty: false,
    supplierFormIsValid: false,
    showLeaveUnsavedChangesModal: false,
    leaveUnsavedChangesDestinationURl: null,
    supplierFormIsSaving: false,
    showSubmitConfirmation: false,
    submitSupplierError: null,
    submitSupplierDocumentsRequiredError: false,
    displayTabPanel: true,
    alertMessage: new AlertMessage(),
    supplierValidatedTabs: [],
    isSavingContact: false,
    newlyAddedContact: null,
    saveContactError: null,
    hasAnyPurchasingOptionsToReview: false,
    brokerOpenedModal: null,
    shouldSaveAfterUpdateForm: false,
    shouldNotShowSuccessToast: false,
    isLoadSupplierBrokerError: false,
    poUncoveredBrandTempClassAlerts: null
  };
};

export const initialState = initializeState();

const rootReducer = createReducer(
  initialState,
  // Load Supplier
  on(SupplierDetailActions.loadSupplierDetail, (state: SupplierDetailState) => ({
    ...state,
    supplierLoading: true,
    detail: null,
  })),
  on(SupplierDetailActions.loadSupplierDetailSuccess, (state: SupplierDetailState, action) => ({
    ...state,
    detail: prepareSupplierDetail(action.supplier),
    supplierLoading: false,
    isDocumentTypesLoading: true,
  })),
  on(SupplierDetailActions.loadSupplierDetailError, (state: SupplierDetailState, action) => ({
    ...state,
    supplierLoading: false,
  })),
  on(SupplierDetailActions.loadSupplierBroker, (state: SupplierDetailState, action) => ({
    ...state,
    brokerLoading: true
  })),
  on(SupplierDetailActions.loadSupplierBrokerSuccess, (state: SupplierDetailState, action) => ({
    ...state,
    broker: action.broker,
    isLoadSupplierBrokerError: false,
    brokerLoading: false
  })),
  on(SupplierDetailActions.loadSupplierBrokerError, (state: SupplierDetailState, action) => ({
    ...state,
    isLoadSupplierBrokerError: true,
    brokerLoading: false
  })),
  on(SupplierDetailActions.clearSupplierBroker, (state: SupplierDetailState, action) => ({
    ...state,
    broker: null,
    isLoadSupplierBrokerError: false,
    brokerLoading: false
  })),
  // Load Supplier

  // Edit Supplier
  on(SupplierDetailActions.editSupplier, (state: SupplierDetailState, action) => ({
    ...state,
    editMode: true,
    supplierForm: initialState.supplierForm,
    supplierFormIsDirty: initialState.supplierFormIsDirty,
    supplierFormIsValid: initialState.supplierFormIsValid,
    alertMessage: initialState.alertMessage,
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: initialState.newlyAddedContact,
    saveContactError: initialState.saveContactError,
  })),
  on(SupplierDetailActions.clearEditSupplier, (state: SupplierDetailState, action) => ({
    ...state,
    editMode: initialState.editMode,
    supplierFormIsDirty: initialState.supplierFormIsDirty,
    supplierFormIsValid: initialState.supplierFormIsValid,
    supplierForm: initialState.supplierForm,
    alertMessage: initialState.alertMessage,
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: initialState.newlyAddedContact,
    saveContactError: initialState.saveContactError,
    brokerOpenedModal: null,
    shouldSaveAfterUpdateForm: false,
    shouldNotShowSuccessToast: false
  })),
  on(SupplierDetailActions.supplierFormChanged, (state: SupplierDetailState, action) => ({
    ...state,
    supplierForm: {...action.form},
    supplierFormIsDirty: action.isDirty,
    supplierFormIsValid: action.isSupplierFormValid,
  })),
  on(SupplierDetailActions.showLeaveUnsavedChangesModal, (state: SupplierDetailState, action) => ({
    ...state,
    showLeaveUnsavedChangesModal: true,
    leaveUnsavedChangesDestinationURl: action.destinationURL,
  })),
  on(SupplierDetailActions.hideLeaveUnsavedChangesModal, (state: SupplierDetailState, action) => ({
    ...state,
    showLeaveUnsavedChangesModal: false
  })),
  on(SupplierDetailActions.confirmLeaveUnsavedChanges, (state: SupplierDetailState, action) => ({
    ...state,
    showLeaveUnsavedChangesModal: false
  })),
  on(SupplierDetailActions.clearLeaveUnsavedChangeDestinationURL, (state: SupplierDetailState, action) => ({
    ...state,
    leaveUnsavedChangesDestinationURl: initialState.leaveUnsavedChangesDestinationURl
  })),
  on(SupplierDetailActions.saveSupplierForm, (state: SupplierDetailState, action) => ({
    ...state,
    supplierFormIsSaving: true,
    showLeaveUnsavedChangesModal: false,
  })),
  on(SupplierDetailActions.saveSupplierFormSuccess, (state: SupplierDetailState, action): SupplierDetailState => ({
    ...state,
    supplierFormIsSaving: false,
    editMode: initialState.editMode,
    supplierFormIsDirty: initialState.supplierFormIsDirty,
    supplierFormIsValid: initialState.supplierFormIsValid,
    supplierForm: initialState.supplierForm,
    shouldSaveAfterUpdateForm: false,
    alertMessage: {
      type: AlertTypes.Success,
      message: 'Your changes have been saved successfully.'
    },
    detail: prepareSupplierDetail(action.supplier),
  })),
  on(SupplierOnboardingActions.sendDocusignEmailSuccess, (state: SupplierDetailState, action) => ({
    ...state,
    detail: prepareSupplierDetail(action.supplier),
  })),
  on(SupplierDetailActions.saveSupplierFormError, (state: SupplierDetailState) => ({
    ...state,
    supplierFormIsSaving: false,
    shouldSaveAfterUpdateForm: false,
    alertMessage: {
      type: AlertTypes.Error,
      message: 'There was a problem saving the record.',
    },
  })),
  on(SupplierDetailActions.addSupplierContact, (state: SupplierDetailState, action) => ({
    ...state,
    isSavingContact: true,
    saveContactError: initialState.saveContactError,
    newlyAddedContact: initialState.newlyAddedContact,
  })),
  on(SupplierDetailActions.addSupplierContactSuccess, (state: SupplierDetailState, action) => ({
    ...state,
    detail: {
      ...state.detail,
      contacts: action.contacts
    },
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: action.contacts.find(item => item.uniqueId === action.created.uniqueId),
    saveContactError: initialState.saveContactError,
  })),
  on(SupplierDetailActions.addSupplierContactError, (state: SupplierDetailState, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: initialState.newlyAddedContact,
    saveContactError: getContactSaveErrorMessage(action.errorCode),
  })),
  on(SupplierDetailActions.clearSupplierContactForm, (state: SupplierDetailState, action) => ({
    ...state,
    isSavingContact: initialState.isSavingContact,
    newlyAddedContact: initialState.newlyAddedContact,
    saveContactError: initialState.saveContactError,
  })),
  // Edit Supplier

  // Submit Supplier
  on(SupplierDetailActions.confirmSubmit, (state: SupplierDetailState) => ({
    ...state,
    showSubmitConfirmation: true,
    submitSupplierError: initialState.submitSupplierError,
    submitSupplierDocumentsRequiredError: false,
  })),
  on(SupplierDetailActions.cancelSubmit, (state: SupplierDetailState) => ({
    ...state,
    showSubmitConfirmation: false,
    submitSupplierError: initialState.submitSupplierError,
    submitSupplierDocumentsRequiredError: false,
  })),
  on(SupplierDetailActions.supplierSubmit, (state: SupplierDetailState) => ({
    ...state,
    supplierFormIsSaving: true,
    submitSupplierError: initialState.submitSupplierError,
    submitSupplierDocumentsRequiredError: false,
  })),
  on(SupplierDetailActions.supplierSubmitSuccess, (state: SupplierDetailState, action) => ({
    ...state,
    detail: prepareSupplierDetail(action.supplier),
    submitSupplierError: initialState.submitSupplierError,
    supplierFormIsSaving: false,
    showSubmitConfirmation: false,
    submitSupplierDocumentsRequiredError: false,
  })),
  on(SupplierDetailActions.supplierSubmitError, (state: SupplierDetailState, action) => ({
    ...state,
    submitSupplierError: getErrorMessage(action.errorCode),
    supplierFormIsSaving: false,
    submitSupplierDocumentsRequiredError: action.errorCode === 400,
  })),
  // Submit Supplier

  // Navigation
  on(SupplierDetailActions.toggleNavigationPanel, (state: SupplierDetailState) => ({
    ...state,
    displayTabPanel: !state.displayTabPanel
  })),
  on(SupplierDetailActions.setCurrentTab, (state: SupplierDetailState, action) => ({
    ...state,
    currentTab: action.tab,
    alertMessage: initialState.alertMessage,
    shouldSaveAfterUpdateForm: false
  })),
  // Navigation

  // Alerts
  on(SupplierDetailActions.showAlertMessage, (state: SupplierDetailState, action) => ({
    ...state,
    alertMessage: action.alert,
  })),
  on(SupplierDetailActions.hideAlertMessage, (state: SupplierDetailState, action) => ({
    ...state,
    alertMessage: initialState.alertMessage,
  })),
  // Alerts

  // Purchasing Options
  on(SupplierDetailActions.loadPurchasingOptionSupplierReviewStatusSuccess,
    SupplierDetailActions.loadPurchasingOptionSupplierReviewStatusFromPurOpSuccess,
      (state: SupplierDetailState, action) => ({
      ...state,
      hasAnyPurchasingOptionsToReview: action.hasAnyPurchasingOptionsToReview,
  })),
  on(SupplierDetailActions.saveUncoveredBrandTempClassAlerts,
      (state: SupplierDetailState, action) => ({
      ...state,
      poUncoveredBrandTempClassAlerts: action.uncoveredBrandTempClassAlerts,
  })),
  // Purchasing Options
  // Utils
  on(SupplierDetailActions.supplierValidated, (state: SupplierDetailState, action) => ({
    ...state,
    supplierValidatedTabs: action.tabs,
  })),
  // Utils

  // Analyst Calendar Link
  on(SupplierDetailActions.loadAnalystCalendarLinkSuccess, (state, { url }) => ({
    ...state,
    analystCalendarLink: url
  })),

  // Broker V2
    on(SupplierDetailActions.showEditBrokerModal, (state) => ({
        ...state,
        brokerOpenedModal: BrokerOpenedModals.editBroker
    })),
    on(SupplierDetailActions.showAddBrokerModal, (state) => ({
        ...state,
        brokerOpenedModal: BrokerOpenedModals.addBroker
    })),
    on(
      SupplierDetailActions.showAddBrokerModalWithSkipOption,
      (state) => ({
          ...state,
          brokerOpenedModal: BrokerOpenedModals.addBrokerWithSkipOption
      })
  ),
    on(SupplierDetailActions.showDeleteBrokerModal, (state) => ({
      ...state,
      brokerOpenedModal: BrokerOpenedModals.delete
  })),


  on(
    SupplierDetailActions.closeBrokerModal,
    (state) => ({
      ...state,
      brokerOpenedModal: null,
      shouldNotShowSuccessToast: false,
      alertMessage: initialState.alertMessage
  })),
  on(SupplierDetailActions.brokerDeleteModalSuccess, (state) => ({
    ...state,
    brokerOpenedModal: null
})),


  on(SupplierDetailActions.updateSupplierFormAndSave, (state: SupplierDetailState, action) => ({
    ...state,
    shouldNotShowSuccessToast: action.shouldNotShowSuccessToast,
    shouldSaveAfterUpdateForm: true,
    alertMessage: initialState.alertMessage
}))
);

export function reducer(state: SupplierDetailState | undefined, action: Action) {
  return rootReducer(state, action);
}

// Other business logic
function prepareSupplierDetail(supplier: SupplierDetail): SupplierDetail {
  if (supplier) {
    supplier.statusTag = prepareStatusTag(supplier.status);

    if (supplier.headquarterAddressId && supplier.contacts) {
      supplier.headquarterAddress = supplier.contacts.find(item => item.uniqueId === supplier.headquarterAddressId);
    } else {
      supplier.headquarterAddress = null;
    }
    if (supplier.keheShipToRetailerDate) {
      supplier.keheShipToRetailerDate = new Date(supplier.keheShipToRetailerDate);
    }
  }
  return supplier;
}

function prepareStatusTag(status: string) {
  const color = status === 'Active' ? '#D5E48F' : '#E2E2E2';
  return {
    bgColor: color,
    borderColor: color,
    text: status,
  };
}

function getErrorMessage(errorCode: number): string {
  return errorCode === 400 ? 'There are missing documents. Please upload the missing documents and try again.' :
    'There was an error submitting your account. Please try again.';
}

function getContactSaveErrorMessage(errorCode: number): string {
  return errorCode === 400 ? 'This address and contact combination already exists.' :
    'There was an error. Please try again.';
}
