export enum SubscriptionFilterKey {
  RetailerArea,
  Dc,
  BrandCode,
  Upc,
  ValuationMethod,
  FillRateUpperLimit,
  Item360RecapType,
  Channel,
}

const keyMappings: { enum: SubscriptionFilterKey, value: string }[] = [
  { enum: SubscriptionFilterKey.RetailerArea, value: 'chainGroup' },
  { enum: SubscriptionFilterKey.Dc, value: 'dc' },
  { enum: SubscriptionFilterKey.BrandCode, value: 'brandCode' },
  { enum: SubscriptionFilterKey.Upc, value: 'upc' },
  { enum: SubscriptionFilterKey.ValuationMethod, value: 'valuationMethod' },
  { enum: SubscriptionFilterKey.FillRateUpperLimit, value: 'fillRateUpperLimit' },
  { enum: SubscriptionFilterKey.Item360RecapType, value: 'type' },
  { enum: SubscriptionFilterKey.Channel, value: 'channel' },
];

export namespace SubscriptionFilterKey {
  export function toString(input: SubscriptionFilterKey): string {
    return keyMappings.filter(o => o.enum === input)[0].value;
  }

  export function parse(input: string): SubscriptionFilterKey {
    return keyMappings.filter(o => o.value === input)[0].enum;
  }
}
