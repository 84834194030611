import { createSelector } from "@ngrx/store";
import { State } from "../index";
import * as fromHelp from "./help.reducer";
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { ResourceActions } from "@app/constants/resource-actions";
import { HelpCenterGroup } from "@app/modules/reporting/models/enum/help-center-group";
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

const selectHelpState = (state: State) => state.help;
export const selectFaqs = createSelector(
    selectHelpState,
    fromHelp.getFaqs,
);
export const selectSelectedVideo = createSelector(
    selectHelpState,
    fromHelp.getSelectedVideo,
)

// returns all non-show related FAQs. currently the only other type of FAQ is BI so check if user has BI access. if so, return BI FAQs.
export const selectEverydayFaqs = createSelector(
    selectFaqs,
    AuthenticationSelectors.selectUserActions,
    (faqs, resourceActions: string[]) => {
        if (resourceActions.some(action => 
            action === ResourceActions.SupplierBiDataRead || 
            action === ResourceActions.SupplierAnalyticsRead || 
            action === ResourceActions.SSRSReportsBaseRead || 
            action === ResourceActions.SSRSReportsEnhancedRead
        )) {
            return faqs.filter(faq => faq.group === HelpCenterGroup.ConnectBi)
        }
        return [];
    }
);

export const selectShowFaqs = createSelector(
    selectFaqs,
    ShowSelectors.selectIsSupplierInShow,
    (faqs, isInShow) => isInShow ? faqs.filter(faq => faq.group === HelpCenterGroup.Show) : []
);