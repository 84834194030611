import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { KeheAnnouncementCardState } from '@kehe/phoenix-announcements';

import { AnnouncementsListRouterLinks } from '../../../announcements-list/enums/announcements-list-router-links.enum';
import { selectRecentAnnouncementsState } from '../../store/recent-announcements.selectors';
import { IRecentAnnouncementsState } from '../../interfaces/recent-announcements-state.interface';
import { IRecentAnnouncementsTile } from '../../interfaces/recent-announcements-tile.interface';
import { IRecentAnnouncementsCardLinks } from '../../interfaces/recent-announcements-card-links.interface';
import { recentAnnouncementsTilesInitialized } from './recent-announcements-tiles.actions';

@Component({
  selector: 'app-recent-announcements-tiles',
  templateUrl: './recent-announcements-tiles.component.html',
  styleUrls: [ './recent-announcements-tiles.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecentAnnouncementsTilesComponent implements OnInit {
  tiles$: Observable<IRecentAnnouncementsTile[]> = this.store.select(selectRecentAnnouncementsState)
    .pipe(map((data: IRecentAnnouncementsState) => this.getTilesList(data)));

  constructor(private store: Store, private router: Router) {
  }

  ngOnInit(): void {
    this.store.dispatch(recentAnnouncementsTilesInitialized());
  }

  private getTilesList({ loading, err, list }: IRecentAnnouncementsState): IRecentAnnouncementsTile[] {
    const state: KeheAnnouncementCardState = loading
      ? KeheAnnouncementCardState.Loading
      : err || !list?.length
        ? KeheAnnouncementCardState.Empty
        : KeheAnnouncementCardState.Default;

    switch (state) {
      case KeheAnnouncementCardState.Loading:
        return Array.from({ length: 4 }, () => ({ header: '', subHeader: '', id: null, state }));
      case KeheAnnouncementCardState.Empty:
        return [ { header: 'Announcements', subHeader: '', id: null, state } ];
      case KeheAnnouncementCardState.Default:
        return list.map(tile => ({ ...tile, state }));
    }
  }

  readMore(id: number): void {
    if (!id && id !== 0) {
      return;
    }

    this.router.navigate([AnnouncementsListRouterLinks.List], { fragment: id.toString() });
  }

  getMobileConfig(tiles: IRecentAnnouncementsTile[]): IRecentAnnouncementsCardLinks {
    return (tiles ?? []).reduce((curVal: IRecentAnnouncementsCardLinks, item: IRecentAnnouncementsTile) => {
        return {
          ...curVal,
          state: item.state,
          links: [
            ...curVal.links,
            {
              href: `${AnnouncementsListRouterLinks.List}#${item.id}`,
              headline: item.header
            }
          ]
        };
      },
      {
        state: KeheAnnouncementCardState.Empty,
        headline: 'Recent Announcements',
        links: []
      }
    );
  }
}

