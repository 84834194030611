import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { isNullOrUndefined } from '@app/utilities/common-utils';
import {
  UserNotificationDeliveryMethod,
  UserNotificationType
} from '../model/user-notifications';
import {
  UserNotificationPreferencesRecord,
  UserNotificationRecord
} from '../model/service/user-notifications';
import { Payload } from '../../../models/payload';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsService {

  constructor(private httpClient: HttpClient) { }

  getNotificationPreferences(
    userNotificationTypes?: UserNotificationType[]
  ): Observable<UserNotificationPreferencesRecord> {
    return this.httpClient.get<UserNotificationPreferencesRecord>(
      `${environment.api}/notifications/notification-preferences`,
      { params: this.buildNotificationTypeParams(userNotificationTypes) }
    );
  }

  /**
   * @param preferences: delta of dictionary entries to update
   **/
  updateNotificationPreferences(
    email: string,
    preferences: { [key in UserNotificationDeliveryMethod]: { [_key in UserNotificationType]: boolean } }
  ): Observable<UserNotificationPreferencesRecord> {
    return this.httpClient.patch<UserNotificationPreferencesRecord>(`${environment.api}/notifications/notification-preferences`, {
      email,
      preferences
    });
  }

  getUserNotifications(): Observable<Payload<UserNotificationRecord[]>> {
    return this.httpClient.get<Payload<UserNotificationRecord[]>>(`${environment.api}/notifications/user`);
  }

  getUserNotificationsCount(): Observable<number> {
    return this.httpClient.get<number>(`${environment.api}/notifications/user-count`);
  }

  /**
   * Response body is ignored
   */
  clearUserNotifications(notificationIds: number[]): Observable<HttpResponse<Object>> {
    return this.httpClient.patch<HttpResponse<Object>>(`${environment.api}/notifications/user`, notificationIds);
  }

  clearAllUserNotifications(): Observable<HttpResponse<Object>> {
    return this.httpClient.delete<HttpResponse<Object>>(`${environment.api}/notifications/user`);
  }

  private buildNotificationTypeParams(userNotificationTypes: UserNotificationType[]): HttpParams {
    let params = new HttpParams();
    if (isNullOrUndefined(userNotificationTypes) || userNotificationTypes.length === 0) {
      return params;
    }
    params = new HttpParams({
      fromObject: { 'types': userNotificationTypes }
    });
    return params;
  }
}
