import { IKeheTab } from '@kehe/phoenix-tabs';
import { Filter } from '../../../models/filter';
import { IProductFilterCheckItem, IProductFilterItem } from './iproduct-filter-item';

export class ProductListFilter extends Filter {
  public status: Array<IProductFilterCheckItem> = [];
  public tempClass: Array<IProductFilterCheckItem> = [];
  public newProductworkflowStatus: Array<IProductFilterCheckItem> = [];
  public scoreCard: Array<IProductFilterCheckItem> = [];
  public suppliers: Array<IProductFilterItem> = [];
  public brands: Array<IProductFilterItem> = [];
  public priceGroups: Array<IProductFilterItem> = [];
  public categoryManagers: Array<IProductFilterItem> = [];
  public createdBy: Array<IProductFilterItem> = [];
  public categoryManagerName: string = null;
  public sortName: number;
  public orderDirection: number;
  public search: string;
  public selectedTab: IKeheTab;
  public createdFrom = '';
  public createdTo = '';

  constructor() {
    super(true);
    this.sortName = 0;
    this.search = '';
  }
}
