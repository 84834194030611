<div class="container">
  <div class="row mb-3">
    <div class="col-sm-12">
      <kendo-label>Supplier</kendo-label>
      <kendo-combobox
        name="select-supplier"
        id="select-supplier"
        placeholder="Select Supplier"
        [data]="supplierOptions$ | async"
        valueField="esn"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [(ngModel)]="selectedSupplier"
        (ngModelChange)="onSupplierSelected()"
      ></kendo-combobox>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-12">
      <div class="d-flex">
        <kendo-label>Brand</kendo-label>
        <div class="ml-auto">
          <app-select-all (clicked)="selectAllBrands()"></app-select-all>
        </div>
      </div>
      <kendo-multiselect 
        name="select-brand"
        id="select-brand"
        placeholder="Select Brand"
        [data]="brandOptions"
        valueField="code"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [autoClose]="false"
        [(ngModel)]="selectedBrands"
        [loading]="isBrandsLoading"
        [disabled]="!brandEnabled()"
        [tagMapper]="tagMapper"
        (ngModelChange)="onBrandSelected()"
      ></kendo-multiselect>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-12">
      <div class="d-flex">
        <kendo-label>Product</kendo-label>
        <div class="ml-auto">
          <app-select-all (clicked)="selectAllProducts()"></app-select-all>
        </div>
      </div>
      <kendo-multiselect 
        name="select-product"
        id="select-product"
        placeholder="Select Product"
        [data]="productOptions"
        valueField="upc"
        textField="itemDesc"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [autoClose]="false"
        [(ngModel)]="selectedProductList"
        [loading]="isProductsLoading"
        [disabled]="!productEnabled()"
        [tagMapper]="tagMapper"
        (ngModelChange)="onProductSelected()"
      ></kendo-multiselect>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-12">
      <div class="d-flex">
        <kendo-label>Retailer Area</kendo-label>
        <div class="ml-auto">
          <app-select-all (clicked)="selectAllRetailerAreas()"></app-select-all>
        </div>
      </div>
      <kendo-multiselect 
        name="select-chain-group"
        id="select-chain-group"
        placeholder="Select Retailer Area"
        [data]="retailerAreaNameOptions"
        valueField="id"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [autoClose]="false"
        [(ngModel)]="selectedRetailerAreaList"
        [tagMapper]="tagMapper"
        [virtual]="{ itemHeight: 24 }"
        (ngModelChange)="onChainGroupSelected()"
      ></kendo-multiselect>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-12 valuation-method">
      <label>Valuation Method</label>
      <kendo-dropdownlist
        [data]="valuationMethods"
        valueField="value"
        textField="label"
        [(ngModel)]="selectedValuationMethod"
        (ngModelChange)="onValuationMethodChanged()"
      ></kendo-dropdownlist>
    </div>
  </div>
</div>
