export class Filter {

    constructor(
        public includeAvailableCount = false,
        public pageIndex = 0,
        public pageCount = 25,
        public orderDirection = 0,
        public skip?: number,
        public take?: number,
    ) {
    }
}
