import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import * as AuthenticationSelectors from '../shared/state/authentication/authentication.selectors';
import { AuthenticationService } from './authentication.service';
import * as AuthenticationActions from './authentication.actions';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AuthGuardService  {

  constructor(public authenticationService: AuthenticationService, private store: Store) {}

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(AuthenticationSelectors.selectIsUserAuthenticated).pipe(
      exhaustMap(isUserAuthenticatedInStore => {
        if (isUserAuthenticatedInStore) {
          return of(true);
        }
        return this.authenticationService.getUser().pipe(
          map(user => !!user),
        )
      }),
      tap(isUserAuthenticated => {
        if (!isUserAuthenticated) {
          this.store.dispatch(AuthenticationActions.userNotAuthenticated({ url: state.url }))
        }
      }),
      map(isUserAuthenticated => isUserAuthenticated),
    );
  }
}
