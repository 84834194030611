import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    map,
    switchMap,
    withLatestFrom,
    filter,
    exhaustMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Constants } from '@app/constants/constants';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { FeatureFlags } from '@app/feature-flag/index';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import * as FeatureFlagActions from '@app/feature-flag/feature-flag.actions';
import * as SupplierApiActions from '@app/supplier/supplier.actions';
import * as SupplierSelectors from '@app/shared/state/supplier/supplier.selectors';
import { ShowService } from '@app/services/show.service';
import * as ShowApiActions from './show.actions';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

@Injectable()
export class ShowEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private showService: ShowService,
  ) { }

  checkSupplierInShowForImpersonatedSupplier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SupplierApiActions.loadSuppliersForUserSuccess,
        FeatureFlagActions.getFeatureFlagSuccess,
      ),
      withLatestFrom(
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.CheckShowEligibilitySplit.key)),
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.ShowImpersonation.key)),
        this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.ProductImpersonate)),
      ),
      filter(([action, isEligibilitySplitOn, isImpersonationFlagOn, doesUserHaveImpersonateAction]) => isImpersonationFlagOn && doesUserHaveImpersonateAction),
      exhaustMap(([_, isEligibilitySplitOn]) => this.showService.getImpersonatedSupplierIsInShow(isEligibilitySplitOn).pipe(
        map(response => ShowApiActions.checkImpersonatedSupplierInShowSuccess({ shows: response || null })),
        catchError(() => of(ShowApiActions.checkImpersonatedSupplierInShowFailure())),
      )),
    )
  });

  checkSupplierInShow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SupplierApiActions.loadSuppliersForUserSuccess,
        FeatureFlagActions.getFeatureFlagSuccess,
      ),
      withLatestFrom(
        this.store.select(SupplierSelectors.selectSuppliers),
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.CheckShowEligibilitySplit.key)),
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.ShowImpersonation.key)),
        this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(Constants.UserActions.ProductImpersonate)),
      ),
      filter(([action, suppliers, isEligibilitySplitOn, isImpersonationFlagOn, doesUserHaveImpersonateAction]) => suppliers?.length > 0 && (!isImpersonationFlagOn || !doesUserHaveImpersonateAction)),
      switchMap(([action, suppliers, isEligibilitySplitOn]) => this.showService.getSupplierIsInShow(suppliers.map(supplier => supplier.esn), isEligibilitySplitOn).pipe(
        map(response => ShowApiActions.checkSupplierInShowSuccess({ shows: response || null })),
        catchError(() => of(ShowApiActions.checkSupplierInShowFailure())),
      )),
    )
  });

  getAllShowCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ShowApiActions.checkSupplierInShowSuccess,
        ShowApiActions.checkImpersonatedSupplierInShowSuccess,
      ),
      withLatestFrom(
        this.store.select(selectIsFeatureFlagOn(FeatureFlags.CheckShowEligibilitySplit.key)),
      ),
      filter(([_, isEligibilitySplitOn]) => isEligibilitySplitOn),
      switchMap(() => this.showService.getCustomersInShow().pipe(
        map(response => ShowApiActions.getAllShowCustomersSuccess({ showCustomers: response?.data || [] })),
        catchError(() => of(ShowApiActions.getAllShowCustomersFailure())),
      )),
    )
  });

  getCarouselForSelectedShow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ShowApiActions.checkSupplierInShowSuccess,
        ShowApiActions.checkImpersonatedSupplierInShowSuccess,
      ),
      withLatestFrom(this.store.select(ShowSelectors.selectPriorityEventToDisplay)),
      filter(([action, priorityEvent]) => !!priorityEvent),
      switchMap(([_, priorityEvent]) => {
        return this.showService.getSupplierSliderElements(priorityEvent.uniqueId).pipe(
          map((sliderElements) => ShowApiActions.getSupplierSliderElementsSuccess({ sliderElements })),
          catchError(() => of(ShowApiActions.getSupplierSliderElementsFailed()))
        );
      })
    )
  });

}
