<div class="notification-template" [ngSwitch]="toastMessage">
  <span class="toast-container-create" *ngSwitchCase="'ShowOrderCreated'">New order created - 
    <a href="#" routerLink="{{orderType}}/orders/{{orderId}}"> <u> {{orderName}} </u> </a>
  </span>
  <div class="toast-container-custom" *ngSwitchCase="'ItemAdded'">
    <div class="toast-image-container">
      <img class="toast-image" src="{{showItemUrl}}" width="60" height="60" alt="showItemName">
    </div>
    <div class="toast-content">
      <div class="toast-item-name">
        <strong>{{showItemName}}</strong>
      </div>
      <div class="toast-item-name">was successfully added to</div>
      <div class="toast-item-name">
        <span *ngIf="!orderId">your order</span>
        <a *ngIf="!!orderId" href="#" routerLink="{{orderType}}/orders/{{orderId}}">
          <u>{{orderName}}</u>
        </a>.
      </div>
      <div class="toast-item-name">{{warningUp}}</div>
      <div class="toast-item-name">{{warningDown}}</div>
    </div>
  </div>
</div>
