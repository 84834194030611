import { createAction, props } from "@ngrx/store";
import { BreadCrumbItem } from "@progress/kendo-angular-navigation";

export const updateKendoBreadCrumbs = createAction(
    '[Order Detail Top Menu] Update BreadCrumbs',
    props <{ item: BreadCrumbItem }>(),
);

export const clearKendoBreadCrumbs = createAction(
    '[Order Detail Top Menu] Clear BreadCrumbs',
);

export const clickedKendoBreadCrumbs = createAction(
    '[Order Detail Top Menu] Clicked BreadCrumbs',
    props <{ item: BreadCrumbItem, selectedTab?: string }>(),
);