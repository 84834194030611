import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  cancelEsnSubscriptionModalCancelClicked,
  cancelEsnSubscriptionModalCloseClicked,
  cancelEsnSubscriptionModalConfirmClick,
  cancelEsnSubscriptionsModalConfirmClick,
} from '../../store/data-exchange.actions';
import {
  selectCancelEsnSubscriptionErrorMessage,
  selectCancelEsnSubscriptionUpdateInProgress,
  selectEsnSubscriptionsToPerformAnActionOnCount,
} from '../../store/data-exchange.selectors';
import { KeheModalEventsTypes } from '@kehe/phoenix-modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cancel-esn-subscription-modal',
  templateUrl: './cancel-esn-subscription-modal.component.html',
})

export class CancelEsnSubscriptionModalComponent implements OnInit {

  esnsToCancelCount$: Observable<number>;
  updateInProgress$: Observable<boolean>;
  errorMessage$: Observable<string>;

  constructor(private _store: Store) { }

  ngOnInit(): void {
    this.esnsToCancelCount$ = this._store.select(selectEsnSubscriptionsToPerformAnActionOnCount);
    this.updateInProgress$ = this._store.select(selectCancelEsnSubscriptionUpdateInProgress);
    this.errorMessage$ = this._store.select(selectCancelEsnSubscriptionErrorMessage);
  }

  handleModalEvent(event): void {
    switch (event.type) {
      case KeheModalEventsTypes.Close:
        this._store.dispatch(cancelEsnSubscriptionModalCloseClicked());
        break;
      default:
    }
  }

  onCancelClick(): void {
    this._store.dispatch(cancelEsnSubscriptionModalCancelClicked());
  }

  onConfirmClick(esnsToCancelCount: number): void {
    if (esnsToCancelCount > 1) {
      this._store.dispatch(cancelEsnSubscriptionsModalConfirmClick());
    } else {
      this._store.dispatch(cancelEsnSubscriptionModalConfirmClick());
    }
  }

}
