import { Filter } from '../../../models/filter';

export class SupplierListFilter extends Filter {
  public status: string = null;
  public supplierDevManager: any;
  public categoryManager: any;
  public sortName: number;
  public orderDirection: number;
  public search: string;
  public esn: string[];
  public marketingAllowanceCode: string;

  constructor() {
    super(true);
    this.sortName = 1;
    this.search = '';
  }
}
