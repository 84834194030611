import { DestroyableDirective } from '@app/abstract/destroyable';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  hasTransactionSucceeded,
  selectIsOrderProcessing,
  selectPaymentFailedErrorMessage
} from '@app/modules/data-exchange/store/data-exchange.selectors';
import { submitOrder, userEntersCheckoutPage } from '@app/modules/data-exchange/store/data-exchange.actions';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag/index';

@Component({
    selector: 'app-checkout-page',
    templateUrl: './checkout-page.component.html',
    styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent extends DestroyableDirective implements OnInit {

  termsForm = new UntypedFormGroup({
    acceptedTerms: new UntypedFormControl(false),
  });

  readonly FREE_TRIAL_TEXT = 'To participate in the Free Integration Period from June 1, 2022 – February 19, 2023, you must complete the checkout process. If you have not terminated your subscription prior to the expiration of the Free Integration Period, your payment method will be processed on February 20, 2023. ';
  readonly NORMAL_TEXT = 'Your subscription shall auto-renew in accordance with the subscription term option selected. Please see the Terms of Data Exchange for Broker Subscription Services for more information.';
  alertMessage: string;
  isCheckoutFormValid = false;
  isOrderProcessing: boolean;
  errorMessage$: Observable<string>;

  constructor(private router: Router, private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(userEntersCheckoutPage());

    this.store.pipe(
      takeUntil(this.destroy$),
      select(selectIsFeatureFlagOn(FeatureFlags.DataExchangeTrialPeriod.key)),
    ).subscribe(x => {
      this.alertMessage = `${x ? this.FREE_TRIAL_TEXT : ''} ${this.NORMAL_TEXT}`;
    });

    this.store.pipe(
      takeUntil(this.destroy$),
      select(selectIsOrderProcessing)
    ).subscribe(isOrderProcessing => this.isOrderProcessing = isOrderProcessing);

    this.errorMessage$ = this.store.select(selectPaymentFailedErrorMessage);

    this.store.pipe(
      takeUntil(this.destroy$),
      select(hasTransactionSucceeded),
      filter(x => !!x)
    ).subscribe(() => this.router.navigate(['/data-exchange']));

  }

  submitOrder(): void {
    this.store.dispatch(submitOrder());
  }

  backToOrder() {
    this.router.navigate(['/shopping-cart/cart']);
  }

  formValidityChange(isFormValid: boolean): void {
    this.isCheckoutFormValid = isFormValid;
  }

}
