<div class="chat-drawer d-flex flex-column ml-3">
  <div [id]="'new-drawer-header'" class="d-flex flex-row mx-3 justify-content-between align-items-center header">
    <div class="d-flex flex-row align-items-center">
      <em class="fas icon fa-shopping-basket" aria-hidden="true"></em>
      <div class="d-flex flex-column m-0 ml-2">
        <h4 class="mb-0">
          New Message
        </h4>
      </div>
    </div>
    <em class="far fa-times" title="Close" (click)="closeChatRoom($event)"></em>
  </div>
  <div class="panel-divider"></div>
  <div [id]="'new-drawer-body'" class="body mx-3" #formRight>

    <div class="title mt-2">From:</div>
    <kendo-combobox
      [data]="filteredSuppliers"
      [textField]="'name'"
      [valueField]="'esn'"
      [(ngModel)]="selectedSupplier"
      [valuePrimitive]="false"
      [placeholder]="'Select Supplier'"
      [filterable]="true"
      (filterChange)="handleFilterSupplier($event)"
    >
      <ng-template kendoComboBoxItemTemplate let-dataItem>
        <div
          class="kendo-dropdown-list-item-template"
          [ngStyle]="{
            'max-width.px': formRight.offsetWidth - 40,
            'overflow': 'hidden',
            'text-overflow': 'ellipsis'
          }"
        >
          <div class="item-header">{{ dataItem?.name }}</div>
          <div
            [ngStyle]="{
              'max-width.px': formRight.offsetWidth - 40,
              'overflow': 'hidden',
              'text-overflow': 'ellipsis'
            }"
            title="{{ dataItem?.esn }}"
          >ESN {{ dataItem?.esn }}</div>
        </div>
      </ng-template>
      <ng-template *ngIf="filteredSuppliers?.length >= longListCount" kendoComboBoxFooterTemplate>
        <kendo-formhint class="col-sm-12">
          Search results are limited to {{longListCount}} items
        </kendo-formhint>
      </ng-template>
    </kendo-combobox>

    <div class="title mt-2">To:</div>
    <kendo-combobox
      [data]="filteredCustomers"
      [textField]="'name'"
      [valueField]="'dcCustomerNumber'"
      [(ngModel)]="selectedCustomer"
      [valuePrimitive]="false"
      [placeholder]="'Select Customer'"
      [filterable]="true"
      (filterChange)="handleFilterCustomer($event)"
    >
      <ng-template kendoComboBoxItemTemplate let-dataItem>
        <div
          class="kendo-dropdown-list-item-template"
          [ngStyle]="{
            'max-width.px': formRight.offsetWidth - 40,
            'overflow': 'hidden',
            'text-overflow': 'ellipsis'
          }"
        >
          <div class="item-header">{{ dataItem?.name }}</div>
          <div
            [ngStyle]="{
              'max-width.px': formRight.offsetWidth - 40,
              'overflow': 'hidden',
              'text-overflow': 'ellipsis'
            }"
            title="{{ dataItem?.dcCustomerNumber }}"
          >{{ dataItem?.dcCustomerNumber }}</div>
        </div>
      </ng-template>
      <ng-template *ngIf="filteredCustomers?.length >= longListCount" kendoComboBoxFooterTemplate>
        <kendo-formhint class="col-sm-12">
          Search results are limited to {{longListCount}} items
        </kendo-formhint>
      </ng-template>
    </kendo-combobox>

    <div class="d-flex justify-content-end mt-3">
      <kehe-phoenix-button classAttr="btn-primary btn-medium" (click)="createMessage()" [disabled]="!canCreateMessage()">Create Message</kehe-phoenix-button>
    </div>

  </div>
</div>
