<kehe-phoenix-modal
  name="manageRecipientsModal"
  modalTitle="Manage Recipients"
  [show]="true"
  (modalEvent)="handleModalEvent($event)"
  size="md"
>
  <div body>
    <kehe-phoenix-alert [show]="true" type="warning" [message]="warningMessage" [stick]="true" [hideCloseIcon]="true"> </kehe-phoenix-alert>
    <kehe-phoenix-alert [show]="errorMessage" type="error" [message]="errorMessage" [stick]="true" [hideCloseIcon]="true"> </kehe-phoenix-alert>
    <form [formGroup]="form">
      <h5><strong>Manage Recipients</strong></h5>
      <div class="my-3">
        <kendo-formfield>
          <kendo-label [for]="emails" text="Select Recipients"></kendo-label>
          <kendo-multiselect #emails
            formControlName="emails"
            [loading]="isEmailRecipientsListLoading$ | async"
            [data]="emailRecipients$ | async" 
            placeholder="Select or search emails" 
            [kendoDropDownFilter]="{ operator: 'contains' }"
            [autoClose]="false"
            [virtual]="{ itemHeight: 24 }"
          ></kendo-multiselect> 
          <kendo-formhint>Select up to 10 emails</kendo-formhint>
          <kendo-formerror *ngIf="form.get('emails').errors?.required">Select at least one email</kendo-formerror>
          <kendo-formerror *ngIf="form.get('emails').errors?.tooManyEmails">Select up to 10 emails</kendo-formerror>
        </kendo-formfield>  
      </div>
      <hr>
      <h5 *ngIf="!thirdPartyUsers"><strong>Day (Weekly)</strong></h5>
      <div *ngIf="!thirdPartyUsers" class="my-3">
        <kendo-formfield>
          <kendo-label for="dayOfTheWeek" text="Select Day"></kendo-label>
          <ng-container *ngFor="let dayOption of dayOptions">
            <input #dayOfTheWeek kendoRadioButton
              type="radio"
              formControlName="day" 
              [value]="dayOption.id" 
            />
            <kendo-label class="mr-2 k-radio-label" [for]="dayOfTheWeek" [text]="dayOption.text"></kendo-label>
          </ng-container>
        </kendo-formfield>
      </div>
    </form>

  </div>
  <div footer class="d-flex justify-content-end">
    <kehe-phoenix-button
      name="cancel"
      classAttr="btn-link btn-medium"
      (click)="onCancelClick()"
    >Cancel</kehe-phoenix-button>
    <kehe-phoenix-button
      name="update"
      classAttr="btn-primary btn-medium"
      [disabled]="!form?.valid || updateInProgress"
      [spinner]="updateInProgress"
      (click)="onUpdateClick(isBulkMode)"
    >Update</kehe-phoenix-button>
  </div>
</kehe-phoenix-modal>
