<div class="side-panel-container">
  <div class="side-panel" [ngClass]="{'closed': open}"
       [ngStyle]="{'margin-top.px': marginTop}">
    <div class="toggler">
      <span class="toggler-icon fas" [ngClass]="{'fa-chevron-left': open, 'fa-chevron-right': !open}"
            (click)="togglePanel()"></span>
    </div>
    <div class="tabs-container" [ngClass]="{'collapse-items': !open}">
      <div class="tabs d-flex align-items-center" *ngFor="let tabItem of tabs" (click)="setTab(tabItem)"
           [ngClass]="{'active':activeTab===tabItem.tab, 'disabled' : tabItem.disabled }">
        <div class="mr-2 align-middle">
          <div [ngSwitch]="tabItem.status">
            <em *ngSwitchCase="tabStatus.checked" class="fas fa-check-circle checked"></em>
            <em *ngSwitchCase="tabStatus.unchecked" class="far fa-circle unchecked"></em>
            <em *ngSwitchCase="tabStatus.info" class="fas fa-info-circle info"></em>
            <em *ngSwitchCase="tabStatus.warning" class="fas fa-exclamation-circle"></em>
            <div *ngSwitchCase="tabStatus.locked" class="d-flex align-items-center justify-content-center locked">
              <em class="fas fa-lock m-auto"></em>
            </div>
            <em *ngSwitchDefault class="far fa-circle unchecked"></em>
          </div>
        </div>
        <span class="align-top  mr-1">{{tabItem.tab}}</span>
        <span class="align-top" *ngIf="tabItem.optional"> (optional)</span>
        <span class="align-top" *ngIf="tabItem.remainingCount > 0"> ({{tabItem.remainingCount}})</span>
      </div>
    </div>
  </div>
</div>
