<div class="container" *ngIf="!!subscription && show">
  <div class="row">
    <div class="col-sm-4 label">Report Type</div>
    <div class="col-sm-8 value">{{subscription.reportNameDisplay.text}}</div>
  </div>

  <div class="row">
    <div class="col-sm-4 label">Created</div>
    <div class="col-sm-8 value">{{subscription.created | date:'MM/dd/yyyy'}}</div>
  </div>

  <div class="row">
    <div class="col-sm-4 label">Supplier</div>
    <div class="col-sm-8 value">{{subscription.supplierName}}</div>
  </div>

  <kehe-phoenix-loading *ngIf="shouldHideFilter()"></kehe-phoenix-loading>
  <ng-container *ngIf="!shouldHideFilter()">
    <div class="row" *ngFor="let filterItem of subscription.filter;">
      <div class="col-sm-4 label">{{createLabelName(filterItem)}}</div>
      <div class="col-sm-8 value">{{transformFilterValues(filterItem)}}
        <div>
          <a *ngIf="!filterItem.expanded && filterItem.value.length > numFilterValuesForPreview"
            class="more-link"
            (click)="filterItem.expanded = !filterItem.expanded">
            {{'+' + (filterItem.value.length - numFilterValuesForPreview) + ' More'}}
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <hr>

  <div class="row">
    <div class="col-sm-12">
      <h4>Recurrence</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4 label">Frequency</div>
    <div class="col-sm-8 value">{{subscription.recurrence}}</div>
  </div>

  <div class="row" *ngIf="subscription.recurrence === 'Weekly'">
    <div class="col-sm-4 label">Days</div>
    <div class="col-sm-8 value">{{subscription.daysView}}</div>
  </div>
</div>
