
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { isEmpty } from 'lodash';
import { GoogleAnalyticsService } from './../../../../services/googleAnalytics.service';
import { IChatRoom } from '../../models/IChatRoom';
import { ICustomer } from '../../models/ICustomer';
import { ISupplier } from '../../models/ISupplier';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DestroyableDirective } from './../../../../abstract/destroyable';

@Component({
  selector: 'app-chat-drawer-new',
  templateUrl: './chat-drawer-new.component.html',
  styleUrls: ['./chat-drawer-new.component.scss']
})

export class ChatDrawerNewComponent extends DestroyableDirective  implements OnInit {

  @Input()
  suppliers: Array<ISupplier>;

  @Input()
  customers: Array<ICustomer>;

  selectedSupplier: ISupplier;
  selectedCustomer: ICustomer;

  filteredSuppliers: Array<ISupplier>;
  filteredCustomers: Array<ICustomer>;
  longListCount = 25;

  customerFilterTextChanged: Subject<string> = new Subject<string>();
  supplierFilterTextChanged: Subject<string> = new Subject<string>();


  @Output()
  closeChat: EventEmitter<null> = new EventEmitter();

  @Output()
  openChat: EventEmitter<IChatRoom> = new EventEmitter();

  public scrolledToBottom = false;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
  }

  ngOnInit() {
    this.filteredSuppliers = this.suppliers ? [ ...this.suppliers ].slice(0, this.longListCount) : [];
    this.filteredCustomers = this.customers ? [ ...this.customers ].slice(0, this.longListCount) : [];

    this.customerFilterTextChanged.pipe(
      takeUntil(this.destroy$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.filteredCustomers = this.customers.filter(
        (customer) =>
          customer.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          customer.dcCustomerNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1
      ).slice(0, this.longListCount);
    });

    this.supplierFilterTextChanged.pipe(
      takeUntil(this.destroy$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.filteredSuppliers = this.suppliers.filter(
        (supplier) =>
          supplier.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          supplier.esn.toLowerCase().indexOf(value.toLowerCase()) !== -1
      ).slice(0, this.longListCount);
    });

  }

  closeChatRoom(event) {
    event.stopPropagation();
    this.closeChat.emit();
  }

  canCreateMessage() {
    return !isEmpty(this.selectedSupplier) && !isEmpty(this.selectedCustomer);
  }

  createMessage() {
    if (this.canCreateMessage()) {

      this.googleAnalyticsService.eventEmitter(
        'show',
        'top-bar-open-messaging',
        'open-messaging',
        1
      );

      this.openChat.emit({
        customerNumber: this.selectedCustomer.dcCustomerNumber,
        customerName: this.selectedCustomer.name,
        supplierNumber: this.selectedSupplier.esn,
        supplierName: this.selectedSupplier.name
      });
    }
  }

  handleFilterSupplier(value) {
    this.supplierFilterTextChanged.next(value);
  }

  handleFilterCustomer(value) {
    this.customerFilterTextChanged.next(value);
  }
}
