import { SupplierUserDetails } from "@app/models/supplier-user-detail";
import { createAction, props } from "@ngrx/store";
import { User } from "oidc-client-ts";

export const logInSuccess = createAction(
    "[Authentication API] User Logged In Success",
    props<{ user: User | null }>(),
);

export const logOut = createAction(
    "[Authentication API] Log Out",
);

export const getLegacyTokenSuccess = createAction(
    '[Authentication API] Get Legacy Token Success',
    props<{ legacyToken: string | null }>(),
);

export const userNotAuthenticated = createAction(
    '[Auth Guard API] User Not Authenticated',
    props<{ url: string }>(),
)

export const handleRedirectError = createAction(
    '[Authentication API] Handle Redirect Error',
    props<{ url: string }>(),
);

export const getUserDetailSuccess = createAction(
    '[Authentication API] Get User Detail Success',
    props<{ user: SupplierUserDetails }>()
);
  
export const getUserDetailFailed = createAction(
    '[Authentication API] Get User Detail Failed',
);

