import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { ReportConfig } from './report.config';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class ApiPowerbiEmbedService {

  constructor(private http: HttpClient, private reportConfig: ReportConfig) {
    //
  }

  public getPowerBiReportEmbed(): Observable<any> {

    const serverData = {
        data: undefined,
        status: 0,
        success: false,
        message: ''
    };

    return this.http.get(this.reportConfig.url, { headers: this.reportConfig.authHeaders })
                    .pipe(map ((response: any) => {
                        serverData.data = JSON.parse(response.Body);
                        serverData.status = response['status'];
                        serverData.success = true;
                        serverData.message = response['statusText'];
                        return serverData; }),
                        catchError(this.handleError));
  }

  private async handleError(error: any) {
    if (error instanceof Response) {
        let errorObject;
        try {
            errorObject = await error.json();
            errorObject.status = error.status;
            errorObject.success = false;
            errorObject.message = errorObject.Message;
        } catch (error) {
            errorObject.success = false;
            errorObject.message = error.statusText;
        }
        return throwError(errorObject);
    }
    return throwError(error || 'Server error');
  }
}
