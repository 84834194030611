import { Constants } from './../../constants/constants';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FeaturesV1 } from '../../../app/constants/features';
import {
  getPromotionsUrl,
  managePricesUrl
} from '../../utilities/url-utility';
import { ResourceActions } from '../../constants/resource-actions';
import { PermissionsService } from '@app/services/permissions.service';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { KeheNavigationItem } from '@kehe/phoenix-top-navigation-panel';
import { takeUntil } from 'rxjs/operators';
import { DestroyableDirective } from '../../abstract/destroyable';
import { FeatureFlags } from '../../feature-flag';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import * as SupplierSelectors from '@app/shared/state/supplier/supplier.selectors';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import * as ComponentSelectors from './cs-navigation.component.selectors';

@Component({
  selector: 'app-cs-navigation',
  templateUrl: './cs-navigation.component.html',
  styleUrls: ['./cs-navigation.component.scss']
})
export class CsNavigationComponent extends DestroyableDirective implements OnInit {

  public loadingDraftSupplierESNs$: Observable<boolean>;

  items = new Array<KeheNavigationItem>();
  purOpsSupplierEditFeatureFlag = false;
  orderProjectionReportFeatureFlag = false;
  public hasDraftSupplier = false;
  public hasMultipleDraftEsn = false;
  showManageDraftSuppliersMenu = false;
  supplierIsInShow: boolean;
  vendors = [];
  promotionalRoadmapFF = false;
  roadmapNationalDealsFF = false;

  showId: string;

  constructor(
    private _permSvc: PermissionsService,
    private _coreStore: Store
  ) {
    super();
  }

  ngOnInit() {

    combineLatest([
      this._coreStore.select(SupplierSelectors.selectDoesUserHaveAccessToSingleDraftEsn),
      this._coreStore.select(SupplierSelectors.selectDoesUserHaveAccessToMultipleDraftEsns),
    ]).subscribe(([ hasOnly1DraftEsn, hasMultipleDraftEsn]) => {
      this.hasDraftSupplier = hasOnly1DraftEsn;
      this.hasMultipleDraftEsn = hasMultipleDraftEsn;
      this.buildNavMenu();
    });

    this._coreStore.select(ShowSelectors.selectFirstEligibleShowId).pipe(takeUntil(this.destroy$)).subscribe((showId) => {
      this.showId = showId;
    });

    this._coreStore.select(selectIsFeatureFlagOn(FeatureFlags.SupplierPurchasingOptionsEdit.key)).subscribe((key) => {
        this.purOpsSupplierEditFeatureFlag = key;
        this.buildManagementNavMenuItem();
    });

    this._coreStore.select(selectIsFeatureFlagOn(FeatureFlags.SupplierPromotionalRoadmap.key)).subscribe((key) => {
        this.promotionalRoadmapFF = key;
        this.buildNavMenu();
    });

    this._coreStore.select(selectIsFeatureFlagOn( FeatureFlags.SupplierRoadmapNationalDeals.key)).subscribe((key) => {
        this.roadmapNationalDealsFF = key;
        this.buildNavMenu();
    });

    this._coreStore.select(selectIsFeatureFlagOn( FeatureFlags.SupplierOrderProjectionsReport.key)).subscribe((key) => {
        this.orderProjectionReportFeatureFlag = key;
        this.buildNavMenu();
    });

		this._coreStore.select(SupplierSelectors.selectSupplierEsns)
			.subscribe((esns) => {
				this.vendors = esns;
				this.buildManagementNavMenuItem();
			});

    combineLatest([
      this._coreStore.select(ComponentSelectors.selectShouldDisplayManageDraftSuppliersMenu),
    ]).subscribe(([ showDraftMenu, ]) => {
      this.showManageDraftSuppliersMenu = showDraftMenu;
      this.buildManagementNavMenuItem();
    });

    combineLatest([
      this._coreStore.pipe(select(ShowSelectors.selectIsSupplierInShow)),
    ]).subscribe(([supplierIsInShow]) => {
      this.supplierIsInShow = supplierIsInShow;
      this.buildShowNavMenuItem();
    });
  }

  private buildNavMenu() {
    this.clearKeheNavigationItems();
    if (!this.hasDraftSupplier) {
      this.buildHomeMenuItem();
      this.buildShowNavMenuItem();
      this.buildDataNavMenuItem();
      this.buildProductsNavMenuItem();
      this.buildPromotionsNavMenuItem();
      this.buildSalesNavMenuItem();
      this.buildManagementNavMenuItem();
    }
  }

  private doesUserHaveAction(action: string): boolean {
    return this._permSvc.userHasAction(action);
  }

  private clearKeheNavigationItems() {
    this.items = [];
  }

  private buildHomeMenuItem() {
    const item = new KeheNavigationItem('Home', '/', { exact: true });
    this.items.push(item);
  }

  private buildShowNavMenuItem() {
    const subItems = new Array<KeheNavigationItem>();

    // rebuild nav each time for feature flag changes
    this.removeItemFromNavMenuByDisplayName('Show');

    const ordersListSubItem = new KeheNavigationItem('Manage Show Orders', `/show/${this.showId || 'no-shows'}/orders`, { exact: true });
    subItems.push(ordersListSubItem);

    if (this.supplierIsInShow && this.doesUserHaveAction(ResourceActions.SupplierProductV2)) {
      const productsNavItem = new KeheNavigationItem('Manage Products', '/show/products', { exact: true });
      subItems.push(productsNavItem);
    }

    const item = new KeheNavigationItem('Show', '/show', { exact: false }, null, subItems);
    this.items.splice(1, 0, item);

  }

  private buildProductsNavMenuItem() {
    const subItems = new Array<KeheNavigationItem>();
    let subItem;

    if (this.doesUserHaveAction(ResourceActions.SupplierProductV2)) {
      subItem = new KeheNavigationItem('Manage Products', '/products', { exact: true });
      subItems.push(subItem);
    }

    if (this.doesUserHaveAction(FeaturesV1.Promotions) && !this.promotionalRoadmapFF && !this.roadmapNationalDealsFF) {
      subItem = new KeheNavigationItem('Promotions', getPromotionsUrl());
      subItems.push(subItem);
    }

    const navigationText = 'Manage Prices';
    subItems.push(new KeheNavigationItem(navigationText, managePricesUrl));

    // Don't include the products menu if user is 3rd party of record, irrespetive of subItems length
    if (subItems.length > 0) {
      const item = new KeheNavigationItem('Products', '/products', { exact: false }, null, subItems);

      const existingIndex = this.items.findIndex(i => i.display === 'Products');
      if (existingIndex > -1) {
        this.items[existingIndex] = item;
      } else {
        this.items.push(item);
      }
    }
  }

  private buildPromotionsNavMenuItem() {
    const subItems = new Array<KeheNavigationItem>();

    if (this.roadmapNationalDealsFF) {
      const subItem = new KeheNavigationItem('Roadmap 2025 National Deals', 'promotional-roadmap/national-deals-template-download', { exact: true }, null, null, null, 'roadmap-2025-national-deals');
      subItems.push(subItem);
    }

    if (this.promotionalRoadmapFF) {
      const subItem = new KeheNavigationItem('Manage Promotional Roadmaps', 'promotional-roadmap', { exact: true }, null, null, null, 'manage-promotional-roadmap');
      subItems.push(subItem);
    }

    if (this.doesUserHaveAction(FeaturesV1.Promotions) && (this.roadmapNationalDealsFF || this.promotionalRoadmapFF)) {
      const subItem = new KeheNavigationItem(
        'Promotions',
        getPromotionsUrl(),
        null,
        null,
        null,
        null,
        'promotions-outer-link'
      );
      subItems.push(subItem);
    }

    const item = new KeheNavigationItem(
      'Promotions',
      'promotional-roadmap',
      { exact: false },
      null,
      subItems,
      null,
      'promotions'
    );

    if (subItems.length > 0) {
      const existingIndex = this.items.findIndex(
        (i) => i.display === 'Promotions'
      );

      if (existingIndex > -1) {
        this.items[existingIndex] = item;
      } else {
        this.items.push(item);
      }
    }
  }

  private buildDataNavMenuItem() {
    const dataNavSubItems = new Array<KeheNavigationItem>();

    if (this.doesUserHaveAction(ResourceActions.SupplierBiDataRead)) {

      const connectAnalyticsSubMenuItems = new Array<KeheNavigationItem>(
        new KeheNavigationItem('Analytics Dashboard', '/analytics'),
        new KeheNavigationItem('Gap & Void Dashboard', '/gap-void'),
      );

      if(this.orderProjectionReportFeatureFlag){
        connectAnalyticsSubMenuItems.push(new KeheNavigationItem('Order Projection Dashboard', '/order-projection'));
      }

      dataNavSubItems.push(new KeheNavigationItem('Connect Analytics', '/analytics', { exact: true }, null, connectAnalyticsSubMenuItems));
    }

    const userHasSsrsAccess = this.doesUserHaveAction(ResourceActions.SSRSReportsBaseRead) ||
      this.doesUserHaveAction(ResourceActions.SSRSReportsEnhancedRead);
    const userHasDataExchangeAccess = this.doesUserHaveAction(ResourceActions.SupplierAnalyticsRead);

    if (userHasSsrsAccess || userHasDataExchangeAccess) {
      const connectDataItems = new Array<KeheNavigationItem>();
      if (userHasSsrsAccess) {
        connectDataItems.push(new KeheNavigationItem('Reporting', '/reporting'));
      }
      if (userHasDataExchangeAccess) {
        connectDataItems.push(new KeheNavigationItem('Data Exchange', '/data-exchange'));
      }
      dataNavSubItems.push(new KeheNavigationItem('Connect Data', '/reporting', { exact: true }, null, connectDataItems));
    }

    if (dataNavSubItems.length > 0) {
      this.items.push( new KeheNavigationItem(`${Constants.KeHE_CONNECT_BI}`, '/analytics', { exact: false }, null, dataNavSubItems));
    }
  }

  private buildSalesNavMenuItem() {
    const salesNavSubItems = new Array<KeheNavigationItem>();

    // Removing manage orders tab to not get confused with the other manage show orders page SK-184
    // if (this.doesUserHaveAction(Constants.UserActions.SalesOrderRead)) {
    //   salesNavSubItems.push(new KeheNavigationItem('Manage Orders', '/sales-orders'));
    // }
    if (this.doesUserHaveAction(FeaturesV1.Ksolve)) {
      salesNavSubItems.push(new KeheNavigationItem('Credits', '/credits', { exact: true }, null, new Array<KeheNavigationItem>(
        new KeheNavigationItem('K-Solve', `https://${environment.name}connect.kehe.com/ksolve/`)
      )));
    }

    if (salesNavSubItems.length > 0) {
      this.items.push(new KeheNavigationItem('Sales Orders', '/sales', { exact: false }, null, salesNavSubItems));
    }

  }

  private buildManagementNavMenuItem() {
    const subItems = new Array<KeheNavigationItem>();
		const hasDummyESN = this.vendors.includes(Constants.SupplierV1DummyESN);

    let subItem;

    // rebuild nav each time for feature flag changes
    this.removeItemFromNavMenuByDisplayName('My Company');

    if (this.showManageDraftSuppliersMenu) {
      subItems.push(new KeheNavigationItem('Manage Draft Suppliers', Constants.ManageDraftSupplierPath));
    }

    if (this.purOpsSupplierEditFeatureFlag) {
      subItems.push(new KeheNavigationItem('Manage Supplier(s)', '/supplier'));
    }

		if (!hasDummyESN && !this.hasMultipleDraftEsn) {
    	if (this.doesUserHaveAction(FeaturesV1.UserManagement)) {
      	subItem = new KeheNavigationItem('Manage Users', `https://${environment.name}entitymanagement.kehe.com`);
      	subItems.push(subItem);
    	}
    	if (this.doesUserHaveAction(FeaturesV1.VendorManagement)) {
      	subItem = new KeheNavigationItem('Supplier Management', `https://${environment.name}connect.kehe.com/vendormanagement/`);
      	subItems.push(subItem);
    	}
		}

    if (subItems.length > 0) {
      const item = new KeheNavigationItem('My Company', '/management', { exact: false }, null, subItems);
      this.items.push(item);
    }
  }

  private removeItemFromNavMenuByDisplayName(name: string) {
    const index =  this.items.findIndex(i => i.display === name);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }
}
