import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CallbackPageActions from './callback-page.actions';

@Component({
  selector: 'app-callback-page',
  templateUrl: './callback-page.component.html',
  styleUrls: [],
})
export class CallbackPageComponent implements OnInit {

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(CallbackPageActions.entered());
  }

}
