import { ExternalVideo } from "@app/models/external-video";
import { createAction, props } from "@ngrx/store";

export const componentLoaded = createAction(
    '[FAQs] Component Loaded'
);

export const videoSelected = createAction(
    '[FAQs] Video Selected',
    props<{ video: ExternalVideo }>()
);