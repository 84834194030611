import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ProvideFeedbackTopicResponse } from '@kehe/phoenix-feedback';
import {
    AbstractFeedbackService,
    IFeedbackTopic
} from '@kehe/phoenix-feedback';

@Injectable()
export class FeedbackService implements AbstractFeedbackService {
    private _feedback = `${environment.api}/feedback`;
    private _topicsUrl = `${this._feedback}/query/feedbackTopics/supplier`;
    private _submitUrl = `${this._feedback}/submit`;

    constructor(private _httpClient: HttpClient) {}

    getTopics(): Observable<ProvideFeedbackTopicResponse> {
        return this._httpClient
            .get<ProvideFeedbackTopicResponse>(`${this._topicsUrl}`)
            .pipe(map((response) => response))
            .pipe(retry(2));
    }
    submit(body: {
        id: string;
        message: string;
    }): Observable<IFeedbackTopic[]> {
        return this._httpClient
            .post<any>(`${this._submitUrl}`, body)
            .pipe(map((response) => response))
            .pipe(retry(2));
    }
}
