import { IChatRoom } from '../models/IChatRoom';

export function filterChats(headerChatsClone: Array<IChatRoom>, lowerCaseText: string) {
  return headerChatsClone.filter(chat => {
    return chat.supplierName.toLowerCase().indexOf(lowerCaseText) !== -1 ||
    chat.supplierNumber.toLowerCase().indexOf(lowerCaseText) !== -1 ||
    chat.customerName.toLowerCase().indexOf(lowerCaseText) !== -1 ||
    chat.customerNumber.toLowerCase().indexOf(lowerCaseText) !== -1 ||
    (chat.lastMessage && chat.lastMessage.content.toLowerCase().indexOf(lowerCaseText) !== -1);
  });
}
