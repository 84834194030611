import { ResourceActions } from './../../../../constants/resource-actions';
import { Environment } from '../../../../../environments/environment-enum';
import { environment } from './../../../../../environments/environment';
export enum ReportName {
  BrandItemLocation = 'BrandItemLocation',
  InventoryStockStatus = 'InventoryStockStatus',
  ChainsAndStoresThatOrderedAnItem = 'ChainsAndStoresThatOrderedAnItem',
  EnterpriseSupplierBrandSalesSummary = 'EnterpriseSupplierBrandSalesSummary',
  KeheFullPodVendor = 'KeheFullPodVendor',
  InboundFillRate = 'InboundFillRate',
  EnterpriseSupplierChainSalesbyBrand = 'EnterpriseSupplierChainSalesbyBrand',
  OpenAPSupplierStatement = 'OpenAPSupplierStatement',
  Item360RecapHd = 'Item360RecapHd',
  InventoryAtRisk = 'InventoryAtRisk',
  BrandSalesByStateDetail = 'BrandSalesByStateDetail',
  AgedInventoryByBrand = 'AgedInventoryByBrand',
  GapVoidSales = 'GapVoidSales',
  ALforESNChainGroup = 'ALforESNChainGroup',
  Kehe30x20 = 'Kehe30x20',
  OrderProjections = 'OrderProjections'
}

const ssrsReportIds: Map<ReportName, Map<Environment, string>> = new Map([
  [ReportName.AgedInventoryByBrand, new Map([
    [Environment.LOCAL, 'NWVTSkFvbkRCYVU9'],
    [Environment.DEV, 'NWVTSkFvbkRCYVU9'],
    [Environment.UAT, 'bWhqMlk1NHptQjg9'],
    [Environment.PROD, 'TGVNNGczMHdRNzA9'],
  ])],
  [ReportName.BrandItemLocation, new Map([
    [Environment.LOCAL, 'N1NoSzlBTzhnMVU9'],
    [Environment.DEV, 'N1NoSzlBTzhnMVU9'],
    [Environment.UAT, 'bVZkRlFwSFYwZGc9'],
    [Environment.PROD, 'blFONkY2QTh6Snc9'],
  ])],
  [ReportName.BrandSalesByStateDetail, new Map([
    [Environment.LOCAL, 'VUJJeVE4cWVLRUE9'],
    [Environment.DEV, 'VUJJeVE4cWVLRUE9'],
    [Environment.UAT, 'M3lTdEJtTEpFcWs9'],
    [Environment.PROD, 'd2l3UkF0eVhDbEU9'],
  ])],
  [ReportName.ChainsAndStoresThatOrderedAnItem, new Map([
    [Environment.LOCAL, 'b0kxZjBndU43MUE9'],
    [Environment.DEV, 'b0kxZjBndU43MUE9'],
    [Environment.UAT, 'c2lDYzNac05oclU9'],
    [Environment.PROD, 'K0g1WXppYkQ5NlU9'],
  ])],
  [ReportName.EnterpriseSupplierBrandSalesSummary, new Map([
    [Environment.LOCAL, 'cHA1UWE2MVpUTzQ9'],
    [Environment.DEV, 'cHA1UWE2MVpUTzQ9'],
    [Environment.UAT, 'RjFLZmM3elFUMFE9'],
    [Environment.PROD, 'K0JYc0k1UEYrbU09'],
  ])],
  [ReportName.EnterpriseSupplierChainSalesbyBrand, new Map([
    [Environment.LOCAL, 'V3U5MmlTaTcxKzA9'],
    [Environment.DEV, 'V3U5MmlTaTcxKzA9'],
    [Environment.UAT, 'eDlqcWd6Q0pUNWs9'],
    [Environment.PROD, 'c3ovbmtjWExVdnM9'],
  ])],
  [ReportName.InboundFillRate, new Map([
    [Environment.LOCAL, 'WnVyb2R0QnZSZkE9'],
    [Environment.DEV, 'WnVyb2R0QnZSZkE9'],
    [Environment.UAT, 'QUtCTXZqL2xyOHc9'],
    [Environment.PROD, 'Z3Z3TUc1dHFVOFE9'],
  ])],
  [ReportName.InventoryAtRisk, new Map([
    [Environment.LOCAL, 'R0UrWmxHVDBMbXc9'],
    [Environment.DEV, 'R0UrWmxHVDBMbXc9'],
    [Environment.UAT, 'eU1KVkQ0NnJwVzQ9'],
    [Environment.PROD, 'ZW5MQWcrdHhnK0E9'],
  ])],
  [ReportName.InventoryStockStatus, new Map([
    [Environment.LOCAL, 'TGcrYXlhYWIvcHc9'],
    [Environment.DEV, 'TGcrYXlhYWIvcHc9'],
    [Environment.UAT, 'R2tSZ1EzY0tETjQ9'],
    [Environment.PROD, 'TmpjWHlWYWxSYzg9'],
  ])],
  [ReportName.Item360RecapHd, new Map([
    [Environment.LOCAL, 'RytTdGVqZjI2Q2M9'],
    [Environment.DEV, 'RytTdGVqZjI2Q2M9'],
    [Environment.UAT, 'NmZqWmVKL2dqVGs9'],
    [Environment.PROD, 'SlNsdTFXaW95QXc9'],
  ])],
  [ReportName.KeheFullPodVendor, new Map([
    [Environment.LOCAL, 'Q0ZPUmdnT3ZFOTA9'],
    [Environment.DEV, 'Q0ZPUmdnT3ZFOTA9'],
    [Environment.UAT, 'KzNuVEtqbDlSTDA9'],
    [Environment.PROD, 'a2x4TkU1M1FlVE09'],
  ])],
  [ReportName.OpenAPSupplierStatement, new Map([
    [Environment.LOCAL, 'RjUzWWQwa2lmVXc9'],
    [Environment.DEV, 'RjUzWWQwa2lmVXc9'],
    [Environment.UAT, 'ell0dGN6WUZGb2s9'],
    [Environment.PROD, 'aVlNRisrNnJjZUE9'],
  ])],
  [ReportName.GapVoidSales, new Map([
    [Environment.LOCAL, 'dEZxYTRsVGtuWGc9'],
    [Environment.DEV, 'dEZxYTRsVGtuWGc9'],
    [Environment.UAT, 'UEVjbllDSTlYWlk9'],
    [Environment.PROD, 'SWlvbHNBL0VPRkU9'],
  ])],
  [ReportName.ALforESNChainGroup, new Map([
    [Environment.LOCAL, 'M3RRL0E1dlFoamM9'],
    [Environment.DEV, 'M3RRL0E1dlFoamM9'],
    [Environment.UAT, 'STFMWElSUXdYOWc9'],
    [Environment.PROD, 'WnVyb2R0QnZSZkE9'],
  ])],
  [ReportName.Kehe30x20, new Map([
    [Environment.LOCAL, 'b2dKV3lWdmExcUE9'], // (235,"KeHE 30x20")
    [Environment.DEV, 'b2dKV3lWdmExcUE9'],
    [Environment.UAT, 'OENhblBvSkk1RWM9'], // (279,"KeHE 30x20")
    [Environment.PROD, 'dEZxYTRsVGtuWGc9'], // (230,"KeHE 30x20")
  ])],
  [ReportName.OrderProjections, new Map([
    [Environment.LOCAL, 'V0RxblYyaWdTZ0E9'],
    [Environment.DEV, 'V0RxblYyaWdTZ0E9'],
    [Environment.UAT, 'ZFNXTlV4eEJVZU09'],
    [Environment.PROD, 'NGNnSTNWSVkybGs9'],
  ])],
]);

export interface ReportMapping {
  type: ReportName;
  displayName: string;
  url: string;
  // The user must have this resource action in order for the report
  // to appear in the dropdown on the "Reporting" SSRS report page
  requiredResourceAction: ResourceActions;
}

export const reportMappings: ReportMapping[] = [
  {
    type: ReportName.AgedInventoryByBrand,
    displayName: 'Aged Inventory by Brand',
    url: ssrsReportIds.get(ReportName.AgedInventoryByBrand).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.BrandItemLocation,
    displayName: 'Brand Item Location',
    url: ssrsReportIds.get(ReportName.BrandItemLocation).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.InventoryStockStatus,
    displayName: 'Inventory Stock Status',
    url: ssrsReportIds.get(ReportName.InventoryStockStatus).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.ChainsAndStoresThatOrderedAnItem,
    displayName: 'Chains and Stores that ordered an Item',
    url: ssrsReportIds.get(ReportName.ChainsAndStoresThatOrderedAnItem).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.EnterpriseSupplierBrandSalesSummary,
    displayName: 'Enterprise Supplier Brand Sales Summary',
    url: ssrsReportIds.get(ReportName.EnterpriseSupplierBrandSalesSummary).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.KeheFullPodVendor,
    displayName: 'KeHE Full POD Vendor',
    url: ssrsReportIds.get(ReportName.KeheFullPodVendor).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.InboundFillRate,
    displayName: 'Inbound Fill Rate',
    url: ssrsReportIds.get(ReportName.InboundFillRate).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.Item360RecapHd,
    displayName: 'Item 360 Recap HD',
    url: ssrsReportIds.get(ReportName.Item360RecapHd).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.EnterpriseSupplierChainSalesbyBrand,
    displayName: 'Enterprise Supplier Chain Sales by Brand',
    url: ssrsReportIds.get(ReportName.EnterpriseSupplierChainSalesbyBrand).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.OpenAPSupplierStatement,
    displayName: 'Open AP Supplier Statement',
    url: ssrsReportIds.get(ReportName.OpenAPSupplierStatement).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsBaseRead
  },
  {
    type: ReportName.InventoryAtRisk,
    displayName: 'Inventory at Risk',
    url: ssrsReportIds.get(ReportName.InventoryAtRisk).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.BrandSalesByStateDetail,
    displayName: 'Enterprise Supplier Brand Channel Sales by State Detail',
    url: ssrsReportIds.get(ReportName.BrandSalesByStateDetail).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.GapVoidSales,
    displayName: 'Gap Void Sales',
    url: ssrsReportIds.get(ReportName.GapVoidSales).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.ALforESNChainGroup,
    displayName: 'AL for ESN Chain Group',
    url: ssrsReportIds.get(ReportName.ALforESNChainGroup).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.Kehe30x20,
    displayName: 'KeHE 30x20',
    url: ssrsReportIds.get(ReportName.Kehe30x20).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
  {
    type: ReportName.OrderProjections,
    displayName: 'Order Projections',
    url: ssrsReportIds.get(ReportName.OrderProjections).get(environment.current),
    requiredResourceAction: ResourceActions.SSRSReportsEnhancedRead
  },
// Sort in-place alphabetically by displayName
].sort((a, b) => a.displayName.localeCompare(b.displayName));

export namespace ReportName {
  export function toString(reportName: ReportName): string {
    const matchingItems = reportMappings.filter(report => report.type === reportName);

    if (matchingItems.length > 0) {
      return matchingItems[0].displayName;
    }
  }

  export function parse(name: string): ReportName {
    const matchingItems = reportMappings.filter(report => report.displayName === name);

    if (matchingItems.length > 0) {
      return matchingItems[0].type;
    }
  }
}
