import { createSelector } from "@ngrx/store";
import { State } from "../index";
import * as fromBreadCrumbs from './breadcrumbs.reducer';

const selectBreadCrumbsState = (state: State) => state.breadCrumbs;
const getBreadCrumbs = createSelector(
    selectBreadCrumbsState,
    fromBreadCrumbs.getBreadCrumbs,
);

export const selectKendoBreadCrumbs = createSelector(
    getBreadCrumbs,
    (breadCrumbs) => breadCrumbs
);
  