import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of, timer } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import * as AuthenticationActions from './authentication.actions';
import * as CallBackPageActions from './components/callback-page/callback-page.actions';
import * as AuthenticationSelectors from '../shared/state/authentication/authentication.selectors';
import * as AppActions from '../app.actions';
import { SupplierService } from "@app/services/supplier.service";
import { AuthenticationState } from "./authentication-state";

@Injectable()
export class AuthenticationEffects {

    constructor(
        private actions$: Actions, 
        private authenticationService: AuthenticationService, 
        private router: Router,
        private store: Store,
        private supplierService: SupplierService,
    ) {}

     /*
        This will dispatch the login success event when the app is loaded and the user has been previously authenticated
     */
    getUser$ = createEffect(() => {
        return this.authenticationService.getUser().pipe(
            filter(user => !!user),
            map(user => AuthenticationActions.logInSuccess({user})),
        )
    });

    redirectUnauthenticatedUserToLogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthenticationActions.userNotAuthenticated,
                AuthenticationActions.handleRedirectError,
            ),
            tap(action => this.authenticationService.login(action.url)),
        )
    }, { dispatch: false });

    handleAuthenticationCallback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CallBackPageActions.entered),
            exhaustMap(() => this.authenticationService.handleRedirect().pipe(
                map(user => AuthenticationActions.logInSuccess({user})),
                catchError(() => of(AuthenticationActions.handleRedirectError({ url: ''})))
            ))
        )}
    );

    handleLoginRedirect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            filter(action => !!action.user.state),
            map(action => action.user.state as AuthenticationState),
            tap(state => this.router.navigateByUrl(state.redirectUrl)), 

        )
    }, { dispatch: false });

    getConnectLegacyTokenFromCookie$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            map(() => {
                const cookieName = 'secToken';
                const cookies = document.cookie.split(';');
                const cookieAsString = cookies.find(cookie => {
                  return cookie.trim().startsWith(cookieName);
                });
                const secToken = !cookieAsString ? '' : cookieAsString.substring(cookieAsString.indexOf('=') + 1, cookieAsString.length);
                return AuthenticationActions.getLegacyTokenSuccess({ legacyToken: secToken }); // to test locally, paste v1 token instead of secToken
            }),
        )
    });

    logOut$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthenticationActions.logOut,
                AppActions.logOut,
                ),
            tap(() => this.authenticationService.logout())
        )
    }, { dispatch: false });

    logOutUserOnTokenExpiration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            withLatestFrom(this.store.select(AuthenticationSelectors.selectTokenExpiration)),
            filter(([, tokenExpiration]) => !!tokenExpiration),
            switchMap(([, tokenExpiration]) => {
                return timer(tokenExpiration).pipe(
                    map(() => AuthenticationActions.logOut())
                );
            }),
        )
    });

    getUserDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActions.logInSuccess),
            exhaustMap(() => this.supplierService.getUserDetails().pipe(
                map(user => AuthenticationActions.getUserDetailSuccess({ user })),
            )),
            catchError(() => of(AuthenticationActions.getUserDetailFailed()))
          )
    }
  );

}