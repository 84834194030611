import { Component, Input } from '@angular/core';
import { UserNotificationsFacade } from '../../store/user-notifications.facade';

@Component({
  selector: 'app-clear-notifications-modal',
  templateUrl: './clear-notifications-modal.component.html',
  styleUrls: ['./clear-notifications-modal.component.scss']
})
export class ClearNotificationsModalComponent {
  @Input()
  showModal: boolean;

  @Input()
  notificationsCount: number;

  constructor(
    private userNotificationsFacade: UserNotificationsFacade,
  ) {}

  handleKeheModalEvents(event) {
    switch (event.type.toLowerCase()) {
      case 'close':
        this.dismissModal();
        break;
    }
  }

  confirmClearAll() {
    this.userNotificationsFacade.clearAllUserNotifications();
    this.dismissModal();
  }

  dismissModal() {
    this.userNotificationsFacade.toggleClearNotificationsModal();
  }

}
