import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './authentication.effects';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { CallbackPageComponent } from './components/callback-page/callback-page.component';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';

@NgModule({
  declarations: [CallbackPageComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([AuthenticationEffects]),
    PhoenixIndicatorsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
  ]
})
export class AuthenticationModule { }
