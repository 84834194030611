import { Action, createReducer, on } from '@ngrx/store';
import {
  initIsLoading,
  userAgreementInitialState,
  UserAgreementRequestType,
  UserAgreementState,
  UserAgreementStatus
} from './user-agreement.state';
import {
  loadPrivacyStatementPageAction,
  loadPrivacyStatementPageSuccessAction,
  loadTermsAndConditionsPageAction,
  loadTermsAndConditionsPageSuccessAction,
  loadUserAgreementStatusAction,
  loadUserAgreementStatusSuccessAction,
  setUserAgreementAction,
  setUserAgreementSuccessAction,
  userAgreementErrorAction
} from './user-agreement.actions';
import { SafeHtml } from '@angular/platform-browser';
import { Payload } from '../../../models/payload';
import { AlertTypes } from '@kehe/phoenix-notifications';

const reducer = createReducer(userAgreementInitialState,
  on(loadUserAgreementStatusAction,
    state => loading(state, UserAgreementRequestType.Read)
  ),
  on(loadUserAgreementStatusSuccessAction,
    (state, { response }) => loadUserAgreementStatusSuccess(state, response)
  ),
  on(setUserAgreementAction,
    state => loading(state, UserAgreementRequestType.Write)
  ),
  on(setUserAgreementSuccessAction,
    state => setUserAgreementSuccess(state)
  ),
  on(loadTermsAndConditionsPageAction,
    state => loading(state, UserAgreementRequestType.Asset)
  ),
  on(loadTermsAndConditionsPageSuccessAction,
    (state, { response }) => loadTermsAndConditionsPageSuccess(state, response)
  ),
  on(loadPrivacyStatementPageAction,
    state => loading(state, UserAgreementRequestType.Asset)
  ),
  on(loadPrivacyStatementPageSuccessAction,
    (state, { response }) => loadPrivacyStatementPageSuccess(state, response)
  ),
  on(userAgreementErrorAction,
    state => userAgreementError(state)
  )
);

export function userAgreementReducer(state: UserAgreementState | undefined, action: Action) {
  return reducer(state, action);
}

function loading(state: UserAgreementState, requestType: UserAgreementRequestType): UserAgreementState {
  const loadingState: { [key in UserAgreementRequestType] } = { ...state.isLoading };
  loadingState[requestType] = true;
  return {
    ...state,
    isLoading: loadingState
  };
}

function loadUserAgreementStatusSuccess(state: UserAgreementState, response: Payload<boolean>): UserAgreementState {
  const loadingState: { [key in UserAgreementRequestType] } = { ...state.isLoading };
  loadingState[UserAgreementRequestType.Read] = false;
  return {
    ...state,
    userAgreementStatus: response.data ? UserAgreementStatus.UserHasAgreed : UserAgreementStatus.UserHasNotAgreed,
    isLoading: loadingState
  };
}

function setUserAgreementSuccess(state: UserAgreementState): UserAgreementState {
  const loadingState: { [key in UserAgreementRequestType] } = { ...state.isLoading };
  loadingState[UserAgreementRequestType.Write] = false;
  return {
    ...state,
    userAgreementStatus: UserAgreementStatus.UserHasAgreed,
    isLoading: loadingState
  };
}

function loadTermsAndConditionsPageSuccess(state: UserAgreementState, response: SafeHtml): UserAgreementState {
  const loadingState: { [key in UserAgreementRequestType] } = { ...state.isLoading };
  loadingState[UserAgreementRequestType.Asset] = false;
  return {
    ...state,
    termsAndConditionsPage: response,
    isLoading: loadingState
  };
}

function loadPrivacyStatementPageSuccess(state: UserAgreementState, response: SafeHtml): UserAgreementState {
  const loadingState: { [key in UserAgreementRequestType] } = { ...state.isLoading };
  loadingState[UserAgreementRequestType.Asset] = false;
  return {
    ...state,
    privacyStatementPage: response,
    isLoading: loadingState
  };
}

function userAgreementError(state: UserAgreementState): UserAgreementState {
  // Bypassing User Agreement if error from api - See SK-168 in Jira
  return {
    ...state,
    userAgreementStatus: UserAgreementStatus.UserHasAgreed,
    isLoading: initIsLoading(),
    shouldDisplayAlert: false,
    alert: {
      type: AlertTypes.Error,
      message: 'An error occurred'
    }
  };
}
