import { infoModalDismissed } from './../../store/data-exchange.actions';
import { KeheModalEventsTypes } from '@kehe/phoenix-modal';
import { selectShowInfoModal, selectInfoModalItem } from './../../store/data-exchange.selectors';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DataSubscription } from '../../models/data-subscription';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-subscription-info-modal',
  templateUrl: './subscription-info-modal.component.html',
  styleUrls: ['./subscription-info-modal.component.scss']
})
export class SubscriptionInfoModalComponent implements OnInit {

  showModal$: Observable<boolean>;
  subscription$: Observable<DataSubscription>;

  constructor(
    private _store: Store
  ) { }

  ngOnInit() {
    this.showModal$ = this._store.pipe(select(selectShowInfoModal));
    this.subscription$ = this._store.pipe(select(selectInfoModalItem));
  }

  onOkayClicked() {
    this._store.dispatch(infoModalDismissed());
  }

  handleModalEvent(event) {
    switch (event.type) {
      case KeheModalEventsTypes.Close:
        this._store.dispatch(infoModalDismissed());
        break;
      default:
    }
  }

}
