import { createSelector } from "@ngrx/store";
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import { EventTypeIds } from '@app/models/event-types';

export const selectEventsBannerDescription = createSelector(
    ShowSelectors.selectSortedEligibleShows,
    (shows) => {
      const amountOfShows = shows.filter(
          (show) =>
              show.eventTypeId === EventTypeIds.PrimaryShow ||
              show.eventTypeId === EventTypeIds.SecondaryShow
      ).length;
      const amountOfEvents = shows.filter((show) => show.eventTypeId === EventTypeIds.Event).length;
  
      const showOrShows = amountOfShows === 1 ? 'Show' : 'Shows';
      const eventOrEvents = amountOfEvents === 1 ? 'Event' : 'Events';
  
      let combinedString = '';
  
      if (amountOfShows && amountOfEvents) {
        combinedString = `${amountOfShows} active ${showOrShows} and ${amountOfEvents} ${eventOrEvents}`;
      } else if (amountOfShows && !amountOfEvents) {
        combinedString = `${amountOfShows} active ${showOrShows}`;
      } else if (!amountOfShows && amountOfEvents) {
        combinedString = `${amountOfEvents} active ${eventOrEvents}`;
      }
  
      return `You are currently enrolled in ${combinedString}.`;
    }
  )