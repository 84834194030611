import { faqs } from './faq';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Faq } from '../models/faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  public getFaqs(): Observable<Faq[]> {
    return of(faqs);
  }

}
